import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

//Service
import api from "../../../../Services/api";

//Toast
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Components
import Loading from "../../../../Components/Loading/index";
import Sidebar from "../../../../Components/SidebarAdmin";
import { InputWrap } from "../../Manager/Management/Legislation/AddLei/style";
//import SidebarOptions from "../../../../Components/SidebarOptions";
import ArrowBack from "../../../../Components/ResponsiveArrowBack";
//Icons
import SearchIcon from "@material-ui/icons/Search";
import "./index.css";
import { CardDiv, DivButtonsCard } from "../Empresas/styles";
import { DivText } from "../../../../Components/NewComponents/NewSideBar/styles";
import ModalDeleteEmpresa from "../Empresas/modalDeleteEmpresa";
import { MdOutlineModeEditOutline } from "react-icons/md";
import CardInfo from "../../../../Components/NewComponents/Text";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

export default function CondominiumListPage() {
  const notifyDelete = () => toast.error("Síndico Reprovado!");
  const notifyApprove = () => toast.success("Síndico Aprovado!");
  const [procurar, setProcurar] = useState("");
  const [managers, setManagers] = useState([]);
  const [filter, setFilter] = useState("name");
  const [company, setCompany] = useState([]);
  const history = useHistory();

  useEffect(() => {
    getData();
    return () => {
      setManagers([]);
    };
  }, []);

  async function getData() {
    try {
      const response = await api.get("/resident/request");
      console.log(response.data, "teste");
      setManagers(response.data);
    } catch (error) {
      console.log("erro1", error);
    }
  }

  async function DeleteCompany(id) {
    try {
      const response = await api.delete(`/company/${id}`);
    } catch (error) {
      console.log(error);
    }
  }

  async function PatchCompany(id) {
    try {
      console.log(id);
      const data = {
        active: "true",
      };
      const formData = new FormData();
      formData.append("data", JSON.stringify(data));

      const response = await api.patch(`/company/${id}`, formData);
      getDataCompany();
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function getDataCompany() {
    try {
      const response = await api.get("/company/inactive");
      setCompany(response.data);
      console.log(response.data, "GET");
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getDataCompany();
  }, []);

  async function deleteManager(id) {
    try {
      await api.delete(`/resident/request/${id}`);
      setManagers(managers.filter((manager) => manager.id !== id));

      notifyDelete();
    } catch (error) {
      console.log("erro3", error);
    }
  }

  async function authorizeManager(id) {
    try {
      const approval = {
        active: "true",
      };
      await api.patch(`/resident/request/${id}`, approval);
      setManagers(managers.filter((manager) => manager.id !== id));
      notifyApprove();
    } catch (error) {
      console.log("erro4", error);
    }
  }

  return (
    <div id="homeContent" style={{ marginLeft: "10vw" }}>
      <Sidebar />
      <div>
        <div className="pageTitleContainer" id="x">
          <ArrowBack />

          <p id="pageTitle">Pedidos de Cadastro</p>
        </div>

        <div id="listArea">
          <div style={{ display: "flex" }}>
            {filter ? (
              <div id="searchContainer">
                <SearchIcon />
                <input
                  id="searchInput"
                  placeholder="Pesquisar"
                  onChange={(event) => {
                    setProcurar(event.target.value);
                  }}
                />
              </div>
            ) : null}
            <InputWrap
              style={{
                marginBottom: "32px",
                marginLeft: "10px",
                width: "200px",
              }}
            >
              <select
                style={{
                  border: "none",
                  borderLeft: "4px solid #00334E",
                  borderRadius: "20px",
                  minWidth: "150px",
                  backgroundColor: "#f8f8f8",
                  padding: "4px 12px",
                }}
                placeholder="None"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              >
                <option value="name">Nome</option>
                <option value="email">Email</option>
                <option value="usuaryType">Tipo de Usuário</option>
              </select>
            </InputWrap>
          </div>

          {managers
            .filter((manager) => {
              if (procurar == "") {
                return manager;
              } else if (
                manager?.name.toLowerCase().includes(procurar.toLowerCase()) &&
                filter === "name"
              ) {
                return manager;
              } else if (
                manager?.email
                  ? manager?.email
                      .toLowerCase()
                      .includes(procurar.toLowerCase()) && filter === "email"
                  : null
              ) {
                return manager;
              } else if (
                manager?.email
                  ? manager?.email
                      .toLowerCase()
                      .includes(procurar.toLowerCase()) &&
                    filter === "usuaryType"
                  : null
              ) {
                return manager;
              }
            })
            .map((manager) => (
              <div className="data-container">
                <div id="data-safe-area">
                  <div className="manager-name-title">
                    Nome: {manager?.name}
                  </div>
                  <div className="manager-name-title">
                    Email: {manager?.email}
                  </div>
                </div>
                <div className="WarnDate"></div>
                <div id="ButtonContainer">
                  <button
                    id="EditButton"
                    onClick={() => {
                      authorizeManager(manager?.id);
                    }}
                  >
                    <CheckIcon></CheckIcon>
                  </button>
                  <button
                    id="deleteButtonWarnings"
                    onClick={() => {
                      deleteManager(manager?.id);
                    }}
                  >
                    <CloseIcon></CloseIcon>
                  </button>
                </div>
              </div>
            ))}
          {company.map((item, index) => (
            <CardDiv key={index}>
              <DivText>
                <CardInfo name={"Razão Social: "} info={item.name}></CardInfo>
                <CardInfo name={"Nome: "} info={item.corporate_name}></CardInfo>
                <CardInfo name={"CNPJ: "} info={item.cnpj}></CardInfo>
                <CardInfo
                  name={"Telefone: "}
                  info={item.phone_number}
                ></CardInfo>
                <CardInfo name={"Email: "} info={item.email}></CardInfo>
                <CardInfo name={"Estado: "} info={item.state}></CardInfo>
                <CardInfo
                  name={"Tipo da Empresa: "}
                  info={item.modality}
                ></CardInfo>
                <CardInfo name={"CEP: "} info={item.cep}></CardInfo>
              </DivText>
              <DivButtonsCard>
                <button
                  id="EditButton"
                  onClick={() => {
                    PatchCompany(item.id);
                  }}
                >
                  <CheckIcon></CheckIcon>
                </button>
                <button id="deleteButtonWarnings">
                  <CloseIcon></CloseIcon>
                </button>
              </DivButtonsCard>
            </CardDiv>
          ))}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
