import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import DatePicker from "react-date-picker";

//Service
import api from "../../../../Services/api";

//Toast
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Components
import Loading from "../../../../Components/Loading/index";
import Sidebar from "../../../../Components/SidebarManager";
import { InputWrap } from "../Management/Legislation/AddLei/style";
//import SidebarOptions from "../../../../Components/SidebarOptions";
import ArrowBack from "../../../../Components/ResponsiveArrowBack";
//Icons
import SearchIcon from "@material-ui/icons/Search";
import plus from "../../../../Assets/plus.svg";
import { MdOutlineModeEditOutline } from "react-icons/md";
import "./index.css";
import { Search } from "@material-ui/icons";
import NewSideBar from "../../../../Components/NewComponents/NewSideBar";

export default function Reservations() {
  useEffect(() => {
    getData();
    api.get("/space").then((response) => {
      setSpaces(response.data);
    });
    return () => {
      setReservas([]);
    };
  }, []);

  const getSpaceName = (spaceId) => {
    return spaces.find((space) => space.id === spaceId)?.name || "";
  };

  const [reservas, setReservas] = useState([]);
  const [procurar, setProcurar] = useState("");
  const [data, setData] = useState();
  const [filter, setFilter] = useState("title");
  const [spaces, setSpaces] = useState([]);

  async function getData() {
    try {
      const response = await api.get("/reservation");
      setReservas(response.data);
    } catch (error) {
      throw error;
    }
  }

  function onChangeData(data) {
    setData(data);
    setProcurar("");
  }

  function convertDate(inicialDate) {
    let data = inicialDate;
    let partes = data.split("-");
    let dataConvertida = partes[2] + "/" + partes[1] + "/" + partes[0];
    return dataConvertida;
  }

  function addOneSecond(time) {
    const [hours, minutes, seconds] = time.split(":");
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(parseInt(seconds) + 1);

    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  }

  return (
    <div id="homeContent">
      <NewSideBar rota={"manager"} tipo={"Síndico"}></NewSideBar>
      <div
        id="WarnSafeArea"
        style={{ marginLeft: "56px", width: "100%", overflowY: "auto" }}
      >
        <div
          className="pageTitleContainer"
          id="x"
          style={{ marginLeft: "0px" }}
        >
          <ArrowBack />
          <p id="pageTitle">Reservas</p>
        </div>

        <div id="listArea" style={{ marginLeft: "0px" }}>
          <div id="TopContainer1" style={{ marginLeft: "0px" }}>
            <div id="searchContainer" style={{ marginLeft: "0px" }}>
              {filter === "date" ? (
                <>
                  <SearchIcon style={{ marginRight: "20px" }} />
                  <DatePicker
                    value={data}
                    onChange={(event) => onChangeData(event)}
                  />
                </>
              ) : (
                <>
                  <SearchIcon />
                  <input
                    id="searchInput"
                    placeholder="Pesquisar"
                    onChange={(event) => {
                      setProcurar(event.target.value);
                    }}
                  />
                </>
              )}
            </div>
            <InputWrap
              style={{
                marginBottom: "32px",
                marginLeft: "10px",
                width: "200px",
              }}
            >
              <select
                style={{
                  border: "none",
                  borderLeft: "4px solid #00334E",
                  borderRadius: "20px",
                  minWidth: "150px",
                  backgroundColor: "#f8f8f8",
                  padding: "4px 12px",
                }}
                placeholder="None"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              >
                <option value="title">Nome do Usuário</option>
                <option value="date">Data</option>
              </select>
            </InputWrap>
          </div>
          <div>
            {reservas
              .filter((space) => {
                if (procurar === "" && data === "") {
                  return space;
                } else if (
                  space?.name.toLowerCase().includes(procurar.toLowerCase()) &&
                  filter === "title"
                ) {
                  return space;
                } else if (
                  convertDate(space?.date).includes(
                    data
                      ? data.toLocaleDateString("pt-BR", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        })
                      : ""
                  ) &&
                  filter === "date"
                ) {
                  return space;
                }
              })
              .map((space) => (
                <div className="data-container" key={space?.id}>
                  <div id="data-safe-area">
                    <div className="WarnTitle">
                      {getSpaceName(space?.space_id)}
                    </div>
                    <div
                      className="manager-name-title"
                      style={{ marginTop: "5px" }}
                    >
                      Responsável: {space?.name}
                    </div>
                    <div className="manager-name-title">
                      Complemento:{" "}
                      {(space?.complement)
                        .replace(/:/g, " ")
                        .replace(/  +/g, " ")}
                    </div>
                    <div className="manager-name-title">
                      Inicio: {space?.start.slice(0, 5)}
                    </div>
                    <div className="manager-name-title">
                      Duração: {addOneSecond(space?.duration)}
                    </div>
                    <div className="manager-name-title"></div>
                  </div>
                  <div className="WarnDate" style={{ marginBottom: "10px" }}>
                    {convertDate(space?.date)}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
