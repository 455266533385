import React from "react";
import { Formik, Field, Form } from "formik";
import { Dialog, DialogTitle, Typography, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const MailConfirmModal = ({
  open,
  handleClose,
  mail_id,
  confirmMailReceipt,
}) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Typography variant="h6" align="center">
          Retirada de Encomenda
        </Typography>
        <IconButton
          style={{
            position: "absolute",
            right: "10px",
            top: "10px",
            zIndex: 1,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <div
        style={{
          width: "25vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Formik
          initialValues={{ code: "" }}
          onSubmit={(values, actions) => {
            if (values.code.charAt(0) !== "#") {
              values.code = "#" + values.code.toUpperCase();
            }
            confirmMailReceipt(mail_id, values);
          }}
        >
          {({ isSubmitting }) => (
            <div
              style={{
                width: "80%",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "2vh",
                paddingBottom: "4vh",
              }}
            >
              <Form>
                <Field
                  type="text"
                  name="code"
                  className="inputField"
                  placeholder="Código de retirada"
                />

                <button type="submit" className="confirmButton">
                  <span>
                    <b>Confirmar</b>
                  </span>
                </button>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </Dialog>
  );
};

export default MailConfirmModal;
