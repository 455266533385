import React, { useState, useEffect } from "react";

import Select from "react-select";

import { Grid } from "@material-ui/core";

import ArrowBack from "../../../../../Components/ResponsiveArrowBack";

import SidebarDoorman from "../../../../../Components/SideBarDoorman";

import {
  Container,
  ContainerButton,
  ContainerLoading,
  ContainerTeam,
  SendVisitor,
  TextInput,
  TextVisitor,
} from "./styles";

import api from "../../../../../Services/api";

import { Oval } from "react-loader-spinner";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function CreateServiceOcurrence() {
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [residents, setResidents] = useState([]);
  const [chooseResident, setChooseResident] = useState([]);
  const [text, setText] = useState("");

  const notifyDelete = () =>
    toast.error("Algo aconteceu de errado. Tente novamente mais tarde!");
  const notifySucess = () => toast.success("Passagem de serviço adicionada!");
  const notifyError = () => toast.error("Preencha todos os campos!");
  const notifyHistory = () =>
    toast.error("Erro ao buscar lista de porteiros, faça login novamente!");

  async function handleSubmit() {
    if (text.length === 0 || chooseResident.length === 0) {
      notifyError();
      return;
    }
    setLoading(true);

    let team = [];
    chooseResident.map((value) => team.push({ id: `${value.value}` }));

    const data = {
      text,
      team,
    };

    try {
      await api.post("/record", data);
      notifySucess();
      setText("");
      setChooseResident([]);
      setLoading(false);
    } catch (err) {
      console.log(err);
      notifyDelete();
      setLoading(false);
    }
  }

  async function getData() {
    setLoadingPage(true);
    try {
      const residentsList = await api
        .get("/employee/doorman")
        .then((res) => res.data);
      const newResidentsList = residentsList.map((item) => ({
        value: item.id,
        label: `${item.name} - ${item.email}`,
      }));
      setResidents(newResidentsList);
      setLoadingPage(false);
    } catch (error) {
      setLoadingPage(false);
      notifyHistory();
      throw error;
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Grid container>
        <SidebarDoorman />
        <Grid
          container
          item
          xs={8}
          style={{ marginBottom: "40px", marginLeft: "33vw" }}
        >
          <ContainerButton>
            <ArrowBack />

            <p id="pageTitle">Adicionar Passagem de Serviço</p>
          </ContainerButton>
          <Container>
            <ContainerTeam>
              {loadingPage ? (
                <ContainerLoading>
                  <Oval
                    ariaLabel="loading-indicator"
                    height={80}
                    width={80}
                    strokeWidth={5}
                    strokeWidthSecondary={1}
                    color="#1D6995"
                    secondaryColor="white"
                  />
                </ContainerLoading>
              ) : (
                <>
                  <TextVisitor>
                    Equipe que vai participar do turno:{" "}
                  </TextVisitor>
                  <Select
                    options={residents}
                    isClearable={true}
                    isMulti
                    value={chooseResident}
                    onChange={(option) => {
                      setChooseResident(option);
                    }}
                  />
                  <TextInput
                    placeholder="Digite suas anotações aqui..."
                    onChange={(e) => setText(e.target.value)}
                  />
                  <SendVisitor
                    onClick={() => handleSubmit()}
                    disabled={loading ? "none" : "auto"}
                  >
                    {loading ? (
                      <Oval
                        ariaLabel="loading-indicator"
                        height={30}
                        width={30}
                        strokeWidth={5}
                        strokeWidthSecondary={1}
                        color="#1D6995"
                        secondaryColor="white"
                      />
                    ) : (
                      "Enviar"
                    )}
                  </SendVisitor>
                </>
              )}
            </ContainerTeam>
          </Container>
        </Grid>
      </Grid>
    </>
  );
}
