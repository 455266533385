import React, { useEffect, useState } from "react";
import {
  ButtonDelete,
  CarContainer,
  Container,
  DivButtonsCard,
  HeaderDiv,
  NewPage,
} from "../../Empresas/styles";
import NewSideBar from "../../../../../Components/NewComponents/NewSideBar";
import NewHeader from "../../../../../Components/NewComponents/Header";
import { InputDiv, NewInput } from "../CreateService/styles";
import api from "../../../../../Services/api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { MdOutlineModeEditOutline } from "react-icons/md";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";

const Button = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: 1px solid black;
  color: #000;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${(props) => props.color};
  }
`;

function CreateEspecialistas() {
  const [data, setData] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [editingTag, setEditingTag] = useState(null);
  const [tags, setTags] = useState([{ name: "Carregando..." }]);
  const history = useHistory();

  const Modal = ({ tag }) => {
    const [newName, setNewName] = useState(tag.name);
    return (
      <div
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0,0,0,0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: "100",
        }}
      >
        <div
          style={{
            padding: "5em",
            backgroundColor: "#fff",
            borderRadius: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1 style={{ marginBottom: "1.5em" }}>
            Insira o novo nome da especialidade
          </h1>
          <NewInput
            value={newName}
            onChange={(event) => setNewName(event.target.value)}
          ></NewInput>
          <button
            className="handleButton"
            style={{
              width: "25vw",
            }}
            onClick={() => {
              editTag(newName, tag.id);
              setShowModal(false);
            }}
          >
            <p>Editar</p>
          </button>
        </div>
      </div>
    );
  };

  const getTags = async () => {
    setTags([{ name: "Carregando..." }]);
    try {
      const response = await api.get("/tag");
      setTags(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteTag = async (id) => {
    try {
      const response = await api.delete(`/tag/${id}`);
      toast.success("Especialidade deletada com sucesso!");
    } catch (error) {
      console.log(error);
      toast.error("Erro ao deletar especialidade!");
    } finally {
      getTags();
    }
  };

  const editTag = async (newName, id) => {
    try {
      const obj = { name: newName };
      const response = await api.patch(`/tag/${id}`, obj);
      toast.success("Especialidade editada com sucesso!");
    } catch (error) {
      console.log(error);
      toast.error("Erro ao editar especialidade!");
    } finally {
      getTags();
    }
  };

  const postData = async () => {
    try {
      const obj = { name: data };

      const response = await toast.promise(api.post("/tag", obj), {
        pending: "Enviando...",
        success: "Especialidade criada com sucesso!",
        error: {
          render({ data }) {
            return `Erro ao criar a nova especialidade! {${data?.response?.data?.msg}}`;
          },
        },
      });
      /* history.push("/admin/servicos"); */
    } catch (error) {
      console.log(error);
    } finally {
      getTags();
    }
  };

  useEffect(() => {
    getTags();
  }, []);

  return (
    <NewPage>
      <NewSideBar tipo={"Administrador"} rota={"admin"}></NewSideBar>
      <Container>
        <HeaderDiv>
          <NewHeader name={"Criar Especialistas"}></NewHeader>
        </HeaderDiv>
        <CarContainer>
          <InputDiv onChange={(event) => setData(event.target.value)}>
            <h1>Nome da Especialidade</h1>
            <NewInput></NewInput>
            <button
              className="handleButton"
              style={{ marginBotton: "5vh", width: "25vw" }}
              onClick={() => {
                postData();
              }}
            >
              <p>Criar</p>
            </button>
          </InputDiv>
        </CarContainer>
        <CarContainer>
          <h1>Especialidades existentes</h1>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingRight: "1%",
              maxHeight: "35vh",
              overflow: "auto",
              gap: "10px",
            }}
          >
            {tags.map((tag) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#efefef",
                    padding: "5%",
                    borderRadius: "20px",
                  }}
                >
                  <h3>{tag.name}</h3>
                  <DivButtonsCard>
                    <Button
                      color="rgb(255, 64, 64)"
                      onClick={() => deleteTag(tag.id)}
                    >
                      <CloseIcon></CloseIcon>
                    </Button>

                    <Button
                      color="#1d6995"
                      onClick={() => {
                        setShowModal(true);
                        setEditingTag(tag);
                      }}
                    >
                      <MdOutlineModeEditOutline />
                    </Button>
                  </DivButtonsCard>
                </div>
              );
            })}
          </div>
        </CarContainer>
      </Container>
      {showModal && <Modal tag={editingTag}></Modal>}
    </NewPage>
  );
}

export default CreateEspecialistas;

/*
value={name} onChange={(e) => setName(e.target.value)}
onClick={handleSubmitNew}
*/
