import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import * as yup from "yup";

// Service
import api from "../../../../Services/api";

// Toast
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Components
import Loading from "../../../../Components/Loading/index";
import Sidebar from "../../../../Components/SidebarManager";
import ArrowBack from "../../../../Components/ResponsiveArrowBack";

// Style
import "./index.css";
import { Grid } from "@material-ui/core";

const initialForm = {
  name: "",
  minimum_time: "",
  capacity: "",
  price: "",
  max_minutes_duration: "",
  min_minutes_interval: "",
  maximum_days: "",
  cancelation_days: "",
};

export default function CreateSpacePage() {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(initialForm);

  const { id } = useParams();

  const notifySucess = () =>
    toast.success(`Cadastro ${id ? "alterado" : "realizado"} com sucesso!`);
  const notifyError = () => toast.error("Erro ao cadastrar espaço!");
  const notifyFetchError = () =>
    toast.error(
      "Desculpe, houve uma falha ao obter dados para edição. Tente novamente mais tarde"
    );
  const requiredFieldMessage = "Este campo deve ser preenchido";

  const spaceValidationSchema = yup.object().shape({
    name: yup.string().required(requiredFieldMessage),
    minimum_time: yup.string().required(requiredFieldMessage),
    capacity: yup.string().required(requiredFieldMessage),
    price: yup.string().required(requiredFieldMessage),
    max_minutes_duration: yup.string().required(requiredFieldMessage),
    min_minutes_interval: yup.string().required(requiredFieldMessage),
    maximum_days: yup.string().required(requiredFieldMessage),
    cancelation_days: yup.string().required(requiredFieldMessage),
  });

  useEffect(() => {
    const retrieveData = async () => {
      if (id) {
        try {
          const data = (await api.get(`/space/${id}`)).data;
          setForm(data);
        } catch (ex) {
          notifyFetchError();
          history.goBack();
          throw ex;
        }
      }
    };
    retrieveData();
  }, [id, history]);

  const createSpace = async (values) => {
    setLoading(true);
    const data = {
      min_minutes_interval: values.min_minutes_interval.toString(),
      max_minutes_duration: values.max_minutes_duration.toString(),
      capacity: values.capacity.toString(),
      name: values.name.toString(),
      maximum_days: values.maximum_days.toString(),
      minimum_time: values.minimum_time.toString(),
      price: values.price.toString(),
      cancelation_days: values.cancelation_days.toString(),
      percentage: values.percentage.toString(),
    };
    try {
      if (!id) await api.post("/space", data);
      else await api.patch(`/space/${id}`, data);
      notifySucess();
      history.goBack();
    } catch (e) {
      notifyError();
    }
    setLoading(false);
  };

  return (
    <Grid container>
      {/*<Grid item xs={3}>
      </Grid>*/}
      <Sidebar />
      <Grid
        container
        item
        xs={8}
        style={{ marginBottom: "40px", marginLeft: "40vw" }}
      >
        <Grid item xs={12}>
          <div className="spacePageTitleContainer">
            <ArrowBack />
            <p className="pageTitle">{`${
              id ? "Editar" : "Cadastrar novo"
            } espaço`}</p>
          </div>
        </Grid>
        <Grid container spacing={2} justify="space-between">
          <Formik
            initialValues={form}
            onSubmit={createSpace}
            validationSchema={spaceValidationSchema}
            enableReinitialize={true}
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              handleChange,
              handleBlur,
            }) => (
              <Form handleSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <div className="formikFieldArea">
                      <label className="formikFieldLabel" htmlFor="name">
                        Nome do espaço
                      </label>
                      {touched.name && errors.name && (
                        <label className="formikErrorMessage">
                          {errors.name}
                        </label>
                      )}
                      <input
                        id="name"
                        name="name"
                        className="formikFieldText"
                        value={values.name}
                        onChange={handleChange("name")}
                        placeholder="Nome"
                        onBlur={handleBlur("name")}
                      />
                    </div>
                  </Grid>
                  <Grid item container xs={5}>
                    <Grid item xs={12}>
                      <div className="formikFieldArea">
                        <label
                          className="formikFieldLabel"
                          htmlFor="minimum_time"
                        >
                          Horário de entrada
                        </label>
                        {touched.minimum_time && errors.minimum_time && (
                          <label className="formikErrorMessage">
                            {errors.minimum_time}
                          </label>
                        )}
                        <input
                          id="minimum_time"
                          name="minimum_time"
                          className="formikFieldText"
                          type="time"
                          value={values.minimum_time}
                          onChange={handleChange("minimum_time")}
                          placeholder="00:00"
                          onBlur={handleBlur("minimum_time")}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item container xs={5}>
                    <Grid item xs={12}>
                      <div className="formikFieldArea">
                        <label
                          className="formikFieldLabel"
                          htmlFor="Percentual de Multa"
                        >
                          Percentual de Multa (%)
                        </label>
                        {touched.percentage && errors.percentage && (
                          <label className="formikErrorMessage">
                            {errors.percentage}
                          </label>
                        )}
                        <input
                          id="percentage"
                          name="percentage"
                          className="formikFieldText"
                          type="number"
                          value={values.percentage}
                          onChange={handleChange("percentage")}
                          placeholder="%"
                          onBlur={handleBlur("percentage")}
                          min={0}
                          max={100}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <div className="formikFieldArea">
                      <label className="formikFieldLabel" htmlFor="capacity">
                        Capacidade máxima
                      </label>
                      {touched.capacity && errors.capacity && (
                        <label className="formikErrorMessage">
                          {errors.capacity}
                        </label>
                      )}
                      <input
                        id="capacity"
                        name="capacity"
                        className="formikFieldText"
                        type="number"
                        value={values.capacity}
                        onChange={handleChange("capacity")}
                        placeholder="000"
                        onBlur={handleBlur("capacity")}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={5}>
                    <div className="formikFieldArea">
                      <label
                        className="formikFieldLabel"
                        htmlFor="max_minutes_duration"
                      >
                        Duração máxima (horas)
                      </label>
                      {touched.max_minutes_duration &&
                        errors.max_minutes_duration && (
                          <label className="formikErrorMessage">
                            {errors.max_minutes_duration}
                          </label>
                        )}
                      <input
                        id="max_minutes_duration"
                        name="max_minutes_duration"
                        className="formikFieldText"
                        value={values.max_minutes_duration}
                        type="number"
                        onChange={handleChange("max_minutes_duration")}
                        placeholder="000"
                        onBlur={handleBlur("max_minutes_duration")}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={5}>
                    <div className="formikFieldArea">
                      <label
                        className="formikFieldLabel"
                        htmlFor="min_minutes_interval"
                      >
                        Intervalo mínimo entre reservas (horas)
                      </label>
                      {touched.min_minutes_interval &&
                        errors.min_minutes_interval && (
                          <label className="formikErrorMessage">
                            {errors.min_minutes_interval}
                          </label>
                        )}
                      <input
                        id="min_minutes_interval"
                        name="min_minutes_interval"
                        className="formikFieldText"
                        value={values.min_minutes_interval}
                        type="number"
                        onChange={handleChange("min_minutes_interval")}
                        placeholder="000"
                        onBlur={handleBlur("min_minutes_interval")}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={5}>
                    <div className="formikFieldArea">
                      <label
                        className="formikFieldLabel"
                        htmlFor="maximum_days"
                      >
                        Antecedência máxima (dias)
                      </label>
                      {touched.maximum_days && errors.maximum_days && (
                        <label className="formikErrorMessage">
                          {errors.maximum_days}
                        </label>
                      )}
                      <input
                        id="maximum_days"
                        name="maximum_days"
                        className="formikFieldText"
                        value={values.maximum_days}
                        type="number"
                        onChange={handleChange("maximum_days")}
                        placeholder="00"
                        onBlur={handleBlur("maximum_days")}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={5}>
                    <div className="formikFieldArea">
                      <label className="formikFieldLabel" htmlFor="price">
                        Preço (reais)
                      </label>
                      {touched.price && errors.price && (
                        <label className="formikErrorMessage">
                          {errors.price}
                        </label>
                      )}
                      <input
                        id="price"
                        name="price"
                        className="formikFieldText"
                        value={values.price}
                        onChange={handleChange("price")}
                        placeholder="000"
                        step="any"
                        type="number"
                        onBlur={handleBlur("price")}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={5}>
                    <div className="formikFieldArea">
                      <label
                        className="formikFieldLabel"
                        htmlFor="cancelation_days"
                      >
                        Antecedência mínima para cancelamento (dias)
                      </label>
                      {touched.price && errors.price && (
                        <label className="formikErrorMessage">
                          {errors.price}
                        </label>
                      )}
                      <input
                        id="cancelation_days"
                        name="cancelation_days"
                        className="formikFieldText"
                        value={values.cancelation_days}
                        onChange={handleChange("cancelation_days")}
                        placeholder="00"
                        step="any"
                        type="number"
                        onBlur={handleBlur("cancelation_days")}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={5}
                  style={{ justifyContent: "flex-end", padding: "0.5rem" }}
                >
                  <Grid item style={{ marginRight: "10vw", marginTop: "5vh" }}>
                    {loading ? (
                      <div id="loadingBox">
                        <Loading />
                      </div>
                    ) : (
                      <button type="submit" className="formikSubmitButton">
                        <p className="formikSubmitButtonText">Cadastrar</p>
                      </button>
                    )}
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Grid>
  );
}
