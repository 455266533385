import styled from "styled-components";



export const ButtonEdit = styled.button`
display: flex;
width: 200px;
height: 5vh;
border-radius: 10px;
background-color: blue;
&:hover{
      background-color: rgb(255, 64, 64);
}
`

export const DivCenter = styled.div`
display: flex;
justify-content: center;
align-items: center;
border: 0;
margin-bottom: 5vh;
`
