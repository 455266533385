import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import ArrowBack from "../../../../Components/ResponsiveArrowBack";
import SidebarManager from "../../../../Components/SidebarManager";
import { Container, ContainerButton, ContainerLoading } from "./styles";
import { InputWrap } from "../Management/Legislation/AddLei/style";
import SearchIcon from "@material-ui/icons/Search";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";

import api from "../../../../Services/api";

import { Oval } from "react-loader-spinner";
import NewSideBar from "../../../../Components/NewComponents/NewSideBar";
import { NewPage } from "../../Admin/Empresas/styles";

export default function WalletConfigAdministration() {
  useEffect(() => {
    getData();
    return () => {
      setResidents([]);
    };
  }, []);

  const notifySucess = () =>
    toast.success("Permissão de acesso à piscina alterada com sucesso");
  const notifyError = () =>
    toast.error("Erro ao alterar a permissão de acesso à piscina");

  const [residents, setResidents] = useState([]);
  const [procurar, setProcurar] = useState("");
  const [filter, setFilter] = useState("name");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function getData() {
    setLoading(true);
    try {
      const response = await api.get("/resident");
      setLoading(false);
      setResidents(response.data);
      console.log(response.data);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }

  async function poolcheck(id) {
    setLoading(true);
    try {
      const response = await api.get(`/resident/pool/${id}`);
      console.log(response, "oi");
      getData();
      setLoading(false);

      notifySucess();
    } catch (error) {
      setLoading(false);
      notifyError();
      throw error;
    }
  }

  return (
    <NewPage>
      <NewSideBar rota={"employee"} tipo={"Administrativo"}></NewSideBar>
      <Grid
        container
        item
        xs={8}
        style={{ marginBottom: "40px", marginLeft: "0px" }}
      >
        <ContainerButton style={{ marginLeft: "56px" }}>
          <ArrowBack />

          <p id="pageTitle">Acesso à piscina</p>
        </ContainerButton>
        <Container style={{ marginLeft: "56px" }}>
          <div style={{ display: "flex", marginLeft: "0px" }}>
            <div id="searchContainer">
              <SearchIcon />
              <input
                id="searchInput"
                placeholder="Pesquisar"
                onChange={(event) => {
                  setProcurar(event.target.value);
                }}
              />
            </div>
            <InputWrap
              style={{
                marginBottom: "32px",
                marginLeft: "30px",
                width: "200px",
              }}
            >
              <select
                style={{
                  border: "none",
                  borderLeft: "4px solid #00334E",
                  borderRadius: "20px",
                  minWidth: "150px",
                  backgroundColor: "#f8f8f8",
                  padding: "4px 12px",
                }}
                placeholder="None"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              >
                <option value="name">Nome</option>
                <option value="complement">Complemento</option>
              </select>
            </InputWrap>
          </div>
          {loading ? (
            <ContainerLoading>
              <Oval
                ariaLabel="loading-indicator"
                height={100}
                width={100}
                strokeWidth={5}
                strokeWidthSecondary={1}
                color="#1D6995"
                secondaryColor="white"
              />
            </ContainerLoading>
          ) : (
            residents
              .filter((resident) => {
                if (procurar == "") {
                  return resident;
                } else if (
                  resident?.name
                    .toLowerCase()
                    .includes(procurar.toLowerCase()) &&
                  filter === "name"
                ) {
                  return resident;
                } else if (
                  resident?.complement
                    ? resident?.complement
                        .toLowerCase()
                        .includes(procurar.toLowerCase()) &&
                      filter === "complement"
                    : null
                ) {
                  return resident;
                }
              })
              .map((resident) => (
                <div className="data-container">
                  <div id="data-safe-area">
                    <div className="manager-name-title">
                      Nome: {resident?.name}
                    </div>
                    <div className="manager-name-title">
                      Complemento: {resident?.complement}
                    </div>
                    <div className="manager-name-title">
                      Acesso à piscina:{" "}
                      {resident?.pool ? "Permitido" : "Bloqueado"}
                    </div>
                    <button
                      onClick={() => {
                        poolcheck(resident?.id);
                      }}
                      className="handleButton"
                    >
                      <p id="signUpCondominiumText">
                        Mudar estado de acesso à piscina
                      </p>
                    </button>
                  </div>
                  <div className="WarnDate"></div>
                </div>
              ))
          )}
          <div style={{ height: "5vh" }}></div>
        </Container>
      </Grid>
    </NewPage>
  );
}
