import React from 'react'
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './Context/auth'
import { ToastContainer } from 'react-toastify'


import './App.css'

import Routes from './Routes/index';

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes />
        <ToastContainer />
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
