import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import * as yup from "yup";

// Service
import api from "../../../../Services/api";

// Toast
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Components
import Loading from "../../../../Components/Loading/index";
import Sidebar from "../../../../Components/SidebarManager";
import ArrowBack from "../../../../Components/ResponsiveArrowBack";

// Style
import "./index.css";
import { Grid } from "@material-ui/core";

const initialForm = {
  name: "",
  minimum_time: "",
  maximum_time: "",
  capacity: "",
  price: "",
  max_minutes_duration: "",
  min_minutes_interval: "",
};

export default function CreateSortitionPage() {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(initialForm);
  const [spaces, setSpaces] = useState([]);

  const { id } = "";

  const notifySucess = () =>
    toast.success(`Cadastro ${id ? "alterado" : "realizado"} com sucesso!`);
  const notifyError = () => toast.error("Erro ao cadastrar sorteio!");
  const notifyFetchError = () =>
    toast.error(
      "Desculpe, houve uma falha ao obter dados para edição. Tente novamente mais tarde"
    );

  const requiredFieldMessage = "Este campo deve ser preenchido";

  const sortitionValidationSchema = yup.object().shape({
    name: yup.string().required(requiredFieldMessage),
    date: yup.date().required(requiredFieldMessage),
    space: yup.string().required(requiredFieldMessage),
  });

  useEffect(async () => {
    const retrieveSpaces = async () => {
      try {
        setLoading(true);
        const data = (await api.get(`/space`)).data;
        setSpaces(data);
        setLoading(false);
      } catch (ex) {
        notifyFetchError();
        history.goBack();
        throw ex;
      }
    };
    await retrieveSpaces();
  }, []);

  const createSortition = async (values) => {
    setLoading(true);
    const data = {
      name: values.name.toString(),
      date: values.date.toString(),
      space_id: values.space.toString(),
    };
    try {
      if (!id) await api.post("/sortition", data);
      else await api.patch(`/sortition/${id}`, data);
      notifySucess();
      history.goBack();
    } catch (e) {
      notifyError();
    }
    setLoading(false);
  };

  return (
    <Grid container>
      <Grid item xs={4}>
        <Sidebar />
      </Grid>
      <Grid container item xs={8} style={{ marginBottom: "40px" }}>
        <Grid item xs={12}>
          <div className="spacePageTitleContainer">
            <ArrowBack />
            <p className="pageTitle">{`${
              id ? "Editar" : "Cadastrar novo"
            } sorteio`}</p>
          </div>
        </Grid>
        <Grid container spacing={2} justify="space-between">
          <Formik
            initialValues={form}
            onSubmit={createSortition}
            validationSchema={sortitionValidationSchema}
            enableReinitialize={true}
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              handleChange,
              handleBlur,
            }) => (
              <Form handleSubmit={handleSubmit}>
                <Grid container spacing={2} justify="space-between">
                  <Grid item xs={6}>
                    <div className="formikFieldArea">
                      <label className="formikFieldLabel" htmlFor="name">
                        Nome
                      </label>
                      {touched.name && errors.name && (
                        <label className="formikErrorMessage">
                          {errors.name}
                        </label>
                      )}
                      <input
                        id="name"
                        name="name"
                        className="formikFieldText"
                        value={values.name}
                        onChange={handleChange("name")}
                        placeholder="Nome"
                        onBlur={handleBlur("name")}
                        disabled={loading}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="formikFieldArea">
                      <label className="formikFieldLabel" htmlFor="date">
                        Data
                      </label>
                      {touched.date && errors.date && (
                        <label className="formikErrorMessage">
                          {errors.date}
                        </label>
                      )}
                      <input
                        id="date"
                        name="date"
                        className="formikFieldText"
                        type="date"
                        format="dd/MM/yyyy"
                        value={values.date}
                        onChange={handleChange("date")}
                        onBlur={handleBlur("date")}
                        disabled={loading}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="formikFieldArea">
                      <label className="formikFieldLabel" htmlFor="space">
                        Espaço
                      </label>
                      {touched.space && errors.space && (
                        <label className="formikErrorMessage">
                          {errors.space}
                        </label>
                      )}
                      <select
                        disabled={loading}
                        id="space"
                        name="space"
                        className="formikFieldText datePicker"
                        value={values.space}
                        onChange={handleChange("space")}
                        onBlur={handleBlur("space")}
                      >
                        <option value={""}> </option>
                        {spaces &&
                          spaces.map((space) => (
                            <option value={space.id}>{space.name}</option>
                          ))}
                      </select>
                    </div>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={5}
                  style={{ justifyContent: "flex-end", padding: "0.5rem" }}
                >
                  <Grid item>
                    {loading ? (
                      <div id="loadingBox">
                        <Loading />
                      </div>
                    ) : (
                      <button type="submit" className="formikSubmitButton">
                        <p className="formikSubmitButtonText">Cadastrar</p>
                      </button>
                    )}
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Grid>
  );
}
