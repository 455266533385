import React, { useEffect, useState } from "react";
import {
  ButtonDelete,
  CarContainer,
  CardDiv,
  Container,
  DivButtonsCard,
  HeaderDiv,
  NewPage,
  RowDiv,
} from "../../Empresas/styles";
import NewSideBar from "../../../../../Components/NewComponents/NewSideBar";

import NewHeader from "../../../../../Components/NewComponents/Header";
import Select from "react-select";
import { Link, useHistory } from "react-router-dom";
import { ButtonCriar } from "../ServicesList/styles";
import { DivText } from "../../../../../Components/NewComponents/NewSideBar/styles";
import CardInfo from "../../../../../Components/NewComponents/Text";
import { MdOutlineModeEditOutline } from "react-icons/md";
import api from "../../../../../Services/api";
import CloseIcon from "@mui/icons-material/Close";

function GestaoEspecialidade() {
  const [options, setOptions] = useState([]);
  useEffect(() => getOptions(), []);
  const getOptions = async () => {
    const response = await api.get("/tag");
    console.log(response.data);
    const options = response.data;
    setOptions(options);
  };

  async function DeleteEspecialidade(id) {
    try {
      const response = await api.delete(`/tag/${id}`);
    } catch (error) {}
    getOptions();
  }

  return (
    <NewPage>
      <NewSideBar tipo={"Administrador"} rota={"admin"}></NewSideBar>
      <Container>
        <HeaderDiv>
          <NewHeader name={"Gestão De Especialidades"}></NewHeader>
        </HeaderDiv>
        <RowDiv style={{ gap: "20px" }}>
          <Link
            to={{
              pathname: "/admin/servicos/criarespecialista",
              state: { data: "test" },
            }}
            params={{ test: "hello" }}
            style={{ textDecoration: "none", color: "black" }}
          >
            <ButtonCriar>
              <p>Criar Especialidade</p>
            </ButtonCriar>
          </Link>
        </RowDiv>
        <CarContainer>
          {options.map((item) => (
            <CardDiv height={"2vh"}>
              <DivText>
                {console.log("dsad", item.id)}
                <CardInfo
                  name={"Especialidade: "}
                  info={item.name}
                  height={"2vh"}
                ></CardInfo>
              </DivText>
              <DivButtonsCard>
                <ButtonDelete
                  onClick={() => {
                    DeleteEspecialidade(item.id);
                  }}
                >
                  <CloseIcon></CloseIcon>
                </ButtonDelete>

                <Link
                  to={`/admin/servicos/GestãoEspecialidade/editar/${item.id}`}
                >
                  <button id="EditButton">
                    <MdOutlineModeEditOutline />
                  </button>
                </Link>
              </DivButtonsCard>
            </CardDiv>
          ))}
        </CarContainer>
      </Container>
    </NewPage>
  );
}

export default GestaoEspecialidade;
