import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
//Service
import api from "../../../../Services/api";
import CloseIcon from "@mui/icons-material/Close";

//Toast
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Components
import Loading from "../../../../Components/Loading/index";
import Sidebar from "../../../../Components/SidebarManager";
import { InputWrap } from "../Management/Legislation/AddLei/style";
//import SidebarOptions from "../../../../Components/SidebarOptions";
import ArrowBack from "../../../../Components/ResponsiveArrowBack";
//Icons
import SearchIcon from "@material-ui/icons/Search";

import "./index.css";
import ModalDelete from "./modal";
import ModalPorteiro from "./modalPorteiro";
import NewSideBar from "../../../../Components/NewComponents/NewSideBar";

export default function ResidentList() {
  const notifyDelete = () => toast.error("Condômino apagado!");

  useEffect(() => {
    getData();
    getEmployee();
    return () => {
      setResidents([]);
      setEmployees([]);
    };
  }, []);

  const [residents, setResidents] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [procurar, setProcurar] = useState("");
  const [filter, setFilter] = useState("name");

  async function getData() {
    try {
      const response = await api.get("/resident");
      const residents = response.data.map((resident) => ({
        name: resident.name,
        modality: resident.modality === "resident" ? "Residente" : "Síndico",
        complement: resident.complement.replace(/:/g, " ").replace(/  +/g, " "),
        email: resident.email,
        phone: resident.phone,
        id: resident.id,
      }));
      setResidents(residents);
      console.log(residents);
      console.log(response.data);
    } catch (error) {
      throw error;
    }
  }
  async function getEmployee() {
    try {
      const response = await api.get("/employee");
      const employees = response.data.map((employee) => ({
        name: employee.name,
        modality:
          employee.modality === "doorman"
            ? "Porteiro"
            : employee.modality === "administration"
            ? "Administrativo"
            : "Técnico de Manutenção",
        email: employee.email,
        id: employee.id,
      }));
      setEmployees(employees);
    } catch (error) {
      throw error;
    }
  }
  async function deleteResident(res_id) {
    try {
      await api.delete(`/resident/${res_id}`);
      setResidents(residents.filter((resident) => resident.id !== res_id));
      notifyDelete();
    } catch (error) {
      throw error;
    }
  }

  async function deleteEmployee(res_id) {
    try {
      await api.delete(`/employee/${res_id}`);
      setEmployees(employees.filter((resident) => resident.id !== res_id));
      notifyDelete();
    } catch (error) {
      throw error;
    }
  }

  return (
    <div id="homeContent" style={{ marginLeft: "0px" }}>
      <NewSideBar rota={"manager"} tipo={"Síndico"}></NewSideBar>
      <div
        id="safeArea"
        style={{ marginLeft: "56px", width: "100%", overflowY: "auto" }}
      >
        <div
          className="pageTitleContainer"
          id="x"
          style={{ marginLeft: "56px" }}
        >
          <ArrowBack />

          <p id="pageTitle">Condôminos Cadastrados</p>
        </div>

        <div id="listArea" style={{ marginLeft: "56px" }}>
          <div style={{ display: "flex" }}>
            <div id="searchContainer">
              <SearchIcon />
              <input
                id="searchInput"
                placeholder="Pesquisar"
                onChange={(event) => {
                  setProcurar(event.target.value);
                }}
              />
            </div>
            <InputWrap
              style={{
                marginBottom: "32px",
                marginLeft: "10px",
                width: "200px",
              }}
            >
              <select
                style={{
                  border: "none",
                  borderLeft: "4px solid #00334E",
                  borderRadius: "20px",
                  minWidth: "150px",
                  backgroundColor: "#f8f8f8",
                  padding: "4px 12px",
                }}
                placeholder="None"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              >
                <option value="name">Nome</option>
                <option value="modality">Modalidade</option>
                <option value="phone">Celular</option>
                <option value="complement">Complemento</option>
                <option value="email">Email</option>
              </select>
            </InputWrap>
          </div>

          {residents
            .filter((resident) => {
              if (procurar == "") {
                return resident;
              } else if (
                resident?.name.toLowerCase().includes(procurar.toLowerCase()) &&
                filter === "name"
              ) {
                return resident;
              } else if (
                resident?.email
                  .toLowerCase()
                  .includes(procurar.toLowerCase()) &&
                filter === "email"
              ) {
                return resident;
              } else if (
                resident?.modality
                  ? resident?.modality
                      .toLowerCase()
                      .includes(procurar.toLowerCase()) && filter === "modality"
                  : null
              ) {
                return resident;
              } else if (
                resident?.phone
                  ? resident?.phone
                      .toLowerCase()
                      .includes(procurar.toLowerCase()) && filter === "phone"
                  : null
              ) {
                return resident;
              } else if (
                resident?.complement
                  ? resident?.complement
                      .toLowerCase()
                      .includes(procurar.toLowerCase()) &&
                    filter === "complement"
                  : null
              ) {
                return resident;
              }
            })
            .map((resident) => (
              <div className="data-container">
                <div id="data-safe-area">
                  <div className="manager-name-title">
                    Nome: {resident?.name}
                  </div>
                  <div className="manager-name-title">
                    Email: {resident?.email}
                  </div>
                  <div className="manager-name-title">
                    Celular: {resident?.phone}
                  </div>
                  <div className="manager-name-title">
                    Modalidade: {resident?.modality}
                  </div>
                  <div className="manager-name-title">
                    Complemento:{" "}
                    {(resident?.complement
                      ? resident?.complement
                      : "Não está definido"
                    ).replace(/:/g, " ")}
                  </div>
                </div>
                <div className="WarnDate"></div>
                {/*<button
                  id="deleteButton"
                  onClick={() => {
                    deleteResident(resident?.id);
                  }}
                >
                  <span id="cross1"></span>
                  <span id="cross2"></span>
                </button>*/}
                <ModalDelete
                  id={resident?.id}
                  onClick={() => console.log("oi", resident?.id)}
                ></ModalDelete>
              </div>
            ))}

          {employees
            .filter((resident) => {
              if (procurar == "") {
                return resident;
              } else if (
                resident?.name.toLowerCase().includes(procurar.toLowerCase()) &&
                filter === "name"
              ) {
                return resident;
              } else if (
                resident?.email
                  .toLowerCase()
                  .includes(procurar.toLowerCase()) &&
                filter === "email"
              ) {
                return resident;
              } else if (
                resident?.modality
                  ? resident?.modality
                      .toLowerCase()
                      .includes(procurar.toLowerCase()) && filter === "modality"
                  : null
              ) {
                return resident;
              } else if (
                resident?.complement
                  ? resident?.complement
                      .toLowerCase()
                      .includes(procurar.toLowerCase()) &&
                    filter === "complement"
                  : null
              ) {
                return resident;
              }
            })
            .map((resident) => (
              <div className="data-container">
                <div id="data-safe-area">
                  <div className="manager-name-title">
                    Nome: {resident?.name}
                  </div>
                  <div className="manager-name-title">
                    Email: {resident?.email}
                  </div>
                  <div className="manager-name-title">
                    Modalidade: {resident?.modality}
                  </div>
                </div>
                <div className="WarnDate"></div>
                {/*<button
                  id="deleteButton"
                  onClick={() => {
                    deleteEmployee(resident?.id);
                  }}
                >
                  <span id="cross1"></span>
                  <span id="cross2"></span>
                </button>*/}
                <ModalPorteiro
                  id={resident?.id}
                  onClick={() => console.log("oi", resident?.id)}
                ></ModalPorteiro>
              </div>
            ))}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
