import Modal from "react-modal";
import { useState } from "react";
import { toast } from "react-toastify";
import {
  ButtonClose,
  ButtonDiv,
  TitleDiv,
} from "../../Manager/ResidentsList/style";
import api from "../../../../Services/api";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    width: "50vw",
    justifyContent: "center",
    height: "10vh",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",

    bordeRadios: "10px",
  },
};

function ModalDeleteCondominio({ func, id }) {
  const notifyDelete = () => toast.error("Condomínio deletado!");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [condominios, setCondominios] = useState([]);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    console.log("false");
    setIsOpen(false);
  }

  async function deleteCond(id, func) {
    try {
      await api.delete(`/condominium/${id}`);
      setCondominios(condominios.filter((condominio) => condominio.id !== id));
      notifyDelete();
      func();
      closeModal();
    } catch (error) {
      throw error;
    }
  }

  return (
    <>
      <button
        id="deleteButton"
        style={{ marginLeft: "0" }}
        onClick={() => {
          openModal();
        }}
      >
        <span>
          <b>Deletar</b>
        </span>
      </button>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(153, 153, 153, 0.5)",
          },
          content: {
            width: "40vw",
            height: "35vh",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            backgroundColor: "#efefef",
            top: "50%",
            left: "60%",
            transform: "translate(-50%, -60%)",
            zIndex: "100",
          },
        }}
      >
        <TitleDiv>
          <h1>Tem certeza que deseja deletar o condomínio?</h1>
        </TitleDiv>
        <ButtonDiv>
          <ButtonClose color="green" onClick={() => deleteCond(id, func)}>
            <h1>Sim</h1>
          </ButtonClose>
          <ButtonClose color="red" onClick={() => closeModal()}>
            <h1>Não</h1>
          </ButtonClose>
        </ButtonDiv>
      </Modal>
    </>
  );
}

export default ModalDeleteCondominio;
