import React from "react";
import { Formik, Field, Form } from "formik";
import { Dialog, DialogTitle, Typography, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const MailLocalModal = ({
  open,
  handleClose,
  mail_id,
  confirmMailLocal,
}) => {
  const optionsArray = ["Portaria", "Administração"]; 

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Typography variant="h6" align="center">
          Mudar Local de Encomenda
        </Typography>
        <IconButton
          style={{
            position: "absolute",
            right: "10px",
            top: "10px",
            zIndex: 1,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <div
        style={{
          width: "25vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Formik
          initialValues={{ location: "Portaria" }}
          onSubmit={(values, actions) => {
            confirmMailLocal(mail_id, values);
          }}
        >
          {({ isSubmitting }) => (
            <div
              style={{
                width: "80%",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "2vh",
                paddingBottom: "4vh",
              }}
            >
              <Form>
                <Field as="select" name="location" className="inputField">
                  {optionsArray.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </Field>

                <button type="submit" className="confirmButton">
                  <span>
                    <b>Confirmar</b>
                  </span>
                </button>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </Dialog>
  );
};
export default MailLocalModal;
