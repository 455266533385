import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { useParams } from "react-router-dom";
import pool from "./assets/pool.jpg";
import Logo from "../../../Assets/Logobranca.png";
import "./index.css";

//Service
import api from "../../../Services/api";

import userPlaceHolder from "../../../Assets/user_placeholder.svg";

const defaultGrid = {
  display: "flex",
  flexWrap: "wrap",
  alignContent: "space-between",
  flexDirection: "column",
  alignContent: "center",
  paddingTop: "4rem",
  backgroundColor: "#FFF",
  height: "100vh",
  width: "100vw",
};

const dataGrid = {
  borderBottom: "1px solid #CACACA",
  /*  borderColor: "#999999", */
  /* borderRadius: "0.75rem", */
  color: "#031A25",
  padding: "0.75rem",
  margin: "0.5rem",
};

export default function Identification() {
  const [loading, setLoading] = useState(false);
  const { code } = useParams();
  const [userData, setUserData] = useState();
  const [invalidCode, setInvalidCode] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState();
  const [condPool, setCondPool] = useState(false);

  const getIdData = async () => {
    setLoading(true);
    try {
      if (!code) return;
      const data = (await api.get(`/resident/card/${code}`)).data;
      console.log(data, " data piscina");

      setUserData(data);
      setProfilePhoto(data.photo_url);
      getCond(data.condominium_id);
    } catch (e) {
      setInvalidCode(true);
    }
    setLoading(false);
  };

  const getCond = async (id) => {
    try {
      const data = (await api.get(`/condominium/info/${id}`)).data;
      setCondPool(data.pool);
    } catch (error) {
      console.log(error);
      console.log(error.response.data);
    }
  };

  useEffect(() => {
    getIdData();
  }, [code]);

  return (
    <Box style={defaultGrid}>
      {loading && (
        <Box style={dataGrid}>
          <div className="idLoading">Aguarde, carregando informações!</div>
        </Box>
      )}
      {userData && (
        <div style={{ backgroundColor: "#FFF" }}>
          <div
            style={{
              width: " 100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "2.5em",
            }}
          >
            <Box
              alt="Molde de foto do usuário"
              style={{
                height: 100,
                width: 100,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
                overflow: "hidden",
              }}
            >
              <Box
                component="img"
                alt="Foto do usuário"
                src={profilePhoto || userPlaceHolder}
                style={{
                  height: "auto",
                  width: "100%",
                  alignSelf: "center",
                }}
              />
            </Box>
            <Box
              style={{
                border: "0",
                padding: "0.75rem",
                margin: "0.5rem",
              }}
            >
              <div className="idLabel">Nome</div>
              <div
                className="idData"
                style={{ fontSize: "1.5em", fontWeight: "500" }}
              >
                {userData.name}
              </div>
            </Box>
          </div>

          <Box style={dataGrid} flex={0.5}>
            <div className="idLabel">Email</div>
            <div className="idData">{userData.email}</div>
          </Box>
          <Box style={dataGrid} flex={0.5}>
            <div className="idLabel">Número do Documento</div>
            <div className="idData">
              {userData?.document ? userData.document : "Não informado"}
            </div>
          </Box>
          <Box style={dataGrid}>
            <div className="idLabel">Residência</div>
            <div className="idData">{userData.complement}</div>
          </Box>
          {condPool && (
            <Box
              style={{
                padding: "0.75rem",
                margin: "0.5rem",
                color: "#031A25",
              }}
            >
              <div className="idLabel">Piscina ativa?</div>
              <div className="idData">{userData.pool ? "Sim" : "Não"}</div>
            </Box>
          )}
          <div
            style={{
              height: "20vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                borderRadius: "5px",
                backgroundImage: `url(${condPool ? pool : Logo})`,
                backgroundSize: condPool ? "cover" : "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: "90%",
              }}
            ></div>
          </div>
        </div>
      )}
      {invalidCode && (
        <Box style={dataGrid}>
          <div className="idWarning">
            Código inválido. Por favor, tente com um novo!
          </div>
        </Box>
      )}
    </Box>
  );
}
