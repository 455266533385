import {
  Wrap,
  Container,
  Input,
  CloseImg,
  Form,
  InputWrap,
  Header,
  Footer,
  AddBtn,
  CloseBtn,
} from "./style";
import { useState } from "react";
import api from "../../../../../../Services/api";
import axios from "axios";

//Toast
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AddManutencao({ isVisible, changeVisibility, handleAddMntc }) {
  const [name, setName] = useState("");
  const [link, setLink] = useState("");

  const notifySucess = () => toast.success("Equipamento enviado com sucesso!");
  const notifyError = () => toast.error("Erro ao enviar equipamento!");

  function postEquipamento(req) {
    const res = api.post(`/maintenance`, req);
    return res;
  }

  async function hadleSubmit(event) {
    const data = {
      name: name,
    };
    console.log(data);
    await postEquipamento(data);
    console.log("ok");
    handleAddMntc();
    notifySucess();
    close();
  }

  function close() {
    changeVisibility(false);
    setName("");
  }

  return (
    <Wrap isVisible={isVisible}>
      <Container>
        <Header>
          <CloseImg
            onClick={close}
            src="https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-close-512.png"
          />
          <h2>Adicionar Equipamento</h2>
        </Header>
        <Form onSubmit={hadleSubmit}>
          <InputWrap>
            <label>Nome</label>
            <input
              className="borda"
              type="text-area"
              name="name"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </InputWrap>
        </Form>
        <Footer>
          <AddBtn onClick={hadleSubmit}>Adicionar</AddBtn>
          <CloseBtn onClick={close} type="submit">
            Cancelar
          </CloseBtn>
        </Footer>
      </Container>
    </Wrap>
  );
}

export default AddManutencao;
