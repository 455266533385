import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import DatePicker from "react-date-picker";

// Auth
import { useAuth } from "../../../../Context/auth";

// Service
import api from "../../../../Services/api";

// Toast
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Components
import Sidebar from "../../../../Components/SidebarManager";
import ArrowBack from "../../../../Components/ResponsiveArrowBack";
import { InputWrap } from "../Management/Legislation/AddLei/style";
import { Container, NewPage } from "../../Admin/Empresas/styles";
// Assets
import plus from "../../../../Assets/plus.svg";

//Icons
import SearchIcon from "@material-ui/icons/Search";

// Style
import "./index.css";
import { Grid } from "@material-ui/core";
import NewSideBar from "../../../../Components/NewComponents/NewSideBar";

export default function DocumentPageAdministraion() {
  const history = useHistory();

  const { user } = useAuth();

  const [name, setName] = useState("");
  const [documents, setDocuments] = useState([]);
  const [procurar, setProcurar] = useState("");
  const [data, setData] = useState();
  const [filter, setFilter] = useState("media_name");
  const infos = localStorage.getItem("@SWCondominio:user");

  const notifyError = () =>
    toast.error(
      "Desculpe, houve uma falha ao obter documentos existentes. Por favor, tente novamente mais tarde."
    );
  const notifyDeleteError = () => toast.error("Erro ao deletar documento!");
  const notifyDelete = () => toast.error("Documento deletado com sucesso!");

  function removeDuplicateObjects(arr) {
    const uniqueIds = {};
    for (let i = 0; i < arr.length; i++) {
      uniqueIds[arr[i].id] = arr[i];
    }
    const uniqueObjects = Object.values(uniqueIds);
    return uniqueObjects;
  }

  const getData = async () => {
    try {
      const data = (await api.get("/document")).data;
      const received = data.filter((x) => Object.keys(x).length > 0);
      const dataReceived = (await api.get("/document/received")).data;
      const filterReceived = dataReceived.filter(
        (x) => Object.keys(x).length > 0
      );
      var response = [...received, ...filterReceived];
      response = response ? removeDuplicateObjects(response) : null;
      console.log(data, " data", dataReceived, "data receivd");
      setDocuments(response);
    } catch (e) {
      notifyError();
      throw e;
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // Getting name to greet user
  useEffect(() => {
    function getProfileData() {
      api.get(`/resident/${JSON.parse(infos).id}`).then((response) => {});
    }
    getProfileData();
  }, []);

  async function deleteItem(id) {
    try {
      await api.delete(`/document/${id}`);
      setDocuments(documents.filter((doc) => doc.id !== id));
      notifyDelete();
    } catch (error) {
      notifyDeleteError();
      throw error;
    }
  }

  function onChangeData(data) {
    setData(data);
    setProcurar("");
  }

  function NewTab(url) {
    // window.open(url, "_blank");
    return (
      <Link style={{ color: "#EE6F57" }} to={{ pathname: url }} target="_blank">
        {" "}
        clique aqui{" "}
      </Link>
    );
  }

  return (
    <Grid container style={{ overflowY: "auto" }}>
      {/*
      <Grid item xs={3}>
      </Grid>
     */}
      <NewSideBar rota={"employee"} tipo={"Administrativo"}></NewSideBar>
      <Container>
        <Grid
          container
          item
          xs={8}
          style={{ marginBottom: "40px", paddingLeft: "56px" }}
        >
          <Grid item xs={12}>
            <div
              className="pageTitleContainer"
              style={{ margin: "0px", paddingBottom: "8vh" }}
            >
              <ArrowBack></ArrowBack>
              <p className="pageTitle">Documentos</p>
            </div>
            <div style={{ display: "flex" }}>
              <div id="searchContainer">
                {filter === "date" ? (
                  <>
                    <SearchIcon style={{ marginRight: "20px" }} />
                    <DatePicker
                      value={data}
                      onChange={(event) => onChangeData(event)}
                      maxDate={new Date()}
                    />
                  </>
                ) : (
                  <>
                    <SearchIcon />
                    <input
                      id="searchInput"
                      placeholder="Pesquisar"
                      onChange={(event) => {
                        setProcurar(event.target.value);
                      }}
                    />
                  </>
                )}
              </div>
              <InputWrap
                style={{
                  marginBottom: "32px",
                  marginLeft: "10px",
                  width: "200px",
                }}
              >
                <select
                  style={{
                    border: "none",
                    borderLeft: "4px solid #00334E",
                    borderRadius: "20px",
                    minWidth: "150px",
                    backgroundColor: "#f8f8f8",
                    padding: "4px 12px",
                  }}
                  placeholder="None"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                >
                  <option value="media_name">Nome do documento</option>
                  <option value="date">Data do documento</option>
                  <option value="user_name">Nome do remetente</option>
                  <option value="user_complement">
                    Complemento do remetente
                  </option>
                </select>
              </InputWrap>
            </div>
            <Link
              to={{
                pathname: "/administration/document/create",
              }}
              style={{ textDecoration: "none", color: "black" }}
            >
              <div className="addButton">
                <img src={plus} id="plusIcon" />
                <p>Enviar novo documento</p>
              </div>
            </Link>
          </Grid>
          <Grid container spacing={2} justifyContent="space-between">
            {documents
              .filter((document) => {
                if (procurar === "" && data === "") {
                  return document;
                } else if (
                  document?.media_name
                    .toLowerCase()
                    .includes(procurar.toLowerCase()) &&
                  filter === "media_name"
                ) {
                  return document;
                } else if (
                  document?.date.includes(
                    data
                      ? data.toLocaleDateString("pt-BR", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        })
                      : ""
                  ) &&
                  filter === "date"
                ) {
                  return document;
                } else if (
                  document?.user_name
                    .toLowerCase()
                    .includes(procurar.toLowerCase()) &&
                  filter === "user_name"
                ) {
                  return document;
                } else if (
                  document?.user_complement
                    ? document?.user_complement
                        .toLowerCase()
                        .includes(procurar.toLowerCase()) &&
                      filter === "user_complement"
                    : null
                ) {
                  return document;
                }
              })
              .map((doc) => (
                <Grid item xs={6}>
                  <div
                    className="dataContainer"
                    style={{ marginLeft: "0px", marginTop: "5vh" }}
                  >
                    <div className="infoLabel" style={{ fontWeight: "bold" }}>
                      Documento{" "}
                    </div>
                    <div className="infoLabel">Nome: </div>
                    <p className="archieveText">{doc?.media_name}</p>
                    <div className="infoLabel">
                      Descrição: {doc?.description}
                    </div>
                    <div className="infoLabel">
                      Data: {doc?.date} às {doc?.time}
                    </div>
                    <div className="infoLabel">
                      Visualizar: {NewTab(doc?.media_url)}
                    </div>
                    <div
                      className="infoLabel"
                      style={{ fontWeight: "bold", marginTop: "2vh" }}
                    >
                      Remetente{" "}
                    </div>
                    <div className="infoLabel">Nome: {doc?.user_name}</div>
                    <div className="infoLabel">
                      Complemento: {doc?.user_complement}
                    </div>
                    <Grid container style={{ justifyContent: "flex-end" }}>
                      <Grid item xs={1}>
                        <button
                          onClick={() => {
                            deleteItem(doc?.id);
                          }}
                          style={{ marginRight: "30px" }}
                          className="actionButton delete"
                        >
                          Apagar
                        </button>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}
