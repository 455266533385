import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./index.css";

import SidebarOptions from "../SidebarOptions/index";

import profilePhoto from "../../Assets/Logobranca.png";

import api from "../../Services/api";

import condCad from "../../Assets/building.svg";
import propaganda from "../../Assets/propaganda.svg";
import sobre from "../../Assets/info.svg";
import sair from "../../Assets/logout.svg";
import Approval from "../../Assets/add-user.svg";
import Home from "../../Assets/Home.svg";
import Users from "../../Assets/users.svg";
import Warn from "../../Assets/danger.svg";

import { useAuth } from "../../Context/auth";
import { useHistory } from "react-router";
import { isAuthenticated } from "../../Context/isAuthenticated";

export default function Sidebar() {
  const [userType, setUserType] = useState("");
  const { isAuthorized, signOut, token, user } = useAuth();
  const history = useHistory();
  const infos = localStorage.getItem("@SWCondominio:user");
  const [handleFile, setHandleFile] = useState("");

  useEffect(() => {
    async function getProfileData() {
      console.log("Oi")
      try {
        const response = await api.get(`/admin/${JSON.parse(infos).id}`);
        console.log(response.data, "teste1")
        setHandleFile(response.data.photo_url);
      } catch (error) {
        console.log(error, "teste")
      }
      
    }
    getProfileData();
    console.log(handleFile, "asdsad");
  }, []);

  function handleSignOut() {
    signOut();
    console.log("dasdasdasOII")
    history.push("/");
  }

  useEffect(() => {
    if (isAuthenticated()) return;
    else history.push("/");
  }, [token]);

  useEffect(() => {
    const modality = isAuthorized();
    setUserType(modality);
  }, []);

  return (
    <div id="sidebarContainer">
      <div id="sidebarTopInfo">
        <div id="pic">
        <img src={handleFile ? handleFile : profilePhoto} id="logo" />
        </div>

        <div id="topInfo">
          <p id="usernameText"> {JSON.parse(infos).name} </p>
          <p id="jobText"> Administrador </p>
          <Link
            to="/admin/profile"
            style={{ textDecoration: "none", color: "black" }}
          >
            {" "}
            <p id="profileText">Meu Perfil</p>{" "}
          </Link>
        </div>
      </div>

      <div id="bar"></div>
      <div id="sidebarMidInfo">
        <Link to="/admin/home" style={{ textDecoration: "none", color: "white" }}>
          <SidebarOptions src={Home} text="Home" />
        </Link>
        <div id="space"></div>
        <Link to="/admin/avisos" style={{ textDecoration: "none" }}>
          <SidebarOptions src={Warn} text="Avisos do App" />
        </Link>
        <div id="space"></div>
        <Link to="/admin/users" style={{ textDecoration: "none" }}>
          <SidebarOptions src={Users} text="Usuários Cadastrados" />
        </Link>

        <div id="space"></div>

        <Link to="/admin/condominiumlist" style={{ textDecoration: "none", backgorundColor: "white" }}>
          <SidebarOptions src={condCad} style={{backgorundColor: "white"}} text="Condomínios Cadastrados" />
        </Link>
        <div id="space"></div>
        <Link to="/admin/managerapproval" style={{ textDecoration: "none" }}>
          <SidebarOptions src={Approval} text="Pedidos de Cadastro" />
        </Link>

        <div id="space"></div>
        <Link to="/admin/propagandalist" style={{ textDecoration: "none" }}>
          <SidebarOptions src={propaganda} text="Gerência de Propaganda" />
        </Link>
      </div>
      <div id="bar"></div>

      <div id="sidebarBottomInfo">
        <Link to="/admin/sobre" style={{ textDecoration: "none", color:"white" }}>
          <SidebarOptions src={sobre} text="Sobre" />
        </Link>
        <SidebarOptions
          src={sair}
          text="Sair"
          onClick={() => {handleSignOut()}}
        />
      </div>
    </div>
  );
}
