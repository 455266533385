import styled from "styled-components";

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 3%;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  margin-bottom: 10%;
`;

export const Container = styled.div`
  width: 70%;
  margin-left: auto;
  margin-right: auto;
`;
