import React, { useState, useEffect } from "react";
import { Grid, Select } from "@material-ui/core";
import { Link } from "react-router-dom";
import ArrowBack from "../../../../Components/ResponsiveArrowBack";
import SidebarDoorman from "../../../../Components/SideBarDoorman";
import { Container, ContainerButton, ContainerLoading } from "./styles";
import SearchIcon from "@material-ui/icons/Search";
import { InputWrap } from "../../Manager/Management/Legislation/AddLei/style";

import "react-toastify/dist/ReactToastify.css";

import api from "../../../../Services/api";

import { Oval } from "react-loader-spinner";

export default function ResidentListDoorman() {
  useEffect(() => {
    getData();
    return () => {
      setResidents([]);
    };
  }, []);

  const [residents, setResidents] = useState([]);
  const [procurar, setProcurar] = useState("");
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("name");

  async function getData() {
    setLoading(true);
    try {
      const response = await api.get("/resident");
      setLoading(false);
      setResidents(response.data);
      console.log(response.data);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }

  return (
    <>
      <Grid container>
        <SidebarDoorman />
        <Grid
          container
          item
          xs={8}
          style={{ marginBottom: "40px", marginLeft: "33vw" }}
        >
          <ContainerButton>
            <ArrowBack />

            <p id="pageTitle">Ver lista de residentes</p>
          </ContainerButton>
          <Container>
            <div style={{ display: "flex" }}>
              {filter ? (
                <div id="searchContainer">
                  <SearchIcon />
                  <input
                    id="searchInput"
                    placeholder="Pesquisar"
                    onChange={(event) => {
                      setProcurar(event.target.value);
                    }}
                  />
                </div>
              ) : null}
              <InputWrap style={{ marginBottom: "32px", marginLeft: "10px" }}>
                <select
                  style={{
                    border: "none",
                    borderLeft: "4px solid #00334E",
                    borderRadius: "20px",
                    backgroundColor: "#f8f8f8",
                    padding: "4px 12px",
                  }}
                  placeholder="None"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                >
                  <option value="name">Nome</option>
                  <option value="complement">Complemento</option>
                </select>
              </InputWrap>
            </div>
            {loading ? (
              <ContainerLoading>
                <Oval
                  ariaLabel="loading-indicator"
                  height={100}
                  width={100}
                  strokeWidth={5}
                  strokeWidthSecondary={1}
                  color="#1D6995"
                  secondaryColor="white"
                />
              </ContainerLoading>
            ) : (
              residents
                .filter((resident) => {
                  if (procurar == "") {
                    return resident;
                  } else if (
                    resident?.name
                      .toLowerCase()
                      .includes(procurar.toLowerCase()) &&
                    filter === "name"
                  ) {
                    return resident;
                  } else if (
                    (resident?.complement)
                      .replace(/:/g, " ")
                      .replace(/  +/g, " ")
                      .toLowerCase()
                      .includes(procurar.toLowerCase()) &&
                    filter === "complement"
                  ) {
                    return resident;
                  }
                })
                .map((resident) => (
                  <div className="data-container">
                    {console.log("RESIDENTE", resident)}
                    <div id="data-safe-area">
                      <div className="manager-name-title">{resident?.name}</div>
                      <div className="manager-name-title">
                        {(resident?.complement
                          ? resident?.complement
                          : "Não está definido"
                        ).replace(/:/g, " ")}
                      </div>
                      <div className="manager-name-title">
                        {(resident?.phone
                          ? resident?.phone
                          : "Número não definido"
                        ).replace(/:/g, " ")}
                      </div>
                    </div>
                    <div className="WarnDate"></div>
                  </div>
                ))
            )}
            <div style={{ height: "5vh" }}></div>
          </Container>
        </Grid>
      </Grid>
    </>
  );
}
