import styled from "styled-components";


export const Circle = styled.button`

  height: 7vh;
  width: 4vw;
  background-color: #00334E;
  border-radius: 50%;
  display: flex;

  align-items: center;

  transition: 0.5s;
  cursor: pointer;
  &:hover{
    
    background-color: rgb(255, 64, 64);
  }

`

export const img = styled.img`
display: flex;


`

