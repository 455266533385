import styled from "styled-components";

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 3%;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

export const Container = styled.div`
  width: 70%;
  margin-left: auto;
  margin-right: auto;
`;

export const ContainerTeam = styled.div``;

export const TextVisitor = styled.p`
  font-size: 17px;
  margin-bottom: 15px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  margin-top: 3px;
`;

export const TextInput = styled.textarea`
  border-radius: 16px;
  width: 100%;
  background-color: #efefef;
  padding-left: 20px;
  height: 200px;
  margin-top: 40px;
  resize: none;
`;

export const SendVisitor = styled.div`
  width: 80%;
  height: 50px;
  background-color: #1d6995;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  margin-top: 40px;
  pointer-events: auto;
`;

export const ContainerLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
