import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import DatePicker from "react-date-picker";

//Service
import api from "../../../../Services/api";

//Toast
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "@mui/icons-material/Close";
//Components
import Loading from "../../../../Components/Loading/index";
import Sidebar from "../../../../Components/SidebarManager";
import { InputWrap } from "../Management/Legislation/AddLei/style";
//import SidebarOptions from "../../../../Components/SidebarOptions";
import ArrowBack from "../../../../Components/ResponsiveArrowBack";
//Icons
import SearchIcon from "@material-ui/icons/Search";
import plus from "../../../../Assets/plus.svg";
import { MdOutlineModeEditOutline } from "react-icons/md";
import "./index.css";
import { Search } from "@material-ui/icons";
import NewSideBar from "../../../../Components/NewComponents/NewSideBar";

export default function WarningList() {
  const notifyDelete = () => toast.error("Aviso apagado!");
  const history = useHistory();

  useEffect(() => {
    getData();
    getDataAdmin();
    return () => {
      setAvisos([]);
      setAvisosAdmin([]);
    };
  }, []);

  const [avisos, setAvisos] = useState([]);
  const [avisosAdmin, setAvisosAdmin] = useState([]);
  const [procurar, setProcurar] = useState("");
  const [data, setData] = useState();
  const [filter, setFilter] = useState("title");

  async function getData() {
    try {
      const response = await api.get("/notice");
      setAvisos(response.data);
      console.log(response.data);
    } catch (error) {
      throw error;
    }
  }

  function convertDate(inicialDate) {
    const dataString = inicialDate;
    const [dia, mes, ano] = dataString.split("/").map(Number);

    const data = new Date(ano, mes - 1, dia); // subtrai 1 do mês porque o mês começa em 0 (janeiro)

    const novaDataString = `${data.getFullYear()}-${(data.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${data.getDate().toString().padStart(2, "0")}`;

    return novaDataString;
  }

  async function getDataAdmin() {
    try {
      const response = await api.get("/notice/admin");
      setAvisosAdmin(response.data);
      console.log(response.data);
    } catch (error) {
      throw error;
    }
  }

  async function deleteWarning(warn_id) {
    try {
      await api.delete(`/notice/${warn_id}`);
      setAvisos(avisos.filter((aviso) => aviso.id !== warn_id));
      notifyDelete();
    } catch (error) {
      throw error;
    }
  }

  function onChangeData(data) {
    setData(data);
    setProcurar("");
  }

  async function Reenviar(aviso_id) {
    try {
      await api.patch(`/notice/refresh/${aviso_id}`);
      toast.success("Aviso reenviado com sucesso!");
    } catch (error) {
      toast.error("Erro ao reenviar aviso!");
      console.log(error);
    } finally {
      getData();
    }
  }

  return (
    <div id="homeContent">
      <NewSideBar rota={"manager"} tipo={"Síndico"}></NewSideBar>
      <div
        id="WarnSafeArea"
        style={{ marginLeft: "56px", width: "100%", overflowY: "auto" }}
      >
        <div
          className="pageTitleContainer"
          id="x"
          style={{ marginLeft: "0px" }}
        >
          <ArrowBack />
          <p id="pageTitle">Avisos</p>
        </div>

        <div id="listArea" style={{ marginLeft: "0px" }}>
          <div id="TopContainer1" style={{ marginLeft: "0px" }}>
            <div id="searchContainer">
              {filter === "date" ? (
                <>
                  <SearchIcon style={{ marginRight: "20px" }} />
                  <DatePicker
                    value={data}
                    onChange={(event) => onChangeData(event)}
                    maxDate={new Date()}
                  />
                </>
              ) : (
                <>
                  <SearchIcon />
                  <input
                    id="searchInput"
                    placeholder="Pesquisar"
                    onChange={(event) => {
                      setProcurar(event.target.value);
                    }}
                  />
                </>
              )}
            </div>
            <InputWrap
              style={{
                marginBottom: "32px",
                marginLeft: "10px",
                width: "200px",
              }}
            >
              <select
                style={{
                  border: "none",
                  borderLeft: "4px solid #00334E",
                  borderRadius: "20px",
                  minWidth: "150px",
                  backgroundColor: "#f8f8f8",
                  padding: "4px 12px",
                }}
                placeholder="None"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              >
                <option value="title">Título</option>
                <option value="date">Data</option>
              </select>
            </InputWrap>
            <Link
              to={{
                pathname: "/manager/aviso/criar",
                state: { data: "test" },
              }}
              params={{ test: "hello" }}
              style={{ textDecoration: "none", color: "black" }}
            >
              <div id="WarnAddButton">
                <p>Criar aviso</p>
              </div>
            </Link>
          </div>
          <div style={{ marginLeft: "0px" }}>
            {avisosAdmin
              .concat(avisos)
              .sort(
                (b, a) =>
                  new Date(convertDate(a.date)) - new Date(convertDate(b.date))
              )
              .filter((aviso) => {
                if (procurar === "" && data === "") {
                  return aviso;
                } else if (
                  aviso?.title.toLowerCase().includes(procurar.toLowerCase()) &&
                  filter === "title"
                ) {
                  return aviso;
                } else if (
                  aviso?.date.includes(
                    data
                      ? data.toLocaleDateString("pt-BR", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        })
                      : ""
                  ) &&
                  filter === "date"
                ) {
                  return aviso;
                }
              })
              .map((aviso) => (
                <div className="data-container" key={aviso?.id}>
                  <div id="data-safe-area">
                    <div className="WarnTitle">{aviso?.title}</div>
                    <div className="manager-name-title">
                      {aviso?.text.split("\n").map((discriptions) => (
                        <p>{discriptions}</p>
                      ))}
                    </div>
                    <div className="manager-name-title">
                      {!aviso?.condominium_id && (
                        <div className="WarnTitle" style={{ color: "#EE6F57" }}>
                          Aviso do app
                        </div>
                      )}
                      {aviso?.photo_url ? (
                        <div className="manager-name-title">
                          <a
                            href={`${aviso?.photo_url}`}
                            style={{ color: "#EE6F57" }}
                            target="_blank"
                          >
                            Ver foto
                          </a>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="WarnDate" style={{ marginTop: 0 }}>
                    {aviso?.date}
                  </div>

                  <button
                    id="deleteButton"
                    onClick={() => {
                      deleteWarning(aviso.id);
                    }}
                  >
                    <CloseIcon></CloseIcon>
                  </button>
                  <button
                    className="WarnResendButton"
                    onClick={() => {
                      Reenviar(aviso.id);
                    }}
                  >
                    Reenviar aviso
                  </button>
                </div>
              ))}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
