import React, { useState } from "react";
import Modal from "react-modal";

//Service
import api from "../../../../Services/api";

//Toast
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Icons
import CloseIcon from "@mui/icons-material/Close";
import "./index.css";
import { ButtonClose, ButtonDiv, TitleDiv } from "./style";
import { ButtonDelete } from "../../Admin/Empresas/styles";

Modal.setAppElement("#root");

function ModalPorteiro({id, setEmployees}) {
  const notifyDelete = () => toast.error("Funcionário apagado!");
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    console.log("false");
    setIsOpen(false);
  }

  async function deleteEmployee(id, setEmployees) {
    try {
      await api.delete(`/employee/${id}`);
      
      notifyDelete();
      closeModal();
      
      setEmployees((prevEmployees) =>
        prevEmployees.filter((employee) => employee.id !== id)
      );

    } catch (error) {
      closeModal();

      console.log(error.response.data.Error);

      const showToastMessage = () => {
        toast.error(error.response.data.Error, {
          position: toast.POSITION.TOP_CENTER,
        });
      };

      showToastMessage();

      throw error;
    }
  }

  return (
    <>
      <ButtonDelete
        onClick={() => {
          openModal();
        }}
      >
        <CloseIcon></CloseIcon>
      </ButtonDelete>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(153, 153, 153, 0.5)",
          },
          content: {
            width: "50vw",
            height: "45vh",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            backgroundColor: "#efefef",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          },
        }}
      >
        <TitleDiv>
          <h1>Tem certeza que deseja deletar o funcionário?</h1>
        </TitleDiv>
        <ButtonDiv>
          <ButtonClose
            color="green"
            onClick={() => {
              deleteEmployee(id, setEmployees);
            }}
          >
            <h1>Sim</h1>
          </ButtonClose>
          <ButtonClose
            color="red"
            onClick={() => {
              closeModal();
            }}
          >
            <h1>Não</h1>
          </ButtonClose>
        </ButtonDiv>
      </Modal>
    </>
  );
}

export default ModalPorteiro;
