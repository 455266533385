import React, { useEffect, useState } from 'react'
import Modal from 'react-modal';
import { toast, ToastContainer } from "react-toastify";
import { ButtonClose, ButtonDiv, TitleDiv } from '../../Manager/ResidentsList/style';
import api from '../../../../Services/api';
import { ButtonDelete } from './styles';
import CloseIcon from '@mui/icons-material/Close';
Modal.setAppElement('#root');

const customStyles = {
      content: {
        width:"50vw",
        justifyContent: "center",
        height:"40vh",
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        
        bordeRadios: "10px",
      },
    };



    function ModalDeleteEmpresa({id, funcao}) {
      const notifyDelete = () => toast.error("Empresa apagado!");
      const [modalIsOpen, setIsOpen] = useState(false);
      const [data, setData] = useState([]);
     
    
      useEffect(()=> {
            const getData = async () => {
                  try {
                        const response = await api.get("/company")
                        
                        setData(response.data)
                        console.log(response.data, "teste123213")
                  } catch (error) {
                        console.log(error)
                  }
                  
            }
            getData();
          },[])
  
     
  
        
  
        function openModal() {
              setIsOpen(true);
        }
  
        function closeModal() {
          console.log("false")
              setIsOpen(false);
        }
  
  
        async function deleteEmpresa(id) {
              try {
                    console.log(id,"id")
                await api.delete(`/company/${id}`);
                
                notifyDelete();
                funcao();
                closeModal();
              } catch (error) {
                closeModal();
                throw error;
                
                console.log(error.response)
              }
            }
  
    return (
      <>
      <div>
      <ButtonDelete
             
                onClick={() => {
                  openModal();
                }}
              >
             <CloseIcon></CloseIcon>
              </ButtonDelete>
              
            </div>  
                
        
      <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={
        {overlay:{
            backgroundColor: 'rgba(153, 153, 153, 0.5)'
        },
        content: {
            
            width: '50vw',
            height: "45vh",
            border: 'none',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            backgroundColor: '#efefef',
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        }
        }
    }
      >
             <TitleDiv>
             <h1>Tem certeza que deseja deletar a Empresa?</h1>
             </TitleDiv>
            <ButtonDiv>
              <ButtonClose color='green' onClick={()=>{deleteEmpresa(id)}}>
                    <h1>Sim</h1></ButtonClose>
              <ButtonClose color='red' onClick={()=>{closeModal()}}>
              <h1>Não</h1></ButtonClose>
             
            </ButtonDiv>
      </Modal>
      </>
    )
  }
  
  export default ModalDeleteEmpresa