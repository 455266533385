import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

//Components
import ReturnIcon from "../../../../Assets/back-icon.png";
import PlusIcon from "../../../../Assets/plus-icon.png";
import { AiFillCloseCircle } from "react-icons/ai";
//Auth
import { useAuth } from "../../../../Context/auth";
//Components
import Sidebar from "../../../../Components/SidebarManager";
import api from "../../../../Services/api";
//import SidebarOptions from "../../../../Components/SidebarOptions";

import plus from "../../../../Assets/plus.svg";

//Icons
import SearchIcon from "@material-ui/icons/Search";

import "./index.css";
import styled from "styled-components";
import AddLei from "./Legislation/AddLei";
import axios from "axios";
import ContratosModal from "./Contracts/ContratosModal";
import ManutencaoModal from "./Manutencao/ManutencaoModal";
import ArrowBack from "../../../../Components/ResponsiveArrowBack";
import NewSideBar from "../../../../Components/NewComponents/NewSideBar";

function LeiCard({ fetch, data }) {
  const Container = styled.div`
    padding: 20px;
    border: 1px solid #000;
    display: flex;
    position: relative;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    border-radius: 8px;
  `;

  const NameWrapp = styled.div`
    word-wrap: break-word;
    padding: 2px 34px 2px 0;

    width: 100%;
    box-sizing: border-box;
  `;

  const Name = styled.a`
    text-decoration: none;
    color: #000;
    word-wrap: break-word;
    width: 100%;
    box-sizing: border-box;
    &:hover {
      color: #4fabe1;
    }
  `;

  const Close = styled.div`
    height: 24px;
    width: 24px;
    color: grey;
    transition: 200ms;
    position: absolute;
    right: 20px;
    &:hover {
      color: red;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  `;

  function handleDelete() {
    api.delete(`/legislation/${data.id}`);
    fetch();
  }

  return (
    <Container>
      <NameWrapp>
        <Name href={data.link}>{data.name}</Name>
      </NameWrapp>
    </Container>
  );
}

function LegislacaoModal() {
  const [leis, setLeis] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [procurar, setProcurar] = useState("");

  function getFederalLeis() {
    const res = api.get("/legislation/federal");
    return res;
  }

  function getMuniciapalLeis() {
    const res = api.get("/legislation/municipal");
    return res;
  }

  function getStateLeis() {
    const res = api.get("/legislation/state");
    return res;
  }

  const fetch = async () => {
    const federal = await getFederalLeis();
    const municipal = await getMuniciapalLeis();
    const state = await getStateLeis();

    setLeis([...federal.data, ...municipal.data, ...state.data]);
  };

  useEffect(() => {
    fetch();
  }, []);

  const list = [{}];

  //Estilos do select
  const styles = {
    container: (base) => ({
      ...base,
      width: "15vw",
      borderRadius: "5px",
      height: "5vh",
    }),
  };
  //Options Select
  const options = [
    { value: "federal", label: "Federal" },
    { value: "municipal", label: "Municipal" },
    { value: "state", label: "Estadual" },
  ];

  const [isVisible, setIsVisible] = useState(false);

  function handleAddMntc(lei) {
    console.log(list);
  }

  return (
    <div className="managementModalContainer">
      <h1>Legislação</h1>
      <div className="mngm-content">
        <h2 className="mngm-mntc-eqp-title"> Leis</h2>
        <div style={{ display: "flex" }}>
          <div id="searchContainer">
            <SearchIcon />
            <input
              id="searchInput"
              placeholder="Pesquisar"
              onChange={(event) => {
                setProcurar(event.target.value);
              }}
            />
          </div>
          <Select
            options={options}
            value={selectedOption}
            placeholder={"Filtre pela lei"}
            isClearable={true}
            backspaceRemovesValue={true}
            styles={styles}
            onChange={(e) => {
              setSelectedOption(e);
            }}
          />
        </div>
        <div className="mngm-mntc-eqp-list">
          {leis
            .filter((lei) => {
              if (selectedOption == null) {
                return lei;
              } else if (
                lei.type
                  .toLowerCase()
                  .includes(selectedOption.value.toLowerCase())
              ) {
                return leis;
              }
            })
            .filter((lei) => {
              if (procurar == "") {
                return lei;
              } else if (
                lei?.name.toLowerCase().includes(procurar.toLowerCase())
              ) {
                return lei;
              }
            })
            .map((lei) => (
              <LeiCard fetch={fetch} data={lei}></LeiCard>
            ))}
        </div>
      </div>
      <AddLei
        handleAddMntc={fetch}
        isVisible={isVisible}
        changeVisibility={setIsVisible}
      ></AddLei>
    </div>
  );
}

export default function Management() {
  const [selected, setSelected] = useState(<LegislacaoModal />);

  return (
    <div id="homeContent">
      <NewSideBar rota={"manager"} tipo={"Síndico"}></NewSideBar>
      <div
        id="managementCenter"
        style={{
          width: "100%",
          overflowY: "auto",
          paddingLeft: "56px",
        }}
      >
        <div className="managementOptionsWrap">
          <div className="mngm-opt-title">
            <ArrowBack></ArrowBack>
            <p> Gestão </p>
          </div>

          <div className="managementOptionsContainer">
            <button onClick={() => setSelected(<LegislacaoModal />)}>
              Legislação
            </button>
            <button onClick={() => setSelected(<ContratosModal />)}>
              Contratos
            </button>
            <button onClick={() => setSelected(<ManutencaoModal />)}>
              Manutenção
            </button>
          </div>
        </div>
        <div className="managementModalWrap">{selected}</div>
      </div>
    </div>
  );
}

/*
 <button onClick={() => setSelected(<LegislacaoModal />)}>
              Legislação
            </button>

*/
