import React from 'react'
import pdf from "../../../Assets/Termos de Uso - Condomíniolegal.app - Usuários.docx.pdf";


function TermosDeUso() {
  return (
    <div style={{width:"100vw", height:"100vh"}}>
        <embed src={pdf} style={{width:"100vw", height:"100vh"}} />
    </div>
  )
}

export default TermosDeUso