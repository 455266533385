import React from "react";
import Lottie from "react-lottie";

import * as loading from "../../Assets/LottieAnimations/99109-loading.json";

import "./index.css";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loading.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default function Loading() {
  return (
    <div id="loadingContainer">
      <Lottie options={defaultOptions} height={40} width={40} />
    </div>
  );
}
