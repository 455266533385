import styled from "styled-components";

export const ButtonCriar = styled.button`
display: flex;
  align-items: center;
  width: 40vw;
  height: 60px;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  background-color: #ee6f57;
  color: white;
  cursor: pointer;
  width: fit-content;
  border: none;
  border-radius: 20px;

`