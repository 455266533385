import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Formik } from "formik";
//Service
import api from "../../../../Services/api";

//Toast
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NewSideBar from "../../../../Components/NewComponents/NewSideBar";
//Components
import Loading from "../../../../Components/Loading/index";
import Sidebar from "../../../../Components/SidebarAdmin";
import { InputWrap } from "../../Manager/Management/Legislation/AddLei/style";
//import SidebarOptions from "../../../../Components/SidebarOptions";
import ArrowBack from "../../../../Components/ResponsiveArrowBack";
//Icons
import SearchIcon from "@material-ui/icons/Search";

import "./index.css";
import { Search } from "@material-ui/icons";
import ModalDeleteCondominio from "./modal";

function CondominiumListPage() {
  const notifyDelete = () => toast.error("Condomínio deletado!");
  const [modalIsOpen, setIsOpen] = useState(false);

  function closeModal() {
    console.log("false");
    setIsOpen(false);
  }

  useEffect(() => {
    getData();
    return () => {
      setCondominios([]);
    };
  }, []);

  const history = useHistory();

  const notifySucess = () =>
    toast.success("Situação do condomínio alterada com sucesso");
  const notifyError = () =>
    toast.error("Erro ao alterar situação do condomínio");

  const [condominios, setCondominios] = useState([]);
  const [procurar, setProcurar] = useState("");
  const [filter, setFilter] = useState("condominium_name");
  const [filterEnable, setFilterEnable] = useState(true);

  async function getData() {
    try {
      const response = await api.get("/condominium");
      setCondominios(response.data);
    } catch (error) {
      throw error;
    }
  }

  async function condominiumBlock(cond_id) {
    try {
      const response = await api.get(`/condominium/block/${cond_id}`);
      console.log(response, "oi");
      getData();

      notifySucess();
    } catch (error) {
      notifyError();
      throw error;
    }
  }

  return (
    <div id="homeContent">
      <NewSideBar tipo={"Administrador"} rota={"admin"}></NewSideBar>
      <div style={{ width: "100%", overflowY: "auto" }}>
        <div
          className="pageTitleContainer"
          id="x"
          style={{
            marginLeft: "0px",
            paddingLeft: "56px",
            width: "100%",
          }}
        >
          <ArrowBack />

          <p id="pageTitle">Condomínios cadastrados</p>
        </div>

        <div id="listArea" style={{ marginLeft: "0px", paddingLeft: "56px" }}>
          <div style={{ display: "flex" }}>
            {filter ? (
              <div id="searchContainer">
                <SearchIcon />
                <input
                  id="searchInput"
                  placeholder="Pesquisar"
                  onChange={(event) => {
                    setProcurar(event.target.value);
                  }}
                />
              </div>
            ) : null}
            <button
              style={{
                border: "none",
                borderRadius: "20px",
                backgroundColor: "#00334E",
                color: "white",
                padding: "4px 12px",
                marginLeft: "5px",
                height: "50px",
                cursor: "pointer",
              }}
              onClick={() => {
                setFilterEnable(!filterEnable);
              }}
            >
              <p>
                <b>Filtro: {filterEnable ? "Ativos" : "Bloqueados"}</b>
              </p>
            </button>
            <InputWrap
              style={{
                marginBottom: "32px",
                marginLeft: "10px",
                width: "200px",
              }}
            >
              <select
                style={{
                  border: "none",
                  borderLeft: "4px solid #00334E",
                  borderRadius: "20px",
                  backgroundColor: "#f8f8f8",
                  padding: "4px 12px",
                }}
                placeholder="None"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              >
                <option value="condominium_name">Nome do condomínio</option>
                <option value="manager_name">Nome do Síndico</option>
                <option value="state">Estado</option>
                <option value="city">Cidade</option>
                <option value="district">Bairro</option>
                <option value="cep">CEP</option>
                <option value="street">Rua</option>
                <option value="creation_date">Data de criação</option>
                <option value="unities">Número de unidades</option>
              </select>
            </InputWrap>
          </div>
          <p style={{ marginTop: 16, marginBottom: 16, fontSize: 20 }}>
            Condomínios {filterEnable ? "Ativos" : "Bloqueados"}
          </p>
          {condominios
            .filter((condominio) => {
              if (filterEnable === condominio?.enable) {
                if (procurar == "") {
                  return condominio;
                } else if (
                  condominio?.condominium_name
                    ? condominio?.condominium_name
                        .toLowerCase()
                        .includes(procurar.toLowerCase()) &&
                      filter === "condominium_name"
                    : null
                ) {
                  return condominio;
                } else if (
                  condominio?.manager_name
                    ? condominio?.manager_name
                        .toLowerCase()
                        .includes(procurar.toLowerCase()) &&
                      filter === "manager_name"
                    : null
                ) {
                  return condominio;
                } else if (
                  condominio?.state
                    ? condominio?.state
                        .toLowerCase()
                        .includes(procurar.toLowerCase()) && filter === "state"
                    : null
                ) {
                  return condominio;
                } else if (
                  condominio?.city
                    ? condominio?.city
                        .toLowerCase()
                        .includes(procurar.toLowerCase()) && filter === "city"
                    : null
                ) {
                  return condominio;
                } else if (
                  condominio?.district
                    ? condominio?.district
                        .toLowerCase()
                        .includes(procurar.toLowerCase()) &&
                      filter === "district"
                    : null
                ) {
                  return condominio;
                } else if (
                  condominio?.cep
                    ? condominio?.cep
                        .toLowerCase()
                        .includes(procurar.toLowerCase()) && filter === "cep"
                    : null
                ) {
                  return condominio;
                } else if (
                  condominio?.street
                    ? condominio?.street
                        .toLowerCase()
                        .includes(procurar.toLowerCase()) && filter === "street"
                    : null
                ) {
                  return condominio;
                } else if (
                  condominio?.creation_date
                    ? condominio?.creation_date
                        .toLowerCase()
                        .includes(procurar.toLowerCase()) &&
                      filter === "creation_date"
                    : null
                ) {
                  return condominio;
                } else if (
                  condominio?.unities
                    ? condominio?.unities
                        .toLowerCase()
                        .includes(procurar.toLowerCase()) &&
                      filter === "unities"
                    : null
                ) {
                  return condominio;
                }
              }
            })
            .map((condominio) => (
              <div className="data-container" style={{ minHeight: "550px" }}>
                <div id="data-safe-area">
                  <div className="condominium-name-title">
                    Nome: {condominio?.condominium_name}
                  </div>
                  <div className="manager-name-title">
                    Síndico: {condominio?.manager_name}
                  </div>
                  <div className="manager-name-title">
                    Contato: {condominio?.contact}
                  </div>
                  <div className="manager-name-title">
                    Estado: {condominio?.state}
                  </div>
                  <div className="manager-name-title">
                    Cidade: {condominio?.city}
                  </div>
                  <div className="manager-name-title">
                    Bairro: {condominio?.district}
                  </div>
                  <div className="manager-name-title">
                    CEP: {condominio?.cep}
                  </div>
                  <div className="manager-name-title">
                    Rua: {condominio?.street}
                  </div>
                  <div className="manager-name-title">
                    Número: {condominio?.number}
                  </div>
                  <div className="manager-name-title">
                    Unidades: {condominio?.unities}
                  </div>
                  <div className="manager-name-title">
                    Contém piscina: {condominio?.pool ? "Sim" : "Não"}
                  </div>
                  <div className="manager-name-title">
                    Data de criação: {condominio?.creation_date}
                  </div>
                  <div
                    className="manager-name-title"
                    style={{ marginTop: "1%" }}
                  >
                    Situação: {condominio?.enable ? "Ativo" : "Bloqueado"}
                  </div>

                  <div id="ButtonContainer2">
                    <button
                      id="editButton"
                      style={{ marginLeft: "0" }}
                      onClick={() => {
                        history.push(
                          `/admin/registercondominium/edit/${condominio?.id}`
                        );
                      }}
                    >
                      <span>
                        <b>Editar</b>
                      </span>
                    </button>

                    <button
                      onClick={() => {
                        condominiumBlock(condominio?.id);
                      }}
                      id="blockButton"
                    >
                      <span
                        style={{
                          fontSize: condominio?.enable ? "80%" : "100%",
                        }}
                      >
                        <b>{condominio?.enable ? "Bloquear" : "Ativar"}</b>
                      </span>
                    </button>

                    {/*<button
                  id="deleteButton"
                  style={{marginLeft: "0"}}
                  onClick={() => {
                    deleteCond(condominio?.id);
                  }}
                >
                  <span ><b>Deletar</b></span>
                  
                </button>*/}
                    <ModalDeleteCondominio
                      func={() => getData()}
                      id={condominio?.id}
                    ></ModalDeleteCondominio>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default CondominiumListPage;
