import styled from "styled-components";

export const InfoDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

export const TextCard = styled.text`
  font-size: 30px;
  padding-left: 10px;
  max-width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
