import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";

import Loading from "../../../Components/Loading/index";

import "./index.css";
import api from "../../../Services/api";

export default function ForgotPass() {
  const history = useHistory();
  const [loading, SetLoading] = useState(false);
  const loginValidationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Entre com um endereço de e-mail válido")
      .required("Endereço de e-mail obrigatório"),
  });

  return (
    <div className="all">
      <div id="esqueciContainer">
        <div id="esqueciMinorContainer">
          <p id="esqueciTitle">Redefinição de Senha</p>
          <p id="esqueciText">
            Para redefinir sua senha, digite seu email para enviarmos um link de
            redefinição.
          </p>
          <Formik
            initialValues={{
              email: "",
            }}
            onSubmit={async (values) => {
              SetLoading(true);
              console.log(values);
              try{
                 const data = { email:values.email }
                 const response = await api.post("/password", data)
                 console.log(response.data)
                 history.push({ 
                  pathname: '/esqueci-confirmação',
                  state: values.email
                 });
              }catch(e){
                console.log(e)
              }
              SetLoading(false)
            }}
            validationSchema={loginValidationSchema}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              errors,
              isValid,
              touched,
            }) => (
              <form onSubmit={handleSubmit} className="forgotPassForm">
                {touched.email && errors.email && (
                  <div id="errorMessage">{errors.email}</div>
                )}
                <input
                  name="email"
                  type="email"
                  placeholder="Email"
                  className="inputText"
                  onChange={handleChange("email")}
                  value={values.email}
                />
                {loading ? (
                  <Loading />
                ) : (
                  <button type="submit" className="handleButton" id="sendEmail">
                    <p>Enviar</p>
                  </button>
                )}
              </form>
            )}
          </Formik>

          <Link to="/">
            <button id="signInBack2">Login</button>
          </Link>
        </div>
      </div>
    </div>
  );
}
