import styled from "styled-components";

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 3%;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

export const Container = styled.div`
  width: 70%;
  margin-left: auto;
  margin-right: auto;
`;

export const ContainerLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextEmpty = styled.p`
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 20px;
`;

export const ContainerCard = styled.div`
  max-width: 100%;

  background-color: #efefef;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  margin-bottom: 30px;
  word-break: break-all;
  transition: 0.25s;
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
`;

export const ViewInfos = styled.div`
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
`;

export const ViewInfoSup = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
`;

export const ViewInfoInf = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 15px;
`;

export const TextVisitor = styled.p`
  font-size: 17px;

  font-family: "Montserrat", sans-serif;
  font-style: normal;
  margin-top: 3px;
`;

export const Line = styled.div`
  width: 90%;
  height: 1px;
  background-color: black;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const ContainerText = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
`;
