import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Formik } from "formik";
//Service
import api from "../../../../Services/api";

//Toast
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Components
import Loading from "../../../../Components/Loading/index";
import Sidebar from "../../../../Components/SidebarManager";
//import SidebarOptions from "../../../../Components/SidebarOptions";
import ArrowBack from "../../../../Components/ResponsiveArrowBack";
//Icons
import SearchIcon from "@material-ui/icons/Search";
import { MdOutlineModeEditOutline } from "react-icons/md";
import plus from "../../../../Assets/plus.svg";
import CloseIcon from "@mui/icons-material/Close";

import "./index.css";
import { Search } from "@material-ui/icons";
import NewSideBar from "../../../../Components/NewComponents/NewSideBar";

export default function NotesListAdministration() {
  const history = useHistory();
  const notifyDelete = () => toast.error("Anotação apagada!");

  useEffect(() => {
    getData();
    return () => {
      setAdverts([]);
    };
  }, []);

  const [adverts, setAdverts] = useState([]);
  const [procurar, setProcurar] = useState("");

  async function getData() {
    try {
      const response = await api.get("/note");
      setAdverts(response.data);
      console.log(response.data, "vsdfsdsa");
    } catch (error) {
      throw error;
    }
  }

  async function deleteAdvert(ad_id) {
    try {
      await api.delete(`/note/${ad_id}`);
      setAdverts(adverts.filter((advert) => advert.id !== ad_id));
      notifyDelete();
    } catch (error) {
      throw error;
    }
  }

  return (
    <div id="homeContent">
      <NewSideBar rota={"employee"} tipo={"Administrativo"} />
      <div id="safeArea">
        <div className="TitleContainer" id="x" style={{ marginLeft: "56px" }}>
          <div className="Title">
            <ArrowBack />
            <p id="pageTitle">Bloco de Notas</p>
          </div>
        </div>

        <div id="listArea" style={{ marginLeft: "56px" }}>
          <div id="TopContainer">
            <div id="searchContainer">
              <SearchIcon />
              <input
                id="searchInput"
                placeholder="Pesquisar"
                onChange={(event) => {
                  setProcurar(event.target.value);
                }}
              />
            </div>
            <Link
              to={{
                pathname: "/administration/notes/create",
                state: { data: "test" },
              }}
              params={{ test: "hello" }}
              style={{ textDecoration: "none", color: "black" }}
            >
              <div id="WarnAddButton">
                <p>Criar anotação</p>
              </div>
            </Link>
          </div>

          {adverts
            .filter((advert) => {
              if (procurar == "") {
                return advert;
              } else if (
                advert?.title.toLowerCase().includes(procurar.toLowerCase())
              ) {
                return advert;
              }
            })
            .map((advert) => (
              <div className="data-container" key={advert?.id}>
                <div id="data-safe-area">
                  <div className="WarnTitle">{advert?.title}</div>
                  <div className="manager-name-title">
                    {advert?.text.split("\n").map((discriptions) => (
                      <p>{discriptions}</p>
                    ))}
                  </div>
                </div>
                <div className="WarnDate">{advert?.date}</div>
                <div id="ButtonContainer">
                  <button
                    id="EditButton"
                    onClick={() => {
                      history.push(`/employee/notes/edit/${advert?.id}`);
                    }}
                  >
                    <MdOutlineModeEditOutline />
                  </button>
                  <button
                    id="deleteButtonWarnings"
                    onClick={() => {
                      deleteAdvert(advert?.id);
                    }}
                  >
                    <CloseIcon></CloseIcon>
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
