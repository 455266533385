import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import api from "../../../../../Services/api";
import ArrowBack from "../../../../../Components/ResponsiveArrowBack";
import Sidebar from "../../../../../Components/SidebarManager";
import { InputWrap } from "../../Management/Legislation/AddLei/style";
import DatePicker from "react-date-picker";
import SearchIcon from "@material-ui/icons/Search";
import {
  ButtonAdd,
  Container,
  ContainerButton,
  ContainerCard,
  ContainerLoading,
  ContainerTrash,
  Line,
  TextEmpty,
  TextVisitor,
  Tittle,
  ViewName,
} from "./styles";

import { Oval } from "react-loader-spinner";

import { GoTrashcan } from "react-icons/go";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function SeeIndication() {
  const [loading, setLoading] = useState(false);
  const [indication, setIndication] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [procurar, setProcurar] = useState("");
  const [data, setData] = useState();
  const [filter, setFilter] = useState('name');

  const notifyError = () =>
    toast.error("Erro ao buscar lista de indicações, faça login novamente!");
  const notifyErrorAdd = () => toast.error("Erro ao adicionar a indicação!");
  const notifySucess = () => toast.success("Indicação excluida!");
  const notifySucessAdd = () => toast.success("Indicação adicionada!");

  async function getData() {
    setLoading(true);
    try {
      const response = await api.get("/service/indication");
      setLoading(false);
      setIndication(response.data);
      console.log(response.data);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }

  async function deleteIndication(id) {
    setLoadingDelete(true);
    try {
      await api.delete(`/service/${id}`);
      notifySucess();
      getData();
      setLoadingDelete(false);
    } catch (error) {
      notifyError();
      setLoadingDelete(false);
      throw error;
    }
  }

  async function addService(id) {
    setAddLoading(true);
    const data = {
      active: true,
    };

    api
      .patch(`/service/${id}`, data)
      .then(() => {
        getData();
        notifySucessAdd();
        setAddLoading(false);
      })
      .catch((e) => {
        notifyErrorAdd();
        setAddLoading(false);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  function onChangeData(data) {
    setData(data);
    setProcurar("");
  }

  return (
    <>
      <Grid container>
        <Sidebar />
        <Grid
          container
          item
          xs={8}
          style={{ marginBottom: "40px", marginLeft: "33vw" }}
        >
          <ContainerButton>
            <ArrowBack />

            <p id="pageTitle">Lista de indicações</p>
          </ContainerButton>
          <Container>
            <div style={{display: 'flex'}}>
              <div id="searchContainer">
                {
                  filter === "date" ?
                  <>
                    <SearchIcon style={{marginRight:"20px"}}/>
                    <DatePicker
                        value={data}
                        onChange={(event) => onChangeData(event)}
                        maxDate={new Date()}
                    />
                  </>
                  :
                  <>
                    <SearchIcon />
                    <input
                      id="searchInput"
                      placeholder="Pesquisar"
                      onChange={(event) => {
                        setProcurar(event.target.value);
                      }}
                    />
                  </>
                }
              </div>
              <InputWrap style={{marginBottom: '32px', marginLeft: '10px', width: '200px'}}>
                <select style={{border: "none",
                    borderLeft: "4px solid #00334E",
                    borderRadius: "20px",
                    minWidth: "150px",
                    backgroundColor: "#f8f8f8",
                    padding: "4px 12px"}}
                  placeholder="None"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                >
                  <option value="name">Nome</option>
                  <option value="tag">Especialidade</option>
                  <option value="date">Data</option>
                </select>
                
              </InputWrap>
            </div>
            {loading ? (
              <ContainerLoading>
                <Oval
                  ariaLabel="loading-indicator"
                  height={100}
                  width={100}
                  strokeWidth={5}
                  strokeWidthSecondary={1}
                  color="#1D6995"
                  secondaryColor="white"
                />
              </ContainerLoading>
            ) : indication.length > 0 ? (
              indication
              .filter((value) => {
                if (procurar === "" && data === "") {
                  return value;
                } else if (
                  value?.name
                    .toLowerCase()
                    .includes(procurar.toLowerCase()) && filter === "name"
                ) {
                  return value;
                } else if (
                  value?.tag
                  .toLowerCase()
                  .includes(procurar.toLowerCase()) && filter === "tag"
              ) {
                return value;
              } else if (
                  value?.date
                    .includes(data ? data.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }) : "") && filter === "date"
                ) {
                  return value;
              }})
              .map((value) => (
                <ContainerCard>
                  <ViewName>
                    <Tittle>Nome: {value.name}</Tittle>
                    {loadingDelete ? (
                      <ContainerTrash>
                        <Oval
                          ariaLabel="loading-indicator"
                          height={20}
                          width={20}
                          strokeWidth={5}
                          strokeWidthSecondary={1}
                          color="#1D6995"
                          secondaryColor="white"
                        />
                      </ContainerTrash>
                    ) : (
                      <ContainerTrash
                        onClick={() => deleteIndication(value.id)}
                      >
                        <GoTrashcan />
                      </ContainerTrash>
                    )}
                  </ViewName>
                  <Line />
                  <TextVisitor>Descrição: {value.description}</TextVisitor>
                  <TextVisitor>Contato: {value.contact}</TextVisitor>
                  <TextVisitor>Especialidade: {value.tag}</TextVisitor>
                  <TextVisitor>Data: {value.date}</TextVisitor>
                  <ButtonAdd
                    disabled={addLoading ? "none" : "auto"}
                    onClick={() => addService(value.id)}
                  >
                    {addLoading ? (
                      <Oval
                        ariaLabel="loading-indicator"
                        height={30}
                        width={30}
                        strokeWidth={5}
                        strokeWidthSecondary={1}
                        color="white"
                        secondaryColor="white"
                      />
                    ) : (
                      "Adicionar"
                    )}
                  </ButtonAdd>
                </ContainerCard>
              ))
            ) : (
              <ContainerLoading>
                <TextEmpty>Sem indicações</TextEmpty>
              </ContainerLoading>
            )}
          </Container>
        </Grid>
      </Grid>
    </>
  );
}
