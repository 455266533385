import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Formik } from "formik";
//Service
import api from "../../../../../Services/api";
import axios from "axios";
import { useAuth } from "./../../../../../Context/auth";

//Toast
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Components
import Loading from "../../../../../Components/Loading/index";
import Sidebar from "../../../../../Components/SidebarManager";
//import SidebarOptions from "../../../../Components/SidebarOptions";
import ArrowBack from "../../../../../Components/ResponsiveArrowBack";
//Icons
import SearchIcon from "@material-ui/icons/Search";

import "./index.css";
import { Photo, Search } from "@material-ui/icons";
import NewSideBar from "../../../../../Components/NewComponents/NewSideBar";

const initialForm = {
  title: "",
  text: "",
};

export default function CreateOccurrence() {
  const { id } = useParams();
  const history = useHistory();
  const [form, setForm] = useState(initialForm);
  const [loading, setLoading] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [handleFile, setHandleFile] = useState("");
  const [people, setPeople] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedManager, setSelectedManager] = useState("");
  const [selectedResponsible, setSelectedResponsible] = useState("");
  const [status, setStatus] = useState("");

  const { user } = useAuth();

  let userJson = user;

  if (typeof user === "string") {
    userJson = JSON.parse(user);
  }

  const userModality = userJson.modality;

  const modalities = {
    admin: { rota: "admin", tipo: "Admin" },
    manager: { rota: "manager", tipo: "Síndico" },
    doorman: { rota: "doorman", tipo: "Porteiro" },
    maintenance: { rota: "maintenance", tipo: "Manutenção" },
    administration: { rota: "employee", tipo: "Administrativo" },
  };

  const { rota, tipo } = modalities[userModality] || { rota: "", tipo: "" };

  const notifySucess = () =>
    toast.success("Ocorrência criada ou alterada com sucesso!");
  const notifyError = () =>
    toast.error("Erro ao criar ou alterar a ocorrência!");
  const notifyFetchError = () =>
    toast.error(
      "Houve uma falha obtendo os dados desta ocorrência. Tente novamente mais tarde"
    );

  useEffect(async () => {
    const retrieveData = async () => {
      if (id) {
        try {
          const response = await api.get(`/occurrence/${id}`);
          const warningData = response.data;
          let setTitle = warningData.title;
          let setText = warningData.text;
          setForm({
            title: `${setTitle}`,
            text: `${setText}`,
          });
          setStatus(warningData.status);
          setHandleFile(warningData.photo_url);
        } catch (ex) {
          notifyFetchError();
          history.goBack();
          throw ex;
        }
      }
    };

    if (userModality === "manager" || userModality === "administration") {
      getEmployees();
    }

    await retrieveData();
  }, [id]);

  function getDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = dd + "/" + mm + "/" + yyyy;

    return today;
  }

  async function getEmployees() {
    const response = await api
      .get("/occurrence/employees/list")
      .then((response) => {
        const data = response.data;
        const employees = data.employees.map((emp) => ({
          ...emp,
          role: "employee",
        }));
        const managers = data.managers.map((mgr) => ({
          ...mgr,
          role: "manager",
        }));
        console.log(employees, "EMPLOYEES");
        console.log(managers, "MANAGERS");
        setPeople([...employees, ...managers]);
      })
      .catch((response) => {
        toast.error("Erro ao buscar funcionários");
      });
  }

  const photoHandler = (event) => {
    setPhoto(event.target.files[0]);
  };

  async function sendWarning(values) {
    setLoading(true);
    const formData = new FormData();
    const data = { ...values };
    if (selectedEmployee) {
      console.log(selectedEmployee, "SELECTED EMPLOYEE");
      data.employee_assigned_id = selectedEmployee.id;
    }
    if (selectedManager) {
      console.log(selectedManager, "SELECTED MANAGER");
      data.manager_assigned_id = selectedManager.id;
    }
    if (id) {
      data.status = status;
    }
    console.log(data, "DATAAAAAAAAAAAAAAAA");
    let completeObj = null;
    if (photo == null) {
      completeObj = {
        ...data,
      };
      console.log(completeObj);
    } else {
      completeObj = {
        ...data,
        header: `content-type:${photo.type}`,
      };
      formData.append("photo", photo);
      console.log(completeObj);
    }
    formData.append("data", JSON.stringify(completeObj));
    try {
      if (!id) await api.post("/occurrence", formData);
      else await api.patch(`/occurrence/${id}`, formData);
      notifySucess();
      // history.goBack()

      // console.log(response);
    } catch (e) {
      console.log("erro");
      console.log(e);
      notifyError();
    }
    setLoading(false);
  }

  return (
    <div id="homeContent" style={{ display: "flex", width: "100vw" }}>
      <NewSideBar rota={rota} tipo={tipo}></NewSideBar>
      <div style={{ marginLeft: "56px", width: "100%", overflowY: "auto" }}>
        <div
          className="pageTitleContainer"
          id="x"
          style={{ marginLeft: "0px" }}
        >
          <ArrowBack />

          <p id="pageTitle">{`${id ? "Editar" : "Criar nova"} ocorrência `}</p>
        </div>

        <div id="listArea" style={{ marginLeft: "0px" }}>
          <Formik
            initialValues={{
              title: form.title,
              text: form.text,
            }}
            enableReinitialize
            onSubmit={(values, { resetForm }) => {
              sendWarning(values);
              setTimeout(function () {
                resetForm({ values: "" });
              }, 1000);
            }}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              errors,
              isValid,
              touched,
            }) => (
              <form onSubmit={handleSubmit}>
                <div id="warnContainer">
                  <p className="registerInputTitle">Assunto</p>
                  {touched.title && errors.title && (
                    <div id="errorMessage2">{errors.title}</div>
                  )}
                  <input
                    className="inputText"
                    id="InputWarnTitle"
                    value={values.title}
                    onChange={handleChange("title")}
                    onBlur={handleBlur("title")}
                  />

                  {userModality === "manager" ||
                  userModality === "administration" ? (
                    <>
                      <p className="registerInputTitle">Responsável</p>
                      {touched.responsible && errors.responsible && (
                        <div id="errorMessage3">{errors.responsible}</div>
                      )}
                      <select
                        className="inputText"
                        id="InputWarnResponsible"
                        value={selectedResponsible}
                        onChange={(event) => {
                          setSelectedResponsible(event.target.value.name);
                          const selectedPersonValue = JSON.parse(
                            event.target.value
                          );
                          const selectedPerson = people.find(
                            (person) =>
                              person.id === selectedPersonValue.id &&
                              person.role === selectedPersonValue.role
                          );
                          console.log(selectedPerson, "SELECTED PERSON");
                          if (
                            selectedPerson &&
                            selectedPerson.role === "employee"
                          ) {
                            setSelectedEmployee(selectedPerson);
                            setSelectedManager("");
                          } else if (
                            selectedPerson &&
                            selectedPerson.role === "manager"
                          ) {
                            setSelectedManager(selectedPerson);
                            setSelectedEmployee("");
                          }
                        }}
                      >
                        <option value="">Selecione o responsável...</option>
                        {people.map((person, index) => (
                          <option key={index} value={JSON.stringify(person)}>
                            {person.name}
                          </option>
                        ))}
                      </select>
                    </>
                  ) : null}

                  {id ? (
                    <>
                      <p className="registerInputTitle">Status</p>
                      <select
                        className="inputText"
                        id="InputWarnStatus"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <option value="Pendente">Pendente</option>
                        <option value="Em progresso">Em progresso</option>
                        <option value="Finalizado">Finalizado</option>
                      </select>
                      {touched.text && errors.text && (
                        <div id="errorMessage2">{errors.text}</div>
                      )}
                    </>
                  ) : null}

                  <p className="registerInputTitle">Escreva aqui</p>
                  {touched.text && errors.text && (
                    <div id="errorMessage2">{errors.text}</div>
                  )}

                  <textarea
                    className="inputText"
                    id="InputWarnBody"
                    value={values.text}
                    onChange={handleChange("text")}
                    onBlur={handleBlur("text")}
                  />
                  <p className="registerInputTitle">Enviar com foto</p>
                  <div id="InputFile">
                    <input
                      id="FileSelect"
                      type="file"
                      onChange={photoHandler}
                    />
                  </div>
                  {/* <div id="InputFile">
                    <input
                      id="FileSelect"
                      type="file"
                      onChange={photoHandler}
                    />
                  </div> */}
                  {loading ? (
                    <Loading />
                  ) : (
                    <button type="submit" className="handleButton">
                      <p>Enviar</p>
                    </button>
                  )}
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
