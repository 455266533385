import styled from "styled-components";

export const Container = styled.div`
  margin-left: 4vw;
  margin-top: 3vh;
`;

export const ContainerButton = styled.div`
  margin-top: -30px;
`;
