import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Formik } from "formik";
import Select from "react-select";

//Service
import api from "../../../../../Services/api";
import axios from "axios";

//Toast
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Components
import Loading from "../../../../../Components/Loading/index";
import Sidebar from "../../../../../Components/SidebarManager";
//import SidebarOptions from "../../../../Components/SidebarOptions";
import ArrowBack from "../../../../../Components/ResponsiveArrowBack";
//Icons
import SearchIcon from "@material-ui/icons/Search";

import "./index.css";
import { Photo, Search } from "@material-ui/icons";
import { initial } from "lodash";

/* const initialForm = {
  name: "",
  contact: "",
  description: "",
  tag:""
}; */


export default function CreateService() {
  const { id } = useParams();
  const history = useHistory();
  //const [form, setForm] = useState(initialForm);
  const [loading, setLoading] = useState(false);
  //const [photo, setPhoto] = useState(null);
  const [handleFile, setHandleFile] = useState("");
  //states para envio pro back do novo serviço
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [description, setDescription] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
 

  const notifySucess = () =>
    toast.success(id ? "Serviço editado!" : "Serviço criado!");
  const notifyError = () => toast.error(id ? "Erro ao editar serviço!" : "Erro ao criar serviço!" );
  const notifyFetchError = () =>
    toast.error(
      "Houve uma falha obtendo os dados desse serviço. Tente novamente mais tarde"
    );

  useEffect( () => {
    const retrieveData = async () => {
      if (id) {
        try {
          const response = await api.get(`/service/${id}`);
          const warningData = response.data;
          console.log(warningData, "warning data");
          setName(warningData.name);
          console.log(name);
          setContact(warningData.contact);
          console.log(contact);
          setDescription(warningData.description);
          console.log(description);
          console.log(response, "dados do useEffect");
          console.log(warningData, "warning data");
          setHandleFile(warningData.photo_url);
          console.log(handleFile, "handleFile do useEffect");
          setSelectedOption(warningData.tag);
          
        } catch (ex) {
          notifyFetchError();
          history.goBack();
          throw ex;
        }
      }
    };
    retrieveData();
  }, [id]);

  //Pegar Tags do Back
  const getOptions = async () => {
    const response = await api.get("/service/tags");
    const options = response.data.tags.map((option) => ({
      value: option,
      label: option,
    }));
    setOptions(options);
  };

  useEffect(() => getOptions(), []); //Setar lista de opcoes.

  //Enviar arquivo para o back
  async function handleSubmitNew() {
    try {
      const data = {
        name: name,
        contact: contact,
        description: description,
        tag: selectedOption.value,
      };
      console.log(selectedOption.value);
      if (!id) await api.post("/service", data);
      else await api.patch(`/service/${id}`, data);
      console.log(data);
      notifySucess();
      history.goBack();
    } catch (error) {
      console.log(error);
      notifyError();
    }
  }

 /* function getDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = dd + "/" + mm + "/" + yyyy;

    return today;
  }

  const photoHandler = (event) => {
    setPhoto(event.target.files[0]);
  };

   async function sendToStorage() {
    try {
      if (photo) {
        const name = photo.name;
        const uriArray = name.split(".");
        console.log(uriArray);
        const tipoImagem = uriArray[uriArray.length - 1];
        console.log(tipoImagem);
        const firstResponse = await api.get(`/file/put_url/${tipoImagem}`);
        console.log(firstResponse);

        const fileName = firstResponse.data.name;
        const mediaUrl = firstResponse.data.url;

        await setHandleFile(fileName);

        console.log(mediaUrl, "primeiro");
        console.log(fileName);
        console.log(handleFile, "useState determinado");

        let blob = photo.slice(0, photo.size, tipoImagem);
        let binaryFile = new File([blob], fileName, {
          type: tipoImagem,
        });

        console.log(photo);
        console.log(binaryFile, "binario");
        console.log(mediaUrl, "segundo");

        await axios.put(mediaUrl, binaryFile, {
          headers: { "x-amz-acl": "public-read", "Content-Type": tipoImagem },
        });
        return fileName;
      } else {
        console.log("está retornando handleFile");
        return handleFile;
      }
    } catch (error) {
      console.log(error);
    }
  }

   async function sendWarning(values) {
    setLoading(true);
    const upload = await sendToStorage();
    console.log(upload, "Upload completo!");
    const data = { ...values };
    console.log(data);
    let completeObj = {
      ...data,
      date: getDate(),
      photo_url: upload,
    };
    console.log(completeObj);
    try {
      if (!id) await api.post("/service", completeObj);
      else await api.patch(`/service/${id}`, completeObj);
      notifySucess();
      // history.goBack()

      // console.log(response);
    } catch (e) {
      console.log("erro");
      console.log(e);
      notifyError();
    }
    setLoading(false);
  } */
  //Funcoes para atualizar o Select com o Formik
 /* const defaultValue = (options, value) => {
    return options.find((options) => options.value === value);
  }; */

  return (
    <div id="homeContent">
      <Sidebar />
      <div>
        <div
          className="pageTitleContainer"
          id="x"
          style={{ marginLeft: "35vw" }}
        >
          <ArrowBack />

          <p id="pageTitle">{`${
            id ? "Editar" : "Registre um novo"
          } serviço`}</p>
        </div>

        <div id="listArea" style={{ marginLeft: "40vw" }}>
          {/* /// Uso do Formik para formulario
          <Formik
            initialValues={{
              name: form.name,
              contact: form.contact,
              description: form.description,
              tag: form.tag
            }}
            enableReinitialize
            onSubmit={(values, { resetForm }) => {
              sendWarning(values);
              setTimeout(function () {
                resetForm({ values: "" });
              }, 1000);
            }}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              values,
              errors,
              isValid,
              touched,
            }) => */}

          <div id="warnContainer">
            <p className="registerInputTitle">Nome da Empresa</p>
            {/*{touched.name && errors.name && (
                    <div id="errorMessage2">{errors.title}</div>
                  )}*/}
            <input
              className="inputText"
              id="InputWarnTitle"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <p className="registerInputTitle">Telefone de contato</p>
            {/*{touched.contact && errors.contact && (
                    <div id="errorMessage2">{errors.contact}</div>
                  )} */}

            <input
              className="inputText"
              id="InputWarnTitle"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
            />

            <p className="registerInputTitle">Especialidade</p>
            <Select
              id="tag"
              className="tag"
              placeholder={id ? selectedOption : "Selecione o serviço"}
              options={options}
              value={selectedOption}
              onChange={(e) => setSelectedOption(e)}
            />

            <p className="registerInputTitle">Descrição</p>
            {/*{touched.description && errors.description && (
                    <div id="errorMessage2">{errors.description}</div>
                  )}*/}

            <textarea
              className="inputText"
              id="InputWarnBody"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            {/* <div id="InputFile">
                    <input
                      id="FileSelect"
                      type="file"
                      onChange={photoHandler}
                    />
                  </div> */}

            <button onClick={handleSubmitNew} className="handleButton" >
              <p>Enviar</p>
            </button>
            {/*<button className="handleButton" id="warnBtn" >
                      <p>Enviar</p>
                    </button>
                      */}
          </div>

          {/*</Formik>*/}
        </div>
      </div>
      {/*<ToastContainer />*/}
    </div>
  );
}
