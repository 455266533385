import React, { useState, useEffect } from "react";
import SindicoImg from "../../../../Assets/menuImages/sindico.png";
import VisitantesImg from "../../../../Assets/menuImages/visitantes.png";
import ChatImg from "../../../../Assets/menuImages/chat.png";
import NotasImg from "../../../../Assets/menuImages/notas.png";
import OcorrenciaImg from "../../../../Assets/menuImages/ocorrencias.png";
import CondominiumImg from "../../../../Assets/menuImages/condominium.jpg";
import VendasImg from "../../../../Assets/menuImages/venda.png";
import ServicosImg from "../../../../Assets/menuImages/servicos.png";
import { Link } from "react-router-dom";

import api from "../../../../Services/api";

import { useAuth } from "../../../../Context/auth";

//Components
//import Loading from "../../../../Components/Loading/index";
import Sidebar from "../../../../Components/SidebarAdmin";
//import SidebarOptions from "../../../../Components/SidebarOptions";

import plus from "../../../../Assets/plus.svg";

import "./index.css";
import NewSideBar from "../../../../Components/NewComponents/NewSideBar";
import { Container, NewPage } from "../../Admin/Empresas/styles";

export default function HomepagePublicidade() {
  const cards = [
    /* { name: "Condomínios", img: SindicoImg, link: "/admin/condominiumlist" },
    {
      name: "Registrar Condominio",
      img: CondominiumImg,
      link: "/admin/registercondominium",
    },
    { name: "Avisos do App", img: OcorrenciaImg, link: "/admin/avisos" }, */
    {
      name: "Propaganda",
      img: VisitantesImg,
      link: "/publicidade/propagandalist",
    },
    /*  {
      name: "Pedidos de cadastro",
      img: ChatImg,
      link: "/admin/managerapproval",
    },
    { name: "Bloco de notas", img: NotasImg, link: "/admin/notes" }, */
    /* { name: "Imóveis", img: VendasImg, link: "/publicidade/Imoveis" }, */
    { name: "Serviços", img: ServicosImg, link: "/publicidade/servicos" },
    /* { name: "Empresas", img: ServicosImg, link: "/admin/Empresas" }, */
  ];

  const { user } = useAuth();

  useEffect(() => {
    console.log(user.modality);
  }, []);

  return (
    <div id="homeContent">
      <NewSideBar tipo={"Empresa de Publicidade"} rota={"publicidade"} />
      <Container>
        <div id="homeCenter" style={{ paddingLeft: "50px" }}>
          {/*
        <p className="welcomeTitle" id="homeWelcome">
          Bem vindo, {name}!
        </p>
        <p className="welcomeTitle" id="homeWelcome">
          Bem vindo, {user.name}!
        </p>
        <Link
            to="/manager/criar-aviso"
            style={{ textDecoration: "none", color: "black" }}
          >
        <div id="option">
            <img src={plus} id="plusIcon" />
            <p>Criar aviso</p>
        </div>
        </Link>
      */}
          {cards.map((card) => (
            <Link
              style={{ textDecoration: "none" }}
              to={card.link ? card.link : "/imobiliaria/home"}
            >
              <div id="home-card">
                <img src={card.img} />
                <h1>{card.name}</h1>
              </div>
            </Link>
          ))}
        </div>
      </Container>
    </div>
  );
}

/*
 <div style={{ width: "100%", display: "flex" }}>
      <NewSideBar tipo={"Empresa de Publicidade"} rota={"publicidade"} />
      <div style={{ width: "100%" }}>
   
        {cards.map((card) => (
          <Link
            style={{ textDecoration: "none" }}
            to={card.link ? card.link : "/publicidade/home"}
          >
            <div id="home-card">
              <img src={card.img} />
              <h1>{card.name}</h1>
            </div>
          </Link>
        ))}
      </div>
    </div>

*/
