import React from "react";
import { InfoDiv, TextCard } from "./styes";

function CardInfo({ name, info }) {
  return (
    <InfoDiv>
      <h1>{name}</h1>
      <TextCard>{info}</TextCard>
    </InfoDiv>
  );
}

export default CardInfo;
