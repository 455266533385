import React, { useEffect, useState } from "react";
import {
  CarContainer,
  Container,
  HeaderDiv,
  NewPage,
} from "../Empresas/styles";

import NewHeader from "../../../../Components/NewComponents/Header";
import NewSideBar from "../../../../Components/NewComponents/NewSideBar";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
//Formik
import { Formik } from "formik";
//yup
import * as yup from "yup";
//Auth

//Components
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../../Services/api";
import { ButtonEdit, DivCenter } from "./styles";

function EditarEmpresas(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [verSenha, setVerSenha] = useState(false);
  const [tipoEmpresa, setTipoEmpresa] = useState("");
  const history = useHistory();
  const [initialValues, setInitialValues] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await api.get(`/company/${id}`);
        let data = response.data;
        setInitialValues({
          nomeFantasia: data.corporate_name,
          name: data.name,
          cnpj: data.cnpj,
          telefone: data.phone_number,
          cep: data.cep,
          city: data.city,
          state: data.state,
          neighborhood: data.district,
          email: data.email,
          modality: data.modality,
        });
        console.log(response.data, "teste123213");
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  console.log(initialValues, "inicial");

  const cadastroValidationSchema = yup.object().shape({
    nomeFantasia: yup.string().required("Nome fantasia obrigatório"),
    name: yup.string().required("Razão social obrigatória"),
    cnpj: yup.string().required("CNPJ obrigatório"),
    telefone: yup.string().required("Telefone obrigatório"),
    cep: yup.string().required("CEP obrigatório"),
    city: yup.string().required("Cidade obrigatória"),
    state: yup.string().required("Estado obrigatório"),
    neighborhood: yup.string().required("Bairro obrigatório"),
    modality: yup.string().required("Bairro obrigatório"),
    email: yup
      .string()
      .email("Entre com um endereço de e-mail válido")
      .required("Endereço de e-mail obrigatório"),
  });

  const Atualizar = async (values) => {
    setLoading(true);
    try {
      const data = {
        name: values.name,
        email: values.email,
        city: values.city,
        state: values.state,
        corporate_name: values.nomeFantasia,
        cnpj: values.cnpj,
        cep: values.cep,
        modality: values.modality,
        district: values.neighborhood,
        phone_number: values.telefone,
      };
      const formData = new FormData();
      formData.append("data", JSON.stringify(data));
      console.log(data, "OII");
      const res = await toast.promise(api.patch(`/company/${id}`, formData), {
        pending: "Enviando...",
        success: "Editado com sucesso!",
        error: {
          render({ data }) {
            return `Erro ao Editar! {${data?.response?.data?.msg}}`;
          },
        },
      });
      console.log(res, "DEU BOM");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCepBlur = async (event, setFieldValue) => {
    const cep = event.target.value.replace(/\D/g, "");

    if (cep.length === 8) {
      try {
        const response = await api.get(`https://viacep.com.br/ws/${cep}/json/`);
        const { data } = response;
        if (!data.erro) {
          setFieldValue("cep", cep);
          setFieldValue("city", data.localidade);
          setFieldValue("state", data.uf);
          setFieldValue("neighborhood", data.bairro);
        } else {
          // Limpar os outros campos se o CEP for inválido ou não for encontrado
          setFieldValue("cep", "");
          setFieldValue("city", "");
          setFieldValue("state", "");
          setFieldValue("neighborhood", "");

          console.log("CEP inválido ou não encontrado");
        }
      } catch (error) {
        // Tratar erros de requisição
        console.log("Erro ao buscar CEP", error);
      }
    } else {
      // Limpar os outros campos caso o CEP não tenha 8 dígitos
      setFieldValue("city", "");
      setFieldValue("state", "");
      setFieldValue("neighborhood", "");
    }
  };

  const campos = [
    {
      label: "nomeFantasia",
      Nome: "Nome Fantasia",
      type: "text",
    },
    {
      label: "name",
      Nome: "Razão social",
      type: "text",
    },
    {
      label: "cnpj",
      Nome: "CNPJ",
      type: "text",
    },
    {
      label: "telefone",
      Nome: "Telefone",
      type: "tel",
    },
    {
      label: "cep",
      Nome: "CEP",
      type: "text",
    },
    {
      label: "city",
      Nome: "Cidade",
      type: "text",
    },
    {
      label: "state",
      Nome: "Estado",
      type: "text",
    },
    {
      label: "neighborhood",
      Nome: "Bairro",
      type: "text",
    },
    {
      label: "email",
      Nome: "Email",
      type: "email",
    },
    {
      label: "modality",
      Nome: "Modality",
      type: "text",
    },
  ];

  return (
    <NewPage>
      <NewSideBar tipo={"Administrador"} rota={"admin"}></NewSideBar>
      <Container>
        <HeaderDiv>
          <NewHeader name="Editar empresa"></NewHeader>
        </HeaderDiv>
        <CarContainer>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(values) => {
              /* console.log("enviado", values); */
              Atualizar(values);
            }}
            validationSchema={cadastroValidationSchema}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              errors,
              isValid,
              touched,
              setFieldValue,
            }) => (
              <form
                id="EditarEmpresa"
                onSubmit={handleSubmit}
                style={{ width: "80%" }}
              >
                <div id="inputs" style={{ maxHeight: "100%" }}>
                  {campos.map((campo) => {
                    if (campo.label === "cep") {
                      return (
                        <div className="inputBox">
                          {console.log(initialValues, "tantoFAz")}

                          <div
                            className={
                              touched[campo.label] && errors[campo.label]
                                ? "erroActive"
                                : "errorMessage"
                            }
                            id="errorMessage"
                          >
                            {errors[campo.label]}
                          </div>

                          <input
                            type={campo.type}
                            name={campo.label}
                            placeholder={campo.Nome}
                            className="inputText"
                            onChange={handleChange(campo.label)}
                            value={values[campo.label]}
                            onBlur={(e) => handleCepBlur(e, setFieldValue)}
                          />
                        </div>
                      );
                    } else {
                      return (
                        <div className="inputBox">
                          <div
                            className={
                              touched[campo.label] && errors[campo.label]
                                ? "erroActive"
                                : "errorMessage"
                            }
                            id="errorMessage"
                          >
                            {errors[campo.label]}
                          </div>

                          <input
                            type={campo.type}
                            name={campo.label}
                            placeholder={campo.Nome}
                            className="inputText"
                            onChange={handleChange(campo.label)}
                            value={values[campo.label]}
                            onBlur={handleBlur(campo.label)}
                          />
                        </div>
                      );
                    }
                  })}
                </div>
              </form>
            )}
          </Formik>
        </CarContainer>
        <DivCenter>
          <button
            className="handleButton"
            style={{ width: "20vw" }}
            type="submit"
            form="EditarEmpresa"
          >
            Editar
          </button>
        </DivCenter>
      </Container>
    </NewPage>
  );
}

export default EditarEmpresas;
