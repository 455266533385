import styled from "styled-components";

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 3%;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

export const Container = styled.div`
  width: 70%;
  margin-left: auto;
  margin-right: auto;
`;

export const ContainerCard = styled.div`
  max-width: 100%;

  background-color: #efefef;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  margin-bottom: 30px;
  word-break: break-all;
  transition: 0.25s;
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
`;

export const ContainerLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextEmpty = styled.p`
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 20px;
`;

export const Tittle = styled.h1`
  font-size: 17px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
`;

export const Line = styled.div`
  height: 1px;
  width: 95%;
  background-color: black;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
`;

export const TextVisitor = styled.p`
  font-size: 17px;

  font-family: "Montserrat", sans-serif;
  font-style: normal;
  margin-top: 3px;
`;

export const ViewName = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ContainerTrash = styled.div`
  font-size: 20px;
  margin-right: 20px;
  cursor: pointer;
`;
