import axios from "axios";

const api = axios.create({
  baseURL: "https://stingray-app-p2cb9.ondigitalocean.app",
});

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("@SWCondominio:token");

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
