import styled from "styled-components";

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 3%;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

export const Container = styled.div`
  width: 70%;
  margin-left: auto;
  margin-right: auto;
`;

export const ContainerCard = styled.div`
  max-width: 100%;

  background-color: #efefef;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  margin-bottom: 30px;
  word-break: break-all;
  transition: 0.25s;
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
`;

export const ContainerLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextEmpty = styled.p`
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
`;

export const Tittle = styled.h1`
  font-size: 17px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
`;

export const Line = styled.div`
  height: 1px;
  width: 95%;
  background-color: black;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
`;

export const TextVisitor = styled.p`
  font-size: 17px;

  font-family: "Montserrat", sans-serif;
  font-style: normal;
  margin-top: 3px;
`;

export const Input = styled.input`
  border-radius: 16px;
  width: 100%;
  background-color: #efefef;
  padding-left: 20px;
  height: 40px;
  padding-right: 20px;
`;

export const LabelInput = styled.h2`
  font-size: 17px;

  font-family: "Montserrat", sans-serif;
  font-style: normal;
  margin-top: 3px;
  margin-bottom: 3px;
`;

export const ContainerInput = styled.div`
  margin-bottom: 15px;
`;

export const ContainerParking = styled.div`
  margin-top: 20px;
  width: 100%;
`;

export const ContainerDecision = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`;

export const ButtonDecision = styled.div`
  width: 30%;
  height: 50px;
  background-color: #efefef;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ButtonDecisionActive = styled.div`
  width: 30%;
  height: 50px;
  background-color: #1d6995;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
`;

export const ContainerInputs = styled.div`
  width: 80%;
  margin-left: auto;
  margin-right: auto;
`;

export const InputParking = styled.input`
  border-radius: 16px;
  width: 100%;
  background-color: #efefef;
  padding-left: 20px;
  height: 40px;
  margin-top: 10px;
`;

export const SendVisitor = styled.div`
  width: 80%;
  height: 50px;
  background-color: #1d6995;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  margin-top: 10px;
  pointer-events: auto;
`;
