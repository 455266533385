import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SindicoImg from "../../../../Assets/menuImages/sindico.png";
import GestaoImg from "../../../../Assets/menuImages/gestao.jpeg";
import OcorrenciaImg from "../../../../Assets/menuImages/ocorrencias.png";
import DeliveryImg from "../../../../Assets/menuImages/delivery.png";
import CorrespondenciaImg from "../../../../Assets/menuImages/correspondencia.png";
import VendasImg from "../../../../Assets/menuImages/venda.png";
import ServicosImg from "../../../../Assets/menuImages/servicos.png";
import VisitantesImg from "../../../../Assets/menuImages/visitantes.png";
import ChatImg from "../../../../Assets/menuImages/chat.png";
import NotasImg from "../../../../Assets/menuImages/notas.png";
import PoolImg from "../../../../Assets/menuImages/pool.jpg";
import ReservaImg from "../../../../Assets/menuImages/reservas.png";

import api from "../../../../Services/api";
//Auth
import { useAuth } from "../../../../Context/auth";
//Components
//import Loading from "../../../../Components/Loading/index";
import Sidebar from "../../../../Components/SidebarManager";
//import SidebarOptions from "../../../../Components/SidebarOptions";

import plus from "../../../../Assets/plus.svg";

import "./index.css";
import NewSideBar from "../../../../Components/NewComponents/NewSideBar";

export default function HomepageMaintenanceTech() {
  const cards = [
    /* { name: "Síndico", img: SindicoImg, link: "/manager/profile" }, */
    { name: "Gestão", img: GestaoImg, link: "/maintenance/gestao" },
    {
      name: "Ocorrências",
      img: OcorrenciaImg,
      link: "/maintenance/ocorrencias",
    },
    /*  { name: "Delivery chegou", img: DeliveryImg, link: "/manager/delivery" }, */
    /* { name: "Correios", img: CorrespondenciaImg, link: "/manager/mail" }, */
    // { name: "Venda/Locação", img: VendasImg, link: "/manager/venda-e-locacao" },
    // { name: "Serviços", img: ServicosImg, link: "/manager/servicos" },
    /* {
      name: "Meus visitantes",
      img: VisitantesImg,
      link: "/manager/visitantes",
    },
    {
      name: "Vídeo Chamada",
      img: ChatImg,
      link: "/manager/video-conferencia",
    },
    { name: "Acesso à piscina", img: PoolImg, link: "/manager/configuração-de-carteira" },
    { name: "Reservas", img: ReservaImg, link: "/manager/reservations" },
    { name: "Bloco de notas", img: NotasImg, link: "/manager/notes" }, */
  ];

  return (
    <div id="homeContent">
      <NewSideBar tipo={"Técnico de manutenção"} rota={"maintenance"} />
      <div id="homeCenter" style={{ paddingLeft: "56px", width: "100%" }}>
        {/*
          <p className="welcomeTitle" id="homeWelcome">
            Bem vindo, {name}!
          </p>
          <p className="welcomeTitle" id="homeWelcome">
            Bem vindo, {user.name}!
          </p>
          <Link
              to="/manager/criar-aviso"
              style={{ textDecoration: "none", color: "black" }}
            >
          <div id="option">
              <img src={plus} id="plusIcon" />
              <p>Criar aviso</p>
          </div>
          </Link>
        */}
        {cards.map((card) => (
          <Link
            style={{ textDecoration: "none" }}
            to={card.link ? card.link : "/maintenance/home"}
          >
            <div id="home-card">
              <img src={card.img} />
              <h1>{card.name}</h1>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
