import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import { Formik } from "formik";
//Service
import api from "../../../../Services/api";
import CloseIcon from "@mui/icons-material/Close";
//Toast
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Components
import Loading from "../../../../Components/Loading/index";
import Sidebar from "../../../../Components/SidebarManager";
import { InputWrap } from "../Management/Legislation/AddLei/style";
//import SidebarOptions from "../../../../Components/SidebarOptions";
import ArrowBack from "../../../../Components/ResponsiveArrowBack";
//Icons
import SearchIcon from "@material-ui/icons/Search";

import "./index.css";
import { ButtonClose, ButtonDiv, TitleDiv } from "./style";
Modal.setAppElement("#root");

const customStyles = {
  content: {
    width: "50vw",
    justifyContent: "center",
    height: "40vh",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",

    bordeRadios: "10px",
  },
};

function ModalPorteiro(id) {
  const notifyDelete = () => toast.error("Empregado apagado!");
  const [residents, setResidents] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [procurar, setProcurar] = useState("");
  const [filter, setFilter] = useState("name");
  const [modalIsOpen, setIsOpen] = useState(false);

  useEffect(() => {
    getData();
    getEmployee();
    return () => {
      setResidents([]);
      setEmployees([]);
    };
  }, []);

  async function getData() {
    try {
      const response = await api.get("/resident");
      const residents = response.data.map((resident) => ({
        name: resident.name,
        modality: resident.modality === "resident" ? "Residente" : "Síndico",
        complement: resident.complement,
        id: resident.id,
      }));
      setResidents(residents);
    } catch (error) {
      throw error;
    }
  }
  async function getEmployee() {
    try {
      const response = await api.get("/employee");
      const employees = response.data.map((employee) => ({
        name: employee.name,
        modality:
          employee.modality === "doorman" ? "Porteiro" : "Administrador",
        id: employee.id,
      }));
      setEmployees(employees);
    } catch (error) {
      throw error;
    }
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    console.log("false");
    setIsOpen(false);
  }

  async function deleteEmployee(id) {
    try {
      console.log("Oi");
      await api.delete(`/employee/${id}`);
      setEmployees(employees.filter((resident) => resident.id !== id));
      notifyDelete();
      closeModal();
    } catch (error) {
      closeModal();
      throw error;

      console.log(error.response);
    }
  }

  return (
    <>
      <button
        id="deleteButton"
        onClick={() => {
          openModal();
        }}
      >
        <CloseIcon></CloseIcon>
      </button>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(153, 153, 153, 0.5)",
          },
          content: {
            width: "50vw",
            height: "28vh",
            border: "none",
            borderRadius: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            backgroundColor: "#efefef",
            top: "50%",
            left: "55%",
            transform: "translate(-50%, -50%)",
          },
        }}
      >
        <TitleDiv>
          <h1>Tem certeza que deseja deletar o empregado?</h1>
        </TitleDiv>
        <ButtonDiv>
          <ButtonClose
            color="#efefef"
            onClick={() => {
              deleteEmployee(id.id);
            }}
          >
            <h1>Sim</h1>
          </ButtonClose>
          <ButtonClose
            color="#efefef"
            onClick={() => {
              closeModal();
            }}
          >
            <h1>Não</h1>
          </ButtonClose>
        </ButtonDiv>
      </Modal>
    </>
  );
}

export default ModalPorteiro;
