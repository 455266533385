import styled from "styled-components";

export const ButtonSide = styled.button`
  display: flex;
  border: 0;
  width: 15vw;
  height: 8vh;
  margin-left: 1vw;
  background: #00334e;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 1220px) {
    display: none;
    margin-left: 0;
  }
`;

export const ButtonDiv = styled.div`
  display: flex;
  margin-left: 2vw;
  align-items: center;
  @media (max-width: 1220px) {
    margin-left: 0;
  }
`;

export const SideButtonText = styled.h1`
  font-size: 20px;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  padding-left: 1vw;
  color: #fff;
  cursor: pointer;
  &:hover {
    color: black;
    transition: color 100ms ease-in;
  }

  @media (max-width: 1220px) {
    display: none;
  }
`;

export const ImgButton = styled.img`
  display: flex;
  width: ${(props) => (props.width ? props.width : "50px")};
  height: ${(props) => (props.width ? props.width : "50px")};
`;
