import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

//Service
import api from "../../../../Services/api";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
//Toast
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Components
import Loading from "../../../../Components/Loading/index";
import Sidebar from "../../../../Components/SidebarManager";
import { InputWrap } from "../Management/Legislation/AddLei/style";
//import SidebarOptions from "../../../../Components/SidebarOptions";
import ArrowBack from "../../../../Components/ResponsiveArrowBack";
//Icons
import SearchIcon from "@material-ui/icons/Search";

import "./index.css";
import NewSideBar from "../../../../Components/NewComponents/NewSideBar";

export default function ApproveResidents() {
  const notifyDelete = () => toast.error("Usuário apagado!");
  const notifyApprove = () => toast.success("Condômino Aprovado!");
  const [residents, setResidents] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [procurar, setProcurar] = useState("");
  const [filter, setFilter] = useState("name");
  const history = useHistory();

  useEffect(() => {
    getData();
    return () => {
      setResidents([]);
    };
  }, []);

  async function getData() {
    try {
      const response = await api.get("/resident/request");
      const residents = response.data.map((resident) => ({
        name: resident.name,
        modality: resident.modality === "resident" ? "Residente" : "Síndico",
        complement: resident.complement.replace(/:/g, " ").replace(/  +/g, " "),
        id: resident.id,
      }));
      setResidents(residents);
      const responseEmployee = await api.get("/employee/request");
      const employees = responseEmployee.data.map((employee) => ({
        name: employee.name,
        modality:
          employee.modality === "doorman"
            ? "Porteiro"
            : employee.modality === "maintenance"
            ? "Técnico de Manutenção"
            : "Administrador",
        id: employee.id,
      }));
      setEmployees(employees);
    } catch (error) {
      throw error;
    }
  }

  async function deleteResident(id) {
    try {
      await api.delete(`/resident/request/${id}`);
      setResidents(residents.filter((resident) => resident.id !== id));
      notifyDelete();
    } catch (error) {
      throw error;
    }
  }

  async function authorizeResident(id) {
    try {
      const approval = {
        active: "true",
      };
      await api.patch(`/resident/request/${id}`, approval);
      setResidents(residents.filter((resident) => resident.id !== id));
      notifyApprove();
    } catch (error) {
      throw error;
    }
  }

  async function deleteEmployee(id) {
    try {
      await api.delete(`/employee/request/${id}`);
      setEmployees(employees.filter((resident) => resident.id !== id));
      notifyDelete();
    } catch (error) {
      throw error;
    }
  }

  async function authorizeEmployee(id) {
    try {
      const approval = {
        active: "true",
      };
      await api.patch(`/employee/request/${id}`, approval);
      setEmployees(employees.filter((resident) => resident.id !== id));
      notifyApprove();
    } catch (error) {
      throw error;
    }
  }

  return (
    <div id="homeContent">
      <NewSideBar rota={"manager"} tipo={"Síndico"}></NewSideBar>
      <div style={{ marginLeft: "56px", width: "100%", overflowY: "auto" }}>
        <div
          className="pageTitleContainer"
          id="x"
          style={{ marginLeft: "0px" }}
        >
          <ArrowBack />

          <p id="pageTitle">Pedidos de Cadastro</p>
        </div>

        <div id="listArea" style={{ marginLeft: "0px" }}>
          <div style={{ display: "flex" }}>
            {filter ? (
              <div id="searchContainer">
                <SearchIcon />
                <input
                  id="searchInput"
                  placeholder="Pesquisar"
                  onChange={(event) => {
                    setProcurar(event.target.value);
                  }}
                />
              </div>
            ) : null}
            <InputWrap
              style={{
                marginBottom: "32px",
                marginLeft: "10px",
                width: "200px",
              }}
            >
              <select
                style={{
                  border: "none",
                  borderLeft: "4px solid #00334E",
                  borderRadius: "20px",
                  minWidth: "150px",
                  backgroundColor: "#f8f8f8",
                  padding: "4px 12px",
                }}
                placeholder="None"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              >
                <option value="name">Nome</option>
                <option value="modality">Modalidade</option>
                <option value="complement">Complemento</option>
              </select>
            </InputWrap>
          </div>

          {residents
            .filter((resident) => {
              if (procurar == "") {
                return resident;
              } else if (
                resident?.name.toLowerCase().includes(procurar.toLowerCase()) &&
                filter === "name"
              ) {
                return resident;
              } else if (
                resident?.modality
                  .toLowerCase()
                  .includes(procurar.toLowerCase()) &&
                filter === "modality"
              ) {
                return resident;
              } else if (
                resident?.complement
                  ? resident?.complement
                      .toLowerCase()
                      .includes(procurar.toLowerCase()) &&
                    filter === "complement"
                  : null
              ) {
                return resident;
              }
            })
            .map((resident) => (
              <div className="data-container">
                <div id="data-safe-area">
                  <div className="manager-name-title">
                    Nome: {resident?.name}
                  </div>
                  <div className="manager-name-title">
                    Modalidade: {resident?.modality}
                  </div>
                  <div className="manager-name-title">
                    Complemento:{" "}
                    {resident?.complement
                      ? resident?.complement
                      : "Não está definido"}
                  </div>
                </div>
                <div className="WarnDate"></div>
                <div id="ButtonContainer">
                  <button
                    id="EditButton"
                    onClick={() => {
                      authorizeResident(resident?.id);
                    }}
                  >
                    <CheckIcon></CheckIcon>
                  </button>
                  <button
                    id="deleteButtonWarnings"
                    onClick={() => {
                      deleteResident(resident?.id);
                    }}
                  >
                    <CloseIcon></CloseIcon>
                  </button>
                </div>
              </div>
            ))}
          {employees
            .filter((resident) => {
              if (procurar == "") {
                return resident;
              } else if (
                resident?.name.toLowerCase().includes(procurar.toLowerCase()) &&
                filter === "name"
              ) {
                return resident;
              } else if (
                resident?.modality
                  .toLowerCase()
                  .includes(procurar.toLowerCase()) &&
                filter === "modality"
              ) {
                return resident;
              } else if (
                resident?.complement
                  ? resident?.complement
                      .toLowerCase()
                      .includes(procurar.toLowerCase()) &&
                    filter === "complement"
                  : null
              ) {
                return resident;
              }
            })
            .map((resident) => (
              <div className="data-container">
                <div id="data-safe-area">
                  <div className="manager-name-title">
                    Nome: {resident?.name}
                  </div>
                  <div className="manager-name-title">
                    Modalidade: {resident?.modality}
                  </div>
                </div>
                <div className="WarnDate"></div>
                <div id="ButtonContainer">
                  <button
                    id="EditButton"
                    onClick={() => {
                      authorizeEmployee(resident?.id);
                    }}
                  >
                    <CheckIcon></CheckIcon>
                  </button>
                  <button
                    id="deleteButtonWarnings"
                    onClick={() => {
                      deleteEmployee(resident?.id);
                    }}
                  >
                    <CloseIcon></CloseIcon>
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
