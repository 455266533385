import React, { useState, useCallback, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Formik, FieldArray, Field } from "formik";
import * as yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  CarContainer,
  Container,
  HeaderDiv,
  NewPage,
} from "../Empresas/styles";
//Service
import api from "../../../../Services/api";

//Components
import Loading from "../../../../Components/Loading/index";
import Sidebar from "../../../../Components/SidebarAdmin";
//import SidebarOptions from "../../../../Components/SidebarOptions";
import ArrowBack from "../../../../Components/ResponsiveArrowBack";

import NewSideBar from "../../../../Components/NewComponents/NewSideBar";
import NewHeader from "../../../../Components/NewComponents/Header";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const initialForm = {
  condominium_name: "",
  state: "",
  city: "",
  district: "",
  street: "",
  number: "",
  cep: "",
  contact: "",
  unities: "",
  complements: [{ name: "" }],
  pool: false,
};

export default function EditCondominiumPage() {
  const [loading, setLoading] = useState(false);
  const notifySucess = () =>
    toast.success(
      !id
        ? "Cadastro do condomínio realizado!"
        : "Condomínio editado com sucesso"
    );
  const notifyError = () =>
    toast.error(
      !id ? "Erro ao cadastrar condomínio!" : "Erro ao editar condomínio"
    );
  const history = useHistory();
  const { id } = useParams();
  const [form, setForm] = useState(initialForm);
  const notifyFetchError = () =>
    toast.error(
      "Houve uma falha obtendo os dados deste condomínio. Tente novamente mais tarde"
    );

  useEffect(() => {
    const retrieveData = async () => {
      console.log(id, "id do useEffect");
      if (id) {
        try {
          const response = await api.get(`/condominium/${id}`);
          const condominiumData = response.data;
          console.log(condominiumData, "warning data");
          setForm(response.data);
          console.log(form, "form do useEffect");
          console.log(response.data, "dados do useEffect");
        } catch (ex) {
          notifyFetchError();
          history.goBack();
          throw ex;
        }
      }
    };
    retrieveData();
    console.log(form);
  }, [id]);

  const handleCepBlur = async (event, setFieldValue) => {
    const cep = event.target.value.replace(/\D/g, "");

    if (cep.length === 8) {
      try {
        const response = await api.get(`https://viacep.com.br/ws/${cep}/json/`);
        const { data } = response;
        if (!data.erro) {
          setFieldValue("cep", cep);
          setFieldValue("city", data.localidade);
          setFieldValue("state", data.uf);
          setFieldValue("district", data.bairro);
          setFieldValue("street", data.logradouro);
          console.log(data, "ENDERECO MIMICO");
        } else {
          // Limpar os outros campos se o CEP for inválido ou não for encontrado
          setFieldValue("cep", "");
          setFieldValue("city", "");
          setFieldValue("state", "");
          setFieldValue("district", "");
          setFieldValue("street", "");

          console.log("CEP inválido ou não encontrado");
        }
      } catch (error) {
        // Tratar erros de requisição
        console.log("Erro ao buscar CEP", error);
      }
    } else {
      // Limpar os outros campos caso o CEP não tenha 8 dígitos
      setFieldValue("city", "");
      setFieldValue("state", "");
      setFieldValue("district", "");
    }
  };

  async function signUpCond(values) {
    setLoading(true);
    try {
      const data = values;
      console.log(data, "JSON");
      if (!id) await api.post("/condominium", data);
      else await api.patch(`/condominium/${id}`, data);
      notifySucess();
      history.push("/admin/home");
    } catch (e) {
      console.log("erro");
      console.log(e);
      notifyError();
    }
    setLoading(false);
  }

  const cadastroValidationSchema = yup.object().shape({
    condominium_name: yup.string().required("Nome do condomínio obrigatório"),
    contact: yup.string().required("Telefone obrigatório"),
    cep: yup.string().required("CEP obrigatório"),
    city: yup.string().required("Cidade obrigatória"),
    state: yup.string().required("Estado obrigatório"),
    district: yup.string().required("Bairro obrigatório"),
    street: yup.string().required("Rua obrigatório"),
    number: yup.number().required("Número obrigatório"),
    unities: yup.string().required("Unidades obrigatório"),
  });

  const campos = [
    {
      label: "condominium_name",
      Nome: "Nome Do condominio",
      type: "text",
    },
    {
      label: "contact",
      Nome: "Telefone",
      type: "tel",
    },
    {
      label: "cep",
      Nome: "CEP",
      type: "text",
    },
    {
      label: "city",
      Nome: "Cidade",
      type: "text",
      disabled: true,
    },
    {
      label: "state",
      Nome: "Estado",
      type: "text",
      disabled: true,
    },
    {
      label: "district",
      Nome: "Bairro",
      type: "text",
      disabled: true,
    },
    {
      label: "street",
      Nome: "Rua",
      type: "text",
      disabled: true,
    },
    {
      label: "number",
      Nome: "Numero",
      type: "number",
    },
    {
      label: "unities",
      Nome: "unidades",
      type: "text",
    },
    {
      label: "pool",
      Nome: "Piscina",
      type: "checkbox",
    },
  ];

  return (
    <NewPage>
      <NewSideBar tipo={"Administrador"} rota={"admin"}></NewSideBar>
      <Container>
        <HeaderDiv>
          <NewHeader name={"Editar Condominio"}></NewHeader>
        </HeaderDiv>

        <CarContainer>
          <Formik
            enableReinitialize
            initialValues={{
              condominium_name: form.condominium_name,
              state: form.state,
              city: form.city,
              district: form.district,
              street: form.street,
              number: form.number,
              cep: form.cep,
              contact: form.contact,
              unities: form.unities,
              complements: form.complements,
              pool: form.pool,
            }}
            onSubmit={(values) => {
              console.log("enviado", values);
              signUpCond(values);
            }}
            /* validationSchema={cadastroValidationSchema} */
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              errors,
              isValid,
              touched,
              setFieldValue,
            }) => (
              <form
                id="cadastroCondominum"
                onSubmit={handleSubmit}
                style={{ width: "80%" }}
              >
                <div id="inputs" style={{ maxHeight: "100%" }}>
                  {campos.map((campo) => {
                    if (campo.label === "cep") {
                      return (
                        <div className="inputBox">
                          <div
                            className={
                              touched[campo.label] && errors[campo.label]
                                ? "erroActive"
                                : "errorMessage"
                            }
                            id="errorMessage"
                          >
                            {errors[campo.label]}
                          </div>

                          <input
                            type={campo.type}
                            name={campo.label}
                            placeholder={campo.Nome}
                            disabled={campo.disabled}
                            className="inputText"
                            onChange={handleChange(campo.label)}
                            value={values[campo.label]}
                            onBlur={(e) => handleCepBlur(e, setFieldValue)}
                          />
                        </div>
                      );
                    } else if (campo.type === "checkbox") {
                      return (
                        <div className="inputBox">
                          <div
                            className={
                              touched[campo.label] && errors[campo.label]
                                ? "erroActive"
                                : "errorMessage"
                            }
                            id="errorMessage"
                          >
                            {errors[campo.label]}
                          </div>

                          <FormControlLabel
                            control={
                              <Switch
                                name={campo.label}
                                onChange={(e) => {
                                  setFieldValue(campo.label, e.target.checked);
                                }}
                                onBlur={handleBlur(campo.label)}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label={campo.Nome}
                            checked={values[campo.label]}
                          />
                        </div>
                      );
                    } else if (campo.label === "number") {
                      return (
                        <div className="inputBox">
                          <div
                            className={
                              touched[campo.label] && errors[campo.label]
                                ? "erroActive"
                                : "errorMessage"
                            }
                            id="errorMessage"
                          >
                            {errors[campo.label]}
                          </div>

                          <input
                            type={campo.type}
                            name={campo.label}
                            placeholder={campo.Nome}
                            disabled={campo.disabled}
                            className="inputText"
                            onChange={handleChange(campo.label)}
                            value={values[campo.label]}
                            onBlur={handleBlur(campo.label)}
                          />
                          {!id ? (
                            <div
                              id="errorMessage2"
                              style={{ color: "blue", width: "375px" }}
                            >
                              Atenção: Não é possivel aumentar ou diminuir a
                              quantidade de complementos após cadastrar o
                              condomínio.
                            </div>
                          ) : null}

                          <FieldArray name="complements">
                            {(fieldArrayProps) => {
                              const { push, remove, form } = fieldArrayProps;
                              const { values } = form;
                              const { complements } = values;

                              return (
                                <div>
                                  {complements?.map((complemento, index) => (
                                    <div key={index}>
                                      <p className="registerInputTitle">
                                        Tipo de Complemento {`${index + 1}`}
                                      </p>

                                      <div style={{ flexDirection: "row" }}>
                                        <Field
                                          style={{
                                            marginBottom: "2vh",
                                            width: "800px",
                                          }}
                                          className="inputText"
                                          id="InputWarnTitle"
                                          name={`complements[${index}].name`}
                                        />
                                        {!!id ? (
                                          index ? (
                                            <button
                                              className="handleButton"
                                              type="button"
                                              style={{
                                                width: "10vh",
                                                marginTop: "-0.5vh",
                                              }}
                                              onClick={() => remove(index)}
                                            >
                                              {" "}
                                              -{" "}
                                            </button>
                                          ) : (
                                            <button
                                              className="handleButton"
                                              type="button"
                                              style={{
                                                width: "10vh",
                                                marginTop: "-0.5vh",
                                              }}
                                              onClick={() => push("")}
                                            >
                                              {" "}
                                              +{" "}
                                            </button>
                                          )
                                        ) : null}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              );
                            }}
                          </FieldArray>
                          <div id="errorMessage2" style={{ color: "red" }}>
                            Insira os tipos de complementos existentes
                          </div>
                          <div id="errorMessage2" style={{ color: "red" }}>
                            no condomínio, adicione quantos quiser!
                          </div>
                          <div id="errorMessage2" style={{ color: "red" }}>
                            EX: Bloco, Apartamento.
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          className="inputBox"
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            className={
                              touched[campo.label] && errors[campo.label]
                                ? "erroActive"
                                : "errorMessage"
                            }
                            id="errorMessage"
                          >
                            {errors[campo.label]}
                          </div>

                          <input
                            type={campo.type}
                            name={campo.label}
                            placeholder={campo.Nome}
                            disabled={campo.disabled}
                            className="inputText"
                            onChange={handleChange(campo.label)}
                            value={values[campo.label]}
                            onBlur={handleBlur(campo.label)}
                          />
                        </div>
                      );
                    }
                  })}
                </div>
              </form>
            )}
          </Formik>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {loading ? (
              <div id="loadingBox">
                <Loading />
              </div>
            ) : (
              <button
                type="submit"
                className="handleButton"
                form="cadastroCondominum"
                style={{
                  width: "88vh",
                  justifyContent: "center",
                  marginBottom: "2vh",
                }}
              >
                <p id="signUpCondominiumText">Editar</p>
              </button>
            )}
          </div>
        </CarContainer>
        <ToastContainer />
      </Container>
    </NewPage>
  );
}

/* 
 <div style={{overflow:"auto"}}>
          <Formik 
            initialValues={{
              condominium_name: form.condominium_name,
              state: form.state,
              city: form.city,
              district: form.district,
              street: form.street,
              number: form.number,
              cep: form.cep,
              contact: form.contact,
              unities: form.unities,
              complements: form.complements
              
            }}
            enableReinitialize
            onSubmit={values => signUpCond(values)}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              errors,
              isValid,
              touched,
            }) => (
              <form id="listArea"  onSubmit={handleSubmit}>
                
                  
                    <p className="registerInputTitle">Nome do Condomínio</p>
                    
                    <input
                      className="inputText"
                      id="InputWarnTitle"
                      value={values.condominium_name}
                      onChange={handleChange("condominium_name")}
                      onBlur={handleBlur("condominium_name")}
                    />
                 
                  
                    <p className="registerInputTitle">Contato</p>
                   
                    <input style={{marginBottom: "2vh"}}
                      className="inputText"
                      id="InputWarnTitle"
                      value={values.contact}
                      onChange={handleChange("contact")}
                      onBlur={handleBlur("contact")}
                    />
                 
                  
                    <p className="registerInputTitle">Número de Unidades</p>
                    
                    <input style={{marginBottom: "2vh"}}
                      className="inputText"
                      id="InputWarnTitle"
                      value={values.unities}
                      onChange={handleChange("unities")}
                      onBlur={handleBlur("unities")}
                    />
    
                    <p className="registerInputTitle">Estado</p>
                   
                    <input style={{marginBottom: "2vh"}}
                      className="inputText"
                      id="InputWarnTitle"
                      value={values.state}
                      onChange={handleChange("state")}
                      onBlur={handleBlur("state")}
                    />
                    <div id="errorMessage2" style={{color: "red"}}>
                      Preencha o estado de forma abreviada, EX: RJ.
                      </div>
                
                  
                  
                    <p className="registerInputTitle">Cidade</p>
                    
                    <input style={{marginBottom: "2vh"}}
                      className="inputText"
                      id="InputWarnTitle"
                      value={values.city}
                      onChange={handleChange("city")}
                      onBlur={handleBlur("city")}
                    />
                  
                  
                    <p className="registerInputTitle">Bairro</p>
                    
                    <input style={{marginBottom: "2vh"}}
                      className="inputText"
                      id="InputWarnTitle"
                      value={values.district}
                      onChange={handleChange("district")}
                      onBlur={handleBlur("district")}
                    />

                    <p className="registerInputTitle">CEP</p>
                    
                    <input style={{marginBottom: "2vh"}}
                      className="inputText"
                      id="InputWarnTitle"
                      value={values.cep}
                      onChange={handleChange("cep")}
                      onBlur={handleBlur("cep")}
                    />
                  
                
                    <p className="registerInputTitle">Rua</p>
                    
                    <input style={{marginBottom: "2vh"}}
                      className="inputText"
                      id="InputWarnTitle"
                      value={values.street}
                      onChange={handleChange("street")}
                      onBlur={handleBlur("street")}
                    />

                    <p className="registerInputTitle">Número</p>
                    
                    <input style={{marginBottom: "2vh"}}
                      className="inputText"
                      id="InputWarnTitle"
                      value={values.number}
                      onChange={handleChange("number")}
                      onBlur={handleBlur("number")}
                    />

                    {!id ? (<div id="errorMessage2" style={{color: "blue", width: "375px"}}>
                     Atenção: Não é possivel aumentar ou diminuir a quantidade de complementos após cadastrar o condomínio.
                    </div>) : null}

                  <FieldArray name = 'complements'>
                  {fieldArrayProps => 
                  {console.log('fieldArrayProps', fieldArrayProps.form.values) 
                  const {push, remove, form} = fieldArrayProps
                  const {values} = form
                  const {complements} = values 
                  
                  
                  console.log(complements, "values")
                  return (
                    <div >
                      {complements.map((complemento, index) => (
                        <div key = {index} >
                          
                    <p className="registerInputTitle">Tipo de Complemento {`${index + 1}`}</p>

                    <div style={{flexDirection: "row"}}>
                    <Field style={{marginBottom: "2vh"}}
                      className="inputText"
                      id="InputWarnTitle"
                      name = {`complements[${index}].name`}
                    />
                    {!id ? (index ? <button className="handleButton" type="button" style = {{width: "10vh", marginTop: "-0.5vh"}} onClick={() => remove(index)}> - </button>
                    :<button className="handleButton" type="button" style = {{width: "10vh", marginTop: "-0.5vh"}} onClick={() => push("")}> + </button>) : null}
                  </div>
                      
                        </div>
                    ))}
                    
                  </div>
                  )
                }}
                  </FieldArray>
                  <div id="errorMessage2" style={{color: "red"}}>
                      Insira os tipos de complementos existentes
                  </div>
                  <div id="errorMessage2" style={{color: "red"}}>
                      no condomínio, adicione quantos quiser!
                  </div>
                  <div id="errorMessage2" style={{color: "red"}}>
                      EX: Bloco, Apartamento.
                  </div>
                
                <div>
                {loading ? (
                  <div id="loadingBox">
                    <Loading />
                  </div>
                ) : (
                  <button
                    type="submit"
                    className="handleButton"
                    style={{width: "50vh"}}
                  >
                    <p id="signUpCondominiumText">{!id? "Cadastrar" : "Alterar"}</p>
                  </button>
                )}
                </div>
              </form>
            )}
          </Formik>
          <div style={{height: "8vh"}}></div>
        </div>
*/
