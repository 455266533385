import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
//Service
import api from "../../../../../Services/api";
import { useAuth } from "../../../../../Context/auth";

//Toast
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Components
import CloseIcon from "@mui/icons-material/Close";
import Loading from "../../../../../Components/Loading/index";
import Sidebar from "../../../../../Components/SidebarManager";
import { InputWrap } from "../../Management/Legislation/AddLei/style";
import DatePicker from "react-date-picker";
//import SidebarOptions from "../../../../Components/SidebarOptions";
import ArrowBack from "../../../../../Components/ResponsiveArrowBack";
//Icons
import SearchIcon from "@material-ui/icons/Search";
import plus from "../../../../../Assets/plus.svg";
import { MdOutlineModeEditOutline } from "react-icons/md";
import "./index.css";
import NewSideBar from "../../../../../Components/NewComponents/NewSideBar";

export default function OccurrencesList() {
  const notifyDelete = () => toast.error("Ocorrência apagada!");
  const history = useHistory();

  const { user } = useAuth();

  let userJson = user;

  if (typeof user === "string") {
    userJson = JSON.parse(user);
  }

  const userModality = userJson.modality;

  const modalities = {
    admin: { rota: "admin", tipo: "Admin" },
    manager: { rota: "manager", tipo: "Síndico" },
    doorman: { rota: "doorman", tipo: "Porteiro" },
    maintenance: { rota: "maintenance", tipo: "Manutenção" },
    administration: { rota: "employee", tipo: "Administrativo" },
  };

  const { rota, tipo } = modalities[userModality] || { rota: "", tipo: "" };

  useEffect(() => {
    getData();
    console.log(avisos[0]);
  }, []);

  const [avisos, setAvisos] = useState([]);
  const [procurar, setProcurar] = useState("");
  const [data, setData] = useState();
  const [filter, setFilter] = useState("title");

  async function getData() {
    try {
      const response = await api.get("/occurrence");
      setAvisos(response.data);
      console.log(response.data);
    } catch (error) {
      throw error;
    }
  }

  function convertDate(inicialDate) {
    const dataString = inicialDate;
    const [dia, mes, ano] = dataString.split("/").map(Number);

    const data = new Date(ano, mes - 1, dia); // subtrai 1 do mês porque o mês começa em 0 (janeiro)

    const novaDataString = `${data.getFullYear()}-${(data.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${data.getDate().toString().padStart(2, "0")}`;

    return novaDataString;
  }

  async function deleteWarning(warn_id) {
    try {
      await api.delete(`/occurrence/${warn_id}`);
      setAvisos(avisos.filter((aviso) => aviso.id !== warn_id));
      notifyDelete();
    } catch (error) {
      throw error;
    }
  }

  function onChangeData(data) {
    setData(data);
    setProcurar("");
  }

  return (
    <div id="homeContent">
      <NewSideBar rota={rota} tipo={tipo}></NewSideBar>
      <div
        id="WarnSafeArea"
        style={{
          marginLeft: "56px",
          width: "100%",
          heigth: "100%",
          overflowY: "auto",
        }}
      >
        <div
          className="pageTitleContainer"
          id="x"
          style={{ marginLeft: "0px" }}
        >
          <ArrowBack />
          <p id="pageTitle">Ocorrências</p>
        </div>

        <div id="listArea" style={{ marginLeft: "0px" }}>
          <div id="TopContainer1">
            <div id="searchContainer">
              {filter === "date" ? (
                <>
                  <SearchIcon style={{ marginRight: "20px" }} />
                  <DatePicker
                    value={data}
                    onChange={(event) => onChangeData(event)}
                    maxDate={new Date()}
                  />
                </>
              ) : (
                <>
                  <SearchIcon />
                  <input
                    id="searchInput"
                    placeholder="Pesquisar"
                    onChange={(event) => {
                      setProcurar(event.target.value);
                    }}
                  />
                </>
              )}
            </div>
            <InputWrap
              style={{
                marginBottom: "32px",
                marginLeft: "10px",
                width: "200px",
              }}
            >
              <select
                style={{
                  border: "none",
                  borderLeft: "4px solid #00334E",
                  borderRadius: "20px",
                  minWidth: "150px",
                  backgroundColor: "#f8f8f8",
                  padding: "4px 12px",
                }}
                placeholder="None"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              >
                <option value="title">Nome</option>
                <option value="date">Data</option>
              </select>
            </InputWrap>
            <Link
              to={{
                pathname: `/${rota}/ocorrencias/criar`,
                state: { data: "test" },
              }}
              params={{ test: "hello" }}
              style={{ textDecoration: "none", color: "black" }}
            >
              <div id="WarnAddButton">
                <p>Criar ocorrência</p>
              </div>
            </Link>
          </div>

          {avisos
            .sort(
              (b, a) =>
                new Date(convertDate(a.date)) - new Date(convertDate(b.date))
            )
            .filter((aviso) => {
              if (procurar === "" && data === "") {
                return aviso;
              } else if (
                aviso?.title.toLowerCase().includes(procurar.toLowerCase()) &&
                filter === "title"
              ) {
                return aviso;
              } else if (
                aviso?.date.includes(
                  data
                    ? data.toLocaleDateString("pt-BR", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      })
                    : ""
                ) &&
                filter === "date"
              ) {
                return aviso;
              }
            })
            .map((aviso) => (
              <div className="data-container" key={aviso?.id}>
                <div id="data-safe-area">
                  <div className="WarnTitle">{aviso?.title}</div>
                  <div className="manager-name-title">
                    {aviso?.text.split("\n").map((discriptions) => (
                      <p>{discriptions}</p>
                    ))}
                  </div>
                  {aviso?.photo_url ? (
                    <div className="manager-name-title">
                      <a
                        href={`${aviso?.photo_url}`}
                        style={{ color: "#EE6F57" }}
                        target="_blank"
                      >
                        Ver foto
                      </a>
                    </div>
                  ) : null}
                </div>
                <div className="WarnDate2">
                  {"Criado por: " +
                    (aviso?.name ? aviso.name : "Não identificado") +
                    (aviso?.complement && aviso.complement !== "Sem complemento"
                      ? " - " +
                        aviso.complement.replace(/:/g, " ").replace(/  +/g, " ")
                      : "")}
                </div>
                <div className="WarnDate2">
                  {"Responsável: " +
                    (aviso?.assigned_employee?.name ||
                      aviso?.assigned_resident?.name ||
                      "Não atribuído")}
                </div>

                <div className="WarnDate2">{"Status: " + aviso?.status}</div>

                <div id="ButtonContainer">
                  <div className="WarnDate" style={{ marginRight: "44%" }}>
                    {aviso?.date}
                    {" - "}
                    {aviso?.time}
                  </div>
                  <button
                    id="EditButton"
                    onClick={() => {
                      history.push(`/${rota}/ocorrencias/editar/${aviso?.id}`);
                    }}
                  >
                    <MdOutlineModeEditOutline />
                  </button>

                  {(userModality === "manager" ||
                    userModality === "administration") && (
                    <button
                      id="deleteButtonWarnings"
                      onClick={() => {
                        deleteWarning(aviso?.id);
                      }}
                      style={{ display: "flex" }}
                    >
                      <CloseIcon></CloseIcon>
                    </button>
                  )}
                </div>
              </div>
            ))}
          <div style={{ height: "8vh" }}></div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
