import Sidebar from "../../../../Components/SidebarManager";

import ArrowBack from "../../../../Components/ResponsiveArrowBack";

import { Grid } from "@material-ui/core";
import {
  Container,
  ImageExample,
  TextAbout,
  TextFinished,
  Tittle,
  ViewBothText,
  ViewText,
} from "./styles";

import SindicoImg from "../../../../Assets/menuImages/sindico.png";
import ChatImg from "../../../../Assets/menuImages/chat.png";
import NewSideBar from "../../../../Components/NewComponents/NewSideBar";
import { NewPage } from "../../Admin/Empresas/styles";

export default function AboutAdministration() {
  return (
    <NewPage>
      {/*<Grid item xs={3} style={{borderStyle:"solid",borderColor:"red"}}>}
    //</Grid>*/}
      <NewSideBar rota={"employee"} tipo={"Administrativo"}></NewSideBar>
      <Grid
        container
        item
        xs={8}
        style={{
          paddingLeft: "56px",
          marginTop: "25px",
          wdith: "100%",
        }}
      >
        <Grid item xs={12}>
          <div className="pageTitleContainer" style={{ margin: "0px" }}>
            <div className="mngm-opt-title">
              <ArrowBack></ArrowBack>
              <p>Sobre</p>
            </div>
          </div>
          <Container>
            <Tittle>O que somos</Tittle>
            <ViewText>
              <TextAbout>
                O condomíniolegal.app.br é um aplicativo de serviços, que tem
                como foco moradores, síndico e portaria do seu condomínio. Como
                função principal, integra condôminos, síndico, administração e a
                portaria. Foi pensado para ser um aplicativo simples e
                intuitivo, visando pessoas sem experiencia na navegação de
                internet e smartphone.
              </TextAbout>
              <ImageExample src={SindicoImg} />
            </ViewText>
            <Tittle marginTop={true}>Nossas funcionalidades</Tittle>
            <ViewText>
              <ViewBothText>
                <TextAbout>
                  Dentre as diversas funções apresentadas, o aplicativo
                  organiza, arquiva documentos importantes e permite
                  temporariamente a divulgação de venda ou locação dos imóveis
                  dos proprietários. Tem espaço para o síndico indicar
                  prestadores de serviços para atendimento ao condomínio e a
                  seus moradores.
                </TextAbout>
                <TextAbout marginTop={true}>
                  Dentre as diversas inovações apresentadas pelo aplicativo
                  condomíniolegal.app.br, tem a função vídeo conferência, que
                  tem objetivo de unir síndico e os proprietários nos momentos
                  de decisões importantes a serem tomadas de imediato, que não
                  podem aguardar uma reunião convencional de condomínio devido a
                  urgência de se tomar uma ação.
                </TextAbout>
              </ViewBothText>
              <ImageExample src={ChatImg} />
            </ViewText>
            <TextFinished>
              Finalizando, o condomíniolegal.app.br se utiliza da tecnologia e
              inovação, proporcionando o melhor para administração de um
              condomínio.
            </TextFinished>
          </Container>
        </Grid>
      </Grid>
    </NewPage>
  );
}
