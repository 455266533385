import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Formik } from "formik";

import Select from "react-select";
//Service
import api from "../../../../../Services/api";
import axios from "axios";

//Toast
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Components
import Loading from "../../../../../Components/Loading/index";
import Sidebar from "../../../../../Components/SidebarManager";
//import SidebarOptions from "../../../../Components/SidebarOptions";
import ArrowBack from "../../../../../Components/ResponsiveArrowBack";
//Icons
import SearchIcon from "@material-ui/icons/Search";
import { GoMail, GoPackage } from "react-icons/go";

import "./index.css";
import { Photo, Search } from "@material-ui/icons";

const initialForm = {
  title: "",
  text: "",
};

export default function CreateSale() {
  const { id } = useParams();
  const history = useHistory();
  const [form, setForm] = useState(initialForm);
  const [loading, setLoading] = useState(false);

  const [photo, setPhoto] = useState(null);
  const [handleFile, setHandleFile] = useState("");
  const [residentId, setResidentId] = useState(null);

  const notifySucess = () => toast.success("Enviado com sucesso!");
  const notifyError = () => toast.error("Erro ao enviar notificação!");
  const notifyFetchError = () =>
    toast.error(
      "Houve uma falha obtendo os dados deste aviso. Tente novamente mais tarde"
    );

  // useEffect(async () => {
  //   getData();
  // }, []);

  // async function getData() {
  //   try {
  //     const residentsList = await api.get("/resident").then((res) => res.data);
  //     const newResidentsList = residentsList.map((item) => ({
  //       value: item.id,
  //       label: item.name,
  //     }));
  //     setResidents(newResidentsList);
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  const types = [
    { value: "Casa", label: "Casa" },
    { value: "Apartamento", label: "Apartamento" },
    { value: "Cobertura", label: "Cobertura" },
    { value: "Terreno", label: "Terreno" },
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      border: "none",
      borderLeft: "4px solid #0a7ef5",
      borderRadius: "10px",
      backgroundColor: "#f8f8f8",
      padding: "4px 12px",
    }),
    container: (provided, state) => ({
      ...provided,
    }),

    // control: () => ({
    //   // none of react-select's styles are passed to <Control />
    //   width: 200,
    // }),
    // singleValue: (provided, state) => {
    //   const opacity = state.isDisabled ? 0.5 : 1;
    //   const transition = "opacity 300ms";

    //   return { ...provided, opacity, transition };
    // },
  };

  return (
    <div id="homeContent">
      <Sidebar />
      <div>
        <div className="pageTitleContainer" id="x">
          <ArrowBack />

          <p id="pageTitle">Venda e Locação</p>
        </div>

        {/* <div id="listArea">
          <Formik
            initialValues={{
              info: "",
              title: "",
              complement: "",
              type: "",
            }}
            enableReinitialize
            onSubmit={(values, { resetForm }) => {
              sendWarning(values);
              setTimeout(function () {
                resetForm({ values: "" });
                setResidentId(null);
              }, 1000);
            }}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              errors,
              isValid,
              touched,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <div id="warnContainer">
                  <p className="registerInputTitle">Vencimento</p>
                  {touched.title && errors.title && (
                    <div id="errorMessage2">{errors.title}</div>
                  )}
                  <input
                    className="inputText"
                    id="InputWarnTitle"
                    type="date"
                    value={values.info}
                    onChange={handleChange("info")}
                    onBlur={handleBlur("info")}
                  />
                  <p className="registerInputTitle">Modalidade</p>
                  {touched.title && errors.title && (
                    <div id="errorMessage2">{errors.title}</div>
                  )}
                  <div className="radio-input-wrap-2">
                    <label className="radio-input">
                      <input
                        type="radio"
                        value="Venda"
                        name="type"
                        checked={values.type === "Venda"}
                        onChange={handleChange}
                      />
                      Venda
                      <GoMail />
                    </label>
                    <label className="radio-input">
                      <input
                        type="radio"
                        value="Locação"
                        name="type"
                        checked={values.type === "Locação"}
                        onChange={handleChange}
                      />
                      Locação
                      <GoPackage />
                    </label>
                  </div>
                  <p className="registerInputTitle">Tipo</p>
                  {touched.title && errors.title && (
                    <div id="errorMessage2">{errors.title}</div>
                  )}
                  <Select
                    options={types}
                    isClearable={true}
                    styles={customStyles}
                    value={residentId}
                    onChange={(option) => {
                      setResidentId(option);
                    }}
                  />
                  <p className="registerInputTitle">Endereço</p>
                  {touched.title && errors.title && (
                    <div id="errorMessage2">{errors.title}</div>
                  )}
                  <input
                    className="inputText"
                    id="InputWarnTitle"
                    value={values.title}
                    onChange={handleChange("title")}
                    onBlur={handleBlur("title")}
                  />
                  <p className="registerInputTitle">Descrição</p>
                  {touched.title && errors.title && (
                    <div id="errorMessage2">{errors.title}</div>
                  )}
                  <textarea
                    className="inputText"
                    id="InputWarnTitle"
                    value={values.complement}
                    onChange={handleChange("complement")}
                    onBlur={handleBlur("complement")}
                  />
                  
                  {loading ? (
                    <Loading />
                  ) : (
                    <button type="submit" className="handleButton" id="warnBtn">
                      <p>Criar</p>
                    </button>
                  )}
                </div>
              </form>
            )}
          </Formik>
        </div> */}
      </div>
      <ToastContainer />
    </div>
  );
}
