import React, {  useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";


import Loading from "../../../Components/Loading/index";

import "./index.css";
import api from "../../../Services/api";

export default function ForgotPassSquare2(props) {
  const history = useHistory();
  const [loading, SetLoading] = useState(false);
  const email = props.location.state || {};

  const forgotPassword2ValidationSchema = yup.object().shape({
    code: yup
      .string("O código inserido deve ser composto apenas por números")
      .required("É necessário inserir o código enviado por email")
      .min(6, "O código deve possuir exatamente 6 dígitos")
      .max(6, "O código deve possuir exatamente 6 dígitos"),
    password: yup
      .string()
      .required("Nova senha obrigatória com 6 ou mais caracteres")
      .min(6, "A senha deve posssuir ao menos 6 caracteres"),
    passwordConfirm: yup
      .string()
      .required("Repita a senha acima")
      .oneOf([yup.ref("password"), null], "As senhas devem coincidir"),
  });

  return (
    <div className="all">
      <div id="esqueciContainer">
        <div id="esqueciMinorContainer">
          <p id="esqueciTitle">Redefinição de Senha</p>
          <p id="esqueciText">Digite sua nova senha e a confirme, por favor.</p>
          <Formik
            initialValues={{
              password: "",
              passwordConfirm: "",
              code: ""
            }}
            onSubmit={async values => {
              SetLoading(true);
              console.log(values);
              try{
                const data = { email:email, password:values.password, pin:values.code}
                console.log(data)
                const response = await api.patch("/password/reset",data)
                console.log(response.data)
                history.push("/")
              }catch(e){
                console.log(e)
              }
              SetLoading(false);
            }}
            validationSchema={forgotPassword2ValidationSchema}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              errors,
              isValid,
              touched,
            }) => (
              <form onSubmit={handleSubmit} className="forgotPassForm">
                {errors.code && touched.code ? (
                  <div id="errorMessage" className="erroActive">{errors.code}</div>
                ) : null}
                <input
                  name="code"
                  type="code"
                  placeholder="Insira aqui o código enviado por email"
                  className="inputText"
                  id="passcode"
                  onChange={handleChange("code")}
                  value={values.code}
                  onBlur={handleBlur("code")}
                />
                {errors.password && touched.password ? (
                  <div id="errorMessage" className="erroActive">{errors.password}</div>
                ) : null}
                <input
                  name="password"
                  type="password"
                  placeholder="Nova senha"
                  className="inputText"
                  id="newPass"
                  onChange={handleChange("password")}
                  value={values.password}
                  onBlur={handleBlur("password")}
                />
                {errors.passwordConfirm && touched.passwordConfirm? (
                  <div id="errorMessage" className="erroActive">{errors.passwordConfirm}</div>
                ) : null}
                <input
                  name="passwordConfirm"
                  type="password"
                  placeholder="Confirmação da senha"
                  className="inputText"
                  id="passwordConfirm"
                  onChange={handleChange("passwordConfirm")}
                  value={values.passwordConfirm}
                  onBlur={handleBlur("passwordConfirm")}
                />
                {loading ? (
                  <Loading />
                ) : (
                  <button
                    type="submit"
                    className="handleButton"
                    id="sendNewPass"
                  >
                    <p>Redefinir</p>
                  </button>
                )}
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
