import styled from "styled-components";

export const Option = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  

  background-color: transparent;
  
  &:hover {
    color: #fff;
    transition: color 100ms ease-in;
  }
`;

export const Text = styled.p`
  font-size: 20px;

  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 15px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  
`;

export const Button = styled.button`
   font-size: 20px;

   margin-bottom: 20px;
margin-top: 20px;
margin-left: 15px;
font-family: "Montserrat", sans-serif;
font-weight: 400;
font-style: normal;
background-color: transparent;
border:none;
color: #fff;
cursor: pointer;
&:hover {
    color: black;
    transition: color 100ms ease-in;
  }




`;
