import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import React, { useEffect, useState } from "react";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { ToastContainer } from "react-toastify";
import Sidebar from "../../../../Components/SidebarAdmin";
import Select from "react-select";
import ArrowBack from "../../../../Components/ResponsiveArrowBack";
import {
  CarContainer,
  CardDiv,
  Container,
  DivButtonsCard,
  HeaderDiv,
  InputSearch,
  NewInputWrap,
  NewPage,
  RowDiv,
} from "./styles";
import api from "../../../../Services/api";
import ModalDeleteEmpresa from "./modalDeleteEmpresa";
import { InputWrap } from "../../Manager/Management/Legislation/AddLei/style";

import NewHeader from "../../../../Components/NewComponents/Header";
import NewSideBar from "../../../../Components/NewComponents/NewSideBar";
import { DivText } from "../../../../Components/NewComponents/NewSideBar/styles";
import CardInfo from "../../../../Components/NewComponents/Text";

function Empresas() {
  const [selectedOption, setSelectedOption] = useState(null);
  const [data, setData] = useState([]);
  const [filtro, setFiltro] = useState("");
  const [type, setType] = useState([]);
  const [search, setSearch] = useState("");
  const [searchFiltro, setSearchFiltro] = useState(type);
  const [atualizar, setAtualizar] = useState(false);
  const history = useHistory();

  const options = [
    { value: "Imboliária", label: "Imboliária" },
    { value: "Publicidade", label: "Publicidade" },
  ];

  useEffect(() => {
    const filtro = async () => {
      try {
      } catch (error) {
        console.log(error);
      }
    };
  });

  function handleSubmit(id) {
    console.log(id);
    history.push({ pathname: `/admin/EditarEmpresa/${id}`, state: id });
  }

  async function getData() {
    try {
      const response = await api.get("/company");
      setData(response.data);
      console.log(response.data, "GET");
    } catch (error) {
      console.log(error);
    }
  }

  async function BlockCompany(id, active) {
    try {
      console.log("Oi");
      const formData = new FormData();
      const newdata = { active: !active };
      formData.append("data", JSON.stringify(newdata));
      const response = await api.patch(`/company/${id}`, formData);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    console.log(data, "data");
    console.log(
      data[0]?.modality,
      data[0]?.modality === filtro,
      filtro,
      "sadas"
    );
    setType(
      data?.filter((item) => {
        if (filtro === "") {
          console.log("oi");
          return data;
        } else if (item.modality === filtro) {
          console.log("oi2");
          return data;
        }
      })
    );
  }, [filtro, data]);

  useEffect(() => {
    console.log("testeFiltro");
    setSearchFiltro(
      type?.filter((item) => {
        if (search === "") {
          console.log("oi");
          return type;
        } else if (
          item.name === search ||
          item.cnpj === search ||
          item.corporate_name === search ||
          item.cep === search ||
          item.city === search ||
          item.email === search ||
          item.phone_number === search ||
          item.state === search ||
          item.district === search
        ) {
          console.log("oi2");
          return type;
        }
      })
    );
  }, [type, search]);

  console.log("saerch", searchFiltro);

  return (
    <NewPage>
      <NewSideBar tipo={"Administrador"} rota={"admin"}></NewSideBar>
      <Container>
        <HeaderDiv>
          <NewHeader name="Empresas"></NewHeader>
        </HeaderDiv>
        <RowDiv>
          <input
            style={{
              width: "30vw",
              height: "50px",
              border: "0px",
              borderRadius: "20px",
              paddingLeft: "20px",
              backgroundColor: "#efefef",
            }}
            placeholder={"Empresas"}
            onChange={(event) => {
              setSearch(event.target.value);
            }}
          />
          {console.log(search)}
          <NewInputWrap>
            <select
              style={{
                border: "none",
                display: "flex",
                borderLeft: "4px solid #00334E",
                borderRadius: "20px",
                minWidth: "150px",
                backgroundColor: "#f8f8f8",
              }}
              placeholder="None"
              onChange={(event) => setFiltro(event.target.value)}
            >
              <option value="imobiliaria">Empresa Imobiliária</option>
              <option value="publicidade">Empresa Publicitária</option>
            </select>
          </NewInputWrap>
        </RowDiv>
        <CarContainer>
          {searchFiltro.map((item, index) => (
            <CardDiv key={index}>
              <DivText>
                <CardInfo
                  name={"Nome Fantasia: "}
                  info={item.corporate_name}
                ></CardInfo>
                <CardInfo name={"CNPJ: "} info={item.cnpj}></CardInfo>
                <CardInfo
                  name={"Telefone: "}
                  info={item.phone_number}
                ></CardInfo>
                <CardInfo name={"Email: "} info={item.email}></CardInfo>
                <CardInfo name={"Estado: "} info={item.state}></CardInfo>
                <CardInfo name={"Cidade: "} info={item.city}></CardInfo>
                <CardInfo
                  name={"Tipo da Empresa: "}
                  info={item.modality}
                ></CardInfo>
                <CardInfo name={"Bairro: "} info={item.district}></CardInfo>
                <CardInfo name={"CEP: "} info={item.cep}></CardInfo>
              </DivText>
              <DivButtonsCard>
                <ModalDeleteEmpresa
                  id={item.id}
                  funcao={getData}
                ></ModalDeleteEmpresa>
                <button
                  onClick={() => {
                    BlockCompany(item.id, item.active);
                  }}
                  id="blockButton"
                >
                  <span
                    style={{
                      fontSize: item?.active ? "80%" : "100%",
                    }}
                  >
                    <b>{item?.active ? "Bloquear" : "Ativar"}</b>
                  </span>
                </button>
                <button
                  id="EditButton"
                  style={{ margin: 0 }}
                  onClick={() => {
                    handleSubmit(
                      item.id,
                      item.name,
                      item.corporate_name,
                      item.cnpj,
                      item.phone_number,
                      item.email,
                      item.state,
                      item.modality,
                      item.cep,
                      item.city,
                      item.district
                    );
                  }}
                >
                  <MdOutlineModeEditOutline />
                </button>
              </DivButtonsCard>
            </CardDiv>
          ))}
        </CarContainer>
      </Container>
    </NewPage>
  );
}

export default Empresas;

/*
<div id="homeContent">
      <Sidebar />
      <div id="WarnSafeArea">
        <div
          className="pageTitleContainer"
          id="x"
          style={{ marginLeft: "35vw" }}
        >
          <ArrowBack />
          <p id="pageTitle">Empresas</p>
        </div>

        <div id="listArea" style={{ marginLeft: "35vw" }}>
          <div id="TopContainer" style={{ display: "flex", gap: "6vw" }}>*/
{
  /*<div id="searchContainer">
              <SearchIcon />
              <input
                id="searchInput"
                placeholder="Pesquisar"
                onChange={(event) => {
                  setProcurar(event.target.value);
                }}
              />
            </div>
              */
}

{
  /*Seletor de Tags do back */
}
/*<div>
              <Select
                options={options}
                value={selectedOption}
                placeholder={"Empresas"}
                isClearable={true}
                backspaceRemovesValue={true}
                styles={styles}
                onChange={(e) => {
                  ;
                }}
              />
            </div>
            <div id="divbutton">
              <Link
                to={{
                  pathname: "/admin/servicos/criar",
                  state: { data: "test" },
                }}
                params={{ test: "hello" }}
                style={{ textDecoration: "none", color: "black" }}
              >
                
              </Link>*/
{
  /* <Link
                to={{
                  pathname: "/manager/servicos/indicacoes",
                  state: { data: "test" },
                }}
                params={{ test: "hello" }}
                style={{ textDecoration: "none", color: "black" }}
              >
                <div id="marginTop">
                  <div id="WarnAddButton">
                    <p>Ver lista de indicações</p>
                  </div>
                </div>
              </Link> */
}
/*</div>
          </div>*/

{
  /*Cards com os serviços que serão gerados*/
}

/*
              <div className="data-container" >
                <div id="data-safe-area">
                  <div className="WarnTitle"></div>
                  <div className="manager-name-title" style={{marginTop: "1vw"}}>Contato: </div>
                  <div className="manager-name-title">Nome: </div>
                  <div className="manager-name-title">CNPJ: </div>*/
{
  /* <div className="manager-name-title">
                    <a  href={`${aviso?.photo_url}`}>Ver foto</a>
                  </div> */
}
/* </div>
                
                <div className="WarnDate"></div>
                <div id="ButtonContainer">
                  <button
                    id="EditButton"
                    onClick={() => {
                      ;
                    }}
                  >
                    <MdOutlineModeEditOutline />
                  </button>
                  <button
                    id="deleteButtonWarnings"
                    onClick={() => {
                      ;
                    }}
                  >
                    <span id="cross1"></span>
                    <span id="cross2"></span>
                  </button>
                </div>
              </div>
            
        </div>
      </div>
      <ToastContainer />
    </div>
    */
