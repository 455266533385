import React, { useState, useEffect } from "react";
import SindicoImg from "../../../../Assets/menuImages/sindico.png";
import VisitantesImg from "../../../../Assets/menuImages/visitantes.png";
import ChatImg from "../../../../Assets/menuImages/chat.png";
import NotasImg from "../../../../Assets/menuImages/notas.png";
import OcorrenciaImg from "../../../../Assets/menuImages/ocorrencias.png";
import CondominiumImg from "../../../../Assets/menuImages/condominium.jpg";
import VendasImg from "../../../../Assets/menuImages/venda.png";
import ServicosImg from "../../../../Assets/menuImages/servicos.png";
import { Link } from "react-router-dom";
import GestaoImg from "../../../../Assets/menuImages/gestao.jpeg";
import NewSideBar from "../../../../Components/NewComponents/NewSideBar";

import api from "../../../../Services/api";

import { useAuth } from "../../../../Context/auth";

//Components
//import Loading from "../../../../Components/Loading/index";

import plus from "../../../../Assets/plus.svg";

import "./index.css";
import { autocompleteClasses } from "@mui/material";

export default function HomepageAdmin() {
  const cards = [
    { name: "Condomínios", img: SindicoImg, link: "/admin/condominiumlist" },
    {
      name: "Registrar Condominio",
      img: CondominiumImg,
      link: "/admin/registercondominium",
    },
    { name: "Avisos do App", img: OcorrenciaImg, link: "/admin/avisos" },
    {
      name: "Propaganda",
      img: VisitantesImg,
      link: "/admin/propagandalist",
    },
    {
      name: "Pedidos de cadastro",
      img: ChatImg,
      link: "/admin/managerapproval",
    },
    { name: "Bloco de notas", img: NotasImg, link: "/admin/notes" },
    { name: "Imóveis", img: VendasImg, link: "/admin/Imoveis" },
    { name: "Serviços", img: ServicosImg, link: "/admin/servicos" },
    { name: "Empresas", img: ServicosImg, link: "/admin/Empresas" },
    { name: "Gestão", img: GestaoImg, link: "/admin/gestao" },
  ];

  const { user } = useAuth();

  useEffect(() => {
    console.log(user.modality);
  }, []);

  return (
    <div id="homeContent">
      <NewSideBar tipo={"Administrador"} rota={"admin"}></NewSideBar>
      <div id="homeCentera" style={{ width: "100%", overflowY: "auto", height: "100%" }}>
        {/*
          <p className="welcomeTitle" id="homeWelcome">
            Bem vindo, {name}!
          </p>
          <p className="welcomeTitle" id="homeWelcome">
            Bem vindo, {user.name}!
          </p>
          <Link
              to="/manager/criar-aviso"
              style={{ textDecoration: "none", color: "black" }}
            >
          <div id="option">
              <img src={plus} id="plusIcon" />
              <p>Criar aviso</p>
          </div>
          </Link>
        */}
        {cards.map((card) => (
          <Link
            style={{ textDecoration: "none" }}
            to={card.link ? card.link : "/admin/home"}
          >
            <div id="home-card">
              <img src={card.img} />
              <h1>{card.name}</h1>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
