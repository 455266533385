import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";

//Select
import Select from "react-select";

//Service
import api from "../../../../../Services/api";
import { useAuth } from "../../../../../Context/auth";

//Grid
import { Grid } from "@material-ui/core";

//Toast
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Components
import Loading from "../../../../../Components/Loading/index";

//import SidebarOptions from "../../../../Components/SidebarOptions";
import ArrowBack from "../../../../../Components/ResponsiveArrowBack";

//Icons
import { GoMail, GoPackage } from "react-icons/go";

//Styles
import "./index.css";

//Components
import SidebarDoorman from "../../../../../Components/SideBarDoorman";
import NewSideBar from "../../../../../Components/NewComponents/NewSideBar";
import { Container, ContainerButton } from "./styles";

const initialForm = {
  title: "",
  text: "",
};

export default function CreateMailDoorman() {
  const history = useHistory();
  const [form, setForm] = useState(initialForm);
  const [loading, setLoading] = useState(false);
  const [residents, setResidents] = useState([]);
  const [residentId, setResidentId] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [handleFile, setHandleFile] = useState("");

  const { user } = useAuth();

  let userJson = user;

  if (typeof user === "string") {
    userJson = JSON.parse(user);
  }

  const userModality = userJson.modality;

  const modalities = {
    admin: { rota: "admin", tipo: "Admin" },
    manager: { rota: "manager", tipo: "Síndico" },
    doorman: { rota: "doorman", tipo: "Porteiro" },
    maintenance: { rota: "maintenance", tipo: "Manutenção" },
    administration: { rota: "employee", tipo: "Administrativo" },
  };

  const { rota, tipo } = modalities[userModality] || { rota: "", tipo: "" };

  const notifySucess = () => toast.success("Notificação enviada com sucesso!");
  const notifyError = () => toast.error("Erro ao enviar notificação!");

  useEffect(async () => {
    getData();
  }, []);

  async function getData() {
    try {
      const residentsList = await api.get("/resident").then((res) => res.data);
      const newResidentsList = residentsList.map((item) => ({
        value: item.id,
        label: item.name,
        complement: item.complement,
      }));
      setResidents(newResidentsList);
    } catch (error) {
      throw error;
    }
  }

  function getDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = dd + "/" + mm + "/" + yyyy;

    return today;
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      border: "none",
      borderLeft: "4px solid #00334E",
      borderRadius: "10px",
      backgroundColor: "#f8f8f8",
      padding: "4px 12px",
    }),
    container: (provided, state) => ({
      ...provided,
    }),

    // control: () => ({
    //   // none of react-select's styles are passed to <Control />
    //   width: 200,
    // }),
    // singleValue: (provided, state) => {
    //   const opacity = state.isDisabled ? 0.5 : 1;
    //   const transition = "opacity 300ms";

    //   return { ...provided, opacity, transition };
    // },
  };

  const photoHandler = (event) => {
    setPhoto(event.target.files[0]);
  };

  async function sendWarning(values) {
    setLoading(true);
    const data = { ...values, resident_id: residentId.value };
    const formData = new FormData();
    let completeObj = {};
    if (photo) {
      completeObj = {
        ...data,
        header: `content-type:${photo.type}`,
      };
      formData.append("photo", photo);
    } else {
      completeObj = {
        ...data,
      };
    }
    formData.append("data", JSON.stringify(completeObj));
    try {
      await api.post("/mail", formData);
      notifySucess();
      history.goBack();

      // console.log(response);
    } catch (e) {
      console.log("erro");
      console.log(e);
      notifyError();
    }
    setLoading(false);
    console.log(data);
  }

  return (
    <div id="homeContent">
      <NewSideBar rota={rota} tipo={tipo}></NewSideBar>
      <Grid
        container
        item
        xs={8}
        style={{
          width: "100%",
          height: "100%",
          overflowY: "auto",
          paddingLeft: "2vw",
          paddingTop: "2vh",
          paddingBottom: "2vh",
          flexBasis: "auto",
          maxWidth: "100%",
        }}
      >
        <Container>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <ArrowBack />

            <p id="pageTitle">Correios</p>
          </div>

          <div>
            <Formik
              initialValues={{
                info: "",
                type: "",
              }}
              enableReinitialize
              onSubmit={(values, { resetForm }) => {
                sendWarning(values);
                setTimeout(function () {
                  resetForm({ values: "" });
                  setResidentId(null);
                }, 1000);
              }}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                isValid,
                touched,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div id="warnContainer">
                    <p className="registerInputTitle">Destinatário</p>
                    {touched.title && errors.title && (
                      <div id="errorMessage2">{errors.title}</div>
                    )}
                    <Select
                      options={residents}
                      isClearable={true}
                      styles={customStyles}
                      value={residentId}
                      onChange={(option) => {
                        setResidentId(option);
                      }}
                    />
                    <p className="registerInputTitle">Complemento</p>
                    {touched.title && errors.title && (
                      <div id="errorMessage2">{errors.title}</div>
                    )}
                    <input
                      className="inputText"
                      id="InputWarnTitle"
                      value={residentId ? residentId.complement : ""}
                      disabled
                    />
                    <p className="registerInputTitle">Descrição</p>
                    {touched.title && errors.title && (
                      <div id="errorMessage2">{errors.title}</div>
                    )}
                    <textarea
                      className="inputText"
                      id="InputWarnTitle"
                      style={{
                        height: "20vh",
                        resize: "both",
                        textAlign: "start",
                      }}
                      value={values.info}
                      onChange={handleChange("info")}
                      onBlur={handleBlur("info")}
                    />

                    <div
                      id="InputFile"
                      style={{ marginTop: "4vh", marginBottom: 0 }}
                    >
                      <input
                        id="FileSelect"
                        type="file"
                        onChange={photoHandler}
                      />
                    </div>

                    <div className="radio-input-wrap">
                      <label className="radio-input">
                        <input
                          type="radio"
                          value="correspondência"
                          name="type"
                          checked={values.type === "correspondência"}
                          onChange={handleChange}
                        />
                        Correspondência
                        <GoMail />
                      </label>
                      <label className="radio-input">
                        <input
                          type="radio"
                          value="material"
                          name="type"
                          checked={values.type === "material"}
                          onChange={handleChange}
                        />
                        Material
                        <GoPackage />
                      </label>
                    </div>
                    {/* <p className="registerInputTitle">Escreva aqui</p>
                  {touched.text && errors.text && (
                    <div id="errorMessage2">{errors.text}</div>
                  )}

                  <textarea
                    className="inputText"
                    id="InputWarnBody"
                    value={values.text}
                    onChange={handleChange("text")}
                    onBlur={handleBlur("text")}
                  /> */}
                    {/* <div id="InputFile">
                    <input
                      id="FileSelect"
                      type="file"
                      onChange={photoHandler}
                    />
                  </div> */}
                    {loading ? (
                      <Loading />
                    ) : (
                      <ContainerButton>
                        <button type="submit" className="handleButton">
                          <p>Enviar Notificação</p>
                        </button>
                      </ContainerButton>
                    )}
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Container>
        <ToastContainer />
      </Grid>
    </div>
  );
}
