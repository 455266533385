import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import CloseIcon from "@mui/icons-material/Close";
//Service
import api from "../../../../../Services/api";

//Toast
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Components
import Loading from "../../../../../Components/Loading/index";
import Sidebar from "../../../../../Components/SidebarAdmin";
//import SidebarOptions from "../../../../Components/SidebarOptions";
import ArrowBack from "../../../../../Components/ResponsiveArrowBack";

//Icons
import SearchIcon from "@material-ui/icons/Search";
import plus from "../../../../../Assets/plus.svg";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { Search } from "@material-ui/icons";
import "./index.css";
import { array } from "yup";
import {
  ButtonDelete,
  CarContainer,
  CardDiv,
  Container,
  DivButtonsCard,
  HeaderDiv,
  NewPage,
  RowDiv,
} from "../../Empresas/styles";
import NewSideBar from "../../../../../Components/NewComponents/NewSideBar";
import NewHeader from "../../../../../Components/NewComponents/Header";
import { Divrow } from "../../../../../Components/NewComponents/Header/styles";
import { ButtonCriar } from "./styles";
import ModalDeleteEmpresa from "../../Empresas/modalDeleteEmpresa";
import { DivText } from "../../../../../Components/NewComponents/NewSideBar/styles";
import CardInfo from "../../../../../Components/NewComponents/Text";

var textBreak = [];

export default function AdminServicesList() {
  //States
  const [avisos, setAvisos] = useState([]);
  const [procurar, setProcurar] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [tagSelectedOption, seTagSelectedOption] = useState("");
  const history = useHistory();

  //Pegar Serviços do back
  async function getData() {
    try {
      const response = await api.get("/service/admin");
      setAvisos(response.data);
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getData();
    //console.log(avisos[0]);
  }, []);

  //Testes
  /*useEffect(() => {
    console.log(selectedOption);
    console.log(tagSelectedOption);
  }, [selectedOption, tagSelectedOption]);*/

  //Pegar Tags do Back
  const getOptions = async () => {
    const response = await api.get("/service/tags");
    const options = response.data.tags.map((option) => ({
      value: option,
      label: option,
    }));
    setOptions(options);
  };

  useEffect(() => getOptions(), []); //Setar lista de opcoes.

  //Estilos do select
  const styles = {
    container: (base) => ({
      ...base,
      width: "25vw",
      borderRadius: "5px",
      height: "5vh",
    }),
  };

  /*Selecionar tag do react select
  const selectTag = function () {
    if (selectedOption) {
      const tag = selectedOption.value;
      seTagSelectedOption(tag);
    }
  };
  useEffect = (selectTag(), [selectedOption]);*/

  //Deletar Serviço
  async function deleteWarning(warn_id) {
    console.log(warn_id);
    try {
      await api.delete(`/service/admin/${warn_id}`);
      setAvisos(avisos.filter((aviso) => aviso.id !== warn_id));
      window.alert("Serviço deletado com sucesso!");
      getData();
      //toast.error("Serviço apagado!");
      //const notifyDelete = () =>
      //notifyDelete();
    } catch (error) {
      throw error;
    }
  }

  const customStyles = {
    control: (base) => ({
      ...base,
      width: "420px",
    }),
  };

  return (
    <NewPage>
      <NewSideBar tipo={"Administrador"} rota={"admin"}></NewSideBar>
      <Container>
        <HeaderDiv>
          <NewHeader name={"Serviços"}></NewHeader>
        </HeaderDiv>
        <RowDiv style={{ gap: "20px" }}>
          <Select
            options={options}
            value={selectedOption}
            placeholder={"Filtre pelo serviço"}
            isClearable={true}
            backspaceRemovesValue={true}
            styles={customStyles}
            onChange={(e) => {
              setSelectedOption(e);
            }}
          />
          <Link
            to={{
              pathname: "/admin/servicos/criar",
              state: { data: "test" },
            }}
            params={{ test: "hello" }}
            style={{ textDecoration: "none", color: "black" }}
          >
            <ButtonCriar>
              <p>Criar Serviço</p>
            </ButtonCriar>
          </Link>

          <Link
            to={{
              pathname: "/admin/servicos/GestãoEspecialidade",
              state: { data: "test" },
            }}
            params={{ test: "hello" }}
            style={{ textDecoration: "none", color: "black" }}
          >
            <ButtonCriar>
              <p>Gestão de Especialidades</p>
            </ButtonCriar>
          </Link>
        </RowDiv>
        <CarContainer>
          {avisos
            .filter((aviso) => {
              if (selectedOption == null) {
                return aviso;
              } else if (
                aviso?.tag
                  .toLowerCase()
                  .includes(selectedOption.value.toLowerCase())
              ) {
                return aviso;
              }
            })
            .map((item) => (
              <CardDiv>
                <DivText>
                  {console.log("dsad", item.id)}
                  <CardInfo name={"Nome: "} info={item.name}></CardInfo>
                  <CardInfo name={"Especialidade: "} info={item.tag}></CardInfo>
                  <CardInfo
                    name={"Descrição: "}
                    info={item.description}
                  ></CardInfo>

                  <CardInfo name={"Contato: "} info={item.contact}></CardInfo>
                  <CardInfo name={"Nota: "} info={item.rating}></CardInfo>
                  <CardInfo name={"Email: "} info={item.email}></CardInfo>
                  <CardInfo name={"CNPJ: "} info={item.cnpj}></CardInfo>
                  <CardInfo
                    name={"Link do Anunciante: "}
                    info={item.link}
                  ></CardInfo>
                  {item.photo_url ? (
                    <a
                      style={{ color: "#EE6F57" }}
                      href={`${item?.photo_url}`}
                      target="_blank"
                    >
                      Ver imagem
                    </a>
                  ) : (
                    <></>
                  )}
                </DivText>
                <DivButtonsCard>
                  <ButtonDelete
                    onClick={() => {
                      deleteWarning(item.id);
                    }}
                  >
                    <CloseIcon></CloseIcon>
                  </ButtonDelete>

                  <Link to={`/admin/servicos/Editar/${item.id}`}>
                    <button id="EditButton">
                      <MdOutlineModeEditOutline />
                    </button>
                  </Link>
                </DivButtonsCard>
              </CardDiv>
            ))}
        </CarContainer>
      </Container>
    </NewPage>
  );
}

/* 
  <div>
              <Select
                options={options}
                value={selectedOption}
                placeholder={"Filtre pelo serviço"}
                isClearable={true}
                backspaceRemovesValue={true}
                styles={styles}
                onChange={(e) => {
                  setSelectedOption(e);
                }}
              />
            </div>
            <div id="divbutton">
              <Link
                to={{
                  pathname: "/admin/servicos/criar",
                  state: { data: "test" },
                }}
                params={{ test: "hello" }}
                style={{ textDecoration: "none", color: "black" }}
              >
                <div id="WarnAddButton">
                  <p>Criar serviço</p>
                </div>
                
              </Link>
               <Link
                to={{
                  pathname: "/manager/servicos/indicacoes",
                  state: { data: "test" },
                }}
                params={{ test: "hello" }}
                style={{ textDecoration: "none", color: "black" }}
              >
                <div id="marginTop">
                  <div id="WarnAddButton">
                    <p>Ver lista de indicações</p>
                  </div>
                </div>
              </Link> }
              </div>
          
              <div id="divbutton">
                  <Link
                    to={{
                      pathname: "/admin/servicos/criar",
                      state: { data: "test" },
                    }}
                    params={{ test: "hello" }}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <div id="WarnAddButton">
                      <p>Criar Especialidade</p>
                    </div>
                    
                  </Link>
                
                </div>
                </div>
    
              
              {avisos
                .filter((aviso) => {
                  if (selectedOption == null) {
                    return aviso;
                  } else if (
                    aviso?.tag
                      .toLowerCase()
                      .includes(selectedOption.value.toLowerCase())
                  ) {
                    return aviso;
                  }
                })
                .map((aviso) => (
                  <div className="data-container" key={aviso?.id}>
                    <div id="data-safe-area">
                      <div className="WarnTitle">{aviso?.name}</div>
                      <div className="manager-name-title" style={{marginTop: "1vw"}}>Contato: {aviso?.contact}</div>
                      <div className="manager-name-title">Especialidade: {aviso?.tag}</div>
                      <div className="manager-name-title">CNPJ: {aviso?.cnpj}</div>
                      { <div className="manager-name-title">
                        <a  href={`${aviso?.photo_url}`}>Ver foto</a>
                      </div> }
                    </div>
                    
                    <div className="WarnDate">{aviso?.description.split("\n").map((discriptions)=>(<p>{discriptions}</p>))}</div>
                    <div id="ButtonContainer">
                      <button
                        id="EditButton"
                        onClick={() => {
                          history.push(`/admin/servicos/editar/${aviso?.id}`);
                        }}
                      >
                        <MdOutlineModeEditOutline />
                      </button>
                      <button
                        id="deleteButtonWarnings"
                        onClick={() => {
                          deleteWarning(aviso?.id);
                        }}
                      >
                        <span id="cross1"></span>
                        <span id="cross2"></span>
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <ToastContainer />
        </div>

*/
