import styled from "styled-components";

export const Container = styled.div`


`;

export const Img = styled.img`
    

`;

export const H1 = styled.h1`
    

`;

export const P = styled.p`
    

`;