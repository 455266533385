import React from "react";

import { Button, Option} from "./styles.js";

export default function SidebarOptions({ text, src, onClick }) {
  return (
    <Option>
      <img src={src} id="icon" style={{backgorundImage: "#fff"}} />
      <Button onClick={onClick}>{text}</Button>
    </Option>
  );
}
