import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";

import Select from "react-select";
//Service
import api from "../../../../../Services/api";

//Toast
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Components
import Loading from "../../../../../Components/Loading/index";
import Sidebar from "../../../../../Components/SideBarDoorman";

//import SidebarOptions from "../../../../Components/SidebarOptions";
import ArrowBack from "../../../../../Components/ResponsiveArrowBack";

//Styles
import "./index.css";

const initialForm = {
  title: "",
  text: "",
};

export default function CreateDeliveryDoorman() {
  const history = useHistory();
  const [form, setForm] = useState(initialForm);
  const [loading, setLoading] = useState(false);
  const [residents, setResidents] = useState([]);
  const [residentId, setResidentId] = useState(null);

  const notifySucess = () => toast.success("Notificação enviada com sucesso!");
  const notifyError = () => toast.error("Erro ao enviar notificação!");

  useEffect(async () => {
    getData();
  }, []);

  async function getData() {
    try {
      const residentsList = await api.get("/resident").then((res) => res.data);
      const newResidentsList = residentsList.map((item) => ({
        value: item.id,
        complement: item.complement,
        label: item.name,
      }));
      setResidents(newResidentsList);
      console.log(residents);
    } catch (error) {
      throw error;
    }
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      border: "none",
      borderLeft: "4px solid #00334E",
      borderRadius: "10px",
      backgroundColor: "#f8f8f8",
      padding: "4px 12px",
    }),
    container: (provided, state) => ({
      ...provided,
    }),

    // control: () => ({
    //   // none of react-select's styles are passed to <Control />
    //   width: 200,
    // }),
    // singleValue: (provided, state) => {
    //   const opacity = state.isDisabled ? 0.5 : 1;
    //   const transition = "opacity 300ms";

    //   return { ...provided, opacity, transition };
    // },
  };

  function getDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = dd + "/" + mm + "/" + yyyy;

    return today;
  }

  async function sendWarning(values) {
    setLoading(true);
    //const upload = await sendToStorage();
    //console.log(upload, "Upload completo!");
    const data = { ...values, resident_id: residentId.value };
    console.log(data);
    // let completeObj = {
    //   ...data,
    //   //date: getDate(),
    //   //photo_url: upload,
    // };
    // console.log(completeObj);

    try {
      await api.post("/delivery", data);
      notifySucess();
      history.goBack();

      // console.log(response);
    } catch (e) {
      console.log("erro");
      console.log(e);
      notifyError();
    }
    setLoading(false);
  }

  return (
    <div id="homeContent" style={{ marginLeft: "11vw" }}>
      <Sidebar />
      <div>
        <div className="pageTitleContainer" id="x">
          <ArrowBack />

          <p id="pageTitle">Delivery</p>
        </div>

        <div id="listArea">
          <Formik
            initialValues={{
              name: "",
              company_name: "",
              /*rg: "", */
            }}
            enableReinitialize
            onSubmit={(values, { resetForm }) => {
              sendWarning(values);
              setTimeout(function () {
                resetForm({ values: "" });
                setResidentId(null);
              }, 1000);
            }}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              errors,
              isValid,
              touched,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <div id="warnContainer">
                  <p className="registerInputTitle">Nome Completo</p>
                  {touched.title && errors.title && (
                    <div id="errorMessage2">{errors.title}</div>
                  )}
                  <input
                    className="inputText"
                    id="InputWarnTitle"
                    value={values.name}
                    onChange={handleChange("name")}
                    onBlur={handleBlur("name")}
                  />
                  <p className="registerInputTitle">Empresa</p>
                  {touched.title && errors.title && (
                    <div id="errorMessage2">{errors.title}</div>
                  )}
                  <input
                    className="inputText"
                    id="InputWarnTitle"
                    value={values.company_name}
                    onChange={handleChange("company_name")}
                    onBlur={handleBlur("company_name")}
                  />
                  <p className="registerInputTitle">Número do documento (opcional)</p>
                  {touched.title && errors.title && (
                    <div id="errorMessage2">{errors.title}</div>
                  )}
                  <input
                    className="inputText"
                    id="InputWarnTitle"
                    value={values.document}
                    onChange={handleChange("document")}
                    onBlur={handleBlur("document")}
                  />
                  <p className="registerInputTitle">Nome do Residente</p>
                  {touched.title && errors.title && (
                    <div id="errorMessage2">{errors.title}</div>
                  )}
                  <Select
                    options={residents}
                    isClearable={true}
                    styles={customStyles}
                    value={residentId}
                    onChange={(option) => {
                      setResidentId(option);
                    }}
                  />
                  <p className="registerInputTitle">Complemento do residente</p>
                  {touched.title && errors.title && (
                    <div id="errorMessage2">{errors.title}</div>
                  )}
                  <input
                    className="inputText"
                    id="InputWarnTitle"
                    value={residentId ? residentId.complement : ""}
                  />
                  <p className="registerInputTitle">Data</p>
                  {touched.title && errors.title && (
                    <div id="errorMessage2">{errors.title}</div>
                  )}
                  <input
                    className="inputText"
                    id="InputWarnTitle"
                    value={getDate()}
                  />
                  {/* <input
                    className="inputText"
                    id="InputWarnTitle"
                    value={values.title}
                    onChange={handleChange("title")}
                    onBlur={handleBlur("title")}
                  /> */}

                  {/* <p className="registerInputTitle">Escreva aqui</p>
                  {touched.text && errors.text && (
                    <div id="errorMessage2">{errors.text}</div>
                  )}

                  <textarea
                    className="inputText"
                    id="InputWarnBody"
                    value={values.text}
                    onChange={handleChange("text")}
                    onBlur={handleBlur("text")}
                  /> */}
                  {/* <div id="InputFile">
                    <input
                      id="FileSelect"
                      type="file"
                      onChange={photoHandler}
                    />
                  </div> */}
                  {loading ? (
                    <Loading />
                  ) : (
                    <button type="submit" className="handleButton">
                      <p>Enviar Notificação</p>
                    </button>
                  )}
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
