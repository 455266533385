import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

// Auth
import { useAuth } from "../../../../Context/auth";

// Service
import api from "../../../../Services/api";

// Toast
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Components
import Sidebar from "../../../../Components/SidebarManager";
import ArrowBack from "../../../../Components/ResponsiveArrowBack";
import { InputWrap } from "../Management/Legislation/AddLei/style";
import DatePicker from "react-date-picker";
import SearchIcon from "@material-ui/icons/Search";

// Assets
import plus from "../../../../Assets/plus.svg";

// Style
import "./index.css";
import { Grid } from "@material-ui/core";
import NewSideBar from "../../../../Components/NewComponents/NewSideBar";

export default function SortitionPageAdministration() {
  const history = useHistory();

  const { user } = useAuth();

  const [name, setName] = useState("");
  const [sortitions, setSortition] = useState([]);
  const [procurar, setProcurar] = useState("");
  const [data, setData] = useState();
  const [filter, setFilter] = useState("name");
  const [spaces, setSpaces] = useState([]);
  const infos = localStorage.getItem("@SWCondominio:user");

  const notifyError = () =>
    toast.error(
      "Desculpe, houve uma falha ao obter dados dos sorteios. Tente novamente mais tarde."
    );
  const notifyDeleteError = () => toast.error("Erro ao excluir sorteio!");
  const notifyDelete = () => toast.success("Sorteio deletado!");

  useEffect(() => {
    const getData = async () => {
      try {
        const response = (await api.get(`/sortition`)).data;
        const spaces = (await api.get(`/space`)).data;
        const sortitions = response.map((sortition) => ({
          name: sortition.name,
          space: spaces.find((x) => x.id == sortition.space_id)?.name,
          date: sortition.date,
          id: sortition.id,
        }));
        setSortition(sortitions);
        setSpaces(spaces);
      } catch (e) {
        notifyError();
        console.log(e);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    function getProfileData() {
      api.get(`/resident/${JSON.parse(infos).id}`).then((response) => {});
    }
    getProfileData();
  }, []);

  async function deleteItem(id) {
    try {
      await api.delete(`/sortition/${id}`);
      setSortition(sortitions.filter((space) => space.id !== id));
      notifyDelete();
    } catch (error) {
      notifyDeleteError();
      throw error;
    }
  }

  function onChangeData(data) {
    setData(data);
    setProcurar("");
  }

  function customizeData(data) {
    const parts = data.split("-");
    const year = parts[0];
    const month = parts[1];
    const day = parts[2];
    const outputString = `${day}/${month}/${year}`;
    return outputString;
  }

  return (
    <Grid container>
      <Grid item xs={4}>
        <NewSideBar rota={"employee"} tipo={"Administrativo"} />
      </Grid>
      <Grid container item xs={8} style={{ marginBottom: "40px" }}>
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "1vh",
              alignItems: "center",
            }}
          >
            <ArrowBack />
            <p className="pageTitle">Sorteio de Reserva</p>
          </div>
          <div style={{ display: "flex", marginTop: "40px" }}>
            <div id="searchContainer">
              {filter === "date" ? (
                <>
                  <SearchIcon style={{ marginRight: "20px" }} />
                  <DatePicker
                    value={data}
                    onChange={(event) => onChangeData(event)}
                    maxDate={new Date()}
                  />
                </>
              ) : (
                <>
                  <SearchIcon />
                  <input
                    id="searchInput"
                    placeholder="Pesquisar"
                    onChange={(event) => {
                      setProcurar(event.target.value);
                    }}
                  />
                </>
              )}
            </div>
            <InputWrap
              style={{
                marginBottom: "32px",
                marginLeft: "10px",
                width: "200px",
              }}
            >
              <select
                style={{
                  border: "none",
                  borderLeft: "4px solid #00334E",
                  borderRadius: "20px",
                  minWidth: "150px",
                  backgroundColor: "#f8f8f8",
                  padding: "4px 12px",
                }}
                placeholder="None"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              >
                <option value="name">Nome</option>
                <option value="date">Data</option>
                <option value="space">Espaço</option>
              </select>
            </InputWrap>
          </div>
          <Link
            to={{
              pathname: "/manager/sortition/create",
            }}
            style={{ textDecoration: "none", color: "black" }}
          >
            <div className="addButton">
              <img src={plus} id="plusIcon" alt="adicionar sorteio" />
              <p>Cadastrar nova data para sorteio</p>
            </div>
          </Link>
        </Grid>
        <Grid container spacing={2} justifyContent="space-between">
          {sortitions &&
            sortitions
              .filter((sortition) => {
                if (procurar === "" && data === "") {
                  return sortition;
                } else if (
                  sortition?.name
                    .toLowerCase()
                    .includes(procurar.toLowerCase()) &&
                  filter === "name"
                ) {
                  return sortition;
                } else if (
                  sortition?.space
                    .toLowerCase()
                    .includes(procurar.toLowerCase()) &&
                  filter === "space"
                ) {
                  return sortition;
                } else if (
                  sortition?.date.includes(
                    data ? data.toISOString().substr(0, 10) : ""
                  ) &&
                  filter === "date"
                ) {
                  return sortition;
                }
              })
              .map((sortition, index) => (
                <div>
                  <div
                    className="dataContainer sm"
                    style={{ width: "50vw", height: "28vh", marginTop: "5vh" }}
                  >
                    <div className="infoLabel" key={`field-name-${index}`}>
                      Nome: {sortition?.name}
                    </div>
                    <div className="infoLabel" key={`field-date-${index}`}>
                      Data: {customizeData(sortition?.date)}
                    </div>
                    <div className="infoLabel" key={`field-space-${index}`}>
                      Espaço: {sortition.space}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                      }}
                    >
                      <button
                        key={`draw-button-${index}`}
                        onClick={() => {
                          history.push(
                            `/manager/sortition/residents/${sortition.name}/${sortition.space}/${sortition.id}`
                          );
                        }}
                        className="actionButton incritos"
                        style={{ marginRight: "10px", fontSize: "10px" }}
                      >
                        Inscritos
                      </button>

                      <button
                        key={`draw-button-${index}`}
                        onClick={() => {
                          history.push(
                            `/manager/sortition/draw/${sortition.id}`
                          );
                        }}
                        className="actionButton edit"
                      >
                        Sortear
                      </button>

                      <button
                        key={`delete-button-${index}`}
                        onClick={() => {
                          deleteItem(sortition?.id);
                        }}
                        style={{ marginLeft: "10px" }}
                        className="actionButton delete"
                      >
                        Apagar
                      </button>
                    </div>
                  </div>
                </div>
              ))}
        </Grid>
      </Grid>
      <ToastContainer />
    </Grid>
  );
}
