import styled from "styled-components";
import ReactSelect from "react-select";

export const NewInput = styled.input`
 width: 30vw;
 height: 5vh;
  text-align: start;

  border-radius: 4px;
  border: none;
  border-left: 4px solid #00334e;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;

  background-color: #f8f8f8;
      min-width: 400px;
  outline: none;
  transition: 0.25s;
`

export const InputDiv = styled.div`
display: flex;
flex-direction: column;
gap: 20px;
align-items: center;
width: 100%;

`

export const CustomSelect = styled(ReactSelect)`
display: flex;
`