import React, { useEffect, useState } from "react";
import {
  CarContainer,
  Container,
  HeaderDiv,
  NewPage,
} from "../../Empresas/styles";
import NewSideBar from "../../../../../Components/NewComponents/NewSideBar";
import NewHeader from "../../../../../Components/NewComponents/Header";
import { InputDiv, NewInput } from "../CreateService/styles";
import api from "../../../../../Services/api";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";

function EditEspecialistas() {
  const [name, setName] = useState("");
  const history = useHistory();
  const { id } = useParams();

  const patchData = async () => {
    try {
      const data = { name: name };
      const response = await toast.promise(api.patch(`/tag/${id}`, data), {
        pending: "Enviando...",
        success: "Especialidade alterada com sucesso!",
        error: {
          render({ data }) {
            return `Erro ao alterar a especialidade! {${data?.response?.data?.msg}}`;
          },
        },
      });
      history.push("/admin/servicos/GestãoEspecialidade");
    } catch (error) {}
  };

  async function getData() {
    try {
      const response = await api.get(`/tag/${id}`);

      setName(response.data.name);
      console.log(response.data.name, "OIIII");
    } catch (error) {}
  }

  console.log(id);
  useEffect(() => {
    getData();
  }, []);

  return (
    <NewPage>
      <NewSideBar tipo={"Administrador"} rota={"admin"}></NewSideBar>
      <Container>
        <HeaderDiv>
          <NewHeader name={"Editar Especialidade"}></NewHeader>
        </HeaderDiv>
        <CarContainer>
          <InputDiv>
            <h1>Nome da Especialidade</h1>
            <NewInput
              value={name}
              onChange={(event) => setName(event.target.value)}
            ></NewInput>
            <button
              className="handleButton"
              style={{ marginBotton: "5vh", width: "25vw" }}
              onClick={() => {
                patchData();
              }}
            >
              <p>Enviar</p>
            </button>
          </InputDiv>
        </CarContainer>
      </Container>
    </NewPage>
  );
}

export default EditEspecialistas;
