import React, { useEffect, useState } from "react";
import {
  CarContainer,
  Container,
  HeaderDiv,
  NewPage,
} from "../../Empresas/styles";
import NewSideBar from "../../../../../Components/NewComponents/NewSideBar";
import NewHeader from "../../../../../Components/NewComponents/Header";
import api from "../../../../../Services/api";
import { toast } from "react-toastify";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { CustomSelect, InputDiv, NewInput } from "../CreateService/styles";
import Select from "react-select";

function EditService() {
  const { id } = useParams();
  const history = useHistory();
  //const [form, setForm] = useState(initialForm);
  const [loading, setLoading] = useState(false);
  //const [photo, setPhoto] = useState(null);
  const [handleFile, setHandleFile] = useState("");
  //states para envio pro back do novo serviço
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [description, setDescription] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [email, setEmail] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [checked, setChecked] = useState([]);
  const [imgErrorText, setImgErrorText] = useState("");
  const [allStates, setAllStates] = useState(false);
  const [img, setImg] = useState("");
  const [link, setLink] = useState("");

  const notifySucess = () =>
    toast.success(id ? "Serviço editado!" : "Serviço criado!");
  const notifyError = () =>
    toast.error(id ? "Erro ao editar serviço!" : "Erro ao criar serviço!");
  const notifyFetchError = () =>
    toast.error(
      "Houve uma falha obtendo os dados desse serviço. Tente novamente mais tarde"
    );

  useEffect(() => {
    const retrieveData = async () => {
      if (id) {
        try {
          const response = await api.get(`/service/admin/${id}`);
          const warningData = response.data;
          console.log(warningData, "warning data");
          setName(warningData.name);
          console.log(name);
          setContact(warningData.contact);
          console.log(contact);
          setDescription(warningData.description);
          console.log(description);
          console.log(response, "dados do useEffect");
          console.log(warningData, "warning data");
          setHandleFile(warningData.photo_url);
          console.log(handleFile, "handleFile do useEffect");
          setSelectedOption({ value: warningData.tag, label: warningData.tag });
          console.log(warningData.tag, "dsadsadasdasdsadasdasdsadas");
          setEmail(warningData.email);
          console.log(warningData.email, "email");
          setImg(warningData.photo_url);
          setLink(warningData.link);
        } catch (ex) {
          notifyFetchError();
          history.goBack();
          throw ex;
        }
      }
    };
    retrieveData();
  }, [id]);

  const fileSelectedHandler = (e) => {
    if (e.target.files && e.target.files[0]) {
      var img = document.createElement("img");

      img.onload = function () {
        console.log(this.width + " " + this.height);
        setImgErrorText("");
        setSelectedFile(e.target.files[0]);
      };

      var reader = new FileReader();
      reader.onloadend = function (ended) {
        img.src = ended.target.result;
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  //Pegar Tags do Back
  const getOptions = async () => {
    const response = await api.get("/service/tags");
    const options = response.data.tags.map((option) => ({
      value: option,
      label: option,
    }));
    setOptions(options);
  };

  useEffect(() => getOptions(), []); //Setar lista de opcoes.

  //Enviar arquivo para o back
  async function handleSubmitNew() {
    try {
      const formData = new FormData();

      if (selectedFile) {
        console.log("hoy");
        const data = {
          name: name,
          contact: contact,
          description: description,
          tag: selectedOption.value,
          email: email,
          link: link,
          header: `content-type:${selectedFile.type}`,
        };
        formData.append("data", JSON.stringify(data));
        formData.append("photo", selectedFile);
      } else {
        const data = {
          name: name,
          contact: contact,
          description: description,
          tag: selectedOption.value,
          email: email,
        };
        formData.append("data", JSON.stringify(data));
        console.log(formData, "algo");
      }

      console.log(selectedOption.value);
      if (!id) await api.post("/service/admin", formData);
      else await api.patch(`/service/admin/${id}`, formData);
      notifySucess();
      history.goBack();
    } catch (error) {
      console.log(error);
      notifyError();
    }
  }

  const customStyles = {
    control: (base) => ({
      ...base,
      width: "500px",
    }),
  };

  return (
    <NewPage>
      <NewSideBar name={"Administrador"} rota={"admin"}></NewSideBar>
      <Container>
        <HeaderDiv>
          <NewHeader name={"Editar Serviços"}></NewHeader>
        </HeaderDiv>
        <CarContainer>
          <InputDiv>
            <h1>Nome da Empresa</h1>
            <NewInput
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></NewInput>
            <h1>Telefone de Contato</h1>
            <NewInput
              value={contact}
              onChange={(e) => setContact(e.target.value)}
            ></NewInput>
            <h1>Email</h1>
            <NewInput
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></NewInput>
            <h2>Página do Anunciante</h2>
            <NewInput
              value={link}
              onChange={(e) => setLink(e.target.value)}
            ></NewInput>
            <h1>Especialidade</h1>
            <Select
              placeholder={"Selecione o serviço"}
              options={options}
              value={selectedOption}
              defaultValue={selectedOption}
              onChange={(e) => setSelectedOption(e)}
              styles={customStyles}
            />
            <h1>Descrição</h1>
            <NewInput
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></NewInput>
            <div
              id="filePicker"
              style={{
                justifyItems: "center",
                width: "50%",
                alignItems: "center",
              }}
            >
              <p>
                Arquivo de imagem <strong>(resolução 16:9)</strong>
              </p>
              <input type="file" onChange={fileSelectedHandler} />
            </div>
            <img src={img}></img>
            <button
              onClick={handleSubmitNew}
              className="handleButton"
              style={{ marginBotton: "5vh", width: "25vw" }}
            >
              <p>Enviar</p>
            </button>
          </InputDiv>
        </CarContainer>
      </Container>
    </NewPage>
  );
}

export default EditService;
