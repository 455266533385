import React, { useState } from "react";
import Sidebar from "../../../../Components/SidebarManager";
import * as Style from "./style";
import ArrowBack from "../../../../Components/ResponsiveArrowBack";
import { InputLink } from "./style";
import api from "../../../../Services/api";
import NewSideBar from "../../../../Components/NewComponents/NewSideBar";
import { NewPage } from "../../Admin/Empresas/styles";

export default function VideoConferenceAdministration() {
  //const id = null;
  const [linkChamada, setLinkChamada] = useState(""); // Pegar link do input de chamada

  //Gerar link de chamada
  async function handleClick() {
    try {
      window.open("https://meet.google.com/new");
    } catch (error) {
      window.alert("Erro ao iniciar a reunião.");
      console.log(error);
    }
  }

  //Cancelar a Reunião
  /*async function handleStopMeeting()
    {
      try {
        const response = await api.get('/videoconference/stop');
        window.alert("Reunião encerrada com sucesso.");
        console.log(response);
      } catch (error) {
        window.alert("Erro ao encerrar a reunião.");
        console.log(error); 
      }
    }
    */

  //Enviar Link para os moradores
  async function handleSendLink() {
    try {
      if (linkChamada === "") {
        window.alert("Não há link para ser enviado.");
      } else {
        //Postar a conferencia no banco de dados
        const data = {
          link: linkChamada,
        };

        const response = await api.post("/videoconference/create", data);
        window.alert("Link enviado com sucesso.");
        console.log(response);
      }
    } catch (error) {
      window.alert("Erro ao enviar o link.");
      console.log(error);
    }
  }
  return (
    <NewPage>
      <NewSideBar rota={"employee"} tipo={"Administrativo"}></NewSideBar>
      <Style.Content style={{ paddingLeft: "56px" }}>
        <Style.Wrapper style={{ paddingLeft: "0" }}>
          <Style.Header>
            <ArrowBack />
            <p id="pageTitle">Vídeo chamada</p>
          </Style.Header>
          <Style.BtnsWrap>
            <Style.Btn
              onClick={() => handleClick()}
              backgroundColor="#1c114a"
              color="white"
            >
              Criar link para reunião
            </Style.Btn>

            <InputLink
              placeholder={
                linkChamada ? `${linkChamada}` : "Insira o link da Reunião"
              }
              value={linkChamada}
              onChange={(e) => {
                setLinkChamada(e.target.value);
              }}
            ></InputLink>

            <Style.Btn
              backgroundColor="#f95f24"
              color="white"
              onClick={() => handleSendLink()}
            >
              Enviar link para moradores
            </Style.Btn>
            {/*<Style.Btn backgroundColor="#242424" color="white">
              Entrar reunião
            </Style.Btn>
              */}
          </Style.BtnsWrap>
        </Style.Wrapper>
      </Style.Content>
    </NewPage>
  );
}
