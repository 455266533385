import { useState } from "react";
import styled from "styled-components";

//Assets
import { AiOutlinePlusCircle } from "react-icons/ai";
import { AiFillCloseCircle } from "react-icons/ai";
import api from "../../../../../../Services/api";
import AddFile from "../AddFile";

function ManutencaoCard({ fetch, data }) {
  const [isVisible, setIsVisible] = useState(false);
  const Container = styled.div`
    padding: 20px;
    border: 1px solid #000;
    display: flex;
    position: relative;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    border-radius: 8px;
  `;

  const NameWrapp = styled.div`
    word-wrap: break-word;
    padding: 2px 34px 2px 0;
    width: 100%;
    box-sizing: border-box;
  `;

  const Name = styled.a`
    text-decoration: none;
    color: #000;
    font-weight: bold;
    word-wrap: break-word;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    &:hover {
      color: #4fabe1;
    }
  `;

  const FileList = styled.div`
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 4px;
  `;

  const File = styled.a`
    font-size: 14px;
    color: #000;
    word-wrap: break-word;
    width: 100%;
    box-sizing: border-box;
    &:hover {
      color: #4fabe1;
    }
  `;

  const Close = styled.div`
    height: 24px;
    width: 24px;
    color: grey;
    transition: 200ms;
    position: absolute;
    right: 60px;
    &:hover {
      color: blue;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  `;

  const Close2 = styled.div`
    height: 24px;
    width: 24px;
    color: grey;
    transition: 200ms;
    position: absolute;
    right: 20px;
    &:hover {
      color: blue;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  `;

  function handleDelete() {
    api.delete(`/maintenance/${data.id}`);
    fetch();
  }

  return (
    <>
      <Container>
        <NameWrapp>
          <Name>{data.name}</Name>
          <FileList>
            {data.categories.map((item) => (
              <File href={item.media_url} target="_blank">
                {item.name}
                {" - "}
                {item.create_time.slice(0, 10)}
              </File>
            ))}
          </FileList>
        </NameWrapp>
        <Close2 onClick={handleDelete}>
          <AiFillCloseCircle />
        </Close2>
        <Close onClick={() => setIsVisible(!isVisible)}>
          <AiOutlinePlusCircle />
        </Close>
      </Container>
      <AddFile
        maintenance_id={data.id}
        handleAddMntc={fetch}
        isVisible={isVisible}
        changeVisibility={setIsVisible}
      ></AddFile>
    </>
  );
}

export default ManutencaoCard;
