import React, { useState, useEffect } from "react";

import { Grid } from "@material-ui/core";

import ArrowBack from "../../../../../Components/ResponsiveArrowBack";

import { InputWrap } from "../../Management/Legislation/AddLei/style";
import DatePicker from "react-date-picker";
import SearchIcon from "@material-ui/icons/Search";

import Sidebar from "../../../../../Components/SidebarManager";

import {
  Container,
  ContainerButton,
  ContainerCard,
  ContainerLoading,
  ContainerText,
  Line,
  TextEmpty,
  TextVisitor,
  ViewInfoInf,
  ViewInfos,
  ViewInfoSup,
} from "./styles";

import api from "../../../../../Services/api";
import { useAuth } from "../../../../../Context/auth";

import { Oval } from "react-loader-spinner";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NewSideBar from "../../../../../Components/NewComponents/NewSideBar";

export default function SeeHistoryOcorrenceManager() {
  const [ocorrenceList, setOcorrenceList] = useState([]);
  const [procurar, setProcurar] = useState("");
  const [data, setData] = useState();
  const [filter, setFilter] = useState("creator_name");
  const [loading, setLoading] = useState(false);
  const notifyError = () => toast.error("Erro ao buscar lista de ocorrências");
  const notifySucess = () => toast.success("Visitante exluído!");
  let userid = localStorage.getItem("@SWCondominio:user");
  const idUser = JSON.parse(userid);

  const { user } = useAuth();

  let userJson = user;

  if (typeof user === "string") {
    userJson = JSON.parse(user);
  }

  const userModality = userJson.modality;

  const modalities = {
    admin: { rota: "admin", tipo: "Admin" },
    manager: { rota: "manager", tipo: "Síndico" },
    doorman: { rota: "doorman", tipo: "Porteiro" },
    maintenance: { rota: "maintenance", tipo: "Manutenção" },
    administration: { rota: "employee", tipo: "Administrativo" },
  };

  const { rota, tipo } = modalities[userModality] || { rota: "", tipo: "" };

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    setLoading(true);
    try {
      const response = await api.get("/record");
      setOcorrenceList(response.data);
      console.log(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notifyError();
      throw error;
    }
  }

  function onChangeData(data) {
    setData(data);
    setProcurar("");
  }

  return (
    <>
      <Grid container>
        <NewSideBar rota={rota} tipo={tipo}></NewSideBar>
        <Grid
          container
          item
          xs={7}
          style={{ marginLeft: "56px", width: "100%", overflowY: "auto" }}
        >
          <ContainerButton>
            <ArrowBack />

            <p id="pageTitle">Histórico de Passagem de Serviço</p>
          </ContainerButton>
          <Container>
            <div style={{ display: "flex" }}>
              <div id="searchContainer">
                {filter === "date" ? (
                  <>
                    <SearchIcon style={{ marginRight: "20px" }} />
                    <DatePicker
                      value={data}
                      onChange={(event) => onChangeData(event)}
                      maxDate={new Date()}
                    />
                  </>
                ) : (
                  <>
                    <SearchIcon />
                    <input
                      id="searchInput"
                      placeholder="Pesquisar"
                      onChange={(event) => {
                        setProcurar(event.target.value);
                      }}
                    />
                  </>
                )}
              </div>
              <InputWrap
                style={{
                  marginBottom: "32px",
                  marginLeft: "10px",
                  width: "200px",
                }}
              >
                <select
                  style={{
                    border: "none",
                    borderLeft: "4px solid #00334E",
                    borderRadius: "20px",
                    minWidth: "150px",
                    backgroundColor: "#f8f8f8",
                    padding: "4px 12px",
                  }}
                  placeholder="None"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                >
                  <option value="creator_name">Nome</option>
                  <option value="date">Data</option>
                </select>
              </InputWrap>
            </div>
            {loading ? (
              <ContainerLoading>
                <Oval
                  ariaLabel="loading-indicator"
                  height={100}
                  width={100}
                  strokeWidth={5}
                  strokeWidthSecondary={1}
                  color="#1D6995"
                  secondaryColor="white"
                />
              </ContainerLoading>
            ) : ocorrenceList.length > 0 ? (
              ocorrenceList
                .filter((value) => {
                  if (procurar === "" && data === "") {
                    return value;
                  } else if (
                    value?.creator_name
                      .toLowerCase()
                      .includes(procurar.toLowerCase()) &&
                    filter === "creator_name"
                  ) {
                    return value;
                  } else if (
                    value?.date.includes(
                      data
                        ? data.toLocaleDateString("pt-BR", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          })
                        : ""
                    ) &&
                    filter === "date"
                  ) {
                    return value;
                  }
                })
                .map((value) => (
                  <ContainerCard>
                    <ViewInfos>
                      <ViewInfoSup
                        style={{
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <TextVisitor>
                          Responsável: {value.creator_name}
                        </TextVisitor>
                        <TextVisitor>
                          Nomes da equipe:{" "}
                          {value.team.map((item) =>
                            item?.name ? item.name + ", " : "Não informado, "
                          )}
                        </TextVisitor>
                      </ViewInfoSup>
                      <ViewInfoSup>
                        <TextVisitor>Data: {value.date}</TextVisitor>
                        <TextVisitor>Hora: {value.time}</TextVisitor>
                      </ViewInfoSup>
                      <Line />
                      <ContainerText>
                        <TextVisitor>
                          {value.text.split("\n").map((discriptions) => (
                            <p>{discriptions}</p>
                          ))}
                        </TextVisitor>
                      </ContainerText>
                    </ViewInfos>
                  </ContainerCard>
                ))
            ) : (
              <ContainerLoading>
                <TextEmpty>Sem passagens de serviço</TextEmpty>
              </ContainerLoading>
            )}
          </Container>
        </Grid>
      </Grid>
    </>
  );
}
