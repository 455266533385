import {
  Wrap,
  Container,
  Input,
  CloseImg,
  Form,
  InputWrap,
  Header,
  Footer,
  AddBtn,
  CloseBtn,
} from "./style";
import { useState } from "react";
import api from "../../../../../../Services/api";

//Toast
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AddLei({ isVisible, changeVisibility, handleAddMntc }) {
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [type, setType] = useState("");

  const notifySucess = () => toast.success("Lei enviada com sucesso!");
  const notifyError = () => toast.error("Erro ao enviar lei!");

  function postLei(leiType, req) {
    const res = api.post(`/legislation/${leiType}`, req);
    return res;
  }

  async function hadleSubmit(event) {
    console.log("ok");
    const lei = {
      name: name,
      link: link,
    };
    await postLei(type, lei);
    console.log("ok");
    handleAddMntc();
    notifySucess();
    close();
  }

  function close() {
    changeVisibility(false);
    setName("");
  }

  return (
    <Wrap isVisible={isVisible}>
      <Container>
        <Header>
          <CloseImg
            onClick={close}
            src="https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-close-512.png"
          />
          <h2>Adicionar Lei</h2>
        </Header>
        <Form onSubmit={hadleSubmit}>
          <InputWrap>
            <label>Nome</label>
            <input
              className="borda"
              type="text-area"
              name="name"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </InputWrap>
          <InputWrap>
            <label>Tipo</label>
            <select
              className="borda"
              name="type"
              id="type"
              placeholder="None"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <option value="" disabled selected>
                Selecione um opção
              </option>
              <option value="federal">Federal</option>
              <option value="state">Estadual</option>
              <option value="municipal">Municipal</option>
            </select>
          </InputWrap>
          <InputWrap>
            <label>Url</label>
            <input
              onChange={(e) => setLink(e.target.value)}
              value={link}
              className="borda"
            />
          </InputWrap>
        </Form>
        <Footer>
          <AddBtn onClick={hadleSubmit}>Adicionar</AddBtn>
          <CloseBtn onClick={close} type="submit">
            Cancelar
          </CloseBtn>
        </Footer>
      </Container>
    </Wrap>
  );
}

export default AddLei;
