import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
//Formik
import { Formik } from "formik";
//yup
import * as yup from "yup";
//Auth
import { useAuth } from "../../../Context/auth";
import { isAuthenticated } from "../../../Context/isAuthenticated";
//Components
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../../../Components/Loading/index";
import Logo from "../../../Assets/logoraiz.svg";

import "./index.css";

export default function LoginSquare() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [verSenha, setVerSenha] = useState(false);
  const { signIn, token, user } = useAuth();
  const history = useHistory();

  /*useEffect(() => {
    if (isAuthenticated()) {
      if (user.modality === "admin") {
        history.push("/admin/home");
        return;
      }
      if (user.modality === "manager") {
        history.push("/manager/home");
        return;
      }
    }
  }, [token]);*/

  const alternarExibicaoSenha = () => {
    setVerSenha(!verSenha);
  };

  const loginValidationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Entre com um endereço de e-mail válido")
      .required("Endereço de e-mail obrigatório"),
    password: yup.string().required("Senha obrigatória"),
  });

  return (
    <div className="all">
      <div id="mainContainer">
        <div id="leftContainer">
          <div id="teste">
            <img src={Logo} />
            <p className="welcomeText">
              Para continuar, realize seu login, ou solicite seu cadastro.
            </p>
          </div>
        </div>

        <div id="rightContainer">
          <p id="loginText">Login</p>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            onSubmit={async (values) => {
              console.log("enviado");
              const res = await signIn(
                values.email,
                values.password,
                setLoading,
                setError
              );
              console.log(res);
              history.push(`/${res}/home`);
            }}
            validationSchema={loginValidationSchema}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              errors,
              isValid,
              touched,
            }) => (
              <form onSubmit={handleSubmit}>
                <div id="inputs">
                  <div className="inputBox">
                    <div
                      className={
                        touched.email && errors.email
                          ? "erroActive"
                          : "errorMessage"
                      }
                      id="errorMessage"
                    >
                      {errors.email}
                    </div>

                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      className="inputText"
                      onChange={handleChange("email")}
                      value={values.email}
                      onBlur={handleBlur("email")}
                    />
                  </div>

                  <div className="inputBox">
                    <div
                      className={
                        touched.password && errors.password
                          ? "erroActive"
                          : "errorMessage"
                      }
                      id="errorMessage"
                    >
                      {errors.password}
                    </div>

                    <input
                      type={verSenha ? "text" : "password"}
                      name="password"
                      placeholder="Senha"
                      className="inputText"
                      onChange={handleChange("password")}
                      value={values.password}
                      onBlur={handleBlur("password")}
                    />
                    {verSenha ? (
                      <RiEyeLine
                        style={{
                          position: "absolute",
                          color: "#00334E",
                          marginTop: "0.6%",
                          marginLeft: "0.6%",
                        }}
                        size={22}
                        onClick={alternarExibicaoSenha}
                      />
                    ) : (
                      <RiEyeOffLine
                        style={{
                          position: "absolute",
                          color: "#00334E",
                          marginTop: "0.6%",
                          marginLeft: "0.6%",
                        }}
                        size={22}
                        onClick={alternarExibicaoSenha}
                      />
                    )}
                  </div>

                  {loading ? (
                    <Loading />
                  ) : (
                    <>
                      <button
                        type="submit"
                        className="handleButton"
                        id="signIn"
                      >
                        <p>ENTRAR</p>
                      </button>
                      <button
                        type="button"
                        className="handleButton"
                        id="cadastrar"
                        onClick={() => history.push("/cadastro")}
                        style={{ marginTop: "5%" }}
                      >
                        <p>CADASTRAR EMPRESA</p>
                      </button>
                    </>
                  )}
                </div>
              </form>
            )}
          </Formik>
          <Link
            to="/esqueci"
            style={{ textDecoration: "none", color: "black" }}
          >
            <p id="esqueci">Esqueceu a senha?</p>
          </Link>
        </div>
      </div>
      {error ? <ToastContainer /> : null}
    </div>
  );
}
