import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams} from "react-router-dom";
import DatePicker from "react-date-picker";

//Service
import api from "../../../../Services/api";

//Toast
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Components
import Loading from "../../../../Components/Loading/index";
import Sidebar from "../../../../Components/SidebarManager";
import { InputWrap } from "../Management/Legislation/AddLei/style";
//import SidebarOptions from "../../../../Components/SidebarOptions";
import ArrowBack from "../../../../Components/ResponsiveArrowBack";
//Icons
import SearchIcon from "@material-ui/icons/Search";
import plus from "../../../../Assets/plus.svg";
import { MdOutlineModeEditOutline } from "react-icons/md";
import "./index.css";
import { Search } from "@material-ui/icons";

export default function SortitionResidentsList() {

    const { id, space, name } = useParams();

    useEffect(() => {
        getData();
    }, []);
    
    const [residents, setResidents] = useState([]);
    const [procurar, setProcurar] = useState("");
    const [data, setData] = useState();
    const [filter, setFilter] = useState('title');

    async function getData() {
        try {
        const response = await api.get(`/sortition/info/${id}`);
        setResidents(response.data);
        } catch (error) {
        throw error;
        }
    }

    function onChangeData(data) {
        setData(data);
        setProcurar("");
    }

    function convertDate(inicialDate){
        let data = inicialDate;
        let partes = data.split("-"); 
        let dataConvertida = partes[2] + "/" + partes[1] + "/" + partes[0];
        return dataConvertida;
    }

    return (
        <div id="homeContent">
        <Sidebar />
        <div id="WarnSafeArea">
            <div
            className="pageTitleContainer"
            id="x"
            style={{ marginLeft: "35vw" }}
            >
            <ArrowBack />
            <p id="pageTitle">Inscritos</p>
            </div>
            
            <div id="listArea">
            <div id="TopContainer1" style={{ marginLeft: "10vw" }}>
                <div id="searchContainer">
                {
                    filter === "date" ?
                    <>
                    <SearchIcon style={{marginRight:"20px"}}/>
                    <DatePicker
                        value={data}
                        onChange={(event) => onChangeData(event)}
                    />
                    </>
                    :
                    <>
                    <SearchIcon />
                    <input
                        id="searchInput"
                        placeholder="Pesquisar"
                        onChange={(event) => {
                        setProcurar(event.target.value);
                        }}
                    />
                    </>
                }
                </div>
                <InputWrap style={{marginBottom: '20px', marginLeft: '10px', width: '200px'}}>
                <select style={{border: "none",
                    borderLeft: "4px solid #00334E",
                    borderRadius: "20px",
                    minWidth: "150px",
                    backgroundColor: "#f8f8f8",
                    padding: "4px 12px"}}
                    placeholder="None"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                >
                    <option value="title">Nome do Usuário</option>
                    <option value="complement">Complemento</option>
                    <option value="date">Data</option>
                </select>
                
                </InputWrap>
            </div>
            
            <div style={{ marginLeft: "10vw" }}>
            {residents
                .filter((residents) => {
                    if (procurar === "" && data === "") {
                    return residents;
                    } else if (
                    residents?.name
                        .toLowerCase()
                        .includes(procurar.toLowerCase()) && filter === "title"
                    ) {
                    return residents;
                    } else if (
                        convertDate(residents?.complement)
                        .includes(procurar.toLowerCase()) && filter === "complement"
                    ) {
                    return residents;
                    } else if (
                        convertDate(residents?.date)
                        .includes(data ? data.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }) : "") && filter === "date"
                    ) {
                    return residents;
                    }
                })
                .map((residents) => (
                    <div className="data-container" key={residents?.id}>
                    <div id="data-safe-area">
                        <div className="manager-name-title">Nome: {residents?.name}</div>
                        <div className="manager-name-title">Complemento: {residents?.complement}</div>
                        <div className="manager-name-title">
                        </div>
                    </div>
                    <div className="WarnDate" 
                        style={{marginBottom: '10px'}}
                    >
                        {convertDate(residents?.date)}</div>
                    </div>
                ))}
            </div>
            </div>
        </div>
        <ToastContainer />
        </div>
    );
}
