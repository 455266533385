import styled from "styled-components";

export const NewPage = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
`;

export const Container = styled.div`
  display: flex;
  width: 100vw;
  flex-direction: column;
  overflow-y: auto;
`;

export const HeaderDiv = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  height: 15vh;
  padding-left: 6vw;
  padding-top: 4vh;
  align-items: center;
`;

export const RowDiv = styled.div`
  display: flex;
  width: 100%;
  padding-left: 6vw;
  padding-top: 10vh;
  align-items: center;
  @media (max-width: 590px) {
    flex-wrap: wrap;
    flex-direction: column;
    gap: 30px;
  }
`;

export const ButtonSearch = styled.button`
  display: flex;
  justify-content: center;
`;

export const NewInputWrap = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  padding-left: 2vw;
  label {
    font-weight: bold;
  }
  input {
    padding: 12px;
  }
  select {
    padding: 12px;
  }
`;

export const CardDiv = styled.div`
  width: 35vw;
  min-height: ${(props) => (props.height ? props.height : "550px")};
  background-color: #efefef;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 22px;
  padding-top: 1.5vh;
  margin-bottom: 30px;
  word-break: break-all;
  transition: 0.25s;

  &:hover {
    border-left: 5px solid #00334e;
  }
  @media (max-width: 1000px) {
    min-width: 400px;
  }
`;

export const CarContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 6vw;
  width: 100%;
  align-items: flex-start;
  margin-top: 8vh;
  gap: 20px;
  justify-content: space-between;
`;

export const InputSearch = styled.input`
  display: flex;
  width: "50vw";
  border-radius: 5px;
`;

export const DivButtonsCard = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  padding-right: 2vw;
  width: 100%;
`;

export const ButtonDelete = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  border-radius: 100%;
  background-color: transparent;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
  margin-left: 25px;
  border: 1px solid black;

  &:hover {
    background-color: rgb(255, 64, 64);
  }
`;
