import styled from "styled-components";

export const TitleDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
`;

export const ButtonClose = styled.button`
  display: flex;
  align-items: center;
  margin-left: 2vw;
  width: 10vw;
  height: 8vh;
  border-radius: 20px;
  border: 1px solid black;
  background-color: ${(props) => props.color};
  justify-content: center;
`;

export const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 5vh;
  background-color: "#efefef";
`;
