import styled from "styled-components";

export const Container = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
`;

export const Tittle = styled.h1`
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-style: normal;
  margin-top: ${(props) => (props.marginTop ? 20 : 0)}px;
`;

export const TextAbout = styled.p`
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 20px;
  text-align: justify;
  margin-top: ${(props) => (props.marginTop ? 20 : 0)}px;
`;

export const ViewText = styled.div`
  display: flex;
  width: 95%;
  margin-top: 10px;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

export const ImageExample = styled.img`
  margin-left: 20px;
`;

export const ViewBothText = styled.div`
  object-fit: cover;
`;

export const TextFinished = styled.h2`
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  margin-top: 20px;
`;
