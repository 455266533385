import styled from "styled-components";

export const CardDiv2 = styled.div`
  width: 35vw;
  min-height: 250px;
  background-color: #efefef;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 22px;
  padding-top: 1.5vh;
  margin-bottom: 30px;
  word-break: break-all;
  transition: 0.25s;
  &:hover {
    border-left: 5px solid #00334e;
  }
  @media (max-width: 1000px) {
    min-width: 400px;
  }
`;
