import React, { useEffect, useState } from "react";
import {
  CarContainer,
  Container,
  HeaderDiv,
  NewPage,
} from "../../../Admin/Empresas/styles";
import NewSideBar from "../../../../../Components/NewComponents/NewSideBar";
import NewHeader from "../../../../../Components/NewComponents/Header";
import { InputDiv, NewInput } from "../CreateService/styles";
import api from "../../../../../Services/api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function CreateEspecialistasPub() {
  const [data, setData] = useState("");
  const history = useHistory();

  const postData = async () => {
    try {
      const obj = { name: data };
      const response = await api.post("/tag", obj);
      history.push("/publicidade/servicos");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <NewPage>
      <NewSideBar tipo={"Empresa de Publicidade"} rota={"publicidade"} />
      <Container>
        <HeaderDiv>
          <NewHeader name={"Criar Especialistas"}></NewHeader>
        </HeaderDiv>
        <CarContainer>
          <InputDiv onChange={(event) => setData(event.target.value)}>
            <h1>Nome da Especialidade</h1>
            <NewInput></NewInput>
            <button
              className="handleButton"
              style={{ marginBotton: "5vh", width: "25vw" }}
              onClick={() => {
                postData();
              }}
            >
              <p>Enviar</p>
            </button>
          </InputDiv>
        </CarContainer>
      </Container>
    </NewPage>
  );
}

export default CreateEspecialistasPub;

/*
value={name} onChange={(e) => setName(e.target.value)}
onClick={handleSubmitNew}
*/
