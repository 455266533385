import styled from "styled-components";

export const Container = styled.div``;

export const Content = styled.div`
  width: 100%;
  height: 100vh;
  padding-left: 440px;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 60px 120px;
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
`;
export const BtnsWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 80px;
  gap: 80px;
`;
export const Btn = styled.button`
  width: 400px;
  height: 64px;
  color: ${(props) => (props.color ? props.color : "black")};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "grey"};
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 20px;
  border: none;
  border-radius: 8px;
  &:hover {
    cursor: pointer;
  }
`;

export const InputLink= styled.input`
padding: 8px;
gap: 8px;
width: 400px;
height: 64px;
font-family: "Montserrat", sans-serif;
font-weight: 500;
font-size: 20px;


border: 1px solid grey;
border-radius: 8px;
background: #FFFFFF;
/* common/border/default */


`