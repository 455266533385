import React, { useState, useEffect, useCallback } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Sidebar from "../../../../Components/SidebarManager";
import ArrowBack from "../../../../Components/ResponsiveArrowBack";
import { Formik } from "formik";
import Loading from "../../../../Components/Loading/index";
import Select from "react-select";
import profilePhoto from "../../../../Assets/unnamed.png";
import "./index.css";
import userEvent from "@testing-library/user-event";
import { useAuth } from "../../../../Context/auth";
import api from "../../../../Services/api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { string } from "yup";
import { Button } from "@material-ui/core";
import NewSideBar from "../../../../Components/NewComponents/NewSideBar";

export default function ProfileManager() {
  const { user, signOut } = useAuth();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  /*const [rg, setRg] = useState(""); */
  const [properties, setProperties] = useState("");
  const [numCond, setNumCond] = useState(null);
  const [carList, setCarList] = useState([]);
  const [car1Id, setCar1Id] = useState("");
  const [car1Model, setCar1Model] = useState("");
  const [car1Modelref, setCar1Modelref] = useState("");
  const [car1License, setCar1License] = useState("");
  const [car1Licenseref, setCar1Licenseref] = useState("");
  const [car1color, setCar1color] = useState("");
  const [color1ref, setColor1ref] = useState("");
  const [car2Id, setCar2Id] = useState("");
  const [car2Model, setCar2Model] = useState("");
  const [car2Modelref, setCar2Modelref] = useState("");
  const [car2License, setCar2License] = useState("");
  const [car2Licenseref, setCar2Licenseref] = useState("");
  const [car2color, setCar2color] = useState("");
  const [color2ref, setColor2ref] = useState("");
  const [car3Id, setCar3Id] = useState("");
  const [car3Model, setCar3Model] = useState("");
  const [car3Modelref, setCar3Modelref] = useState("");
  const [car3License, setCar3License] = useState("");
  const [car3Licenseref, setCar3Licenseref] = useState("");
  const [car3color, setCar3color] = useState("");
  const [color3ref, setColor3ref] = useState("");
  const [numero, setNumero] = useState("");
  const [phone, setPhone] = useState("");

  const [loading, setLoading] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [handleFile, setHandleFile] = useState("");
  const history = useHistory();

  const notifySucess = () => toast.success("Perfil editado com sucesso!");
  const notifyError = () => toast.error("Erro ao editar perfil!");
  const notifySucess2 = () => toast.success("Perfil apagado com sucesso!");
  const notifyError2 = () => toast.error("Erro ao apagar perfil!");
  const notifyFetchError = () =>
    toast.error(
      "Houve uma falha ao obtender os dados. Tente novamente mais tarde"
    );

  async function deleteUser() {
    const infos = localStorage.getItem("@SWCondominio:user");
    await api
      .delete(`/resident/${JSON.parse(infos).id}`)
      .then(() => {
        notifySucess2();
        console.log("Perfil apagado");
      })
      .catch(() => {
        notifyError2();
      });

    signOut();
  }

  async function handleSubmit() {
    setLoading(true);
    const body = {
      name,
      email,
      condominium_id: numCond,
      phone: phone,
      document: numero,
    };
    const formData = new FormData();
    const data = { ...body };
    console.log(data);
    let completeObj = null;
    if (photo == null) {
      completeObj = {
        ...data,
      };
      console.log(completeObj);
    } else {
      completeObj = {
        ...data,
        header: `content-type:${photo.type}`,
      };
      formData.append("photo", photo);
      console.log(completeObj);
    }
    formData.append("data", JSON.stringify(completeObj));
    const infos = localStorage.getItem("@SWCondominio:user");

    await api
      .patch(`/resident/${JSON.parse(infos).id}`, formData)
      .then(() => {
        notifySucess();
        history.push("/manager/home");
      })
      .catch(() => {
        notifyError();
      });

    const car1 = {
      model: car1Model,
      license: car1License,
      color: car1color,
    };

    const car2 = {
      model: car2Model,
      license: car2License,
      color: car2color,
    };

    const car3 = {
      model: car3Model,
      license: car3License,
      color: car3color,
    };

    if (
      car1Id &&
      (car1color !== color1ref ||
        car1Model !== car1Modelref ||
        car1License !== car1Licenseref)
    ) {
      api
        .patch(`/car/${car1Id}`, car1)
        .then(() => {})
        .catch(() => {
          notifyError();
        });
    }
    if (
      carList.length < 3 &&
      (car1color !== color1ref ||
        car1Model !== car1Modelref ||
        car1License !== car1Licenseref) &&
      !car1Id
    ) {
      api
        .post(`/car`, car1)
        .then(() => {})
        .catch(() => {
          notifyError();
        });
    }
    if (
      car2Id &&
      (car2color !== color2ref ||
        car2Model !== car2Modelref ||
        car2License !== car2Licenseref)
    ) {
      api
        .patch(`/car/${car2Id}`, car2)
        .then(() => {})
        .catch(() => {
          notifyError();
        });
    }
    if (
      carList.length < 3 &&
      (car2color !== color2ref ||
        car2Model !== car2Modelref ||
        car2License !== car2Licenseref) &&
      !car2Id
    ) {
      api
        .post(`/car`, car2)
        .then(() => {})
        .catch(() => {
          notifyError();
        });
    }
    if (
      car3Id &&
      (color3ref !== car3color ||
        car3Model !== car3Modelref ||
        car3License !== car3Licenseref)
    ) {
      api
        .patch(`/car/${car3Id}`, car3)
        .then(() => {})
        .catch((err) => {
          console.log(err);
          notifyError();
        });
    }
    if (
      carList.length < 3 &&
      (color3ref !== car3color ||
        car3Model !== car3Modelref ||
        car3License !== car3Licenseref) &&
      !car3Id
    ) {
      api
        .post(`/car`, car3)
        .then(() => {})
        .catch(() => {
          notifyError();
        });
    }
    console.log(name);
    console.log("imagem");
    console.log(photo);
    setLoading(false);
  }

  const getProfileData = useCallback(async () => {
    const infos = localStorage.getItem("@SWCondominio:user");
    console.log(JSON.parse(infos), " infos");
    api.get(`/resident/${JSON.parse(infos).id}`).then((response) => {
      if (response.data.photo_url == null) {
        setHandleFile(profilePhoto);
      } else {
        setHandleFile(response.data.photo_url);
      }
      setName(response.data.name);
      setEmail(response.data.email);
      setNumero(response.data.document);
      setPhone(response.data.phone);
      //setRg(response.data.rg);
      setNumCond(String(response.data.condominium_id));
      console.log(user, "user");
      console.log(handleFile);

      let newArray = response.data.complements.map((item) => {
        return `${item.name} - ${item.value}`;
      });
      let text = "";
      newArray.map((value) => {
        text = text + value + " ";
      });
      setProperties(text);
      console.log(response.data.complements, "text");
      setCarList(response.data.cars);
      setCar1Id(response.data.cars[0] ? response.data.cars[0].id : "");
      setCar1Model(response.data.cars[0] ? response.data.cars[0].model : "");
      setCar1License(
        response.data.cars[0] ? response.data.cars[0].license : ""
      );
      setCar1color(response.data.cars[0] ? response.data.cars[0].color : "");
      setColor1ref(response.data.cars[0] ? response.data.cars[0].color : "");
      setCar2Id(response.data.cars[1] ? response.data.cars[1].id : "");
      setCar2Model(response.data.cars[1] ? response.data.cars[1].model : "");
      setCar2License(
        response.data.cars[1] ? response.data.cars[1].license : ""
      );
      setCar2color(response.data.cars[1] ? response.data.cars[1].color : "");
      setColor2ref(response.data.cars[1] ? response.data.cars[1].color : "");
      setCar3Id(response.data.cars[2] ? response.data.cars[2].id : "");
      setCar3Model(response.data.cars[2] ? response.data.cars[2].model : "");
      setCar3License(
        response.data.cars[2] ? response.data.cars[2].license : ""
      );
      setCar3color(response.data.cars[2] ? response.data.cars[2].color : "");
      setColor3ref(response.data.cars[2] ? response.data.cars[2].color : "");
      console.log(response.data.cars, car2Id, car3Id, "ids");
      console.log(response.data, "VSFD MT Texto");
    });
  }, [car2Id, car3Id, handleFile, user]);

  useEffect(() => {
    getProfileData();
  }, [getProfileData]);

  const photoHandler = (event) => {
    setPhoto(event.target.files[0]);
  };

  return (
    <div id="homeContent">
      <NewSideBar rota={"manager"} tipo={"Síndico"}></NewSideBar>
      <div
        id="WarnSafeArea"
        style={{ marginLeft: "56px", width: "100%", overflowY: "auto" }}
      >
        <div
          className="pageTitleContainer"
          id="x"
          style={{ marginLeft: "0px" }}
        >
          <ArrowBack />

          <p id="pageTitle">Meu Perfil</p>
        </div>
        <div id="listArea" style={{ marginLeft: "0px" }}>
          <div id="sidebarTopInfo" style={{ marginLeft: "2vw" }}>
            <div id="picPerfil">
              <img
                id="logoPerfil"
                src={handleFile ? handleFile : profilePhoto}
              />
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <p className="registerInputTitle">Foto de Perfil</p>
            <div id="InputFile">
              <input id="FileSelect" type="file" onChange={photoHandler} />
            </div>

            <div id="warnContainer">
              <p className="registerInputTitle">Nome</p>

              <input
                className="inputText"
                id="InputWarnTitle"
                value={name}
                onChange={(text) => setName(text.target.value)}
              />

              <p className="registerInputTitle">Email</p>
              <input
                className="inputText"
                id="InputWarnTitle"
                value={email}
                onChange={(text) => setEmail(text.target.value)}
              />
              {/* <p className="registerInputTitle">RG</p>

              <input
                className="inputText"
                id="InputWarnTitle"
                value={rg}
                onChange={(text) => setRg(text.target.value)}
                /> */}

              <p className="registerInputTitle">Imóvel</p>

              <input
                className="inputText"
                id="InputWarnTitle"
                value={properties}
              />

              <p className="registerInputTitle">Telefone</p>

              <input
                className="inputText"
                id="InputWarnTitle"
                value={numero}
                onChange={(event) => setNumero(event.target.value)}
              />

              <p className="registerInputTitle">Número do documento</p>

              <input
                className="inputText"
                id="InputWarnTitle"
                value={phone}
                onChange={(event) => setPhone(event.target.value)}
              />

              <p className="registerInputTitle" style={{ marginTop: "10px" }}>
                Veículo 1
              </p>
              <p className="registerInputTitle" style={{ fontSize: "15px" }}>
                Modelo
              </p>
              <input
                className="inputText"
                id="InputWarnTitle"
                value={car1Model}
                onChange={(text) => setCar1Model(text.target.value)}
              />
              <p className="registerInputTitle" style={{ fontSize: "15px" }}>
                Placa
              </p>
              <input
                className="inputText"
                id="InputWarnTitle"
                value={car1License}
                onChange={(text) => setCar1License(text.target.value)}
              />
              <p className="registerInputTitle" style={{ fontSize: "15px" }}>
                Cor
              </p>
              <input
                className="inputText"
                id="InputWarnTitle"
                value={car1color}
                onChange={(text) => setCar1color(text.target.value)}
              />

              <p className="registerInputTitle" style={{ marginTop: "10px" }}>
                Veículo 2
              </p>

              <p className="registerInputTitle" style={{ fontSize: "15px" }}>
                Modelo
              </p>
              <input
                className="inputText"
                id="InputWarnTitle"
                value={car2Model}
                onChange={(text) => setCar2Model(text.target.value)}
              />

              <p className="registerInputTitle" style={{ fontSize: "15px" }}>
                Placa
              </p>
              <input
                className="inputText"
                id="InputWarnTitle"
                value={car2License}
                onChange={(text) => setCar2License(text.target.value)}
              />
              <p className="registerInputTitle" style={{ fontSize: "15px" }}>
                Cor
              </p>
              <input
                className="inputText"
                id="InputWarnTitle"
                value={car2color}
                onChange={(text) => setCar2color(text.target.value)}
              />

              <p className="registerInputTitle">Veículo 3</p>

              <p className="registerInputTitle" style={{ fontSize: "15px" }}>
                Modelo
              </p>
              <input
                className="inputText"
                id="InputWarnTitle"
                value={car3Model}
                onChange={(text) => setCar3Model(text.target.value)}
              />

              <p className="registerInputTitle" style={{ fontSize: "15px" }}>
                Placa
              </p>
              <input
                className="inputText"
                id="InputWarnTitle"
                value={car3License}
                onChange={(text) => setCar3License(text.target.value)}
              />

              <p className="registerInputTitle" style={{ fontSize: "15px" }}>
                Cor
              </p>
              <input
                className="inputText"
                id="InputWarnTitle"
                value={car3color}
                onChange={(text) => setCar3color(text.target.value)}
              />

              {loading ? (
                <Loading />
              ) : (
                <>
                  <button
                    type="submit"
                    className="handleButton"
                    onClick={handleSubmit}
                  >
                    <p>Atualizar</p>
                  </button>

                  <button className="clearButton" onClick={deleteUser}>
                    <p>Apagar Perfil</p>
                  </button>
                </>
              )}
              <div style={{ height: "8vh" }}></div>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
