import { useEffect } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import styled from "styled-components";
import api from "../../../../../../Services/api";
import axios from "axios";

function ContratoCard({ fetch, data }) {

  function download() {
    axios({
      url: data.media_url, //your url
      method: 'GET',
      responseType: 'blob', // important
  }).then((response) => {
      // create file link in browser's memory
      console.log(response)
      const href = URL.createObjectURL(response.data);
  
      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `documento_condominio.${response.data.type}`); //or any other extension
      document.body.appendChild(link);
      link.click();
  
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
  }); }


  const Container = styled.div`
    padding: 20px;
    border: 1px solid #000;
    display: flex;
    position: relative;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    border-radius: 8px;
  `;

  const NameWrapp = styled.div`
    word-wrap: break-word;
    padding: 2px 34px 2px 0;

    width: 100%;
    box-sizing: border-box;
  `;

  const Name = styled.a`
    text-decoration: none;
    color: #000;
    word-wrap: break-word;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    &:hover {
      color: #4fabe1;
    }
  `;

  const Close = styled.div`
    height: 24px;
    width: 24px;
    color: grey;
    transition: 200ms;
    position: absolute;
    right: 20px;
    &:hover {
      color: red;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  `;

  function handleDelete() {
    api.delete(`/archive/${data.id}`);
    fetch();
  }

  return (
    <Container>
      <NameWrapp>
        <Name href={`${data.media_url}`} target = "_blank" >
          {data.name}
        </Name>
      </NameWrapp>
      <Close onClick={handleDelete}>
        <AiFillCloseCircle />
      </Close>
    </Container>
  );
}

export default ContratoCard;
