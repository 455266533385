import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Formik } from "formik";
//Service
import api from "../../../../Services/api";

//Toast
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Components
import Loading from "../../../../Components/Loading/index";
import Sidebar from "../../../../Components/SidebarAdmin";
//import SidebarOptions from "../../../../Components/SidebarOptions";
import ArrowBack from "../../../../Components/ResponsiveArrowBack";
//Icons
import SearchIcon from "@material-ui/icons/Search";
import { MdOutlineModeEditOutline } from "react-icons/md";
import plus from "../../../../Assets/plus.svg";

import "./index.css";
import { Search } from "@material-ui/icons";
import {
  ButtonDelete,
  CarContainer,
  CardDiv,
  Container,
  DivButtonsCard,
  HeaderDiv,
  NewPage,
  RowDiv,
} from "../../Admin/Empresas/styles";
import NewSideBar from "../../../../Components/NewComponents/NewSideBar";
import NewHeader from "../../../../Components/NewComponents/Header";
import { DivText } from "../../../../Components/NewComponents/NewSideBar/styles";
import CloseIcon from "@mui/icons-material/Close";

export default function AdvertisementListPub() {
  const history = useHistory();
  const notifyDelete = () => toast.error("Propaganda apagada!");

  useEffect(() => {
    getData();
    // return () => {
    //   setAdverts([]);
    //  };
  }, []);

  const [adverts, setAdverts] = useState([]);
  const [procurar, setProcurar] = useState("");

  async function getData() {
    try {
      console.log("teste");
      const response = await api.get("/advertising");
      console.log("response", response.data);
      setAdverts(response.data);
    } catch (error) {
      throw error;
    }
  }

  async function deleteAdvert(ad_id) {
    try {
      await api.delete(`/advertising/${ad_id}`);
      setAdverts(adverts.filter((advert) => advert.id !== ad_id));
      notifyDelete();
    } catch (error) {
      throw error;
    }
  }

  return (
    <NewPage>
      <NewSideBar tipo={"Empresa de Publicidade"} rota={"publicidade"} />
      <Container>
        <HeaderDiv>
          <NewHeader name={"Propagandas"}></NewHeader>
        </HeaderDiv>
        <RowDiv>
          <input
            id="searchInput"
            placeholder="Pesquisar pelo título"
            onChange={(event) => {
              setProcurar(event.target.value);
            }}
          />
          <Link
            to={{
              pathname: "/publicidade/propaganda/create",
              state: { data: "test" },
            }}
            params={{ test: "hello" }}
            style={{ textDecoration: "none", color: "black" }}
          >
            <div id="WarnAddButton">
              <p>Criar propaganda</p>
            </div>
          </Link>
        </RowDiv>
        <CarContainer>
          {adverts
            .filter((advert) => {
              if (procurar == "") {
                return advert;
              } else if (
                advert?.name.toLowerCase().includes(procurar.toLowerCase())
              ) {
                return advert;
              }
            })
            .map((advert, index) => (
              <CardDiv key={index}>
                <DivText>
                  <h1>{advert?.name}</h1>
                  <a
                    style={{ color: "#EE6F57" }}
                    href={`${advert?.link}`}
                    target="_blank"
                  >
                    Ver conteúdo da propaganda
                  </a>
                  <a
                    style={{ color: "#EE6F57" }}
                    href={`${advert?.media_url}`}
                    target="_blank"
                  >
                    Ver imagem
                  </a>
                </DivText>
                <DivButtonsCard>
                  <ButtonDelete
                    onClick={() => {
                      deleteAdvert(advert?.id);
                    }}
                  >
                    <CloseIcon></CloseIcon>
                  </ButtonDelete>

                  <Link to={`/publicidade/propaganda/edit/${advert.id}`}>
                    <button id="EditButton">
                      <MdOutlineModeEditOutline />
                    </button>
                  </Link>
                </DivButtonsCard>
              </CardDiv>
            ))}
        </CarContainer>
      </Container>
    </NewPage>
  );
}

/*<div id="homeContent" style={{ marginLeft: "10vw" }}>
<Sidebar />
<div id="safeArea">
  <div className="TitleContainer" id="x">
    <div className="Title">
      <ArrowBack />
      <p id="pageTitle">Propagandas</p>
    </div>
  </div>

  <div id="listArea">
    <div id="TopContainer">
      <div id="searchContainer">
        <SearchIcon />
        <input
          id="searchInput"
          placeholder="Pesquisar pelo título"
          onChange={(event) => {
            setProcurar(event.target.value);
          }}
        />
      </div>
      <Link
        to={{
          pathname: "/admin/propaganda/create",
          state: { data: "test" },
        }}
        params={{ test: "hello" }}
        style={{ textDecoration: "none", color: "black" }}
      >
        <div id="WarnAddButton">
          <p>Criar propaganda</p>
        </div>
      </Link>
    </div>

    {adverts
      .filter((advert) => {
        if (procurar == "") {
          return advert;
        } else if (
          advert?.name.toLowerCase().includes(procurar.toLowerCase())
        ) {
          return advert;
        }
      })
      .map((advert) => (
        <div className="data-container" key={advert?.id}>
          <div id="data-safe-area">
            <div className="WarnTitle">{advert?.name}</div>
            <div className="manager-name-title"/>
            <div className="manager-name-title">
              <a style={{color: "#EE6F57"}} href={`${advert?.media_url}`} target = "_blank">Ver imagem</a>
            </div>
            <div className="manager-name-title">
              <a style={{color: "#EE6F57"}} href={`${advert?.link}`} target = "_blank">Ver conteúdo da propaganda</a>
            </div>
            <button
              id="deleteButton"
              onClick={() => {
                deleteAdvert(advert?.id);
              }}
            >
              <span id="cross1"></span>
              <span id="cross2"></span>
            </button>
            <button id="EditButton" >
              <MdOutlineModeEditOutline />
            </button>
          </div>
        </div>
      ))}
  </div>
</div>
<ToastContainer />
</div>*/
