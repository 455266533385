import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Formik } from "formik";
//Service
import api from "../../../../Services/api";
import axios from "axios";

import { Grid } from "@material-ui/core";

//Toast
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Components
import Loading from "../../../../Components/Loading/index";
import SidebarDoorman from "../../../../Components/SideBarDoorman";
//import SidebarOptions from "../../../../Components/SidebarOptions";
import ArrowBack from "../../../../Components/ResponsiveArrowBack";
//Icons
import SearchIcon from "@material-ui/icons/Search";

import "./index.css";
import { Photo, Search } from "@material-ui/icons";
import { Container } from "./styles";

const initialForm = {
  title: "",
  text: "",
};

export default function CreateNoteDoorman() {
  const { id } = useParams();
  const history = useHistory();
  const [form, setForm] = useState(initialForm);
  const [loading, setLoading] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [handleFile, setHandleFile] = useState("");

  const notifySucess = () => toast.success("Anotação criada!");
  const notifyError = () => toast.error("Erro ao criar anotação!");
  const notifyFetchError = () =>
    toast.error(
      "Houve uma falha obtendo os dados desta anotação. Tente novamente mais tarde"
    );

  useEffect(async () => {
    const retrieveData = async () => {
      if (id) {
        try {
          const response = await api.get(`/note/${id}`);
          const warningData = response.data;
          console.log(warningData, "warning data");
          let setTitle = warningData.title;
          console.log(setTitle);
          let setText = warningData.text;
          console.log(setText);
          setForm({
            title: `${setTitle}`,
            text: `${setText}`,
          });
        } catch (ex) {
          notifyFetchError();
          history.goBack();
          throw ex;
        }
      }
    };
    await retrieveData();
    console.log(form);
  }, [id]);

  async function sendWarning(values) {
    setLoading(true);
    //const upload = await sendToStorage();
    //console.log(upload, "Upload completo!");
    const data = { ...values };
    console.log(data);
    let completeObj = {
      ...data,
      //date: getDate(),
      //photo_url: upload,
    };
    console.log(completeObj);
    try {
      if (!id) await api.post("/note", completeObj);
      else await api.patch(`/note/${id}`, completeObj);
      notifySucess();
      // history.goBack()

      // console.log(response);
    } catch (e) {
      console.log("erro");
      console.log(e);
      notifyError();
    }
    setLoading(false);
  }

  return (
    <Grid container>
      <Grid
        container
        item
        xs={8}
        style={{ marginBottom: "40px", marginLeft: "15vw" }}
      >
        <SidebarDoorman />
        <Container>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <ArrowBack />

            <p id="pageTitle">{`${id ? "Editar" : "Criar nova"} anotação`}</p>
          </div>

          <div>
            <Formik
              initialValues={{
                title: form.title,
                text: form.text,
              }}
              enableReinitialize
              onSubmit={(values, { resetForm }) => {
                sendWarning(values);
                setTimeout(function () {
                  resetForm({ values: "" });
                }, 1000);
              }}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                isValid,
                touched,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div id="warnContainer">
                    <p className="registerInputTitle">Título</p>
                    {touched.title && errors.title && (
                      <div id="errorMessage2">{errors.title}</div>
                    )}
                    <input
                      className="inputText"
                      id="InputWarnTitle"
                      value={values.title}
                      onChange={handleChange("title")}
                      onBlur={handleBlur("title")}
                    />

                    <p className="registerInputTitle">Descrição</p>
                    {touched.text && errors.text && (
                      <div id="errorMessage2">{errors.text}</div>
                    )}

                    <textarea
                      className="inputText"
                      id="InputWarnBody"
                      value={values.text}
                      onChange={handleChange("text")}
                      onBlur={handleBlur("text")}
                    />
                    {/* <div id="InputFile">
                    <input
                      id="FileSelect"
                      type="file"
                      onChange={photoHandler}
                    />
                  </div> */}
                    {loading ? (
                      <Loading />
                    ) : (
                      <button
                        type="submit"
                        className="handleButton"
                        
                      >
                        <p>Enviar</p>
                      </button>
                    )}
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Container>
        <ToastContainer />
      </Grid>
    </Grid>
  );
}
