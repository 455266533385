import { useState, useEffect } from "react";

//Assets
import PlusIcon from "../../../../../../Assets/plus-icon.png";

//Components
import ManutencaoCard from "../ManutencaoCard";

import api from "../../../../../../Services/api";
import AddManutencao from "../AddManutencao";
import SearchIcon from "@material-ui/icons/Search";
import NewSideBar from "../../../../../../Components/NewComponents/NewSideBar";
import ArrowBack from "../../../../../../Components/ResponsiveArrowBack";

function ManutencaoModalTech() {
  const [equipamentos, setEquipamentos] = useState([]);
  const [procurar, setProcurar] = useState("");

  function getEquipamentos() {
    const res = api.get("/maintenance");
    return res;
  }

  const fetch = async () => {
    const res = await getEquipamentos();
    setEquipamentos(res.data);
  };

  useEffect(() => {
    fetch();
  }, []);

  const list = [{}];

  const [isVisible, setIsVisible] = useState(false);

  function handleAddMntc(lei) {
    console.log(list);
  }

  const displayList = equipamentos
    .filter((equipamento) => {
      if (procurar == "") {
        return equipamento;
      } else if (
        equipamento?.name.toLowerCase().includes(procurar.toLowerCase())
      ) {
        return equipamento;
      }
    })
    .map((equipamento) => (
      <ManutencaoCard fetch={fetch} data={equipamento}>
        {" "}
      </ManutencaoCard>
    ));

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
      <NewSideBar tipo={"Técnico de manutenção"} rota={"maintenance"} />

      <div className="managementModalContainer" style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
            padding: "40px 0 0 40px",
          }}
        >
          <ArrowBack></ArrowBack>
          <h1>Manutenção</h1>
        </div>

        <div className="mngm-content">
          <div
            className="mngm-add-manutencao"
            onClick={() => setIsVisible(true)}
          >
            <img src={PlusIcon}></img>
            <span>Novo equipamento</span>
          </div>
          <h2 className="mngm-mntc-eqp-title"> Equipamentos</h2>
          <div id="searchContainer">
            <SearchIcon />
            <input
              id="searchInput"
              placeholder="Pesquisar"
              onChange={(event) => {
                setProcurar(event.target.value);
              }}
            />
          </div>
          <div className="mngm-mntc-eqp-list">{displayList}</div>
        </div>
        <AddManutencao
          handleAddMntc={fetch}
          isVisible={isVisible}
          changeVisibility={setIsVisible}
        ></AddManutencao>
      </div>
    </div>
  );
}

export default ManutencaoModalTech;
