import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../../Context/auth";
import DatePicker from "react-date-picker";

//Service
import api from "../../../../../Services/api";

//Icons
import SearchIcon from "@material-ui/icons/Search";

//Toast
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Components
import Sidebar from "../../../../../Components/SidebarManager";
import { InputWrap } from "../../Management/Legislation/AddLei/style";

//import SidebarOptions from "../../../../Components/SidebarOptions";
import ArrowBack from "../../../../../Components/ResponsiveArrowBack";

//Styles
import "./index.css";
import NewSideBar from "../../../../../Components/NewComponents/NewSideBar";

export default function CreateDelivery() {
  const [procurar, setProcurar] = useState("");
  const [data, setData] = useState();
  const [filter, setFilter] = useState("company_name");
  const [deliveryList, setDeliveryList] = useState([]);
  const { user } = useAuth();

  const notifyFetchSucess = () =>
    toast.success("Delivery carregado com Sucesso!");
  const notifyFetchError = () =>
    toast.error("Houve uma falha ao obter os deliverys.");

  async function getList() {
    api
      .get("/delivery")
      .then((response) => {
        setDeliveryList(response.data);
        console.log(response.data);
      })
      .catch(() => {
        notifyFetchError();
      });
  }

  useEffect(() => {
    getList();
  }, []);

  function onChangeData(data) {
    setData(data);
    setProcurar("");
  }

  return (
    <div id="homeContent">
      <NewSideBar rota={"manager"} tipo={"Síndico"}></NewSideBar>
      <div
        id="safeArea"
        style={{ marginLeft: "56px", width: "100%", overflowY: "auto" }}
      >
        <div
          className="pageTitleContainer"
          id="x"
          style={{ marginLeft: "0px" }}
        >
          <ArrowBack />

          <p id="pageTitle">Delivery</p>
        </div>

        <div id="listArea" style={{ marginLeft: "0px" }}>
          <div style={{ display: "flex" }}>
            <div id="searchContainer">
              {filter === "date" ? (
                <>
                  <SearchIcon style={{ marginRight: "20px" }} />
                  <DatePicker
                    value={data}
                    onChange={(event) => onChangeData(event)}
                    maxDate={new Date()}
                  />
                </>
              ) : (
                <>
                  <SearchIcon />
                  <input
                    id="searchInput"
                    placeholder="Pesquisar"
                    onChange={(event) => {
                      setProcurar(event.target.value);
                    }}
                  />
                </>
              )}
            </div>
            <InputWrap
              style={{
                marginBottom: "32px",
                marginLeft: "10px",
                width: "200px",
              }}
            >
              <select
                style={{
                  border: "none",
                  borderLeft: "4px solid #00334E",
                  borderRadius: "20px",
                  minWidth: "150px",
                  backgroundColor: "#f8f8f8",
                  padding: "4px 12px",
                }}
                placeholder="None"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              >
                <option value="company_name">Serviço</option>
                <option value="name">Nome do entregador</option>
                <option value="date">Data</option>
              </select>
            </InputWrap>
          </div>
          <div>
            {!deliveryList.length && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <p style={{ marginTop: 16, fontSize: 16 }}>
                  Nenhum delivery foi encontrado.
                </p>
              </div>
            )}

            {deliveryList
              .filter((delivery) => {
                if (procurar === "" && data === "") {
                  return delivery;
                } else if (
                  delivery?.company_name
                    .toLowerCase()
                    .includes(procurar.toLowerCase()) &&
                  filter === "company_name"
                ) {
                  return delivery;
                } else if (
                  delivery?.date.includes(
                    data
                      ? data.toLocaleDateString("pt-BR", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        })
                      : ""
                  ) &&
                  filter === "date"
                ) {
                  return delivery;
                } else if (
                  delivery?.name
                    ? delivery?.name
                        .toLowerCase()
                        .includes(procurar.toLowerCase()) && filter === "name"
                    : null
                ) {
                  return delivery;
                }
              })
              .map((delivery) => (
                <div className="data-container">
                  <div id="data-safe-area">
                    <div style={{ marginTop: "2vw" }}>
                      <div className="manager-name-title">
                        Serviço: {delivery?.company_name}
                      </div>
                      <div className="manager-name-title">
                        Nome do entregador: {delivery?.name}
                      </div>
                      <div className="manager-name-title">
                        N° documento: {delivery?.document}{" "}
                      </div>
                      <div className="manager-name-title">
                        Data: {delivery?.date}
                      </div>
                      <div className="manager-name-title">
                        Horas: {delivery?.time}
                      </div>
                    </div>
                  </div>
                  <div className="WarnDate"></div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
