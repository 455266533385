import styled from "styled-components";

export const Wrap = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #00000029;
  display: ${(props) => (props.isVisible ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 400px;
  img {
    height: 20px;
    cursor: pointer;
  }
`;

export const Input = styled.input``;

export const CloseImg = styled.img`
  width: 20px;
  height: 20px;
`;

export const Form = styled.form`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px;
  gap: 12px;

  .borda {
    padding: 4px 8px;
    width: 200px;
    box-sizing: border-box;
  }
`;

export const InputWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  label {
    font-weight: bold;
  }
  input {
    padding: 12px;
  }
  select {
    padding: 12px;
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 40px;
  border-bottom: 1px solid grey;
  box-sizing: border-box;
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  width: 100%;
  border-top: 1px solid grey;
  padding: 40px;
  gap: 8px;
`;

export const AddBtn = styled.button`
  padding: 8px 12px;
  background-color: blue;
  color: #fff;
  border: 1px solid grey;
  border-radius: 4px;
`;

export const CloseBtn = styled.button`
  padding: 8px 12px;
  background-color: white;
  border: 1px solid grey;
  border-radius: 4px;
`;
