import React from 'react'
import { Divrow, H1text } from './styles'
import SetaBack from '../Seta'
import ArrowBack from '../../ResponsiveArrowBack'

function NewHeader({name}) {
  return (
    <Divrow>
      <ArrowBack></ArrowBack>
      <H1text>{name}</H1text>
    </Divrow>
  )
}

export default NewHeader