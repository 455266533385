import React from 'react'
import { H1text } from '../Header/styles'
import { ButtonDiv, ButtonSide, ImgButton, SideButtonText } from './styles'

function SideButtons({name, src, width, onClick=""}) {
  return (
    <ButtonDiv>
      <ImgButton width={width} src={src}></ImgButton>
      <ButtonSide onClick={onClick}>
      <SideButtonText>{name}</SideButtonText>
    </ButtonSide>
    </ButtonDiv>
  )
}

export default SideButtons