import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Formik } from "formik";
import Select from "react-select";

//Service
import api from "../../../../../Services/api";
import axios from "axios";

//Toast
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Components
import Loading from "../../../../../Components/Loading/index";
import Sidebar from "../../../../../Components/SidebarAdmin";
//import SidebarOptions from "../../../../Components/SidebarOptions";
import ArrowBack from "../../../../../Components/ResponsiveArrowBack";
//Icons
import SearchIcon from "@material-ui/icons/Search";

import "./index.css";
import { Photo, Search } from "@material-ui/icons";
import { initial } from "lodash";
import {
  CarContainer,
  Container,
  HeaderDiv,
  NewPage,
} from "../../Empresas/styles";
import NewSideBar from "../../../../../Components/NewComponents/NewSideBar";
import NewHeader from "../../../../../Components/NewComponents/Header";
import { CustomSelect, InputDiv, NewInput } from "./styles";

/* const initialForm = {
  name: "",
  contact: "",
  description: "",
  tag:""
}; */

export default function AdminCreateService() {
  const { id } = useParams();
  const history = useHistory();
  //const [form, setForm] = useState(initialForm);
  const [loading, setLoading] = useState(false);
  //const [photo, setPhoto] = useState(null);
  const [handleFile, setHandleFile] = useState("");
  //states para envio pro back do novo serviço
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [description, setDescription] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [email, setEmail] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [url, setUrl] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [checked, setChecked] = useState([]);
  const [imgErrorText, setImgErrorText] = useState("");
  const [allStates, setAllStates] = useState(false);

  const notifySucess = () =>
    toast.success(id ? "Serviço editado!" : "Serviço criado!");
  const notifyError = () =>
    toast.error(id ? "Erro ao editar serviço!" : "Erro ao criar serviço!");
  const notifyFetchError = () =>
    toast.error(
      "Houve uma falha obtendo os dados desse serviço. Tente novamente mais tarde"
    );

  const fileSelectedHandler = (e) => {
    if (e.target.files && e.target.files[0]) {
      var img = document.createElement("img");

      img.onload = function () {
        console.log(this.width + " " + this.height);
        setImgErrorText("");
        setSelectedFile(e.target.files[0]);
      };

      var reader = new FileReader();
      reader.onloadend = function (ended) {
        img.src = ended.target.result;
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  useEffect(() => {
    const retrieveData = async () => {
      if (id) {
        try {
          const response = await api.get(`/service/${id}`);
          const warningData = response.data;
          console.log(warningData, "warning data");
          setName(warningData.name);
          console.log(name);
          setContact(warningData.contact);
          console.log(contact);
          setDescription(warningData.description);
          console.log(description);
          console.log(response, "dados do useEffect");
          console.log(warningData, "warning data");
          setHandleFile(warningData.photo_url);
          console.log(handleFile, "handleFile do useEffect");
          setSelectedOption(warningData.tag);
        } catch (ex) {
          notifyFetchError();
          history.goBack();
          throw ex;
        }
      }
    };
    retrieveData();
  }, [id]);

  //Pegar Tags do Back
  const getOptions = async () => {
    const response = await api.get("/service/tags");
    const options = response.data.tags.map((option) => ({
      value: option,
      label: option,
    }));
    setOptions(options);
  };

  useEffect(() => getOptions(), []); //Setar lista de opcoes.

  //Enviar arquivo para o back
  async function handleSubmitNew() {
    try {
      const data = {
        name: name,
        contact: contact,
        description: description,
        tag: selectedOption.value,
        email: email,
        cnpj: cnpj,
        link: url,
        header: `content-type:${selectedFile.type}`,
      };
      const formData = new FormData();
      formData.append("data", JSON.stringify(data));
      formData.append("photo", selectedFile);
      console.log(selectedOption.value);
      if (!id) await api.post("/service/admin", formData);
      else await api.patch(`/service/admin/${id}`, formData);
      console.log(data);
      notifySucess();
      history.goBack();
    } catch (error) {
      console.log(error);
      notifyError();
    }
  }

  const customStyles = {
    control: (base) => ({
      ...base,
      width: "500px",
    }),
  };

  /* function getDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = dd + "/" + mm + "/" + yyyy;

    return today;
  }

  const photoHandler = (event) => {
    setPhoto(event.target.files[0]);
  };

   async function sendToStorage() {
    try {
      if (photo) {
        const name = photo.name;
        const uriArray = name.split(".");
        console.log(uriArray);
        const tipoImagem = uriArray[uriArray.length - 1];
        console.log(tipoImagem);
        const firstResponse = await api.get(`/file/put_url/${tipoImagem}`);
        console.log(firstResponse);

        const fileName = firstResponse.data.name;
        const mediaUrl = firstResponse.data.url;

        await setHandleFile(fileName);

        console.log(mediaUrl, "primeiro");
        console.log(fileName);
        console.log(handleFile, "useState determinado");

        let blob = photo.slice(0, photo.size, tipoImagem);
        let binaryFile = new File([blob], fileName, {
          type: tipoImagem,
        });

        console.log(photo);
        console.log(binaryFile, "binario");
        console.log(mediaUrl, "segundo");

        await axios.put(mediaUrl, binaryFile, {
          headers: { "x-amz-acl": "public-read", "Content-Type": tipoImagem },
        });
        return fileName;
      } else {
        console.log("está retornando handleFile");
        return handleFile;
      }
    } catch (error) {
      console.log(error);
    }
  }

   async function sendWarning(values) {
    setLoading(true);
    const upload = await sendToStorage();
    console.log(upload, "Upload completo!");
    const data = { ...values };
    console.log(data);
    let completeObj = {
      ...data,
      date: getDate(),
      photo_url: upload,
    };
    console.log(completeObj);
    try {
      if (!id) await api.post("/service", completeObj);
      else await api.patch(`/service/${id}`, completeObj);
      notifySucess();
      // history.goBack()

      // console.log(response);
    } catch (e) {
      console.log("erro");
      console.log(e);
      notifyError();
    }
    setLoading(false);
  } */
  //Funcoes para atualizar o Select com o Formik
  /* const defaultValue = (options, value) => {
    return options.find((options) => options.value === value);
  }; */

  return (
    <NewPage>
      <NewSideBar tipo={"Administrador"} rota={"admin"}></NewSideBar>
      <Container>
        <HeaderDiv>
          <NewHeader name={"Criar Serviço"}></NewHeader>
        </HeaderDiv>
        <CarContainer style={{ marginBottom: "5vh" }}>
          <InputDiv>
            <h2>Nome da Empresa</h2>
            <NewInput
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></NewInput>
            <h2>Telefone de Contato</h2>
            <NewInput
              value={contact}
              onChange={(e) => setContact(e.target.value)}
            ></NewInput>
            <h2>Email</h2>
            <NewInput
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></NewInput>
            <h2>CNPJ</h2>
            <NewInput
              value={cnpj}
              onChange={(e) => setCnpj(e.target.value)}
            ></NewInput>
            <h2>Página do Anunciante</h2>
            <NewInput
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            ></NewInput>
            <h2>Especialidade</h2>
            <CustomSelect
              placeholder={"Selecione o serviço"}
              options={options}
              value={selectedOption}
              onChange={(e) => setSelectedOption(e)}
              styles={customStyles}
            />
            <h2>Descrição</h2>
            <NewInput
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></NewInput>
            <div
              id="filePicker"
              style={{
                justifyItems: "center",
                width: "50%",
                alignItems: "center",
              }}
            >
              <p>
                Arquivo de imagem <strong>(resolução 16:9)</strong>
              </p>
              <input type="file" onChange={fileSelectedHandler} />
            </div>
            <button
              onClick={handleSubmitNew}
              className="handleButton"
              style={{ marginBotton: "8vh", width: "25vw" }}
            >
              <p>Enviar</p>
            </button>
          </InputDiv>
        </CarContainer>
      </Container>
    </NewPage>
  );
}

{
  /*
 <div>
        <div id="listArea" >
          

            <p className="registerInputTitle">Nome da Empresa</p>
        
            <input
              className="inputText"
              id="InputWarnTitle"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <p className="registerInputTitle">Telefone de contato</p>
           

            <input
              className="inputText"
              id="InputWarnTitle"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
            />

            <p className="registerInputTitle">Especialidade</p>
            <Select
              id="tag"
              className="tag"
              placeholder={id ? selectedOption : "Selecione o serviço"}
              options={options}
              value={selectedOption}
              onChange={(e) => setSelectedOption(e)}
            />

            <p className="registerInputTitle">Descrição</p>
           

            <textarea
              className="inputText"
              id="InputWarnBody"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
           

            <button onClick={handleSubmitNew} className="handleButton" >
              <p>Enviar</p>
            </button>
          </div>
                  
          
        </div>

*/
}
