import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Sidebar from "../../../../Components/SidebarAdmin";
import ArrowBack from "../../../../Components/ResponsiveArrowBack";
import { Formik } from "formik";
import Loading from "../../../../Components/Loading/index";
import Select from "react-select";
import profilePhoto from "../../../../Assets/unnamed.png";
import "./index.css";
import userEvent from "@testing-library/user-event";
import { useAuth } from "../../../../Context/auth";
import api from "../../../../Services/api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NewSideBar from "../../../../Components/NewComponents/NewSideBar";
import { Container, NewPage } from "../../Admin/Empresas/styles";

export default function ProfilePub() {
  const { user } = useAuth();
  const [selectedFile, setSelectedFile] = useState(null);
  const [checked, setChecked] = useState([]);
  const [imgErrorText, setImgErrorText] = useState("");
  const [allStates, setAllStates] = useState(false);
  const [img, setImg] = useState("");
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const notifySucess = () => toast.success("Perfil editado com sucesso!");
  const notifyError = () => toast.error("Erro ao editar perfil!");
  const notifyError2 = () => toast.error("Perfil deletado");
  const infos = localStorage.getItem("@SWCondominio:user");
  const [handleFile, setHandleFile] = useState("");
  const notifyFetchError = () =>
    toast.error(
      "Houve uma falha ao obtender os dados. Tente novamente mais tarde"
    );

  const fileSelectedHandler = (e) => {
    if (e.target.files && e.target.files[0]) {
      var img = document.createElement("img");

      img.onload = function () {
        console.log(this.width + " " + this.height);
        setImgErrorText("");
        setSelectedFile(e.target.files[0]);
      };

      var reader = new FileReader();
      reader.onloadend = function (ended) {
        img.src = ended.target.result;
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  async function handleSubmit(values) {
    setLoading(true);

    const formData = new FormData();

    if (selectedFile) {
      console.log("hoy");
      const data = {
        name: values.name,
        email: values.email,
        city: values.city,
        corporate_name: values.nomeFantasia,
        cnpj: values.cnpj,
        phone_number: values.telefone,
        state: values.state,
        district: values.neighborhood,
        cep: values.cep,
        header: `content-type:${selectedFile.type}`,
      };
      formData.append("data", JSON.stringify(data));
      formData.append("media", selectedFile);
    } else {
      const data = {
        name: values.name,
        email: values.email,
        city: values.city,
        corporate_name: values.nomeFantasia,
        cnpj: values.cnpj,
        phone_number: values.telefone,
        state: values.state,
        district: values.neighborhood,
        cep: values.cep,
      };
      formData.append("data", JSON.stringify(data));
      console.log(formData, "algo");
    }

    /* const formData = new FormData();
    formData.append("data", JSON.stringify(completeObj));
    console.log("AAAAAAAAAAAAAAAAAAA", formData.get("data"));
    const infos = localStorage.getItem("@SWCondominio:user"); */

    await api
      .patch(`/company/${JSON.parse(infos).id}`, formData)
      .then(() => {
        notifySucess();
      })
      .catch(() => {
        notifyError();
      });
    setLoading(false);
  }

  const [initialValues, setInitialValues] = useState({});

  function getProfileData() {
    console.log(user);
    const infos = localStorage.getItem("@SWCondominio:user");
    console.log(JSON.parse(infos), " infos");
    api.get(`/company/${JSON.parse(infos).id}`).then((response) => {
      setInitialValues({
        nomeFantasia: response.data.corporate_name,
        name: response.data.name,
        cnpj: response.data.cnpj,
        telefone: response.data.phone_number,
        cep: response.data.cep,
        city: response.data.city,
        state: response.data.state,
        neighborhood: response.data.district,
        email: response.data.email,
      });
      console.log(user, "user");
    });
  }

  useEffect(() => {
    getProfileData();
  }, []);

  useEffect(() => {
    async function getProfileData() {
      console.log("Oi");
      try {
        const response = await api.get(`/company/${JSON.parse(infos).id}`);
        console.log(response.data, "teste1");
        setHandleFile(response.data.photo_url);
      } catch (error) {
        console.log(error, "teste");
      }
    }
    getProfileData();
    console.log(handleFile, "asdsad");
  }, []);

  const campos = [
    {
      label: "nomeFantasia",
      Nome: "Nome fantasia",
      type: "text",
    },
    {
      label: "name",
      Nome: "Razão social",
      type: "text",
    },
    {
      label: "cnpj",
      Nome: "CNPJ",
      type: "text",
    },
    {
      label: "telefone",
      Nome: "Telefone",
      type: "tel",
    },
    {
      label: "cep",
      Nome: "CEP",
      type: "text",
    },
    {
      label: "city",
      Nome: "Cidade",
      type: "text",
    },
    {
      label: "state",
      Nome: "Estado",
      type: "text",
    },
    {
      label: "neighborhood",
      Nome: "Bairro",
      type: "text",
    },
    {
      label: "email",
      Nome: "Email",
      type: "email",
    },
  ];

  const handleCepBlur = async (event, setFieldValue) => {
    const cep = event.target.value.replace(/\D/g, "");

    if (cep.length === 8) {
      try {
        const response = await api.get(`https://viacep.com.br/ws/${cep}/json/`);
        const { data } = response;
        if (!data.erro) {
          setFieldValue("cep", cep);
          setFieldValue("city", data.localidade);
          setFieldValue("state", data.uf);
          setFieldValue("neighborhood", data.bairro);
        } else {
          // Limpar os outros campos se o CEP for inválido ou não for encontrado
          setFieldValue("cep", "");
          setFieldValue("city", "");
          setFieldValue("state", "");
          setFieldValue("neighborhood", "");

          console.log("CEP inválido ou não encontrado");
        }
      } catch (error) {
        // Tratar erros de requisição
        console.log("Erro ao buscar CEP", error);
      }
    } else {
      // Limpar os outros campos caso o CEP não tenha 8 dígitos
      setFieldValue("city", "");
      setFieldValue("state", "");
      setFieldValue("neighborhood", "");
    }
  };

  return (
    <div id="homeContent">
      <NewSideBar tipo={"Empresa de Publicidade"} rota={"publicidade"} />
      <Container>
        <div
          id="WarnSafeArea"
          style={{
            marginLeft: "5vw",
            height: "100vh  ",
            width: "100vw",
            maxHeight: "100vh",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            className="pageTitleContainer"
            id="x2"
            style={{ marginLeft: "0" }}
          >
            <ArrowBack />

            <p id="pageTitle">Meu Perfil</p>
          </div>
          <div
            id="listArea2"
            style={{
              marginLeft: "0",
              height: "100vh  ",
              maxHeight: "100vh",
            }}
          >
            <div
              id="sidebarTopInfo"
              style={{ marginLeft: "2vw", display: "flex" }}
            >
              <div id="picPerfil">
                <img
                  src={handleFile ? handleFile : profilePhoto}
                  id="logo"
                  style={{
                    width: "240px",
                    height: "240px",
                    borderRadius: "50%",
                  }}
                />
              </div>
            </div>

            <Formik
              initialValues={initialValues}
              onSubmit={(values) => {
                /* console.log("enviado", values); */
                handleSubmit(values);
              }}
              enableReinitialize
              /* validationSchema={cadastroValidationSchema} */
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                isValid,
                touched,
                setFieldValue,
              }) => (
                <form
                  id="cadastro"
                  onSubmit={handleSubmit}
                  style={{
                    width: "100%",
                    height: "80%",
                    display: "flex",
                  }}
                >
                  <div
                    id="inputs"
                    style={{
                      height: "100vh",
                      width: "60%",
                      display: "flex",
                      overflow: "hidden",
                    }}
                  >
                    {campos.map((campo) => {
                      if (campo.label === "cep") {
                        return (
                          <div className="inputBox">
                            <div
                              className={
                                touched[campo.label] && errors[campo.label]
                                  ? "erroActive"
                                  : "errorMessage"
                              }
                              id="errorMessage"
                            >
                              {errors[campo.label]}
                            </div>

                            <input
                              type={campo.type}
                              name={campo.label}
                              placeholder={campo.Nome}
                              className="inputText"
                              onChange={handleChange(campo.label)}
                              value={values[campo.label]}
                              onBlur={(e) => handleCepBlur(e, setFieldValue)}
                            />
                          </div>
                        );
                      } else {
                        return (
                          <div className="inputBox">
                            <div
                              className={
                                touched[campo.label] && errors[campo.label]
                                  ? "erroActive"
                                  : "errorMessage"
                              }
                              id="errorMessage"
                            >
                              {errors[campo.label]}
                            </div>

                            <input
                              type={campo.type}
                              name={campo.label}
                              placeholder={campo.Nome}
                              className="inputText"
                              onChange={handleChange(campo.label)}
                              value={values[campo.label]}
                              onBlur={handleBlur(campo.label)}
                            />
                          </div>
                        );
                      }
                    })}
                  </div>
                </form>
              )}
            </Formik>
            <div
              id="filePicker"
              style={{
                justifyItems: "center",
                width: "50%",
                alignItems: "center",
              }}
            >
              <p>
                Arquivo de imagem <strong>(resolução 16:9)</strong>
              </p>
              <input type="file" onChange={fileSelectedHandler} />
            </div>
            <div id="warnContainer" style={{ display: "flex", width: "55%" }}>
              {loading ? (
                <Loading />
              ) : (
                <button type="submit" className="handleButton" form="cadastro">
                  <p>Atualizar</p>
                </button>
              )}
            </div>
            <div style={{ height: "8vh" }}></div>
          </div>
        </div>
      </Container>
      <ToastContainer />
    </div>
  );
}
