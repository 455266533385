import {
  Wrap,
  Container,
  Input,
  CloseImg,
  Form,
  InputWrap,
  Header,
  Footer,
  AddBtn,
  CloseBtn,
} from "./style";
import { useState } from "react";
import api from "../../../../../../Services/api";
import axios from "axios";

//Toast
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AddContrato({ isVisible, changeVisibility, handleAddMntc }) {
  const [name, setName] = useState("");
  //const [link, setLink] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const notifySucess = () => toast.success("Contrato enviado com sucesso!");
  const notifyError = () => toast.error("Erro ao enviar contrato, nenhum arquivo foi selecionado!");

  const fileSelectedHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    console.log(selectedFile);
  };

  function postArchive(req) {
    const res = api.post(`/archive`, req);
    return res;
  }

  async function hadleSubmit(event) {
    if (selectedFile === null) {
      notifyError()
      return;
    }
    else {
    const data = {
      name: name,
      "header": `content-type:${selectedFile.type}`
    };
    const formData = new FormData();
    formData.append("media", selectedFile);
    formData.append("data", JSON.stringify(data));
    if (selectedFile === null) {
      notifyError()
      return;
    }
    await postArchive(formData);
    console.log("ok");
    handleAddMntc();
    notifySucess();
    close();
  }
  }

  function close() {
    changeVisibility(false);
    setName("");
  }

  return (
    <Wrap isVisible={isVisible}>
      <Container>
        <Header>
          <CloseImg
            onClick={close}
            src="https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-close-512.png"
          />
          <h2>Adicionar Contrato</h2>
        </Header>
        <Form onSubmit={hadleSubmit}>
          <InputWrap>
            <label>Nome</label>
            <input
              className="borda"
              type="text-area"
              name="name"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </InputWrap>
          <InputWrap>
            <label>Arquivo</label>
            <input
              type="file"
              onChange={fileSelectedHandler}
              //value={link}
              className="borda"
            />
          </InputWrap>
        </Form>
        <Footer>
          <AddBtn onClick={hadleSubmit}>Adicionar</AddBtn>
          <CloseBtn onClick={close} type="submit">
            Cancelar
          </CloseBtn>
        </Footer>
      </Container>
    </Wrap>
  );
}

export default AddContrato;
