import { 
    Container,
    Img,
    H1,
    P   
} from "./style";

import { useAuth } from "../../../Context/auth";
import { useEffect } from "react";

function AccessDenied() {
    const {user} = useAuth();

    useEffect(() => {
        console.table(Object.keys(user))
    }, [])

    return(
        <>
            <Container>
                <Img src="https://cdn-icons-png.flaticon.com/512/1000/1000954.png"/>
                <H1>Acesso negado</H1>
                <P>Você não tem permissão para acessar essas página</P>
                <P>Entre em contato com o administrador para conseguir permissão ou volte a página de Home.</P>
            </Container>
        </>
    );
}

export default AccessDenied;