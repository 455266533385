import React, { useEffect, useState } from "react";
import DatePicker from "react-date-picker";

//Service
import api from "../../../../../Services/api";
import { useAuth } from "../../../../../Context/auth";

//Toast
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Components
import { InputWrap } from "../../Management/Legislation/AddLei/style";
import MailConfirmModal from "./confirmModal";
import MailLocalModal from "./localModal";

//import SidebarOptions from "../../../../Components/SidebarOptions";
import ArrowBack from "../../../../../Components/ResponsiveArrowBack";

//Icons
import SearchIcon from "@material-ui/icons/Search";

//Styles
import "./index.css";
import NewSideBar from "../../../../../Components/NewComponents/NewSideBar";

const initialForm = {
  title: "",
  p: "",
};

export default function MailHistory() {
  const [isLoading, setIsLoading] = useState(true);
  const [procurar, setProcurar] = useState("");
  const [data, setData] = useState();
  const [filter, setFilter] = useState("complement");
  const [mailList, setMailList] = useState([]);
  const [open, setOpen] = useState(false);
  const [openLocal, setOpenLocal] = useState(false);
  const [openMailId, setOpenMailId] = useState(null);

  const { user } = useAuth();

  let userJson = user;

  if (typeof user === "string") {
    userJson = JSON.parse(user);
  }

  const userModality = userJson.modality;

  const modalities = {
    admin: { rota: "admin", tipo: "Admin" },
    manager: { rota: "manager", tipo: "Síndico" },
    doorman: { rota: "doorman", tipo: "Porteiro" },
    maintenance: { rota: "maintenance", tipo: "Manutenção" },
    administration: { rota: "employee", tipo: "Administrativo" },
  };

  const { rota, tipo } = modalities[userModality] || { rota: "", tipo: "" };

  const handleClickOpen = (mail_id) => {
    setOpenMailId(mail_id);
    setOpen(true);
  };

  const handleClickOpenLocal = (mail_id) => {
    setOpenMailId(mail_id);
    setOpenLocal(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenLocal(false);
    getList();
  };

  const notifyFetchSucess = () =>
    toast.success("Correios carregados com sucesso!");
  const notifyFetchError = () =>
    toast.error(
      "Houve uma falha ao obter os correios deste usuario. Tente novamente mais tarde"
    );

  // control: () => ({
  //   // none of react-select's styles are passed to <Control />
  //   width: 200,
  // }),
  // singleValue: (provided, state) => {
  //   const opacity = state.isDisabled ? 0.5 : 1;
  //   const transition = "opacity 300ms";

  //   return { ...provided, opacity, transition };
  // },

  async function confirmMailReceipt(id, data) {
    api
      .patch(`/mail/confirm/${id}`, data)
      .then((response) => {
        toast.success("Encomenda retirada com sucesso!");
        handleClose();
      })
      .catch((error) => {
        toast.error(error.response.data.Error);
      });
  }

  async function confirmLocalChange(id, data) {
    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    api
      .patch(`/mail/${id}`, formData)
      .then((response) => {
        toast.success("Localização alterada com sucesso!");
        handleClose();
      })
      .catch((error) => {
        toast.error(error.response.data.Error);
      });
  }

  async function getList() {
    api
      .get("/mail")
      .then((response) => {
        setMailList(response.data);
      })
      .catch(() => {
        notifyFetchError();
      });
  }

  useEffect(() => {
    getList();
  }, []);

  function onChangeData(data) {
    setData(data);
    setProcurar("");
  }

  return (
    <div id="homeContent">
      <NewSideBar rota={rota} tipo={tipo}></NewSideBar>
      <div
        id="safeArea"
        style={{ marginLeft: "56px", width: "100%", overflowY: "auto" }}
      >
        <div
          className="pageTitleContainer"
          id="x"
          style={{ marginLeft: "0px" }}
        >
          <ArrowBack />

          <p id="pageTitle">Correios</p>
        </div>

        <div id="listArea" style={{ marginLeft: "0px" }}>
          <div style={{ display: "flex" }}>
            <div id="searchContainer">
              {filter === "date" ? (
                <>
                  <SearchIcon style={{ marginRight: "20px" }} />
                  <DatePicker
                    value={data}
                    onChange={(event) => onChangeData(event)}
                    maxDate={new Date()}
                  />
                </>
              ) : (
                <>
                  <SearchIcon />
                  <input
                    id="searchInput"
                    placeholder="Pesquisar"
                    onChange={(event) => {
                      setProcurar(event.target.value);
                    }}
                  />
                </>
              )}
            </div>
            <InputWrap
              style={{
                marginBottom: "32px",
                marginLeft: "10px",
                width: "200px",
              }}
            >
              <select
                style={{
                  border: "none",
                  borderLeft: "4px solid #00334E",
                  borderRadius: "20px",
                  minWidth: "150px",
                  backgroundColor: "#f8f8f8",
                  padding: "4px 12px",
                }}
                placeholder="None"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              >
                <option value="status">Status</option>
                <option value="complement">Complemento</option>
                <option value="resident">Destinatário</option>
                <option value="receiver">Recebedor</option>
                <option value="info">Descrição</option>
                <option value="type">Tipo</option>
                <option value="date">Data</option>
              </select>
            </InputWrap>
          </div>
          <div>
            {!mailList.length && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <p style={{ marginTop: 16, fontSize: 16 }}>
                  Nenhuma correspondência encontrada.
                </p>
              </div>
            )}
            {mailList
              .filter((mail) => {
                if (procurar === "" && data === "") {
                  return mail;
                } else if (
                  mail?.info
                    ? mail?.info
                        .toLowerCase()
                        .includes(procurar.toLowerCase()) && filter === "info"
                    : null
                ) {
                  return mail;
                } else if (
                  mail?.type
                    ? mail?.type
                        .toLowerCase()
                        .includes(procurar.toLowerCase()) && filter === "type"
                    : null
                ) {
                  return mail;
                } else if (
                  mail?.status
                    ? mail?.status
                        .toLowerCase()
                        .includes(procurar.toLowerCase()) && filter === "status"
                    : null
                ) {
                  return mail;
                } else if (
                  mail?.resident.name
                    ? mail?.resident.name
                        .toLowerCase()
                        .includes(procurar.toLowerCase()) &&
                      filter === "resident"
                    : null
                ) {
                  return mail;
                } else if (
                  mail?.date.includes(
                    data
                      ? data.toLocaleDateString("pt-BR", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        })
                      : ""
                  ) &&
                  filter === "date"
                ) {
                  return mail;
                } else if (
                  mail?.receiver.name
                    ? mail?.receiver.name
                        .toLowerCase()
                        .includes(procurar.toLowerCase()) &&
                      filter === "receiver"
                    : null
                ) {
                  return mail;
                } else if (
                  mail?.resident.complement
                    ? mail?.resident.complement
                        .replace(/:/g, " ")
                        .replace(/  +/g, " ")
                        .toLowerCase()
                        .includes(procurar.toLowerCase()) &&
                      filter === "complement"
                    : null
                ) {
                  return mail;
                }
              })
              .map((mail) => (
                <div className="data-container">
                  <div id="data-safe-area">
                    <div>
                      <div className="manager-name-title">
                        <span className="boldFields">Destinatário:</span>{" "}
                        {mail.resident.name}
                      </div>
                      <div className="manager-name-title">
                        <span className="boldFields">Complemento:</span>{" "}
                        {mail.resident.complement
                          .replace(/:/g, " ")
                          .replace(/  +/g, " ")}
                      </div>
                      <div className="manager-name-title">
                        <span className="boldFields">Recebido por:</span>{" "}
                        {mail.receiver.name}
                      </div>
                      <div className="manager-name-title">
                        <span className="boldFields">Data:</span> {mail?.date} -{" "}
                        {mail?.time}
                      </div>
                      <div className="manager-name-title">
                        <span className="boldFields">Local:</span>{" "}
                        {mail?.location}
                      </div>
                      {/* {mail?.type && (
                        <div className="manager-name-title">
                          <span className="boldFields">Tipo:</span>{" "}
                          {mail.type.charAt(0).toUpperCase() +
                            mail.type.slice(1)}
                        </div>
                      )} */}
                      <div className="manager-name-title">
                        <span className="boldFields">Status:</span>{" "}
                        {mail?.status}
                      </div>
                      {mail?.info && (
                        <div className="manager-name-title">
                          <span className="boldFields">Descrição:</span>{" "}
                          {mail.info}
                        </div>
                      )}
                      {mail?.photo_url ? (
                        <div className="manager-name-title">
                          <a
                            href={`${mail?.photo_url}`}
                            style={{ color: "#EE6F57" }}
                            target="_blank"
                          >
                            Ver foto
                          </a>
                        </div>
                      ) : null}

                      {mail.status !== "Entregue" && (
                        <>
                          <div
                            style={{
                              display: "flex",
                              marginTop: "3vh",
                              justifyContent: "center",
                              gap: "1vh",
                            }}
                          >
                            <button
                              className="confirmButton"
                              onClick={() => handleClickOpen(mail.id)}
                            >
                              <span>
                                <b>Confirmar retirada</b>
                              </span>
                            </button>
                            <button
                              className="confirmButton"
                              onClick={() => handleClickOpenLocal(mail.id)}
                            >
                              <span>
                                <b>Mudar local</b>
                              </span>
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <MailConfirmModal
        open={open}
        handleClose={handleClose}
        confirmMailReceipt={confirmMailReceipt}
        mail_id={openMailId}
      />
      <MailLocalModal
        open={openLocal}
        handleClose={handleClose}
        confirmMailLocal={confirmLocalChange}
        mail_id={openMailId}
      />
      <ToastContainer />
    </div>
  );
}
