import React, { createContext, useCallback, useContext, useState } from "react";
//import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import api from "../Services/api";
import { useHistory } from "react-router";

const AuthContext = createContext({});

export function AuthProvider({ children }) {
  const [token, setToken] = useState(() => {
    const token = localStorage.getItem("@SWCondominio:token");
    if (token) {
      return token;
    }
    return "";
  });
  const [user, setUser] = useState(() => {
    const userdata = localStorage.getItem("@SWCondominio:user");
    if (userdata) {
      return userdata;
    } else {
      return "";
    }
  });

  const notifyError = () => toast.error("Erro na validação do Login!");

  async function signIn(email, password, setLoading, setError) {
    setLoading(true);
    var type = "";
    try {
      const data = { email: email, password: password };

      const response = await api.post("/swtoken", data);

      const token = response.data.token;
      const userData = response.data.user;

      localStorage.setItem("@SWCondominio:token", token);
      localStorage.setItem("@SWCondominio:user", JSON.stringify(userData));
      console.log(token);
      console.log(userData);
      setUser(userData);
      setToken(token);
      setError(false);
      type = userData.modality;
    } catch (e) {
      setError(true);
      console.log("erro");
      console.log(e);
      notifyError();
    }
    setLoading(false);
    return type;
  }

  const signOut = useCallback(() => {
    localStorage.removeItem("@SWCondominio:token");
    localStorage.removeItem("@SWCondominio:user");
    setToken("");
    setUser("");
  }, []);

  const isAuthenticated = () =>
    localStorage.getItem("@SWCondominio:token") ? true : false;

  const isAuthorized = () => {
    console.log("user");
    console.log(user);
    const userInfo = user;
    if (typeof userInfo === "string") {
      return userInfo ? JSON.parse(user).modality : "";
    } else {
      return userInfo.modality;
    }
  };

  // modality é o tipo de usuário (admin, manager ou porteiro)

  return (
    <AuthContext.Provider
      value={{ signIn, signOut, isAuthenticated, token, user, isAuthorized }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
}
