import styled from "styled-components";

export const SideDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 30vw;
  max-width: 30vw;
  height: 100vh;
  background: #00334e;
  overflow-y: auto;
  min-width: 140px;
`;

export const SideHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: 2vh 2vw 0 2vw;
  @media (max-width: 1160px) {
    flex-direction: column;
    gap: 15px;
    align-items: center;
  }
`;

export const LogoDiv = styled.div`
  max-width: 140px;
`;
export const Logo = styled.img`
  display: flex;
  border-radius: 50%;
  width: 80px;
  height: 80px;
`;

export const SideText = styled.h1`
  font-family: "Montserrat", sans-serif;
  font-size: 1.1rem;
  font-weight: 500;
  color: white;

  @media (max-width: 1500px) {
    font-size: 0.8rem;
  }
`;

export const DivText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding-left: 1vw;
  @media (max-width: 1160px) {
    align-items: center;
  }
`;

export const Barra = styled.div`
  display: flex;
  height: 2px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "4vh")};
  width: 15vw;
  background-color: white;
  justify-content: center;
`;

export const DivButtons = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
  margin-top: 2vh;
  padding-right: 2vw;
  @media (max-width: 1220px) {
    align-items: center;
    padding-right: 0;
  }
`;

export const DivCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const FooterSide = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 2px;
  margin-top: 2vh;
  height: 5vh;
  @media (max-width: 1220px) {
    align-items: center;
    padding-top: 20px;
    gap: 10px;
  }
`;
