import styled from "styled-components";

export const DataText = styled.div`
  text-decoration: none;
  color: #000;
  word-wrap: break-word;
  width: 100%;
  max-width: 10ch;
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    color: #4fabe1;
  }
`;
