import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import * as yup from "yup";

// Service
import api from "../../../../Services/api";

// Toast
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Components
import Sidebar from "../../../../Components/SidebarManager";
import ArrowBack from "../../../../Components/ResponsiveArrowBack";

// Style
import "./index.css";
import { Grid } from "@material-ui/core";

const initialForm = {
  name: "",
  minimum_time: "",
  maximum_time: "",
  capacity: "",
  price: "",
  max_minutes_duration: "",
  min_minutes_interval: "",
};

export default function SortitionDrawPage() {
  const history = useHistory();

  const [sortition, setSortition] = useState();

  const notifySucess = () => toast.success("Reserva sorteada com sucesso!");
  const notifyError = () =>
    toast.error(
      "Desculpe, houvel uma falha ao gerar sorteio, tente novamente mais tarde."
    );

  const { id } = useParams();

  useEffect(async () => {
    const retrieveData = async () => {
      try {
        const data = (await api.get(`/sortition/draw/${id}`)).data;
        const space = (await api.get(`/space/${data.space_id}`)).data;
        const resident = (await api.get(`/resident/${data.resident_id}`)).data;
        const sortition = {
          ...data,
          space,
          resident,
        };
        setSortition(sortition);
        notifySucess();
      } catch (ex) {
        notifyError();
        history.goBack();
        throw ex;
      }
    };
    await retrieveData();
  }, []);

  return (
    <Grid container>
      <Grid item xs={3}>
        <Sidebar />
      </Grid>
      <Grid container  item xs={8} style={{ marginLeft: "15vh" }}>
        <Grid item xs={12} >
          <div className="spacePageTitleContainer">
            <ArrowBack />
            <p className="pageTitle">Resultado do sorteio</p>
          </div>
        </Grid>
        <Grid hight = "100vh">
          <div className="dataContainer md" style={{ marginLeft: "11vh" }} >
            <div className="infoLabel">Morador: {sortition?.resident.name}</div>
            <div className="infoLabel">Complemento: {sortition?.resident.complement}</div>
            <div className="infoLabel">Espaço: {sortition?.space.name}</div>
            <div className="infoLabel">Data: {sortition?.date}</div>
            <div className="infoLabel">Começo: {sortition?.start}</div>
            <div className="infoLabel">Fim: {sortition?.end}</div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}
