import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
//Formik
import { Formik } from "formik";
//yup
import * as yup from "yup";
//Auth
import { useAuth } from "../../../Context/auth";
import { isAuthenticated } from "../../../Context/isAuthenticated";
//Components
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../../../Components/Loading/index";
import Logo from "../../../Assets/logoraiz.svg";

import "./index.css";
import api from "../../../Services/api";

export default function CadastroSquare() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [verSenha, setVerSenha] = useState(false);
  const [tipoEmpresa, setTipoEmpresa] = useState("");
  const { signIn, token, user } = useAuth();
  const history = useHistory();
  const [selectedFile, setSelectedFile] = useState(null);
  const [checked, setChecked] = useState([]);
  const [imgErrorText, setImgErrorText] = useState("");
  const [allStates, setAllStates] = useState(false);

  /*useEffect(() => {
    if (isAuthenticated()) {
      if (user.modality === "admin") {
        history.push("/admin/home");
        return;
      }
      if (user.modality === "manager") {
        history.push("/manager/home");
        return;
      }
    }
  }, [token]);*/
  console.log(setSelectedFile);
  const fileSelectedHandler = (e) => {
    if (e.target.files && e.target.files[0]) {
      var img = document.createElement("img");

      img.onload = function () {
        console.log(this.width + " " + this.height);
        setImgErrorText("");
        setSelectedFile(e.target.files[0]);
      };

      var reader = new FileReader();
      reader.onloadend = function (ended) {
        img.src = ended.target.result;
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const alternarExibicaoSenha = () => {
    setVerSenha(!verSenha);
  };

  const cadastroValidationSchema = yup.object().shape({
    nomeFantasia: yup.string().required("Nome fantasia obrigatório"),
    name: yup.string().required("Razão social obrigatória"),
    cnpj: yup.string().required("CNPJ obrigatório"),
    telefone: yup.string().required("Telefone obrigatório"),
    cep: yup.string().required("CEP obrigatório"),
    city: yup.string().required("Cidade obrigatória"),
    state: yup.string().required("Estado obrigatório"),
    neighborhood: yup.string().required("Bairro obrigatório"),
    email: yup
      .string()
      .email("Entre com um endereço de e-mail válido")
      .required("Endereço de e-mail obrigatório"),
    password: yup.string().required("Senha obrigatória"),
  });

  const register = async (
    name,
    email,
    password,
    city,
    nomeFantasia,
    cnpj,
    telefone,
    state,
    neighborhood,
    cep,
    street,
    number
  ) => {
    setLoading(true);
    console.log("MIMICo");
    try {
      const data = {
        name: name,
        email: email,
        password: password,
        modality: tipoEmpresa,
        city: city,
        corporate_name: nomeFantasia,
        cnpj: cnpj,
        phone_number: telefone,
        state: state,
        district: neighborhood,
        cep: cep,
        street: street,
        number: number,
        header: `content-type:${selectedFile.type}`,
      };
      const formData = new FormData();
      formData.append("data", JSON.stringify(data));
      formData.append("photo", selectedFile);

      console.log("formd ata", formData);
      console.log(data);
      const res = await toast.promise(api.post("/company", formData), {
        pending: "Enviando...",
        success: "Cadastrado com sucesso!",
        error: {
          render({ data }) {
            return `Erro ao cadastrar! {${data?.response?.data?.msg}}`;
          },
        },
      });
      console.log(res);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      history.push(`/`);
    }
  };

  const campos = [
    {
      label: "nomeFantasia",
      Nome: "Nome fantasia",
      type: "text",
    },
    {
      label: "name",
      Nome: "Razão social",
      type: "text",
    },
    {
      label: "cnpj",
      Nome: "CNPJ",
      type: "text",
    },
    {
      label: "telefone",
      Nome: "Telefone",
      type: "tel",
    },
    {
      label: "cep",
      Nome: "CEP",
      type: "text",
    },
    {
      label: "city",
      Nome: "Cidade",
      type: "text",
    },
    {
      label: "state",
      Nome: "Estado",
      type: "text",
    },
    {
      label: "neighborhood",
      Nome: "Bairro",
      type: "text",
    },
    {
      label: "street",
      Nome: "Rua",
      type: "text",
    },
    {
      label: "number",
      Nome: "Número",
      type: "text",
    },
    {
      label: "email",
      Nome: "Email",
      type: "email",
    },
  ];

  const handleCepBlur = async (event, setFieldValue) => {
    const cep = event.target.value.replace(/\D/g, "");

    if (cep.length === 8) {
      try {
        const response = await api.get(`https://viacep.com.br/ws/${cep}/json/`);
        const { data } = response;
        if (!data.erro) {
          setFieldValue("cep", cep);
          setFieldValue("city", data.localidade);
          setFieldValue("state", data.uf);
          setFieldValue("neighborhood", data.bairro);
          setFieldValue("street", data.logradouro);
        } else {
          // Limpar os outros campos se o CEP for inválido ou não for encontrado
          setFieldValue("cep", "");
          setFieldValue("city", "");
          setFieldValue("state", "");
          setFieldValue("neighborhood", "");
          setFieldValue("street", "");

          console.log("CEP inválido ou não encontrado");
        }
      } catch (error) {
        // Tratar erros de requisição
        console.log("Erro ao buscar CEP", error);
      }
    } else {
      // Limpar os outros campos caso o CEP não tenha 8 dígitos
      setFieldValue("city", "");
      setFieldValue("state", "");
      setFieldValue("neighborhood", "");
    }
  };

  return (
    <div className="all">
      <div id="mainContainerCad">
        <div id="leftContainerCad">
          <div id="teste2">
            <img src={Logo} />
            <p className="welcomeText">
              Para continuar, realize seu login, ou solicite seu cadastro.
            </p>
          </div>
        </div>

        <div id="rightContainerCad">
          <p id="loginText">Cadastrar Empresa</p>
          <div className="radio-group">
            <label className="radio-label">
              <input
                type="radio"
                name="tipoEmpresa"
                className="radio-input"
                value="publicidade"
                checked={tipoEmpresa === "publicidade"}
                onChange={(e) => setTipoEmpresa(e.target.value)}
              />
              Empresa Publicitária
            </label>
            <label className="radio-label">
              <input
                type="radio"
                name="tipoEmpresa"
                className="radio-input"
                value="imobiliaria"
                checked={tipoEmpresa === "imobiliaria"}
                onChange={(e) => setTipoEmpresa(e.target.value)}
              />
              Imobiliária
            </label>
          </div>

          <Formik
            initialValues={{
              nomeFantasia: "",
              name: "",
              cnpj: "",
              telefone: "",
              cep: "",
              city: "",
              state: "",
              neighborhood: "",
              email: "",
              password: "",
              number: "",
              street: "",
            }}
            onSubmit={(values) => {
              /* console.log("enviado", values); */
              if (tipoEmpresa.length) {
                register(
                  values.name,
                  values.email,
                  values.password,
                  values.city,
                  values.nomeFantasia,
                  values.cnpj,
                  values.telefone,
                  values.state,
                  values.neighborhood,
                  values.cep,
                  values.street,
                  values.number
                );
              } else {
                toast.error("Selecione o tipo de empresa");
              }
            }}
            validationSchema={cadastroValidationSchema}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              errors,
              isValid,
              touched,
              setFieldValue,
            }) => (
              <form
                id="cadastro"
                onSubmit={handleSubmit}
                style={{ width: "100%", height: "73%" }}
              >
                <div
                  id="inputs"
                  style={{ maxHeight: "100%", overflow: "auto" }}
                >
                  {campos.map((campo) => {
                    if (campo.label === "cep") {
                      return (
                        <div className="inputBox">
                          <div
                            className={
                              touched[campo.label] && errors[campo.label]
                                ? "erroActive"
                                : "errorMessage"
                            }
                            id="errorMessage"
                          >
                            {errors[campo.label]}
                          </div>

                          <input
                            type={campo.type}
                            name={campo.label}
                            placeholder={campo.Nome}
                            className="inputText"
                            onChange={handleChange(campo.label)}
                            value={values[campo.label]}
                            onBlur={(e) => handleCepBlur(e, setFieldValue)}
                          />
                        </div>
                      );
                    } else {
                      return (
                        <div className="inputBox">
                          <div
                            className={
                              touched[campo.label] && errors[campo.label]
                                ? "erroActive"
                                : "errorMessage"
                            }
                            id="errorMessage"
                          >
                            {errors[campo.label]}
                          </div>

                          <input
                            type={campo.type}
                            name={campo.label}
                            placeholder={campo.Nome}
                            className="inputText"
                            onChange={handleChange(campo.label)}
                            value={values[campo.label]}
                            onBlur={handleBlur(campo.label)}
                          />
                        </div>
                      );
                    }
                  })}

                  <div className="inputBox">
                    <div
                      className={
                        touched.password && errors.password
                          ? "erroActive"
                          : "errorMessage"
                      }
                      id="errorMessage"
                    >
                      {errors.password}
                    </div>
                    <div className="inputBox2">
                      <input
                        type={verSenha ? "text" : "password"}
                        name="password"
                        placeholder="Senha"
                        className="inputText"
                        onChange={handleChange("password")}
                        value={values.password}
                        onBlur={handleBlur("password")}
                      />
                      {verSenha ? (
                        <RiEyeLine
                          style={{
                            /*  position: "absolute", */
                            color: "#00334E",
                            marginTop: "0.6%",
                            marginLeft: "1.6%",
                          }}
                          size={22}
                          onClick={alternarExibicaoSenha}
                        />
                      ) : (
                        <RiEyeOffLine
                          style={{
                            /* position: "absolute", */
                            color: "#00334E",
                            marginTop: "0.6%",
                            marginLeft: "1.6%",
                          }}
                          size={22}
                          onClick={alternarExibicaoSenha}
                        />
                      )}
                    </div>
                    <div
                      id="filePicker"
                      style={{
                        justifyItems: "center",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <p>
                        Arquivo de imagem <strong>(resolução 16:9)</strong>
                      </p>
                      <input type="file" onChange={fileSelectedHandler} />
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>

          {loading ? (
            <Loading />
          ) : (
            <>
              <button
                type="submit"
                className="handleButton"
                id="signIn"
                form="cadastro"
                style={{ marginTop: "5%" }}
              >
                <p>CADASTRAR</p>
              </button>
            </>
          )}
        </div>
      </div>
      {error ? <ToastContainer /> : null}
    </div>
  );
}
