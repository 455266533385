import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

//SignOut
import AccessDenied from "../Pages/SignOut/AccessDenied";
import Login from "../Pages/SignOut/Login";
import ForgotPass from "../Pages/SignOut/ForgotPass";
import ForgotPassConfirmation from "../Pages/SignOut/ForgotPassConfirmation";
import Identification from "../Pages/SignOut/Identification";
import PoliticaPrivacidade from "../Pages/SignOut/PoliticaPrivacidade/index";
import Cadastro from "../Pages/SignOut/Cadastro/index";

//Admin
import AdminHome from "../Pages/SignIn/Admin/Home";
import ProfileAdmin from "../Pages/SignIn/Admin/Profile";
import RegisterCondominium from "../Pages/SignIn/Admin/RegisterCondominium";
import CondominiumList from "../Pages/SignIn/Admin/CondominiumList";
import ApproveManager from "../Pages/SignIn/Admin/ApproveManager";
import Propaganda from "../Pages/SignIn/Admin/Propaganda";
import NotesListAdmin from "../Pages/SignIn/Admin/NotesList";
import CreateNoteAdmin from "../Pages/SignIn/Admin/CreateNote";
import AdvertisementList from "../Pages/SignIn/Admin/PropagandaList";
import AboutAdmin from "../Pages/SignIn/Admin/About";
import Users from "../Pages/SignIn/Admin/Users";
import WarningListAdmin from "../Pages/SignIn/Admin/WarningsListAdmin";
import AdminCreateService from "../Pages/SignIn/Admin/Employees/CreateService";
import AdminServicesList from "../Pages/SignIn/Admin/Employees/ServicesList";

// Empresa imobiliaria
import EmpresaImobiliariaHome from "../Pages/SignIn/ImobiliaryCompanies/Home";
import ImoveisImobiliaria from "../Pages/SignIn/ImobiliaryCompanies/Imoveis";
import ProfileImob from "../Pages/SignIn/ImobiliaryCompanies/Profile";

//Doorman
import HomepageDoorman from "../Pages/SignIn/Doorman/Home";
import WarningListDoorman from "../Pages/SignIn/Doorman/WarningList";
import CreateDeliveryDoorman from "../Pages/SignIn/Doorman/Delivery/CreateDelivery";
import CreateMailDoorman from "../Pages/SignIn/Doorman/Mail/CreateMail";
import NotesListDoorman from "../Pages/SignIn/Doorman/NoteList";
import CreateNoteDoorman from "../Pages/SignIn/Doorman/CreateNote";
import SeeHistory from "../Pages/SignIn/Doorman/VisitorPage/SeeHistory";
import CreateVisitor from "../Pages/SignIn/Doorman/VisitorPage/CreateVisitor";
import SeeHistoryOcorrence from "../Pages/SignIn/Doorman/Occurrences/SeeService";
import CreateServiceOcurrence from "../Pages/SignIn/Doorman/Occurrences/CreateService";
import AboutDoorman from "../Pages/SignIn/Doorman/About";
import ResidentListDoorman from "../Pages/SignIn/Doorman/ResidentListDoorman";
import ProfileDoorman from "../Pages/SignIn/Doorman/Profile";

//Manager
import ManagerHome from "../Pages/SignIn/Manager/Home";
import Management from "../Pages/SignIn/Manager/Management";
import CreateDelivery from "../Pages/SignIn/Manager/Delivery/CreateDelivery";
import CreateMail from "../Pages/SignIn/Manager/Mail/CreateMail";
import CreateSale from "../Pages/SignIn/Manager/Sales/CreateSale";
import ProfileManager from "../Pages/SignIn/Manager/Profile";
import SpacePage from "../Pages/SignIn/Manager/Space";
import CreateSpacePage from "../Pages/SignIn/Manager/Space/create";
import SortitionPage from "../Pages/SignIn/Manager/Sortition";
import SortitionCreatePage from "../Pages/SignIn/Manager/Sortition/create";
import SortitionDrawPage from "../Pages/SignIn/Manager/Sortition/draw";
import DocumentPage from "../Pages/SignIn/Manager/Document";
import DocumentCreatePage from "../Pages/SignIn/Manager/Document/create";
import About from "../Pages/SignIn/Manager/About";
import SeeHistoryManager from "../Pages/SignIn/Manager/VisitorPage/SeeHistory";
import CreateVisitorManager from "../Pages/SignIn/Manager/VisitorPage/CreateVisitor";
import DivisionScreen from "../Pages/SignIn/Manager/Occurrences/SelectOption";
import SeeHistoryOcorrenceManager from "../Pages/SignIn/Manager/Occurrences/SeeService";
import SeeIndication from "../Pages/SignIn/Manager/Services/SeeIndications";
import Reservations from "../Pages/SignIn/Manager/Reservations";
import SortitionResidentsList from "../Pages/SignIn/Manager/Sortition/list";

// import Warnings from "../Pages/SignIn/Manager/Warnings";
import CreateOccurrence from "../Pages/SignIn/Manager/Occurrences/CreateOccurrence";

import WarningList from "../Pages/SignIn/Manager/WarningsList";
import OccurrencesList from "../Pages/SignIn/Manager/Occurrences/OccurrencesList";
import ResidentsList from "../Pages/SignIn/Manager/ResidentsList";
import ApproveResident from "../Pages/SignIn/Manager/ApproveResidents";
import CreateWarning from "../Pages/SignIn/Manager/CreateWarning";
import ServicesList from "../Pages/SignIn/Manager/Services/ServicesList";

import { useAuth } from "../Context/auth";
import NotesListManager from "../Pages/SignIn/Manager/NotesList";
import CreateNoteManager from "../Pages/SignIn/Manager/CreateNote";
import VideoConference from "../Pages/SignIn/Manager/VideoConference";
import CreateService from "../Pages/SignIn/Manager/Services/CreateService";
import Properties from "../Pages/SignIn/Manager/Properties";
import WalletConfig from "../Pages/SignIn/Manager/WalletConfig";
import CreateWarningAdmin from "../Pages/SignIn/Admin/CreateWarningAdmin";
import TermosDeUso from "../Pages/SignOut/TermosDeUso";
import Imoveis from "../Pages/SignIn/Admin/Imoveis";
import Empresas from "../Pages/SignIn/Admin/Empresas";
import EditarEmpresas from "../Pages/SignIn/Admin/EditEmpresas";
import EditarImoveis from "../Pages/SignIn/Admin/EditImoveis";
import HomepageImobiliaria from "../Pages/SignIn/ImobiliaryCompanies/Home";
import CreateEspecialistas from "../Pages/SignIn/Admin/Employees/CreateEspecialistas";
import EditCondominiumPage from "../Pages/SignIn/Admin/EditCondominium";
import EditService from "../Pages/SignIn/Admin/Employees/EditService";
import HomepagePublicidade from "../Pages/SignIn/PublicityCompanies/Home";
import HomepageMaintenanceTech from "../Pages/SignIn/MaintenanceTech/Home";
import OccurrencesListTech from "../Pages/SignIn/MaintenanceTech/Occurrences/OccurrencesList";
import ManagementTech from "../Pages/SignIn/MaintenanceTech/Management";
import ManutencaoModalTech from "../Pages/SignIn/MaintenanceTech/Management/Manutencao/ManutencaoModal";
import ProfileTech from "../Pages/SignIn/MaintenanceTech/Profile";
import EditPropagandas from "../Pages/SignIn/Admin/EditPropagandas";
import PubServicesList from "../Pages/SignIn/PublicityCompanies/Employees/ServicesList";
import PubCreateService from "../Pages/SignIn/PublicityCompanies/Employees/CreateService";
import CreateEspecialistasPub from "../Pages/SignIn/PublicityCompanies/Employees/CreateEspecialistas";
import ProfilePub from "../Pages/SignIn/PublicityCompanies/Profile";
import EditServicePub from "../Pages/SignIn/PublicityCompanies/Employees/EditService";
import AdvertisementListPub from "../Pages/SignIn/PublicityCompanies/PropagandaList";
import PropagandaPagePub from "../Pages/SignIn/PublicityCompanies/Propaganda";
import HomepageAdministration from "../Pages/SignIn/Administration/Home";
import ProfileAdminstration from "../Pages/SignIn/Administration/Profile";
import CreateWarningAdministration from "../Pages/SignIn/Administration/CreateWarning";
import ResidentListAdministration from "../Pages/SignIn/Administration/ResidentsList";
import ApproveResidentsAdministration from "../Pages/SignIn/Administration/ApproveResidents";
import ManagementAdm from "../Pages/SignIn/Admin/Management";
import ManagementAdministration from "../Pages/SignIn/Administration/Management";
import SpacePageAdministrarion from "../Pages/SignIn/Administration/Space";
import SortitionPageAdministration from "../Pages/SignIn/Administration/Sortition";
import DocumentPageAdministraion from "../Pages/SignIn/Administration/Document";
import VideoConferenceAdministration from "../Pages/SignIn/Administration/VideoConference";
import WalletConfigAdministration from "../Pages/SignIn/Administration/WalletConfig";
import ReservationsAdminstration from "../Pages/SignIn/Administration/Reservations";
import NotesListAdministration from "../Pages/SignIn/Administration/NotesList";
import CreateNoteAdministration from "../Pages/SignIn/Administration/CreateNote";
import DocumentCreatePageAdministration from "../Pages/SignIn/Administration/Document/create";
import OccurrencesListAdministration from "../Pages/SignIn/Administration/Occurrences/OccurrencesList";
import CreateOccurrenceAdministration from "../Pages/SignIn/Administration/Occurrences/CreateOccurrence";
import SeeHistoryOcorrenceAdministration from "../Pages/SignIn/Administration/Occurrences/SeeService";
import AboutAdministration from "../Pages/SignIn/Administration/About";
import GestaoEspecialidade from "../Pages/SignIn/Admin/Employees/GestaoEspecialidades";
import EditEspecialistas from "../Pages/SignIn/Admin/Employees/EditEspecialidade";
import EditPropagandasPub from "../Pages/SignIn/PublicityCompanies/EditPropaganda";
import EditPropagandasPubli from "../Pages/SignIn/PublicityCompanies/EditPropaganda";

export default function Routes() {
  //const VerifyAuthorization = isAuthorized("admin") ? <Component {...props}/> : <Redirect to={{ pathname: "/acessonegado", state: { from: props.location //} }}/>;
  // se isAuthorized() retorn true exibe a página atual, do contrário redireciona
  // para a página AccessDenied

  const { isAuthenticated, isAuthorized, user } = useAuth();

  const ManagerRoute = ({ component: Component, ...rest }) => {
    console.log(isAuthorized());
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthorized() === "manager" ? (
            <Component {...props} />
          ) : (
            <Redirect to="/" />
          )
        }
      />
    );
  };

  const AdminRoute = ({ component: Component, ...rest }) => {
    console.log(isAuthorized(), " função authorized admin");
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthorized() === "admin" ? (
            <Component {...props} />
          ) : (
            <Redirect to="/" />
          )
        }
      />
    );
  };

  const DoormanRoute = ({ component: Component, ...rest }) => {
    console.log(isAuthorized(), " função authorized doorman");
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthorized() === "doorman" ? (
            <Component {...props} />
          ) : (
            <Redirect to="/" />
          )
        }
      />
    );
  };

  const EmpresaMobiRoute = ({ component: Component, ...rest }) => {
    console.log(isAuthorized());
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthorized() === "imobiliaria" ? (
            <Component {...props} />
          ) : (
            <Redirect to="/" />
          )
        }
      />
    );
  };

  const EmpresaPubliRoute = ({ component: Component, ...rest }) => {
    console.log(isAuthorized());
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthorized() === "publicidade" ? (
            <Component {...props} />
          ) : (
            <Redirect to="/" />
          )
        }
      />
    );
  };

  const TecnicoManutencaoRoute = ({ component: Component, ...rest }) => {
    console.log(isAuthorized());
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthorized() === "maintenance" ? (
            <Component {...props} />
          ) : (
            <Redirect to="/" />
          )
        }
      />
    );
  };

  const AdministrationRoute = ({ component: Component, ...rest }) => {
    console.log(isAuthorized());
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthorized() === "administration" ? (
            <Component {...props} />
          ) : (
            <Redirect to="/" />
          )
        }
      />
    );
  };

  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/cadastro" exact component={Cadastro} />
      <Route path="/esqueci" exact component={ForgotPass} />
      <Route path="/acessonegado" exact component={AccessDenied} />
      <Route
        path="/PoliticaPrivacidade"
        exact
        component={PoliticaPrivacidade}
      />
      <Route path="/TermosDeUso" exact component={TermosDeUso} />
      <Route
        path="/validar-carteirinha/:code"
        exact
        component={Identification}
      />
      <Route
        path="/esqueci-confirmação"
        exact
        component={ForgotPassConfirmation}
      />
      {/* Admin */}
      <AdminRoute path="/admin/home" exact component={AdminHome} />
      <AdminRoute path="/admin/profile" exact component={ProfileAdmin} />
      <AdminRoute
        path="/admin/registercondominium"
        exact
        component={RegisterCondominium}
      />
      <AdminRoute
        path={"/admin/registercondominium/edit/:id"}
        component={EditCondominiumPage}
      />
      <AdminRoute path="/admin/users" exact component={Users} />
      <AdminRoute
        path="/admin/condominiumlist"
        exact
        component={CondominiumList}
      />
      <AdminRoute
        path="/admin/managerapproval"
        exact
        component={ApproveManager}
      />
      <AdminRoute path="/admin/gestao" exact component={ManagementAdm} />
      <AdminRoute
        path="/admin/propaganda/edit/:id"
        exact
        component={EditPropagandas}
      />
      <AdminRoute
        path="/admin/propaganda/create"
        exact
        component={Propaganda}
      />
      <AdminRoute
        path="/admin/propagandalist"
        exact
        component={AdvertisementList}
      />
      <AdminRoute
        path={"/admin/space/create"}
        exact
        component={CreateSpacePage}
      />
      <AdminRoute path={"/admin/space/edit/:id"} component={CreateSpacePage} />
      {/* gestao */}
      <AdminRoute path="/admin/gestao" exact component={Management} />
      {/* ocorrencias */}
      <AdminRoute
        path="/admin/ocorrencias/criar"
        exact
        component={CreateOccurrence}
      />
      <AdminRoute
        path="/admin/ocorrencias/editar/:id"
        exact
        component={CreateOccurrence}
      />
      <AdminRoute path="/admin/ocorrencias" exact component={OccurrencesList} />
      {/* delivery */}
      <AdminRoute path="/admin/delivery" exact component={CreateDelivery} />
      {/* correios */}
      <AdminRoute path="/admin/mail" exact component={CreateMail} />
      {/* venda e locacao */}
      <AdminRoute path="/admin/sales" exact component={CreateSale} />
      Profile
      {/* meus visitantes */}
      <AdminRoute path="/admin/condominos" exact component={ResidentsList} />
      {/* bloco de notas */}
      <AdminRoute path="/admin/notes" exact component={NotesListAdmin} />
      <AdminRoute
        path="/admin/notes/create"
        exact
        component={CreateNoteAdmin}
      />
      <AdminRoute
        path="/admin/aviso/criar"
        exact
        component={CreateWarningAdmin}
      />
      <AdminRoute
        path="/admin/aviso/editar/:id"
        exact
        component={CreateWarningAdmin}
      />
      <AdminRoute path="/admin/avisos" exact component={WarningListAdmin} />
      <AdminRoute
        path="/admin/notes/edit/:id"
        exact
        component={CreateNoteAdmin}
      />
      <AdminRoute path="/admin/Imoveis" exact component={Imoveis} />
      <AdminRoute path="/admin/servicos" exact component={AdminServicesList} />
      <AdminRoute
        path="/admin/servicos/criar"
        exact
        component={AdminCreateService}
      />
      <AdminRoute
        path="/admin/servicos/criarespecialista"
        exact
        component={CreateEspecialistas}
      />
      <AdminRoute
        path="/admin/servicos/Editar/:id"
        exact
        component={EditService}
      />
      <AdminRoute
        path="/admin/EditarEmpresa/:id"
        exact
        component={EditarEmpresas}
      />
      <AdminRoute
        path="/admin/EditarImoveis/:id"
        exact
        component={EditarImoveis}
      />
      <AdminRoute
        path="/admin/servicos/editar/:id"
        exact
        component={AdminCreateService}
      />
      <AdminRoute
        path="/admin/servicos/GestãoEspecialidade"
        exact
        component={GestaoEspecialidade}
      />
      <AdminRoute
        path="/admin/servicos/GestãoEspecialidade/editar/:id"
        exact
        component={EditEspecialistas}
      />
      <AdminRoute path="/admin/Empresas" exact component={Empresas} />
      {/* sobre */}
      <AdminRoute path="/admin/sobre" exact component={AboutAdmin} />
      {/* Manager */}
      <ManagerRoute path="/manager/home" exact component={ManagerHome} />
      <ManagerRoute path="/manager/space" exact component={SpacePage} />
      <ManagerRoute
        path={"/manager/space/create"}
        exact
        component={CreateSpacePage}
      />
      <ManagerRoute
        path={"/manager/space/edit/:id"}
        component={CreateSpacePage}
      />
      <ManagerRoute
        path={"/manager/sortition"}
        exact
        component={SortitionPage}
      />
      <ManagerRoute
        path={"/manager/sortition/create"}
        exact
        component={SortitionCreatePage}
      />
      <ManagerRoute
        path={"/manager/sortition/draw/:id"}
        exact
        component={SortitionDrawPage}
      />
      <ManagerRoute
        path={"/manager/sortition/residents/:name/:space/:id"}
        exact
        component={SortitionResidentsList}
      />
      <ManagerRoute
        path="/manager/aviso/criar"
        exact
        component={CreateWarning}
      />
      <ManagerRoute
        path="/manager/aviso/editar/:id"
        exact
        component={CreateWarning}
      />
      <ManagerRoute path="/manager/avisos" exact component={WarningList} />
      <ManagerRoute
        path="/manager/ocorrencias/criar"
        exact
        component={CreateOccurrence}
      />
      <ManagerRoute
        path="/manager/ocorrencias/editar/:id"
        exact
        component={CreateOccurrence}
      />
      <ManagerRoute
        path="/manager/listar-ocorrencias"
        exact
        component={OccurrencesList}
      />
      <ManagerRoute
        path="/manager/servicos/criar"
        exact
        component={CreateService}
      />
      <ManagerRoute
        path="/manager/servicos/indicacoes"
        exact
        component={SeeIndication}
      />
      <ManagerRoute
        path="/manager/servicos/editar/:id"
        exact
        component={CreateService}
      />
      <ManagerRoute
        path="/manager/venda-e-locacao"
        exact
        component={Properties}
      />
      <ManagerRoute path="/manager/profile" exact component={ProfileManager} />
      <ManagerRoute path="/manager/servicos" exact component={ServicesList} />
      <ManagerRoute path="/manager/delivery" exact component={CreateDelivery} />
      <ManagerRoute path="/manager/list/mail" exact component={CreateMail} />
      <ManagerRoute path="/manager/create/mail" exact component={CreateMailDoorman} />
      <ManagerRoute
        path="/manager/mail"
        exact
        component={() => (
          <DivisionScreen
            links={[
              {
                pathname: "/manager/create/mail",
                text: "Adicionar encomenda",
              },
              {
                pathname: "/manager/list/mail",
                text: "Ver histórico de encomendas",
              },
            ]}
            pageTitle="Correios"
          />
        )}
      />
      <ManagerRoute path="/manager/sales" exact component={CreateSale} />
      <ManagerRoute path="/manager/notes" exact component={NotesListManager} />
      <ManagerRoute
        path="/manager/reservations"
        exact
        component={Reservations}
      />
      <ManagerRoute
        path="/manager/notes/create"
        exact
        component={CreateNoteManager}
      />
      <ManagerRoute
        path="/manager/notes/edit/:id"
        exact
        component={CreateNoteManager}
      />
      <ManagerRoute
        path="/manager/condominos"
        exact
        component={ResidentsList}
      />
      <ManagerRoute
        path="/manager/pedidos-de-cadastro"
        exact
        component={ApproveResident}
      />
      <ManagerRoute
        path="/manager/video-conferencia"
        exact
        component={VideoConference}
      />
      <ManagerRoute path="/manager/gestao" exact component={Management} />
      <ManagerRoute
        path={"/manager/document/"}
        exact
        component={DocumentPage}
      />
      <ManagerRoute
        path={"/manager/document/create"}
        exact
        component={DocumentCreatePage}
      />
      <ManagerRoute path="/manager/sobre" exact component={About} />
      <ManagerRoute
        path="/manager/visitantes"
        exact
        component={() => (
          <DivisionScreen
            links={[
              {
                pathname: "/manager/create-visitante",
                text: "Adicionar visitante externo",
              },
              {
                pathname: "/manager/historico-visitantes",
                text: "Ver lista de visitantes",
              },
            ]}
            pageTitle="Visitantes"
          />
        )}
      />
      <ManagerRoute
        path="/manager/historico-visitantes"
        exact
        component={SeeHistoryManager}
      />
      <ManagerRoute
        path="/manager/create-visitante"
        exact
        component={CreateVisitorManager}
      />
      <ManagerRoute
        path="/manager/ocorrencias"
        exact
        component={() => (
          <DivisionScreen
            links={[
              {
                pathname: "/manager/ocorrencias/criar",
                text: "Criar Ocorrência",
              },
              {
                pathname: "/manager/listar-ocorrencias",
                text: "Listar Ocorrências",
              },
              {
                pathname: "/manager/historico-ocorrencias",
                text: "Ver lista de Passagem de Serviço",
              },
            ]}
            pageTitle="Ocorrências"
          />
        )}
      />
      <ManagerRoute
        path="/manager/historico-ocorrencias"
        exact
        component={SeeHistoryOcorrenceManager}
      />
      <ManagerRoute
        path="/manager/configuração-de-carteira"
        exact
        component={WalletConfig}
      />
      {/* Doorman */}
      <DoormanRoute path="/doorman/home" exact component={HomepageDoorman} />
      <DoormanRoute
        path="/doorman/avisos"
        exact
        component={WarningListDoorman}
      />
      <DoormanRoute
        path="/doorman/delivery"
        exact
        component={CreateDeliveryDoorman}
      />
      <DoormanRoute path="/doorman/list/mail" exact component={CreateMail} />
      <DoormanRoute path="/doorman/create/mail" exact component={CreateMailDoorman} />
      <DoormanRoute
        path="/doorman/mail"
        exact
        component={() => (
          <DivisionScreen
            links={[
              {
                pathname: "/doorman/create/mail",
                text: "Adicionar encomenda",
              },
              {
                pathname: "/doorman/list/mail",
                text: "Ver histórico de encomendas",
              },
            ]}
            pageTitle="Correios"
          />
        )}
      />
      <DoormanRoute path="/doorman/notes" exact component={NotesListDoorman} />
      <DoormanRoute
        path="/doorman/notes/create"
        exact
        component={CreateNoteDoorman}
      />
      <DoormanRoute
        path="/doorman/notes/edit/:id"
        exact
        component={CreateNoteDoorman}
      />
      <DoormanRoute
        path="/doorman/visitantes"
        exact
        component={() => (
          <DivisionScreen
            links={[
              {
                pathname: "/doorman/create-visitante",
                text: "Adicionar visitante externo",
              },
              {
                pathname: "/doorman/historico-visitantes",
                text: "Ver lista de visitantes",
              },
            ]}
            pageTitle="Visitantes"
          />
        )}
      />
      <DoormanRoute
        path="/doorman/historico-visitantes"
        exact
        component={SeeHistory}
      />
      <DoormanRoute
        path="/doorman/create-visitante"
        exact
        component={CreateVisitor}
      />
      <DoormanRoute
        path="/doorman/listar-ocorrencias"
        exact
        component={OccurrencesList}
      />
      <DoormanRoute
        path="/doorman/ocorrencias/criar"
        exact
        component={CreateOccurrence}
      />
      <DoormanRoute
        path="/doorman/ocorrencias/editar/:id"
        exact
        component={CreateOccurrence}
      />
      <DoormanRoute
        path="/doorman/ocorrencias"
        exact
        component={() => (
          <DivisionScreen
            links={[
              {
                pathname: "/doorman/create-ocorrencias",
                text: "Criar Passagem de Serviço",
              },
              {
                pathname: "/doorman/historico-ocorrencias",
                text: "Ver lista de Passagem de Serviço",
              },
              { 
                pathname: "/doorman/listar-ocorrencias",
                text: "Ver lista de Ocorrências"
              }
            ]}
            pageTitle="Ocorrências"
          />
        )}
      />
      <DoormanRoute
        path="/doorman/historico-ocorrencias"
        exact
        component={SeeHistoryOcorrence}
      />
      <DoormanRoute
        path="/doorman/create-ocorrencias"
        exact
        component={CreateServiceOcurrence}
      />
      <DoormanRoute path="/doorman/sobre" exact component={AboutDoorman} />
      <DoormanRoute
        path="/doorman/residentlist"
        exact
        component={ResidentListDoorman}
      />
      <DoormanRoute path="/doorman/profile" exact component={ProfileDoorman} />
      {/* Empresa Imobiliaria */}
      <EmpresaMobiRoute
        path="/imobiliaria/home"
        exact
        component={HomepageImobiliaria}
      />
      <EmpresaMobiRoute
        path="/imobiliaria/imoveis"
        exact
        component={ImoveisImobiliaria}
      />
      <EmpresaMobiRoute
        path="/imobiliaria/profile"
        exact
        component={ProfileImob}
      />
      {/* Empresa de publicidade */}
      <EmpresaPubliRoute
        path="/publicidade/home"
        exact
        component={HomepagePublicidade}
      />
      <EmpresaPubliRoute
        path="/publicidade/profile"
        exact
        component={ProfilePub}
      />
      <EmpresaPubliRoute
        path="/publicidade/servicos"
        exact
        component={PubServicesList}
      />
      <EmpresaPubliRoute
        path="/publicidade/servicos/criar"
        exact
        component={PubCreateService}
      />
      <EmpresaPubliRoute
        path="/publicidade/servicos/Editar/:id"
        exact
        component={EditServicePub}
      />
      <EmpresaPubliRoute
        path="/publicidade/servicos/criarespecialista"
        exact
        component={CreateEspecialistasPub}
      />
      <EmpresaPubliRoute
        path="/publicidade/propagandalist"
        exact
        component={AdvertisementListPub}
      />
      <EmpresaPubliRoute
        path="/publicidade/propaganda/create"
        exact
        component={PropagandaPagePub}
      />
      <EmpresaPubliRoute
        path="/publicidade/propaganda/edit/:id"
        exact
        component={EditPropagandasPubli}
      />
      {/* Técnico de manutenção */}
      <TecnicoManutencaoRoute
        path="/maintenance/home"
        exact
        component={HomepageMaintenanceTech}
      />
      <TecnicoManutencaoRoute
        path="/maintenance/ocorrencias"
        exact
        component={OccurrencesList}
      />
      <TecnicoManutencaoRoute
        path="/maintenance/gestao"
        exact
        component={ManutencaoModalTech}
      />
      <TecnicoManutencaoRoute
        path="/maintenance/profile"
        exact
        component={ProfileTech}
      />
      <TecnicoManutencaoRoute
        path="/maintenance/ocorrencias/criar"
        exact
        component={CreateOccurrence}
      />
      <TecnicoManutencaoRoute
        path="/maintenance/ocorrencias/editar/:id"
        exact
        component={CreateOccurrence}
      />
      {/* Administration */}
      <AdministrationRoute
        path="/administration/home"
        exact
        component={HomepageAdministration}
      />
      <AdministrationRoute
        path="/employee/profile"
        exact
        component={ProfileAdminstration}
      />
      <AdministrationRoute
        path="/administration/avisos"
        exact
        component={CreateWarningAdministration}
      />
      <AdministrationRoute
        path="/administration/condominos"
        exact
        component={ResidentListAdministration}
      />
      <AdministrationRoute
        path="/administration/pedidos-de-cadastro"
        exact
        component={ApproveResidentsAdministration}
      />
      <AdministrationRoute
        path="/administration/space"
        exact
        component={SpacePageAdministrarion}
      />
      <AdministrationRoute
        path="/administration/sortition"
        exact
        component={SortitionPageAdministration}
      />
      <AdministrationRoute
        path="/administration/gestao"
        exact
        component={ManagementAdministration}
      />
      <AdministrationRoute
        path="/administration/document"
        exact
        component={DocumentPageAdministraion}
      />
      <AdministrationRoute
        path="/administration/ocorrencias"
        exact
        component={OccurrencesListAdministration}
      />
      <AdministrationRoute
        path="/administration/video-conferencia"
        exact
        component={VideoConferenceAdministration}
      />
      <AdministrationRoute
        path="/administration/configuração-de-carteira"
        exact
        component={WalletConfigAdministration}
      />
      <AdministrationRoute
        path="/administration/reservations"
        exact
        component={ReservationsAdminstration}
      />
      <AdministrationRoute
        path="/administration/notes"
        exact
        component={NotesListAdministration}
      />
      <AdministrationRoute
        path="/administration/notes/create"
        exact
        component={CreateNoteAdministration}
      />
      <AdministrationRoute
        path="/administration/document/create"
        exact
        component={DocumentCreatePageAdministration}
      />
      <AdministrationRoute
        path="/administration/document/create"
        exact
        component={DocumentCreatePageAdministration}
      />
      <AdministrationRoute
        path="/employee/ocorrencias/criar"
        exact
        component={CreateOccurrence}
      />
      <AdministrationRoute
        path="/adminstration/ocorrencias/selecionar"
        exact
        component={() => (
          <DivisionScreen
            links={[
              {
                pathname: "/employee/ocorrencias/criar",
                text: "Criar Ocorrência",
              },
              {
                pathname: "/employee/listar-ocorrencias",
                text: "Listar Ocorrências",
              },
              {
                pathname: "/employee/historico-ocorrencias",
                text: "Ver lista de Passagem de Serviço",
              },
            ]}
            pageTitle="Ocorrências"
          />
        )}
      />
      <AdministrationRoute
        path="/employee/ocorrencias/editar/:id"
        exact
        component={CreateOccurrence}
      />
      <AdministrationRoute
        path="/employee/historico-ocorrencias"
        exact
        component={SeeHistoryOcorrenceManager}
      />
      <AdministrationRoute
        path="/employee/listar-ocorrencias"
        exact
        component={OccurrencesList}
      />
      <AdministrationRoute
        path="/employee/sobre"
        exact
        component={AboutAdministration}
      />
      <AdministrationRoute path="/employee/list/mail" exact component={CreateMail} />
      <AdministrationRoute path="/employee/create/mail" exact component={CreateMailDoorman} />
      <AdministrationRoute
        path="/employee/mail"
        exact
        component={() => (
          <DivisionScreen
            links={[
              {
                pathname: "/employee/create/mail",
                text: "Adicionar encomenda",
              },
              {
                pathname: "/employee/list/mail",
                text: "Ver histórico de encomendas",
              },
            ]}
            pageTitle="Correios"
          />
        )}
      />
    </Switch>
  );
}
