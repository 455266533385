import React, { useState, useCallback, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Buffer } from "buffer";

//Service
import api from "../../../../Services/api";
import axios from "axios";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
//Components

import Sidebar from "../../../../Components/SidebarAdmin";
//import SidebarOptions from "../../../../Components/SidebarOptions";
import ArrowBack from "../../../../Components/ResponsiveArrowBack";

import "./../Propaganda/index.css";

export default function EditPropagandas() {
  const history = useHistory();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [checked, setChecked] = useState([]);
  const [imgErrorText, setImgErrorText] = useState("");
  const [allStates, setAllStates] = useState(false);
  const [data, setData] = useState([]);
  const [dataInputs, setDataInputs] = useState([]);
  const [backData, setBackData] = useState({});

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const CustomCheckbox = ({ selected, handleCheckboxChange, option}) => {
    
    console.log(option, "option");
    if (typeof option === 'string' && option.includes("Selecione um")) {
      return null;
    }

    return (
      <input
        type="checkbox"
        style={{ marginRight: "8px"}}
        checked={selected}
        onChange={handleCheckboxChange}
      />
    );
  };

  const notifySucess = () => toast.success("Propaganda publicada!");
  const notifyError = () => toast.error("Houve um erro, tente novamente!");

  async function getDataBack() {
    try {
      const response = await api.get(`/advertising/${id}`);
      /* console.log(response.data, "Response Data adiquirido"); */
      setBackData(response.data);
      setName(response.data.name);
      setLink(response.data.link);
      const newTree = buildTree(
        response.data.condominiums.map((item) => item.condominiums)
      );
      /* console.log("TESTE BUILDDDDDDD", newTree); */
      setDataInputs(newTree);
    } catch (error) {
      console.log(error);
    }
  }

  function reBuildTree(referenceObj, input) {
    let tree = {};

    // Iterar sobre os estados na árvore de referência
    for (let state in referenceObj) {
      if (input.state.includes(state)) {
        tree[state] = {};

        // Iterar sobre as cidades dentro do estado
        for (let city in referenceObj[state]) {
          if (input.city.includes(city)) {
            tree[state][city] = {};

            // Iterar sobre os bairros dentro da cidade
            for (let district in referenceObj[state][city]) {
              if (input.district.includes(district)) {
                tree[state][city][district] = referenceObj[state][city][
                  district
                ].filter((condominium) =>
                  input.condominium_name.includes(condominium)
                );
              }
            }
          }
        }
      }
    }
    setDataInputs(tree);
  }

  function findCategorizedBranches(obj) {
    let result = {
      state: {},
      city: {},
      district: {},
      condominium_name: {},
    };

    function traverse(currentObj, currentPath) {
      let keys = Object.keys(currentObj);

      keys.forEach((key) => {
        let newPath = currentPath.concat(key);

        if (Array.isArray(currentObj[key])) {
          if (currentObj[key].length === 0) {
            // Array vazio, considerado como distrito
            result.district[key] = true;
          } else {
            // Array não vazio, considerado como nomes de condomínio
            currentObj[key].forEach((condo) => {
              result.condominium_name[condo] = true;
            });
          }
        } else if (typeof currentObj[key] === "object") {
          if (Object.keys(currentObj[key]).length === 0) {
            if (currentPath.length === 0) {
              // Objeto vazio no nível superior, considerado como estado
              result.state[key] = true;
            } else {
              // Objeto vazio em nível inferior, considerado como cidade
              result.city[key] = true;
            }
          } else {
            // Continua a busca recursiva
            traverse(currentObj[key], newPath);
          }
        }
      });
    }

    traverse(obj, []);
    return result;
  }

  function buildTree(data) {
    const tree = {};

    data.forEach((item) => {
      const { city, state, district, condominium_name } = item;

      if (!tree[state]) {
        tree[state] = {};
      }

      if (!tree[state][city]) {
        tree[state][city] = {};
      }

      if (!tree[state][city][district]) {
        tree[state][city][district] = [];
      }

      tree[state][city][district].push(condominium_name);
    });

    return tree;
  }

  const [referenceTree, setReferenceTree] = useState({});
  useEffect(() => {
    setReferenceTree(buildTree(data));
  }, [data]);

  useEffect(() => {
    /* console.log(referenceTree, "ReferenceTree"); */
  }, [referenceTree]);

  useEffect(() => {
    getData();
    getDataBack();
  }, []);

  useEffect(() => {
    //reBuildTree(referenceTree, backData);
  }, [backData]);

  async function getData() {
    try {
      const response = await api.get(
        `/condominium?fields=state,city,district,condominium_name,id`
      );
      setData(response.data);
      console.log("data", response.data);
    } catch (error) {
      console.log(error);
    }
  }

  function handleValueChange(newValues, type) {
    if (dataInputs.length !== 0) {
      /* console.log("newv", newValues, "type", type); */
      let tree = { ...dataInputs };

      if (type === "state") {
        Object.keys(tree).forEach((existingState) => {
          if (!newValues.includes(existingState)) {
            delete tree[existingState];
          }
        });
        newValues.forEach((newState) => {
          if (!tree[newState]) {
            tree[newState] = {};
          }
        });
      } else if (type === "city") {
        const states = Object.keys(referenceTree);

        states.forEach((existingState) => {
          const existingCities = tree[existingState]
            ? Object.keys(tree[existingState])
            : [];
          const ReferenceCities = Object.keys(
            referenceTree[existingState] || {}
          );

          existingCities.forEach((existingCity) => {
            if (!newValues.includes(existingCity)) {
              delete tree[existingState][existingCity];
            }
          });

          newValues.forEach((newCity) => {
            if (
              ReferenceCities.includes(newCity) &&
              !existingCities.includes(newCity)
            ) {
              tree[existingState][newCity] = {};
            }
          });
        });
      } else if (type === "district") {
        const states = Object.keys(referenceTree);

        states.forEach((existingState) => {
          const cities = Object.keys(referenceTree[existingState] || {});
          cities.forEach((existingCity) => {
            /* const existingDistricts = !!tree[existingState][existingCity]
            ? Object.keys(tree[existingState][existingCity])
            : []; */
            let existingDistricts = [];
            if (tree[existingState] && tree[existingState][existingCity]) {
              existingDistricts = Object.keys(
                tree[existingState][existingCity]
              );
            } else {
              existingDistricts = [];
            }
            /*  console.log(existingDistricts, "existingDistricts"); */
            const referenceDistricts = Object.keys(
              referenceTree[existingState][existingCity] || {}
            );
            /* console.log(referenceDistricts, "referenceDistricts"); */

            existingDistricts.forEach((existingDistrict) => {
              if (!newValues.includes(existingDistrict)) {
                delete tree[existingState][existingCity][existingDistrict];
              }
            });

            newValues.forEach((newDistrict) => {
              if (
                referenceDistricts.includes(newDistrict) &&
                !existingDistricts.includes(newDistrict)
              ) {
                /* console.log(existingState, existingCity, newDistrict, "new");
                console.log(tree[existingState][existingCity]); */
                tree[existingState][existingCity][newDistrict] = [];
              }
            });
          });
        });
      } else if (type === "condominium_name") {
        const states = Object.keys(referenceTree);

        states.forEach((existingState) => {
          const cities = Object.keys(referenceTree[existingState] || {});
          cities.forEach((existingCity) => {
            const districts = Object.keys(
              referenceTree[existingState][existingCity] || {}
            );
            districts.forEach((existingDistrict) => {
              /* const existingCondos = tree[existingState][existingCity][
              existingDistrict
            ]
              ? tree[existingState][existingCity][existingDistrict]
              : []; */
              let existingCondos = [];
              if (
                tree[existingState] &&
                tree[existingState][existingCity] &&
                tree[existingState][existingCity][existingDistrict]
              ) {
                existingCondos =
                  tree[existingState][existingCity][existingDistrict];
              } else {
                existingCondos = [];
              }
              const referenceCondos =
                referenceTree[existingState][existingCity][existingDistrict] ||
                [];
              existingCondos.forEach((existingCondo) => {
                if (!newValues.includes(existingCondo)) {
                  tree[existingState][existingCity][existingDistrict] = tree[
                    existingState
                  ][existingCity][existingDistrict].filter(
                    (condo) => condo !== existingCondo
                  );
                }
              });

              newValues.forEach((newCondo) => {
                if (
                  referenceCondos.includes(newCondo) &&
                  !existingCondos.includes(newCondo)
                ) {
                  tree[existingState][existingCity][existingDistrict].push(
                    newCondo
                  );
                }
              });
            });
          });
        });
      }

      /* console.log(tree); */
      setDataInputs(tree);
    }
  }

  function handleOptions(type) {
    if (
      Object.keys(dataInputs).length !== 0 &&
      Object.keys(referenceTree).length !== 0
    ) {
      const tree = { ...dataInputs };

      if (type === "city") {
        if (Object.keys(tree).length === 0) {
          return ["Selecione uma cidade"];
        }
        return Object.keys(tree).flatMap((state) =>
          Object.keys(referenceTree[state]).map((city) => city)
        );
      } else if (type === "district") {
        if (Object.keys(tree).length === 0) {
          return ["Selecione um bairro"];
        }
        return Object.keys(tree).flatMap((state) =>
          Object.keys(tree[state]).flatMap((city) =>
            Object.keys(referenceTree[state][city]).map((district) => district)
          )
        );
      } else if (type === "condominium_name") {
        if (Object.keys(tree).length === 0) {
          return ["Selecione um condomínio"];
        }
        return Object.keys(tree).flatMap((state) =>
          Object.keys(tree[state]).flatMap((city) =>
            Object.keys(tree[state][city]).flatMap((district) =>
              referenceTree[state][city][district].map(
                (condominium) => condominium
              )
            )
          )
        );
      }
    } else {
      return [];
    }
  }

  /*   const getQueryString = () => {
    let statesList = `filter=state;in;${states}&`;
    console.log(statesList, "OIIIIIIdasdsadsadsad12312321321s");

    let citiesList = `filter=city;in;${cities}&`;

    let neighborhoodsList = `filter=district;in;${neighborhoods}&`;

    let condominiumsList = `filter=condominium_name;in;${condominiums}`;

    return { statesList, citiesList, neighborhoodsList, condominiumsList };
  }; */

  const fileSelectedHandler = (e) => {
    if (e.target.files && e.target.files[0]) {
      var img = document.createElement("img");

      img.onload = function () {
        /* console.log(this.width + " " + this.height); */
        const wr = Math.floor(this.width / 16);
        const hr = Math.floor(this.height / 9);
        if (hr !== wr) {
          setImgErrorText("O arquivo deve ser uma imagem de 16:9");
          setSelectedFile(null);
          return;
        }
        setImgErrorText("");
        setSelectedFile(e.target.files[0]);
      };

      var reader = new FileReader();
      reader.onloadend = function (ended) {
        img.src = ended.target.result;
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
    /* console.log(checked); */
  };

  async function HandleSubmit() {
    /* if (selectedFile === null) {
      toast.error("Preencha os campos corretamente");
      return;
    }*/

    setLoading(true);
    const result = findCategorizedBranches(dataInputs);
    //console.log(result, "OIIOIIODIOSAIDIaSIDHSAhdi8hDsa");
    var dataSubmit = {};
    if (allStates) {
      dataSubmit = {
        name,
        link,
      };
    } else {
      dataSubmit = {
        name,
        link,
        state: Object.keys(result.state),
        city: Object.keys(result.city),
        district: Object.keys(result.district),
        id_list: data
          .map((item) =>
            Object.keys(result.condominium_name).includes(item.condominium_name)
              ? item.id
              : null
          )
          .filter((item) => item !== null),
      };
    }
    if (selectedFile) {
      dataSubmit["header"] = `content-type:${selectedFile.type}`;
    }
    /* console.log(data, "dasdsadasdsad213213213213"); */
    const formData = new FormData();
    /* console.log(selectedFile); */
    if (selectedFile) {
      formData.append("media", selectedFile);
    }
    formData.append("data", JSON.stringify(dataSubmit));
    /* console.log(formData); */
    try {
      const response = await api.patch(`/advertising/${id}`, formData);
      /* console.log(response, " response"); */
      notifySucess();
      history.goBack();
    } catch (error) {
      /* console.log(error); */
      notifyError();
    }
  }

  return (
    <div id="homeContent">
      <Sidebar />

      <div className="ta">
        <div className="pageTitleContainerx">
          <ArrowBack />

          <p id="pageTitle">Gerência de Propaganda</p>
        </div>
        <div id="inputArea">
          <div id="content">
            <div>
              <p>Selecione os estados da propaganda</p>
              <div id="states">
                {/* {stateList.map((item) => (
                  <div id="stateOption" key={item.id}>
                    <input
                      type="checkbox"
                      value={item.value}
                      id={item.label}
                      onChange={(event) => handleCheck(event)}
                    ></input>
                    <label>{item.label}</label>
                  </div>
               ))}*/}
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={data
                    .map((item) => item.state)
                    .filter(
                      (value, index, self) => self.indexOf(value) === index
                    )}
                  disableCloseOnSelect
                  value={Object.keys(dataInputs)}
                  onChange={(event, newValue) => {
                    handleValueChange(newValue, "state");
                  }}
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <CustomCheckbox
                        selected={selected}
                        handleCheckboxChange={handleCheckboxChange}
                        option={option}
                      />
                      {option}
                    </li>
                  )}
                  style={{ width: 500 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Estados"
                    />
                  )}
                />

                {/* <div id="stateOption">
                  <input
                    type="checkbox"
                    value={allStates}
                    onChange={() => setAllStates(!allStates)}
                  ></input>
                  <label>Todos estados</label>
                </div>*/}
              </div>
            </div>

            <div>
              <p>Selecione as cidades da propaganda</p>
              <div id="states">
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={handleOptions("city")}
                  disableCloseOnSelect
                  value={Object.values(dataInputs).flatMap((cities) =>
                    Object.keys(cities)
                  )}
                  onChange={(event, newValue) => {
                    handleValueChange(newValue, "city");
                  }}
                  //inputValue={inputValue}
                  /*onInputChange={(event, newInputValue) => {
                    setStates(newInputValue);
                  }}*/
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <CustomCheckbox
                        selected={selected}
                        handleCheckboxChange={handleCheckboxChange}
                        option={option}
                      />
                      {option}
                    </li>
                  )}
                  style={{ width: 500 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Cidades"
                    />
                  )}
                />
              </div>
            </div>
            <div>
              <p>Selecione os bairros da propaganda</p>
              <div id="states">
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={handleOptions("district")}
                  disableCloseOnSelect
                  value={Object.values(dataInputs)
                    .flatMap((cities) => Object.values(cities))
                    .flatMap((districts) => Object.keys(districts))}
                  onChange={(event, newValue) => {
                    handleValueChange(newValue, "district");
                  }}
                  //inputValue={inputValue}
                  /*onInputChange={(event, newInputValue) => {
                    setStates(newInputValue);
                  }}*/
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <CustomCheckbox
                        selected={selected}
                        handleCheckboxChange={handleCheckboxChange}
                        option={option}
                      />
                      {option}
                    </li>
                  )}
                  style={{
                    width: 500,
                    overflow: "ellipsis",
                    maxLines: "1",
                    textOverflow: "ellipsis",
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Bairros"
                    />
                  )}
                />
              </div>
            </div>
            <div>
              <p>Selecione os condomínios da propaganda</p>
              <div id="states">
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={handleOptions("condominium_name")}
                  disableCloseOnSelect
                  value={Object.values(dataInputs)
                    .flatMap((cities) => Object.values(cities))
                    .flatMap((districts) => Object.values(districts))
                    .flatMap((condominiums) => condominiums)}
                  onChange={(event, newValue) => {
                    handleValueChange(newValue, "condominium_name");
                  }}
                  //inputValue={inputValue}
                  /*onInputChange={(event, newInputValue) => {
                    setStates(newInputValue);
                  }}*/
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <CustomCheckbox
                        selected={selected}
                        handleCheckboxChange={handleCheckboxChange}
                        option={option}
                      />
                      {option}
                    </li>
                  )}
                  style={{ width: 500 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Condomínios"
                    />
                  )}
                />
              </div>
            </div>
            <div id="propContainer">
              <p className="propagandaInput">Nome da propaganda</p>
              <input
                className="inputText"
                id="InputWarnTitle"
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </div>
            <div id="propContainer">
              <p className="propagandaInput">Link da propaganda</p>
              <input
                className="inputText"
                id="InputWarnTitle"
                value={link}
                onChange={(event) => setLink(event.target.value)}
              />
            </div>
            <div id="filePicker" style={{}}>
              <p>
                Arquivo de imagem <strong>(resolução 16:9)</strong>
              </p>
              <input type="file" onChange={fileSelectedHandler} />
              <p className="image-erro">{imgErrorText}</p>
              <button className="handleButton" onClick={HandleSubmit}>
                Enviar arquivo
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
