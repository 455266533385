import React from "react";
import { useHistory } from "react-router";
import "./index.css";

export default function ArrowBack() {
  const history = useHistory();

  return (
    <button onClick={(e) => history.goBack()} id="circle">
      <ul>
        <li id="back">
          <span></span>
        </li>
      </ul>
    </button>
  );
}
