import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import ArrowBack from "../../../../../Components/ResponsiveArrowBack";
import Sidebar from "../../../../../Components/SidebarManager";
import { Container, ContainerButton } from "./styles";
import NewSideBar from "../../../../../Components/NewComponents/NewSideBar";
import { useAuth } from "../../../../../Context/auth";
import React, { useState, useEffect } from "react";

export default function DivisionScreen({ links, pageTitle }) {
  const { user } = useAuth();
  let userJson = user;
  if (typeof user === "string") {
    userJson = JSON.parse(user);
  }
  const userModality = userJson.modality;

  const modalities = {
    admin: { rota: "admin", tipo: "Admin" },
    manager: { rota: "manager", tipo: "Síndico" },
    doorman: { rota: "doorman", tipo: "Porteiro" },
    maintenance: { rota: "maintenance", tipo: "Manutenção" },
    administration: { rota: "employee", tipo: "Administrativo" },
  };

  const { rota, tipo } = modalities[userModality] || { rota: "", tipo: "" };

  console.log("rota", rota);
  console.log("tipo", tipo);

  return (
    <>
      <Grid container style={{ display: "flex", width: "100vw" }}>
        <NewSideBar rota={rota} tipo={tipo}></NewSideBar>
        <Grid
          container
          item
          xs={7}
          style={{
            marginBottom: "40px",
            marginLeft: "56px",
            width: "100%",
            display: "flex",
          }}
        >
          <ContainerButton>
            <ArrowBack />
            <p id="pageTitle">{pageTitle}</p>
          </ContainerButton>
          <Container>
            {links.map((link, index) => (
              <Link
                key={index}
                to={{
                  pathname: link.pathname,
                }}
                style={{ textDecoration: "none", color: "black" }}
              >
                <button type="submit" className="handleButton">
                  <p>{link.text}</p>
                </button>
              </Link>
            ))}
          </Container>
        </Grid>
      </Grid>
    </>
  );
}
