import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../Context/auth";
import { Link, useHistory } from "react-router-dom";

//Service
import api from "../../../../Services/api";

//Toast
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Components

import Sidebar from "../../../../Components/SidebarAdmin";
import { InputWrap } from "../../Manager/Management/Legislation/AddLei/style.js";
//import SidebarOptions from "../../../../Components/SidebarOptions";
import ArrowBack from "../../../../Components/ResponsiveArrowBack";
//Icons
import SearchIcon from "@material-ui/icons/Search";
import "./index.css";
import { MdOutlineModeEditOutline } from "react-icons/md";
import NewSideBar from "../../../../Components/NewComponents/NewSideBar";

export default function Properties() {
  const history = useHistory();
  const notifyApprove = () => toast.success("Venda/locação aprovada!");
  const notifyDelete = () => toast.error("Venda/locação apagada!");
  const [procurar, setProcurar] = useState("");
  const [photos, setPhotos] = useState([]);
  const [filter, setFilter] = useState("owner_email");
  const [aprovados, setAprovados] = useState(false);
  const [properties, setProperties] = useState([]);
  const [photosA, setPhotosA] = useState([]);
  const [propertiesA, setPropertiesA] = useState([]);
  const { user } = useAuth();
  const infos = localStorage.getItem("@SWCondominio:user");

  // useEffect(() => {
  //   getData();
  //   return () => {
  //     setProperties([]);
  //   };
  // }, []);

  /* {
      owner_email: "igorcarvalhh@gmail.com",
      owner_contact: "21988150626",
      description:
        "Casa duplex com varanda, 2 Salas,4 quartos (suíte), sendo 2quartos em cada andar,cozinha, 3 banheiros, area de serviço coberta, terraço com vista páramo mar, garagem, pequeno quintal e cisterna. Não tem RGI. Posse antiga",
      photos: [
        "https://plantasdecasas.com/wp-content/uploads/2017/07/planta-fachada-casa-115-fr-AG.jpg",
        "https://system.soprojetos.com.br/files/1290/og_image/casa-terrea-2-quartos-com-cozinha-americana-cod-170-fachada.jpg?1558649233",
      ],
    },*/

  //Pegar advertising do backend

  async function getData() {
    try {
      const response = await api.get("/properties/inactive");
      const data = response.data;
      const responsePhoto = await api.get("properties/photos");
      setProperties(data);
      setPhotos(responsePhoto.data);
      console.log(response.data);

      const responseA = await api.get("/properties");
      const dataA = responseA.data;
      const responsePhotoA = await api.get("properties/photos");
      setPropertiesA(dataA);
      setPhotosA(responsePhotoA.data);
      console.log(responseA.data);
    } catch (error) {
      throw error;
    }
  }

  useEffect(() => {
    getData();
  }, []);

  //Aprovar properties
  async function approveAdvert(ad_id) {
    try {
      const formData = new FormData();
      formData.append(
        "data",
        JSON.stringify({
          active: true,
        })
      );
      const response = await api.patch(`/properties/${ad_id}`, formData);
      setProperties(properties.filter((advert) => advert.id !== ad_id));
      notifyApprove();
      console.log(response);
      getData();
    } catch (error) {
      throw error;
    }
  }

  //Deletar properties
  async function deleteAdvert(ad_id) {
    try {
      const response = await api.delete(`/properties/${ad_id}`);
      setProperties(properties.filter((advert) => advert.id !== ad_id));
      notifyDelete();
      console.log(response);
      getData();
    } catch (error) {
      throw error;
    }
  }

  function handleSubmit(id) {
    history.push({ pathname: `/admin/EditarImoveis/${id}` });
  }

  return (
    <div id="homeContent" style={{ width: "100%" }}>
      <NewSideBar tipo={"Empresa Imobiliária"} rota={"imobiliaria"} />
      <div id="safeArea" style={{ width: "100%" }}>
        <div className="TitleContainer" id="x2">
          <div className="Title">
            <ArrowBack />
            <p id="pageTitle">Venda e Locação</p>
          </div>
        </div>

        <div id="listArea2">
          <div id="TopContainer" style={{ marginLeft: "11vw" }}>
            <div style={{ display: "flex" }}>
              {filter ? (
                <div id="searchContainer">
                  <SearchIcon />
                  <input
                    id="searchInput"
                    placeholder="Pesquisar"
                    onChange={(event) => {
                      setProcurar(event.target.value);
                    }}
                  />
                </div>
              ) : null}
              <button
                style={{
                  border: "none",
                  borderRadius: "20px",
                  backgroundColor: "#00334E",
                  color: "white",
                  padding: "4px 12px",
                  marginLeft: "5px",
                  width: "100px",
                  height: "50px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setAprovados(!aprovados);
                }}
              >
                <p>
                  <b>{aprovados ? "Aprovados" : "Para aprovação"}</b>
                </p>
              </button>
              <InputWrap style={{ marginBottom: "32px", marginLeft: "10px" }}>
                <select
                  style={{
                    border: "none",
                    borderLeft: "4px solid #00334E",
                    borderRadius: "20px",
                    backgroundColor: "#f8f8f8",
                    padding: "4px 12px",
                  }}
                  placeholder="None"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                >
                  <option value="owner_email">E-mail</option>
                  <option value="owner_contact">Contato (telefone)</option>
                  <option value="vendaLocacao">Serviço</option>
                  <option value="description">Descrição</option>
                </select>
              </InputWrap>
            </div>
            {/* <Link
              to={{
                pathname: "/admin/propaganda/create",
                state: { data: "test" },
              }}
              params={{ test: "hello" }}
              style={{ textDecoration: "none", color: "black" }}
            >
              <div id="WarnAddButton">
                <p>Criar propaganda</p>
              </div> 
            </Link>*/}
          </div>
          <div id="advertisingList" style={{ marginLeft: "11vw" }}>
            {aprovados ? (
              propertiesA || propertiesA.lenght === 0 ? (
                propertiesA
                  .filter((advert) => {
                    if (procurar === "") {
                      return advert;
                    } else if (
                      advert?.owner_email
                        .toLowerCase()
                        .includes(procurar.toLowerCase()) &&
                      filter === "owner_email"
                    ) {
                      return advert;
                    } else if (
                      advert?.owner_contact
                        .toLowerCase()
                        .includes(procurar.toLowerCase()) &&
                      filter === "owner_contact"
                    ) {
                      return advert;
                    } else if (
                      advert?.vendaLocacao
                        .toLowerCase()
                        .includes(procurar.toLowerCase()) &&
                      filter === "vendaLocacao"
                    ) {
                      return advert;
                    } else if (
                      advert?.description
                        .toLowerCase()
                        .includes(procurar.toLowerCase()) &&
                      filter === "description"
                    ) {
                      return advert;
                    }
                  })
                  .map((advert, i) => (
                    <div className="data-container" key={advert?.id}>
                      <div
                        id="data-safe-area"
                        className="properties-data-safe-area"
                      >
                        <div className="WarnTitle">
                          E-mail: {advert?.owner_email}
                        </div>
                        <div className="WarnTitle">
                          Contato: {advert?.owner_contact}
                        </div>
                        <div className="WarnTitle">
                          Tipo de Serviço: {advert?.vendaLocacao}
                        </div>
                        <p>
                          {advert?.description
                            .split("\n")
                            .map((discriptions) => (
                              <p>{discriptions}</p>
                            ))}
                        </p>

                        {
                          <div className="manager-name-title properties-photos-list">
                            {photos[`${i}`] ? (
                              photos[`${i}`].map((url, i) => {
                                return (
                                  <>
                                    <a
                                      style={{ color: "#EE6F57" }}
                                      key={i}
                                      href={url}
                                      title={url}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      Ver mídia ({i + 1})
                                    </a>
                                    <p>, </p>
                                  </>
                                );
                              })
                            ) : (
                              <p>Não há mídia</p>
                            )}
                          </div>
                        }
                        <div className="properties-btn-wrapp">
                          <button
                            id="propertiesApproveButton"
                            onClick={() => {
                              deleteAdvert(advert?.id);
                            }}
                          >
                            Deletar
                          </button>
                          <button
                            id="propertiesApproveButton"
                            onClick={() => {
                              handleSubmit(advert.id);
                            }}
                          >
                            Editar
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
              ) : (
                <p>A lista de venda e locação está vazia.</p>
              )
            ) : properties || properties.lenght === 0 ? (
              properties
                .filter((advert) => {
                  if (procurar === "") {
                    return advert;
                  } else if (
                    advert?.owner_email
                      .toLowerCase()
                      .includes(procurar.toLowerCase()) &&
                    filter === "owner_email"
                  ) {
                    return advert;
                  } else if (
                    advert?.owner_contact
                      .toLowerCase()
                      .includes(procurar.toLowerCase()) &&
                    filter === "owner_contact"
                  ) {
                    return advert;
                  } else if (
                    advert?.vendaLocacao
                      .toLowerCase()
                      .includes(procurar.toLowerCase()) &&
                    filter === "vendaLocacao"
                  ) {
                    return advert;
                  } else if (
                    advert?.description
                      .toLowerCase()
                      .includes(procurar.toLowerCase()) &&
                    filter === "description"
                  ) {
                    return advert;
                  }
                })
                .map((advert, i) => (
                  <div className="data-container" key={advert?.id}>
                    <div
                      id="data-safe-area"
                      className="properties-data-safe-area"
                    >
                      <div className="WarnTitle">
                        E-mail: {advert?.owner_email}
                      </div>
                      <div className="WarnTitle">
                        Contato: {advert?.owner_contact}
                      </div>
                      <div className="WarnTitle">
                        Tipo de Serviço: {advert?.vendaLocacao}
                      </div>
                      <p>
                        {advert?.description.split("\n").map((discriptions) => (
                          <p>{discriptions}</p>
                        ))}
                      </p>

                      {
                        <div className="manager-name-title properties-photos-list">
                          {photos[`${i}`] ? (
                            photos[`${i}`].map((url, i) => {
                              return (
                                <>
                                  <a
                                    style={{ color: "#EE6F57" }}
                                    key={i}
                                    href={url}
                                    title={url}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Ver mídia ({i + 1})
                                  </a>
                                  <p>, </p>
                                </>
                              );
                            })
                          ) : (
                            <p>Não há mídia</p>
                          )}
                        </div>
                      }
                      <div className="properties-btn-wrapp">
                        <button
                          id="propertiesDeclineButton"
                          onClick={() => {
                            approveAdvert(advert?.id);
                          }}
                        >
                          Aprovado
                        </button>

                        <button
                          id="propertiesApproveButton"
                          onClick={() => {
                            deleteAdvert(advert?.id);
                          }}
                        >
                          Reprovado
                        </button>
                      </div>
                    </div>
                  </div>
                ))
            ) : (
              <p>A lista de venda e locação está vazia.</p>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
