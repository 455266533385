import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Sidebar from "../../../../Components/SidebarAdmin";
import ArrowBack from "../../../../Components/ResponsiveArrowBack";
import { Formik } from "formik";
import Loading from "../../../../Components/Loading/index";
import Select from "react-select";
import profilePhoto from "../../../../Assets/unnamed.png";
import "./index.css";
import userEvent from "@testing-library/user-event";
import { useAuth } from "../../../../Context/auth";
import api from "../../../../Services/api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ProfileAdmin() {
  const { user } = useAuth();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const notifySucess = () => toast.success("Perfil editado com sucesso!");
  const notifyError = () => toast.error("Erro ao editar perfil!");
  const notifyError2 = () => toast.error("Perfil deletado");
  const infos = localStorage.getItem("@SWCondominio:user");
  const [handleFile, setHandleFile] = useState("");
  const notifyFetchError = () =>
    toast.error(
      "Houve uma falha ao obtender os dados. Tente novamente mais tarde"
    );

  async function handleSubmit() {
    setLoading(true);
    const body = {
      name,
      email,
    };
    const data = { ...body };
    console.log(data);
    let completeObj = {
      ...data,
    };
    console.log(completeObj);

    const infos = localStorage.getItem("@SWCondominio:user");

    await api
      .patch(`/admin/${JSON.parse(infos).id}`, completeObj)
      .then(() => {
        notifySucess();
        history.push("/admin/home");
      })
      .catch(() => {
        notifyError();
      });

    console.log(name);
    setLoading(false);
  }

  function getProfileData() {
    console.log(user);
    const infos = localStorage.getItem("@SWCondominio:user");
    console.log(JSON.parse(infos), " infos");
    api.get(`/admin/${JSON.parse(infos).id}`).then((response) => {
      setName(response.data.name);
      setEmail(response.data.email);
      console.log(user, "user");
    });
  }

  useEffect(() => {
    getProfileData();
  }, []);

  useEffect(() => {
    async function getProfileData() {
      console.log("Oi");
      try {
        const response = await api.get(`/admin/${JSON.parse(infos).id}`);
        console.log(response.data, "teste1");
        setHandleFile(response.data.photo_url);
      } catch (error) {
        console.log(error, "teste");
      }
    }
    getProfileData();
    console.log(handleFile, "asdsad");
  }, []);

  return (
    <div id="homeContent">
      <Sidebar />
      <div id="WarnSafeArea" style={{ marginLeft: "11vw" }}>
        <div className="pageTitleContainer" id="x">
          <ArrowBack />

          <p id="pageTitle">Meu Perfil</p>
        </div>
        <div id="listArea">
          <div id="sidebarTopInfo" style={{ marginLeft: "2vw" }}>
            <div id="picPerfil">
              <img src={handleFile ? handleFile : profilePhoto} id="logo" />
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div id="warnContainer">
              <p className="registerInputTitle">Nome</p>

              <input
                className="inputText"
                id="InputWarnTitle"
                value={name}
                onChange={(text) => setName(text.target.value)}
              />

              <p className="registerInputTitle">Email</p>

              <input
                className="inputText"
                id="InputWarnTitle"
                value={email}
                onChange={(text) => setEmail(text.target.value)}
              />

              {loading ? (
                <Loading />
              ) : (
                <button
                  type="submit"
                  className="handleButton"
                  onClick={handleSubmit}
                >
                  <p>Atualizar</p>
                </button>
              )}
            </div>
          </form>
          <div style={{ height: "8vh" }}></div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
