import styled from "styled-components";

export const Divrow = styled.div`
display: flex;
align-items: center;
@media (max-width:490px){
  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;
}

`

export const H1text = styled.h1`
padding-left: 40px;
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;

`