import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import Select from "react-select";
import { DropzoneDialog } from "material-ui-dropzone";

// Service
import api from "../../../../Services/api";

// Toast
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Components
import Loading from "../../../../Components/Loading/index";
import Sidebar from "../../../../Components/SidebarManager";
import ArrowBack from "../../../../Components/ResponsiveArrowBack";

// Style
import "./index.css";
import "./create.css";

const initialForm = {
  name: "",
  media: null,
  media_name: "",
};

const modalities = [
  { value: "resident", label: "Morador comum" },
  { value: "manager", label: "Síndico" },
  { value: "maintenance", label: "Funcionário da administração" },
  { value: "doorman", label: "Porteiro" },
  { value: "delivery", label: "Entregador" },
  { value: "other", label: "Serviços externos" },
];

export default function DocumentCreatePage() {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const [permittedUsers, setPermittedUsers] = useState([]);
  const [permitedModalities, setPermitedModalities] = useState([]);
  const [residents, setResidents] = useState([]);
  const [description, setDescription] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const { id } = useParams();

  const notifySucess = () => toast.success(`Cadastro realizado com sucesso!`);
  const notifyError = () => toast.error("Erro ao cadastrar documento!");
  const todositens = { value: "Todos", label: "Todos" };

  useEffect(() => {
    const getResidents = async () => {
      const list = (await api.get("/resident")).data;
      setResidents(
        list.map(({ id, name, modality }) => ({
          value: { id, name, modality },
          label: name,
        }))
      );
      console.log(list);
    };
    getResidents();
  }, []);

  const handleSubmit = async () => {
    if (!file) {
      toast.warn("Favor, selecionar arquivo");
      return;
    }
    setLoading(true);
    try {
      const data = {
        permited: permittedUsers,
        modalities: permitedModalities,
        description: description,
      };
      const formData = new FormData();
      formData.append("media", file);
      formData.append("data", JSON.stringify(data));

      if (!id) await api.post("/document", formData);
      else await api.patch(`/document/${id}`, formData);
      notifySucess();
      history.goBack();
    } catch (e) {
      notifyError();
    }
    setLoading(false);
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleFileSave = (files) => {
    setFile(files[0]);
    setOpenModal(false);
  };

  return (
    <Grid container>
      {/* <Grid item xs={3}>
      </Grid>*/}
      <Sidebar />
      <Grid
        container
        item
        xs={8}
        style={{ marginBottom: "40px", marginLeft: "33vw" }}
      >
        <Grid item xs={12}>
          <div className="spacePageTitleContainer">
            <ArrowBack />
            <p className="pageTitle">Cadastrar novo documento</p>
          </div>
        </Grid>
        <Grid container spacing={2} justify="space-between">
          <form handleSubmit={handleSubmit}>
            <Grid container spacing={2} justify="space-between">
              <Grid item xs={12}>
                <div className="formikFieldArea">
                  <label className="formikFieldLabel" htmlFor="mediaUpload">
                    Arquivo: {file ? file.name : "não selecionado"}
                  </label>
                  <label onClick={handleOpen} className="formikFieldSelectFile">
                    {file ? "Alterar arquivo" : "Adicionar arquivo"}
                  </label>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="formikFieldArea">
                  <label className="formikFieldLabel" htmlFor="mediaUpload">
                    Usuários que podem acessar
                  </label>
                  <Select
                    options={[todositens, ...residents]}
                    isMulti
                    className="react-select-container"
                    classNamePrefix="react-select"
                    onChange={(selection) => {
                      setPermittedUsers(
                        selection.includes(todositens)
                          ? residents.map((x) => x.value)
                          : selection.map((x) => x.value)
                      );
                    }}
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="formikFieldArea">
                  <label className="formikFieldLabel" htmlFor="mediaUpload">
                    Modalidades que podem acessar
                  </label>
                  <Select
                    options={[todositens, ...modalities]}
                    isMulti
                    className="react-select-container"
                    classNamePrefix="react-select"
                    onChange={(selection) => {
                      setPermitedModalities(
                        selection.includes(todositens)
                          ? modalities.map((x) => x.value)
                          : selection.map((x) => x.value)
                      );
                    }}
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="formikFieldArea">
                  <label className="formikFieldLabel" htmlFor="mediaUpload">
                    Descrição do documento
                  </label>
                  <textarea
                    style={{
                      backgroundColor: "rgba(72, 219, 251, 0.3)",
                      width: "97%",
                      height: "100px",
                      border: "none",
                      borderRadius: "10px",
                      padding: "10px",
                    }}
                    onChange={(text) => {
                      setDescription(text.target.value);
                    }}
                    fullWidth
                  />
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={5}
              style={{ justifyContent: "flex-end", padding: "0.5rem" }}
            >
              <Grid item>
                {loading ? (
                  <div id="loadingBox">
                    <Loading />
                  </div>
                ) : (
                  <button
                    type="submit"
                    className="formikSubmitButton"
                    onClick={handleSubmit}
                  >
                    <p className="formikSubmitButtonText">Cadastrar</p>
                  </button>
                )}
              </Grid>
            </Grid>
          </form>
        </Grid>
        <DropzoneDialog
          open={openModal}
          onSave={handleFileSave}
          maxFileSize={50000000}
          onClose={handleClose}
          filesLimit={1}
          dropzoneText="Arraste ou clique para inserir um arquivo"
          dialogTitle="Selecionar arquivo"
          cancelButtonText="Cancelar"
          submitButtonText={file ? "Alterar" : "Adicionar"}
          showPreviews={false}
          alertSnackbarProps={{
            anchorOrigin: {
              horizontal: "center",
              vertical: "bottom",
            },
          }}
          onChange={handleFileSave}
        />
      </Grid>
    </Grid>
  );
}
