import { useState, useEffect } from "react";
import PlusIcon from "../../../../../../Assets/plus-icon.png";
import ContratoCard from "../ContratoCard";
import AddContrato from "../AddContrato";
import api from "../../../../../../Services/api";
import SearchIcon from "@material-ui/icons/Search";

function ContratosModal() {
  const [contratos, setContratos] = useState([]);
  const [procurar, setProcurar] = useState("");

  async function getContratos() {
    const res = await api.get("/archive");
    console.log("OIIIIIIIIII", res);
    return res;
  }

  const fetch = async () => {
    const res = await getContratos();
    setContratos(res.data);
  };

  useEffect(() => {
    fetch();
  }, []);

  const list = [{}];

  const [isVisible, setIsVisible] = useState(false);

  function handleAddMntc(lei) {
    console.log(list);
  }

  const displayList = contratos
        .filter((contrato) => {
          if (procurar == "") {
            return contrato;
          } else if (
            contrato?.name.toLowerCase().includes(procurar.toLowerCase())
          ) {
            return contrato;
          }
        })
        .sort((contract1, contract2) => new Date(contract2.create_time) - new Date(contract1.create_time)) // sort by create_time in descending order
        .map((contrato) => (
          <ContratoCard fetch={fetch} data={contrato}>
            {" "}
          </ContratoCard>
        ));

  return (
    <div className="managementModalContainer">
      <h1>Contratos</h1>
      <div className="mngm-content">
        <div className="mngm-add-manutencao" onClick={() => setIsVisible(true)}>
          <img src={PlusIcon}></img>
          <span>Novo contrato</span>
        </div>
        <h2 className="mngm-mntc-eqp-title"> Contratos</h2>
        <div id="searchContainer">
          <SearchIcon />
          <input
            id="searchInput"
            placeholder="Pesquisar"
            onChange={(event) => {
              setProcurar(event.target.value);
            }}
          />
        </div>
        <div className="mngm-mntc-eqp-list">{displayList}</div>
      </div>
      <AddContrato
        handleAddMntc={fetch}
        isVisible={isVisible}
        changeVisibility={setIsVisible}
      ></AddContrato>
    </div>
  );
}

export default ContratosModal;
